import React, { FC, useEffect, useState } from 'react';
import { useSearch } from '../../../../../hooks/useSearch';
import { useLocation, useNavigate } from 'react-router-dom';
import { Content, Root, Trigger } from '@radix-ui/react-popover';
import { SearchResult } from '../../../SearchResult/SearchResult';
import { Search } from '../../../Search/Search';
import s from './HeaderSearch.module.scss';
import clsx from 'clsx';

interface OwnProps {
  openSearch: boolean;
}

export const HeaderSearch: FC<OwnProps> = function HeaderSearch(props) {
  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();

  const [search, setSearch] = useState(new URLSearchParams(locationSearch).get('query') || '');
  const { searchResults, debouncedGetSearchResults } = useSearch(1, 3);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!search && !!searchResults);
  }, [search, searchResults]);

  const handleInputChange = (value: string) => {
    setSearch(value);
    debouncedGetSearchResults(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || !search) return;

    setIsOpen(false);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('query', search);

    navigate(`/search/?${searchParams.toString()}`);
  };

  return (
    <div className={clsx(s.searchWrapper, props.openSearch && s.open)}>
      <Root open={isOpen} onOpenChange={setIsOpen}>
        <Trigger asChild={true}>
          <div className={s.search}>
            <Search isFilled={!!search} clear={() => setSearch('')} isInHeader={true}>
              <input
                value={search}
                placeholder={'Поиск'}
                onChange={e => handleInputChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </Search>
          </div>
        </Trigger>
        <Content
          onOpenAutoFocus={e => e.preventDefault()}
          onCloseAutoFocus={e => e.preventDefault()}
        >
          {!!search && !!searchResults && (
            <SearchResult results={searchResults} onMenuItemClick={() => setIsOpen(false)} />
          )}
        </Content>
      </Root>
    </div>
  );
};
