import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { throwError } from '../../../../utils/errorThrower';
import {
  ServiceStationDto,
  ServiceStationsControllerGetServiceStationsTypesEnum,
} from '../../../../api/generated/caready';
import { useFilteredServiceStations } from '../../../../hooks/useFilteredServiceStations';
import { useUtm } from '../../../../hooks/useUtm';

interface MapContextType {
  address: string;
  setAddress: (value: string) => void;
  serviceStations: ServiceStationDto[];
  serviceStationsBySlugs: ServiceStationDto[];
  selectedCategories: string[];
  setSelectedCategories: (value: string[]) => void;
  stationTypes?: ServiceStationsControllerGetServiceStationsTypesEnum[];
  selectedServiceStation: ServiceStationDto | null;
  setSelectedServiceStation: (value: ServiceStationDto | null) => void;
}

interface OwnProps {
  serviceCategories?: string[];
  stationTypes?: ServiceStationsControllerGetServiceStationsTypesEnum[];
  initialSelectedServiceStation?: ServiceStationDto;
}

export const MapContext = createContext<MapContextType | null>(null);

export const useMap = () =>
  useContext(MapContext) ?? throwError('useMap can be used only inside MapProvider');

export const MapProvider: FC<PropsWithChildren<OwnProps>> = function MapProvider(props) {
  const [address, setAddress] = useState<string>('');
  const [selectedServiceStation, setSelectedServiceStation] = useState<ServiceStationDto | null>(
    props.initialSelectedServiceStation || null
  );
  const { utmData } = useUtm();

  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    props.serviceCategories ?? []
  );

  const serviceStations = useFilteredServiceStations({
    types: props.stationTypes,
    address: address,
    serviceCategories:
      !props.stationTypes || (props.stationTypes && props.stationTypes[0] === 'service-station')
        ? selectedCategories
        : undefined,
    electricChargerTypes:
      props.stationTypes && props.stationTypes[0] === 'electric-charger'
        ? selectedCategories
        : undefined,
  });

  const serviceStationsBySlugs =
    utmData?.slugs?.length === 2
      ? serviceStations.filter(it => !!utmData.slugs?.find(slug => slug === it.slug)?.length)
      : serviceStations;

  useEffect(() => {
    setSelectedCategories(props.serviceCategories ?? []);
  }, [props.stationTypes]);

  return (
    <MapContext.Provider
      value={{
        address,
        setAddress,
        serviceStations,
        serviceStationsBySlugs,
        selectedCategories,
        setSelectedCategories,
        stationTypes: props.stationTypes,
        selectedServiceStation,
        setSelectedServiceStation,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};
