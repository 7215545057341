/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { SendTradeInRequestDto } from '../dtos';
// @ts-ignore
import type { TradeInQuestionnaireQuestionDto } from '../dtos';
// @ts-ignore
import type { TradeInRequestAttachedCarPhotoDto } from '../dtos';
/**
 * TradeInRequestsApi - axios parameter creator
 * @export
 */
export const TradeInRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get trade-in questionnaire questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeInRequestsControllerGetQuestionnaire: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trade-in/requests/questionnaire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authorization is optional
         * @summary Send a trade-in request
         * @param {SendTradeInRequestDto} sendTradeInRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeInRequestsControllerSendTradeInRequest: async (sendTradeInRequestDto: SendTradeInRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendTradeInRequestDto' is not null or undefined
            assertParamExists('tradeInRequestsControllerSendTradeInRequest', 'sendTradeInRequestDto', sendTradeInRequestDto)
            const localVarPath = `/trade-in/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendTradeInRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a car photo to attach it to the trade-in request
         * @param {TradeInRequestsControllerUploadCarPhotoCarSideEnum} carSide Side of the car
         * @param {File} file Photo of the car
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeInRequestsControllerUploadCarPhoto: async (carSide: TradeInRequestsControllerUploadCarPhotoCarSideEnum, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'carSide' is not null or undefined
            assertParamExists('tradeInRequestsControllerUploadCarPhoto', 'carSide', carSide)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('tradeInRequestsControllerUploadCarPhoto', 'file', file)
            const localVarPath = `/trade-in/requests/car-photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (carSide !== undefined) { 
                localVarFormParams.append('carSide', carSide as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradeInRequestsApi - functional programming interface
 * @export
 */
export const TradeInRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TradeInRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get trade-in questionnaire questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeInRequestsControllerGetQuestionnaire(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeInQuestionnaireQuestionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeInRequestsControllerGetQuestionnaire(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TradeInRequestsApi.tradeInRequestsControllerGetQuestionnaire']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Authorization is optional
         * @summary Send a trade-in request
         * @param {SendTradeInRequestDto} sendTradeInRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeInRequestsControllerSendTradeInRequest(sendTradeInRequestDto: SendTradeInRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeInRequestsControllerSendTradeInRequest(sendTradeInRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TradeInRequestsApi.tradeInRequestsControllerSendTradeInRequest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload a car photo to attach it to the trade-in request
         * @param {TradeInRequestsControllerUploadCarPhotoCarSideEnum} carSide Side of the car
         * @param {File} file Photo of the car
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeInRequestsControllerUploadCarPhoto(carSide: TradeInRequestsControllerUploadCarPhotoCarSideEnum, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeInRequestAttachedCarPhotoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeInRequestsControllerUploadCarPhoto(carSide, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TradeInRequestsApi.tradeInRequestsControllerUploadCarPhoto']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TradeInRequestsApi - factory interface
 * @export
 */
export const TradeInRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TradeInRequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get trade-in questionnaire questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeInRequestsControllerGetQuestionnaire(options?: any): AxiosPromise<Array<TradeInQuestionnaireQuestionDto>> {
            return localVarFp.tradeInRequestsControllerGetQuestionnaire(options).then((request) => request(axios, basePath));
        },
        /**
         * Authorization is optional
         * @summary Send a trade-in request
         * @param {SendTradeInRequestDto} sendTradeInRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeInRequestsControllerSendTradeInRequest(sendTradeInRequestDto: SendTradeInRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.tradeInRequestsControllerSendTradeInRequest(sendTradeInRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a car photo to attach it to the trade-in request
         * @param {TradeInRequestsControllerUploadCarPhotoCarSideEnum} carSide Side of the car
         * @param {File} file Photo of the car
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeInRequestsControllerUploadCarPhoto(carSide: TradeInRequestsControllerUploadCarPhotoCarSideEnum, file: File, options?: any): AxiosPromise<TradeInRequestAttachedCarPhotoDto> {
            return localVarFp.tradeInRequestsControllerUploadCarPhoto(carSide, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradeInRequestsApi - interface
 * @export
 * @interface TradeInRequestsApi
 */
export interface TradeInRequestsApiInterface {
    /**
     * 
     * @summary Get trade-in questionnaire questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeInRequestsApiInterface
     */
    tradeInRequestsControllerGetQuestionnaire(options?: RawAxiosRequestConfig): AxiosPromise<Array<TradeInQuestionnaireQuestionDto>>;

    /**
     * Authorization is optional
     * @summary Send a trade-in request
     * @param {SendTradeInRequestDto} sendTradeInRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeInRequestsApiInterface
     */
    tradeInRequestsControllerSendTradeInRequest(sendTradeInRequestDto: SendTradeInRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Upload a car photo to attach it to the trade-in request
     * @param {TradeInRequestsControllerUploadCarPhotoCarSideEnum} carSide Side of the car
     * @param {File} file Photo of the car
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeInRequestsApiInterface
     */
    tradeInRequestsControllerUploadCarPhoto(carSide: TradeInRequestsControllerUploadCarPhotoCarSideEnum, file: File, options?: RawAxiosRequestConfig): AxiosPromise<TradeInRequestAttachedCarPhotoDto>;

}

/**
 * TradeInRequestsApi - object-oriented interface
 * @export
 * @class TradeInRequestsApi
 * @extends {BaseAPI}
 */
export class TradeInRequestsApi extends BaseAPI implements TradeInRequestsApiInterface {
    /**
     * 
     * @summary Get trade-in questionnaire questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeInRequestsApi
     */
    public tradeInRequestsControllerGetQuestionnaire(options?: RawAxiosRequestConfig) {
        return TradeInRequestsApiFp(this.configuration).tradeInRequestsControllerGetQuestionnaire(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authorization is optional
     * @summary Send a trade-in request
     * @param {SendTradeInRequestDto} sendTradeInRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeInRequestsApi
     */
    public tradeInRequestsControllerSendTradeInRequest(sendTradeInRequestDto: SendTradeInRequestDto, options?: RawAxiosRequestConfig) {
        return TradeInRequestsApiFp(this.configuration).tradeInRequestsControllerSendTradeInRequest(sendTradeInRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a car photo to attach it to the trade-in request
     * @param {TradeInRequestsControllerUploadCarPhotoCarSideEnum} carSide Side of the car
     * @param {File} file Photo of the car
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeInRequestsApi
     */
    public tradeInRequestsControllerUploadCarPhoto(carSide: TradeInRequestsControllerUploadCarPhotoCarSideEnum, file: File, options?: RawAxiosRequestConfig) {
        return TradeInRequestsApiFp(this.configuration).tradeInRequestsControllerUploadCarPhoto(carSide, file, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const TradeInRequestsControllerUploadCarPhotoCarSideEnum = {
    Front: 'front',
    Back: 'back',
    Right: 'right',
    Left: 'left',
    Interior: 'interior'
} as const;
export type TradeInRequestsControllerUploadCarPhotoCarSideEnum = typeof TradeInRequestsControllerUploadCarPhotoCarSideEnum[keyof typeof TradeInRequestsControllerUploadCarPhotoCarSideEnum];
