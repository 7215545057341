import { Appointment } from '../../../ui/modal/Appointment';
import { PrimaryService } from '../../../ui/components/ServiceCard/PrimaryService';
import { useModal } from '../../../hooks/useModal';
import s from './PromoPage.module.scss';
import { SlugWrapper } from '../../../ui/components/SlugWrapper/SlugWrapper';
import { WYSIWYG } from '../../../ui/base/WYSIWYG';
import clsx from 'clsx';
import { FC } from 'react';
import { PromotionPageDto } from '../../../api/generated/caready';
import { PromotionsApi } from '../../../api';
import { format } from 'date-fns';

const PageTitle: FC<PromotionPageDto> = function PageTitle(props) {
  const { toggleModal, isShowing } = useModal();

  return (
    <>
      {props?.endsAt && (
        <span className={clsx(s.tag, 'tag', 'p3')}>
          До {format(new Date(props.endsAt), 'dd.MM.yyyy')}
        </span>
      )}
      <h1 className={clsx('h2', s.mainTitle)}>{props.headline}</h1>
      <button className="btn" onClick={toggleModal}>
        Записаться на сервис
      </button>
      <Appointment
        isOpen={isShowing}
        promotedServices={props.promotedServices}
        onCloseButtonClick={toggleModal}
      />
    </>
  );
};

const PageContent: FC<PromotionPageDto> = function PageContent(props) {
  const { toggleModal, isShowing } = useModal();

  return (
    <>
      <div className={s.mainBlock}>
        <div className={s.pageContent}>
          <picture>
            <img src={props.coverImageUrl} />
          </picture>
          <WYSIWYG html={props.content} />
          <button className={'btn'} onClick={toggleModal}>
            Записаться на сервис
          </button>
        </div>
      </div>
      {!!props.serviceCategories.length && (
        <div className={s.lowerBlock}>
          <h4 className={'h4'}>Услуги</h4>
          <div className={s.lowerCards}>
            {props.serviceCategories.map((category, index) => (
              <PrimaryService key={index} category={category} />
            ))}
          </div>
        </div>
      )}
      <Appointment
        isOpen={isShowing}
        promotedServices={props.promotedServices}
        onCloseButtonClick={toggleModal}
      />
    </>
  );
};

const parentPage = {
  name: 'Акции',
  path: '/promo',
};

export const PromoPage = function PromoPage() {
  const getPromotionPageBySlug = async (slug: string): Promise<PromotionPageDto> => {
    const { data } = await PromotionsApi.promotionsControllerGetPublishedPromotionPageBySlug(slug);
    return data;
  };

  return (
    <SlugWrapper<PromotionPageDto, 'headline'>
      request={getPromotionPageBySlug}
      parentPage={parentPage}
      PageContent={PageContent}
      PageTitle={PageTitle}
      titleName={'headline'}
    />
  );
};
