import { FC } from 'react';

interface OwnProps {
  onClose: () => void;
}

export const SuccessQuickAppointment: FC<OwnProps> = function SuccessQuickAppointment(props) {
  return (
    <>
      <header className={'modal_header'}>
        <h4 className={'h4'}>Заявка успешно отправлена</h4>
      </header>
      <div className={'modal_container'}>
        <div className={'modal_inner'}>
          <p className={'p2'}>Мы свяжемся с вами в ближайшее время для уточнения деталей записи</p>
        </div>
        <footer className={'modal_footer'}>
          <button className={'btn'} onClick={props.onClose}>
            Закрыть
          </button>
        </footer>
      </div>
    </>
  );
};
