import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { AuthErrorEnum } from '../../../../config/auth-error.enum';
import { ERROR_EMAIL, ERROR_REQUIRED } from '../../../../config/errorTexts';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Input } from '../../../base/Input';
import { ModalClose } from '../../../base/ModalBase';
import { getErrorMessage } from '../../../../utils/getErrorMessage';
import { object, string } from 'yup';
import React, { FC, useState } from 'react';
import classes from './recover-password.module.css';
import { AuthApi } from '../../../../api';

interface FormValues {
  email: '';
}

const initialValues: FormValues = {
  email: '',
};

const validationSchema = object().shape({
  email: string().email(ERROR_EMAIL).required(ERROR_REQUIRED),
});

interface OwnProps {
  toVerifyPassword: () => void;
  toPrevStep: () => void;
  setEmail: (e: string) => void;
}

export const ForgotPassword: FC<OwnProps> = function ForgotPassword({
  toVerifyPassword,
  toPrevStep,
  setEmail,
}) {
  const [error, setError] = useState('');

  const onSubmit = async ({ email }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    try {
      await AuthApi.authControllerResetPassword({ email, notifyType: 'email' });
      setEmail(email);
      formikHelpers.setSubmitting(false);
      toVerifyPassword();
    } catch (e: any) {
      switch (e.response.data.message) {
        case AuthErrorEnum.USER_IS_NOT_ACTIVE:
          toVerifyPassword();
          break;
        default:
          setError(getErrorMessage(e));
      }
    }
  };

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched }) => (
        <>
          <header className="modal_header">
            <div className={classes.titleBlock}>
              <button className="btn-text p3 gray" onClick={toPrevStep}>
                <ArrowLeft />
                Назад
              </button>
              <h4 className={`${classes.title} h4`}>Забыли пароль?</h4>
              <p className="p3">Введите ваш email и мы вышлем код для авторизации</p>
            </div>
            <ModalClose />
          </header>
          <Form className="modal_container">
            <div className={`${classes.form} modal_inner`}>
              {error && <ErrorMessage message={error} />}
              <Input label="Адрес электронной почты" error={errors.email} touched={touched.email}>
                <Field name="email" placeholder="Укажите ваш email" />
              </Input>
            </div>
            <footer className={`${classes.footer} modal_footer`}>
              <button disabled={!values.email} className="btn" type="submit">
                Выслать код
              </button>
            </footer>
          </Form>
        </>
      )}
    </Formik>
  );
};
