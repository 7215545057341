const openedPoints = [
  'Барнаул',
  'Ачинск',
  'Иркутск',
  'Москва',
  'Долгопрудный',
  'Оренбург',
  'Дзержинск',
  'Владимир',
] as const;

type OpenedPoints = typeof openedPoints;

type Contact = {
  address: string;
  phone?: string;
  mail?: string;
};

type Service = {
  name: string;
  contacts: Contact[];
};

export type MapPoint = {
  city: OpenedPoints[number];
  top: number;
  left: number;
  services: Service[];
};

export const mapOpenedPoints: MapPoint[] = [
  {
    city: 'Барнаул',
    top: 86,
    left: 42,
    services: [
      {
        name: 'Союз Авто',
        contacts: [
          {
            address: 'Павловский тракт, 186В',
            // phone: '+7 (385) 250-45-05',
            // mail: 'sa@sa22.ru',
          },
        ],
      },
    ],
  },
  {
    city: 'Ачинск',
    top: 78,
    left: 49,
    services: [
      {
        name: 'АВТОАЗИЯ',
        contacts: [
          {
            address: 'ул. Первая, строение 7',
            // phone: '+7 (902) 973-05-55',
          },
        ],
      },
    ],
  },
  {
    city: 'Иркутск',
    top: 88,
    left: 60,
    services: [
      {
        name: 'АвтоГарант',
        contacts: [
          {
            address: 'ул. Баррикад 88 Г/2 ',
            // phone: '+7 (395) 250-01-11',
          },
        ],
      },
    ],
  },
  {
    city: 'Москва',
    top: 57,
    left: 11,
    services: [
      {
        name: 'Квазар',
        contacts: [
          {
            address: 'МКАД 26 км, вл13',
            // phone: '+7 (495) 126-63-53',
          },
        ],
      },
    ],
  },
  // {
  //   city: 'Долгопрудный',
  //   top: 48,
  //   left: 11,
  //   services: [
  //     {
  //       name: 'Долавто',
  //       contacts: [
  //         {
  //           address: 'Транспортный проезд, 3',
  //           // phone: '+7 (495) 984-74-44',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    city: 'Оренбург',
    top: 76,
    left: 21,
    services: [
      {
        name: 'Золотой Дракон',
        contacts: [
          {
            address: 'ул. Полтавская 43',
            // phone: '+7 (353) 248-34-93',
            // mail: 'info@tvsmotors.ru',
          },
        ],
      },
    ],
  },
  {
    city: 'Дзержинск',
    top: 56,
    left: 21,
    services: [
      {
        name: 'БестВей',
        contacts: [
          {
            address: 'Красноармейская 21Ак3',
            // phone: '+7 (831) 280-96-19',
            // mail: 'info@bestwayservice.ru',
          },
        ],
      },
    ],
  },
  {
    city: 'Владимир',
    top: 56,
    left: 16,
    services: [
      {
        name: 'БестВей',
        contacts: [
          {
            address: 'Быковский проезд 3',
            // phone: '+7 (4922) 49-46-47',
            // mail: 'bezrukov@bestwayservice.ru',
          },
        ],
      },
    ],
  },
];
