import { Clusterer, Map, Placemark } from '@pbe/react-yandex-maps';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import React, { FC, useEffect, useId, useRef, useState } from 'react';
import classes from '../../../ui/components/Map/map.module.scss';
import { ServiceStationDto } from '../../../api/generated/caready';
import { DefaultCity, useGeo } from '../../../context/GeoProvider';
import { createPortal } from 'react-dom';
import { Balloon } from '../../components/Map/mobile/components/balloon/Balloon';
import { MapControls } from '../../components/Map/components/mapControls/MapControls';
import { useMapZoom } from '../../components/Map/hooks/useMapZoom';
import { placemarkDefaultOptions } from '../../components/Map/config/placemarkDefaultOptions';
import { PlacemarkList } from '../../components/Map/components/PlacemarkList/PlacemarkList';
import { useMatch } from '../../../hooks/useMatch';
import breakPoints from '../../../styles/breakpoints.module.scss';
import { useGeoObjectsEvent } from '../../components/Map/hooks/useGeoObjectsEvent';

interface OwnProps {
  selectedServiceStation: ServiceStationDto | null;
  serviceStations: ServiceStationDto[];
  setSelect: (v: string) => void;
}

export const MapComponent: FC<OwnProps> = function MapComponent(props) {
  const mapRef = useRef<ymaps.Map | undefined>(undefined);
  const [ymaps, setYmaps] = useState<YMapsApi | undefined>();
  const { isMatch } = useMatch(breakPoints.bp576);

  const { bounds, serviceStation: geoServiceStation } = useGeo();

  const { zoom, setZoom } = useMapZoom(mapRef, ymaps);
  const [center, setCenter] = useState<number[] | undefined>();

  const [isBalloonOpen, setIsBalloonOpen] = useState(false);
  const [isBalloonShow, setIsBalloonShow] = useState(true);

  const [selectedServiceStation, setSelectedServiceStation] = useState<ServiceStationDto | null>(
    props.selectedServiceStation
  );

  const balloonId = useId();
  const balloonElement = document.getElementById(balloonId);

  useEffect(() => {
    if (!props.serviceStations.length) {
      setSelectedServiceStation(null);
      return;
    }

    if (!geoServiceStation && !props.selectedServiceStation) {
      return;
    }

    const newSelectedServiceStation = props.selectedServiceStation || geoServiceStation;

    if (!newSelectedServiceStation) {
      return;
    }

    const { latitude, longitude } = newSelectedServiceStation.fullAddress.coordinates;

    if (newSelectedServiceStation.id !== selectedServiceStation?.id) {
      setIsBalloonOpen(false);
      setIsBalloonShow(true);
    }

    setZoom(12);
    setCenter([latitude, longitude]);
    setSelectedServiceStation(newSelectedServiceStation);
  }, [geoServiceStation, props.selectedServiceStation, props.serviceStations]);

  function onCloseBalloon() {
    setIsBalloonOpen(false);
    setIsBalloonShow(false);
  }

  function onOpenBalloon() {
    setIsBalloonOpen(true);
    setIsBalloonShow(true);
  }

  useGeoObjectsEvent('balloonclose', onCloseBalloon, mapRef, ymaps);
  useGeoObjectsEvent('balloonopen', onOpenBalloon, mapRef, ymaps);

  return (
    <section className={classes.wrapper}>
      <Map
        instanceRef={mapRef}
        width={'100%'}
        height={'100%'}
        state={{
          zoom: zoom,
          center: center,
          behaviors: ['drag', 'dblClickZoom'],
          bounds: center ? undefined : bounds || DefaultCity.bounds,
        }}
        options={{
          suppressMapOpenBlock: true,
          // @ts-ignore
          balloonPanelMaxMapArea: isMatch ? 'Infinity' : '0',
        }}
        modules={['templateLayoutFactory', 'layout.ImageWithContent', 'util.bounds']}
        onLoad={setYmaps}
      >
        {ymaps && selectedServiceStation && center && (
          <Placemark
            modules={['geoObject.addon.balloon']}
            key={selectedServiceStation.id}
            defaultGeometry={[
              selectedServiceStation.fullAddress.coordinates.latitude,
              selectedServiceStation.fullAddress.coordinates.longitude,
            ]}
            instanceRef={ref => {
              if (!ref || isBalloonOpen || !isBalloonShow) return;

              ref.balloon.open();
            }}
            options={{
              ...placemarkDefaultOptions,
              balloonOffset: [0, -24],
              hideIconOnBalloonOpen: false,
            }}
            onClick={() => setSelectedServiceStation(props.selectedServiceStation)}
            properties={{
              balloonContent: `<div id=${balloonId} class="balloon"/>`,
            }}
          />
        )}
        {ymaps && (
          <>
            <Clusterer
              options={{
                clusterIcons: [
                  {
                    size: [40, 40],
                    offset: [-20, -40],
                  },
                ],
                clusterIconContentLayout: ymaps?.templateLayoutFactory.createClass(
                  `<div class='${classes.cluster}'>{{ properties.geoObjects.length }}</div>`
                ),
              }}
            >
              <PlacemarkList
                selectedServiceStation={selectedServiceStation}
                serviceStations={props.serviceStations}
                balloonId={balloonId}
                onClick={setSelectedServiceStation}
              />
            </Clusterer>
            <MapControls ymaps={ymaps} zoom={zoom} setZoom={setZoom} setCenter={setCenter} />
          </>
        )}
      </Map>
      {selectedServiceStation &&
        isBalloonOpen &&
        balloonElement &&
        createPortal(
          <Balloon
            selectedServiceStation={selectedServiceStation}
            onClick={value => props.setSelect(value.id)}
            onCloseBalloon={onCloseBalloon}
          />,
          balloonElement
        )}
    </section>
  );
};
