import { Checkbox } from '../../../ui/base/Checkbox';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { Content, Item, Trigger } from '@radix-ui/react-accordion';
import { Field } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import classes from '../../../ui/components/Accordion/accordion.module.css';
import {
  MaintenanceServiceCategoryTreeDto,
  MaintenanceServiceTreeDto,
} from '../../../api/generated/caready';

export const AccordionItem = ({
  category,
  values,
}: {
  category: MaintenanceServiceCategoryTreeDto;
  values: string[];
}) => {
  const { subcategories } = category;
  const [icon, setIcon] = useState<string | undefined>();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (category?.iconUrl?.includes('.svg')) {
      axios.get(category.iconUrl).then(res => {
        setIcon(res.data);
      });
    }
  }, [subcategories]);

  useEffect(() => {
    let arr: MaintenanceServiceTreeDto[] = [];
    subcategories
      .map(sc => sc.services.filter(s => values.includes(s.slug)))
      .map(s => {
        arr = [...arr, ...s];
      });
    setSelected(arr.length);
  }, [values]);

  return (
    <Item value={category.name} className={classes.accordion_item}>
      <Trigger className={classes.trigger}>
        <div className={classes.header}>
          {icon ? (
            <span className={`${classes.icon}`} dangerouslySetInnerHTML={{ __html: icon }} />
          ) : (
            <span className={`${classes.icon} ${classes.changedColor}`}>
              <img src={category.iconUrl} />
            </span>
          )}
          <span className="h6">{category.name}</span>
        </div>
        <div className={classes.header}>
          {!!selected && (
            <div className={classes.count}>
              <span className="pm2">{selected}</span>
            </div>
          )}
          <span className={classes.arrow}>
            <ChevronDown />
          </span>
        </div>
      </Trigger>
      <Content className={classes.content}>
        {subcategories.map(
          (subcategory, idx) =>
            !!subcategory.services.length && (
              <Fragment key={idx}>
                {subcategory.services.map((service, idx) => (
                  <div className={classes.item} key={idx}>
                    <Checkbox text={service.name}>
                      <Field type="checkbox" name="maintenanceServices" value={service.slug} />
                    </Checkbox>
                    {/* <span className={`h6 ${classes.price}`}>
                      от {service.minPriceRub} <span className="ruble_back small" />
                    </span> */}
                  </div>
                ))}
              </Fragment>
            )
        )}
      </Content>
    </Item>
  );
};
