import { FC } from 'react';
import { ServiceStationWorkStatus } from '../../../ServiceStationWorkStatus/ServiceStationWorkStatus';
import s from './ServiceStationList.module.scss';
import { ServiceStationDto } from '../../../../../api/generated/caready';

interface OwnProps {
  serviceStations: ServiceStationDto[];
  setSelectedServiceStation: (value: ServiceStationDto) => void;
  onClose?: (value?: ServiceStationDto) => void;
}

export const ServiceStationList: FC<OwnProps> = function ServiceStationList(props) {
  const onClick = (value: ServiceStationDto) => {
    props.setSelectedServiceStation(value);
    props.onClose?.(value);
  };

  return (
    <>
      {!!props.serviceStations.length ? (
        <ul>
          {props.serviceStations.map(it => (
            <li key={it.id} className={s.item} onClick={() => onClick(it)}>
              <ServiceStationWorkStatus serviceStation={it} className={'p3'} />
            </li>
          ))}
        </ul>
      ) : (
        <div className={s.notFound}>
          <p className={'h6'}>По вашему запросу ничего не найдено</p>
        </div>
      )}
    </>
  );
};
