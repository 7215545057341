import { useEffect, useState } from 'react';

export function useMatch(breakpoint: string) {
  const [isMatch, setIsMatch] = useState<boolean>(false);
  const mediaQuery = window.matchMedia(`(max-width: ${breakpoint})`);

  useEffect(() => {
    function evaluateMatch() {
      setIsMatch(mediaQuery.matches);
    }

    mediaQuery.addEventListener('change', evaluateMatch);
    evaluateMatch();

    return () => mediaQuery.removeEventListener('change', evaluateMatch);
  }, []);

  return { isMatch };
}
