import React, { FC, useState } from 'react';
import { ModalOverlay } from '../../base/ModalBase';
import { QuickAppointmentForm } from './compoents/quickAppointmentForm/QuickAppointmentForm';
import { SuccessQuickAppointment } from './compoents/successQuickAppointment/SuccessQuickAppointment';

interface OwnProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
}

export const QuickAppointment: FC<OwnProps> = function QuickAppointment(props) {
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState<boolean>(false);

  return (
    <ModalOverlay open={props.open} onOpenChange={props.onOpenChange}>
      {isSubmissionSuccessful ? (
        <SuccessQuickAppointment
          onClose={() => {
            props.onOpenChange(false);
            setIsSubmissionSuccessful(false);
          }}
        />
      ) : (
        <QuickAppointmentForm
          onOpenChange={props.onOpenChange}
          onSubmissionSuccess={() => setIsSubmissionSuccessful(true)}
        />
      )}
    </ModalOverlay>
  );
};
