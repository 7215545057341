import {
  Close,
  Content,
  DialogProps,
  Overlay,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dialog';
import { ReactComponent as Cross } from 'assets/svg/cross.svg';
import React, { ReactNode } from 'react';
import classes from './modal.module.css';

export const ModalClose = () => (
  <Close asChild>
    <button className={classes.close}>
      <Cross />
    </button>
  </Close>
);

type OwnProps = {
  trigger?: ReactNode;
} & DialogProps;

export const ModalOverlay = ({ trigger, children, onOpenChange, open, defaultOpen }: OwnProps) => {
  return (
    <Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Overlay className={classes.overlay}>
          <Content className={classes.wrap}>{children}</Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
