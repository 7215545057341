import { AUTH_TOKEN_KEY } from '../config/auth';
import { AuthErrorEnum } from '../config/auth-error.enum';
import { AxiosError } from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { throwError } from '../utils/errorThrower';
import { useNavigate } from 'react-router-dom';
import { AuthApi, UsersApi } from '../api';
import { AuthCredentialsDto, UserProfileDto } from '../api/generated/caready';

export type AuthContextType = {
  user?: UserProfileDto;
  login: (authCredentials: AuthCredentialsDto) => Promise<{ isSignIn: boolean; error?: string }>;
  checkAuth: () => Promise<string | undefined>;
  logout: () => void;
  setUser: (user?: UserProfileDto) => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () =>
  useContext(AuthContext) ?? throwError('useAuth can be used only inside AuthProvider');

export const AuthProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [user, setUser] = useState<UserProfileDto | undefined>();
  const navigate = useNavigate();

  const login = async (authCredentials: AuthCredentialsDto) => {
    try {
      const { data } = await AuthApi.authControllerLogin({
        ...authCredentials,
        rememberMe: true,
      });
      if (data) {
        localStorage.setItem(AUTH_TOKEN_KEY, data.accessToken);

        navigate('/profile/');

        const { data: userDate } = await UsersApi.usersControllerGetProfile();
        setUser(userDate);

        return { isSignIn: true };
      }

      return { isSignIn: false };
    } catch (e: any) {
      let error = AuthErrorEnum.SOMETHING_WENT_WRONG as string;

      if (e instanceof AxiosError) {
        error = e.response?.data?.message as string;
      }

      return {
        isSignIn: false,
        error,
      };
    }
  };

  const logout = async () => {
    await AuthApi.authControllerLogout();
    localStorage.removeItem(AUTH_TOKEN_KEY);
    setUser(undefined);
    navigate('/');
  };

  const getUser = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);

    if (!!token) {
      const { data } = await UsersApi.usersControllerGetProfile();
      setUser(data);
    }
  };

  const checkAuth = async (): Promise<string | undefined> => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);

    if (!token) {
      return undefined;
    } else {
      const { data } = await UsersApi.usersControllerGetProfile();
      await getUser();
      return data.id;
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, checkAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
