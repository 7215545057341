import { useEffect, useState } from 'react';
import { GarageApi } from '../api';
import { MaintenanceHistoryItemDto } from '../api/generated/caready';

export const useMaintenanceHistoryByCarId = (id?: string | undefined) => {
  const [histories, setHistories] = useState<MaintenanceHistoryItemDto[] | []>([]);

  useEffect(() => {
    if (!!id) {
      GarageApi.garageControllerControllerGetGarageCarMaintenanceHistory(id).then(({ data }) => {
        setHistories(data);
      });
    }
  }, [id]);

  return histories;
};
