import { createBrowserRouter, createRoutesFromElements, redirect, Route } from 'react-router-dom';
import { NewsListPage } from '../pages/news/NewsListPage';
import { RootLayout } from '../ui/layouts/RootLayout';
import { MainLayout } from '../ui/layouts/MainLayout';
import { HomePage } from '../pages/home/HomePage';
import { Policy } from '../pages/policy';
import { RulesOfUsingSite } from '../pages/rulesOfUsingSite';
import { NotFoundPage } from '../pages/NotFoundPage/NotFoundPage';
import { NewsPage } from '../pages/news/[slug]/NewsPage';
import { PromoListPage } from '../pages/promo/PromoListPage';
import { PromoPage } from '../pages/promo/[slug]/PromoPage';
import { ServicesListPage } from '../pages/services/ServiceListPage';
import { ServiceSubcategoryListPage } from '../pages/services/[category]/[subcategory]/ServiceSubcategoryListPage';
import { ServicePage } from '../pages/services/[category]/[subcategory]/[slug]/ServicePage';
import SpareParts from '../pages/spareParts/spare-parts';
import MapPage from '../pages/map/map';
import Search from '../pages/search/search';
import Profile from '../pages/profile';
import PersonalInfo from '../pages/profile/personal-info';
import MyCarsPage from '../pages/profile/my-cars';
import MaintenanceHistoryPages from '../pages/profile/maintenance-history';
import { Franchise } from '../pages/franchise/Franchise';
import { ProtectedLayout } from '../ui/layouts/ProtectedLayout';
import { GuaranteePolicy } from '../pages/guaranteePolicy/GuaranteePolicy';
import { PersonalDataAgreement } from '../pages/personalDataAgreement/PersonalDataAgreement';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route element={<MainLayout className={'app'} />}>
        <Route index={true} element={<HomePage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/rules-using-official-site" element={<RulesOfUsingSite />} />
        <Route path="/guarantee-policy" element={<GuaranteePolicy />} />
        <Route path="/personal-data-agreement" element={<PersonalDataAgreement />} />
        {/*<Route path="/about" element={<About />} />*/}
        {/*// todo: hid jobs*/}
        {/*<Route path="/about/jobs" element={<Jobs />} />*/}
        {/*<Route path="/about/jobs/:slug" element={<ChosenJobPage />} />*/}
        <Route path="/news">
          <Route index={true} element={<NewsListPage />} />
          <Route path=":slug" element={<NewsPage />} />
        </Route>
        <Route path="/promo">
          <Route index={true} element={<PromoListPage />} />
          <Route path=":slug" element={<PromoPage />} />
        </Route>
        <Route path="/services">
          <Route index={true} element={<ServicesListPage />} />
          <Route path=":slug">
            <Route index={true} element={<ServiceSubcategoryListPage />} />
            <Route path=":slug" element={<ServicePage />} />
          </Route>
        </Route>
        {/*todo: hid additional-services*/}
        {/*<Route path="/additional-services" element={<AdditionalServices />} />*/}
        <Route path="/spare-parts" element={<SpareParts />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/search" element={<Search />} />
        <Route path="/profile" element={<ProtectedLayout />}>
          <Route index={true} element={<Profile />} />
          <Route path="personal-info" element={<PersonalInfo />} />
          <Route path="my-cars">
            <Route index={true} element={<MyCarsPage />} />
            <Route path=":id" element={<MyCarsPage />} />
          </Route>
          <Route path="maintenance-history/:id" element={<MaintenanceHistoryPages />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} loader={() => redirect('/', 301)} />
      </Route>

      <Route path="/franchise" element={<MainLayout />}>
        <Route index={true} element={<Franchise />} />
      </Route>
    </Route>
  )
);
