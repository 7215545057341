import { useEffect, useState } from 'react';
import {
  ServiceStationDto,
  ServiceStationsControllerGetServiceStationsTypesEnum,
} from '../api/generated/caready';
import { useServiceStations as useServiceStationsBase } from './useServiceStations';
import { getServiceStationAddress } from '../utils/getServiceStationAddress';
import { useUtm } from './useUtm';

export type ServiceStationParams = {
  address?: string;
  types?: ServiceStationsControllerGetServiceStationsTypesEnum[];
  serviceCategories?: string[];
  services?: string[];
  electricChargerTypes?: string[];
};

export const useFilteredServiceStations = ({ address, ...params }: ServiceStationParams) => {
  const [serviceStations, setServiceStations] = useState<ServiceStationDto[]>([]);

  const { data: allServiceStations } = useServiceStationsBase({
    address: undefined,
    types: params?.types,
    serviceCategories: params?.serviceCategories,
    services: params?.services,
    electricChargerTypes: params?.electricChargerTypes,
  });

  useEffect(() => {
    if (!allServiceStations?.length) return;

    setServiceStations(allServiceStations);
  }, [allServiceStations]);

  const { utmData } = useUtm();

  useEffect(() => {
    const slugs = utmData?.slugs;

    if (!allServiceStations?.length) return;

    const restServiceStations: ServiceStationDto[] = [];
    const serviceStationsBySlug: ServiceStationDto[] = [];

    allServiceStations.forEach(it => {
      if (address && !getServiceStationAddress(it).toLowerCase().includes(address.toLowerCase())) {
        return;
      }

      if (!slugs || slugs.length !== 2) {
        restServiceStations.push(it);
        return;
      }

      if (slugs.find(slug => slug === it.slug)) {
        serviceStationsBySlug.push(it);
        return;
      }

      restServiceStations.push(it);
    });

    if (slugs && slugs.length === 2) {
      serviceStationsBySlug.sort((a, b) => slugs.indexOf(a.slug) - slugs.indexOf(b.slug));
    }

    setServiceStations([...serviceStationsBySlug, ...restServiceStations]);
  }, [address, allServiceStations, utmData]);

  return serviceStations;
};
