import { AxiosResponse } from 'axios';
import { Combobox, ComboboxProps } from './Combobox';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsParam } from '../../../types/UseQueryOptionsParam';
import { Option } from './types/Option';
import { OptionWithName } from './types/OptionWithName';

type OwnProps<TData> = {
  request: (name?: string) => Promise<AxiosResponse<TData, any>>;
  queryKey: (string | undefined)[];
  readOnly?: boolean;
  options?: UseQueryOptionsParam<TData, unknown, Option[] | OptionWithName[]>;
} & Omit<ComboboxProps, 'setQuery' | 'isLoading' | 'options'>;

export function QueryCombobox<TData>({
  request,
  queryKey,
  options,
  readOnly,
  ...comboboxProps
}: OwnProps<TData>) {
  const [searchName, setSearchName] = useState<string>('');

  const { data, isLoading } = useQuery<TData, unknown, Option[] | OptionWithName[]>({
    queryKey: [...queryKey, searchName],
    queryFn: async () => {
      const { data } = await request(searchName);
      return data;
    },
    ...options,
  });

  return (
    <Combobox
      {...comboboxProps}
      options={data}
      isLoading={isLoading}
      setQuery={readOnly ? undefined : setSearchName}
    />
  );
}
