import { useEffect, useState } from 'react';

export const useTimer = (initialValue: number) => {
  const [seconds, setSeconds] = useState<number>(initialValue);
  const [isActive, setIsActive] = useState<boolean>(true);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isActive) {
      interval = setInterval(() => {
        if (seconds <= 0) {
          clearInterval(interval);
          setIsActive(false);
        } else {
          setSeconds(prevValue => prevValue - 1);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const resetTimer = () => {
    setIsActive(true);
    setSeconds(initialValue);
  };

  return { seconds, isActive, resetTimer };
};
