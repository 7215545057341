import 'swiper/css';
import 'swiper/css/navigation';
import { ExperienceTabSection } from '../ExperienceTabContent/ExperienceTabSection';
import { serviceData } from './data';
import { useState } from 'react';
import styles from './experience.module.css';

export const ExperienceSection = () => {
  const [tab, setTab] = useState(0);

  return (
    <div className={styles.subSection}>
      <h3 className={`${styles.subTitleBlock} h2-52`}>Опыт наших франчайзи</h3>
      <div className={styles.buttonsBlock}>
        <div className={styles.buttons}>
          {serviceData.map((it, index) => (
            <button
              className={`${styles.menuButton} ${tab === index ? styles.active : ''}`}
              key={it.serviceName}
              onClick={() => setTab(index)}
            >
              {it.serviceName}
            </button>
          ))}
        </div>
      </div>
      <ExperienceTabSection service={serviceData[tab]} />
    </div>
  );
};
