import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { ReactComponent as Loading } from 'assets/svg/loading.svg';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import styles from './button.module.css';

export const Button = ({
  onClick,
  children,
  plus,
  color,
  secondary,
}: {
  color?: string;
  secondary?: boolean;
  onClick?: () => void;
  children?: JSX.Element | string;
  plus?: boolean;
}) => {
  const loading = false;
  const leftArrow = false;
  const rightArrow = false;
  return (
    <button
      onClick={onClick}
      className={`h6
      ${loading && styles.loadingText} ${secondary && styles.secondary} ${styles.btn} ${
        color ? styles[color] : ''
      }`}
    >
      {leftArrow ? <ArrowLeft /> : plus ? <Plus /> : ''} {children} {rightArrow && <ArrowRight />}
      {loading && (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      )}
    </button>
  );
};

export const ButtonLink = ({
  mediumType,
  largeSize,
  color,
}: {
  mediumType: string;
  largeSize: string;
  color?: string;
}) => {
  // const mediumType = true;
  // const largeSize = true;
  // const color = styles.btnLink;
  const loading = true;
  const arrow = true;
  return (
    <a
      className={`${mediumType ? (largeSize ? 'h6' : 'pm1') : largeSize ? 'p2' : 'p3'} ${
        color ? styles[color] : ''
      } ${styles.btnLink} ${loading ? styles.loadingLink : ''}`}
      href="#"
    >
      {arrow ? loading ? <Loading /> : <ArrowLeft /> : null}
      Button link
      {arrow ? loading ? <Loading /> : <ArrowRight /> : null}
    </a>
  );
};

export const ButtonIcon = ({ color }: { color?: string }) => {
  const large = true;
  const loading = false;
  const second = false;
  const third = false;
  return (
    <button
      className={`${large ? styles.btnLarge : styles.btnMedium} ${
        color ? styles[`${color}Icon`] : ''
      } ${styles.btnIcon} ${loading ? styles.loadingIcon : ''} ${second && styles.secondaryIcon}  ${
        third && styles.tertiaryIcon
      }`}
    >
      {loading ? <Loading /> : <ArrowRight />}
    </button>
  );
};
