import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Input } from '../../base/Input';
import React, { FC } from 'react';
import { CarDictionaryApi } from '../../../api';
import { QueryCombobox } from '../../base/Combobox/QueryCombobox';
import { CarBrandWithPopularityDto } from '../../../api/generated/caready';
import { mapPopularityCarBrandToOptionsWithName } from '../../../utils/mapPopularityCarBrandToOptionsWithName';

interface OwnProps {
  values: FormikValues;
  setValue: (field: string, value: any) => void;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  appointment?: boolean;
}

export const Step1: FC<OwnProps> = function Step1(props) {
  return (
    <>
      <QueryCombobox<CarBrandWithPopularityDto>
        queryKey={['car-brand', 'list', 'with-rating']}
        value={props.values.brand}
        setValue={value => props.setValue('brand', value)}
        placeholder={'Выберите бренд'}
        label={'Бренд *'}
        error={props.errors.brand as string}
        touched={!!props.touched.brand}
        request={name => CarDictionaryApi.carDictionaryControllerGetCarBrandsWithRating(name)}
        options={{
          select: mapPopularityCarBrandToOptionsWithName,
        }}
      />
      <QueryCombobox
        queryKey={['car-model', 'list', props.values.brand?.slug]}
        value={props.values.carModel}
        setValue={value => props.setValue('carModel', value)}
        placeholder={'Выберите модель'}
        label={'Модель *'}
        error={props.errors.carModel as string}
        disabled={!props.values.brand}
        touched={!!props.touched.carModel}
        request={name =>
          CarDictionaryApi.carDictionaryControllerGetCarModelsByBrandSlug(
            props.values.brand?.slug || '',
            name
          )
        }
        options={{
          enabled: !!props.values.brand?.slug,
        }}
      />
      {props.appointment && (
        <Input label="VIN" error={props.errors.carVin as string} touched={!!props.touched.carVin}>
          <Field name="carVin" placeholder="Укажите VIN" disabled={!props.values.carModel} />
        </Input>
      )}
      <Input label="Год" error={props.errors.carYear as string} touched={!!props.touched.carYear}>
        <Field name="carYear" placeholder="Укажите год" disabled={!props.values.carModel} />
      </Input>
    </>
  );
};
