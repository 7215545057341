export const COLORS = [
  {
    value: '#FFFFFF',
    name: 'Белый',
  },
  {
    value: '#010101',
    name: 'Чёрный',
  },
  {
    value: '#838383',
    name: 'Серый',
  },
  {
    value: '#6A1515',
    name: 'Красый',
  },
  {
    value: '#183156',
    name: 'Тёмно-синий',
  },
  {
    value: '#F1E7E0',
    name: 'Светло-серый',
  },
  {
    value: '#CBB28D',
    name: 'Бронзовый',
  },
  {
    value: '#5070E2',
    name: 'Тёмно-голубой',
  },
  {
    value: '#E25050',
    name: 'Красный',
  },
  {
    value: '#85C283',
    name: 'Бледно-зелёный',
  },
  {
    value: '#F0A83B',
    name: 'Жёлтый',
  },
];
