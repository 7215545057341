import { COLORS } from '../../../config/colors';
import { ReactComponent as Camera } from 'assets/svg/camera.svg';
import { ColorPicker } from '../../components/ColorPicker';
import { DragAndDropCar } from '../../components/DragAndDrop/DragAndDropCar';
import { DriveTypeEnum, EngineTypeEnum, TransmissionEnum } from '../../../config/car-modifications';
import { Dropdown } from '../../base/Dropdown';
import { Field, Form, FormikErrors, FormikTouched } from 'formik';
import { Input } from '../../base/Input';
import { LicensePlateNumberInput } from '../../base/Input/Input';
import { useCarModelGenerations, useCarModelModification } from '../../../hooks/useCarDictionary';
import React, { FC, useEffect } from 'react';
import classes from './add-new-car.module.scss';
import { AddNewCarFormValues } from './AddNewCarModal';
import { QueryCombobox } from '../../base/Combobox/QueryCombobox';
import { CarDictionaryApi } from '../../../api';
import { CarBrandWithPopularityDto } from '../../../api/generated/caready';
import { mapPopularityCarBrandToOptionsWithName } from '../../../utils/mapPopularityCarBrandToOptionsWithName';

interface OwnProps {
  photoUrl?: string | null;
  values: AddNewCarFormValues;
  touched: FormikTouched<AddNewCarFormValues>;
  errors: FormikErrors<AddNewCarFormValues>;
  setFieldValue: (field: string, value: any) => void;
  isValid: boolean;
}

export const AddNewCarForm: FC<OwnProps> = function AddNewCarForm(props) {
  const generations = useCarModelGenerations(props.values.carModel?.id || '');
  const modification = useCarModelModification(
    props.values.carModel?.id || '',
    props.values.generation,
    props.values.bodyType,
    props.values.transmissionType,
    props.values.engineFuelType,
    props.values.driveType
  );

  const generation = generations.find(generation => props.values.generation === generation.id);

  useEffect(() => {
    if (!!props.values.modelModificationId && modification) {
      const selectedModification = modification.modifications.find(
        modification => modification.id === props.values.modelModificationId
      );
      if (selectedModification) {
        props.setFieldValue('generation', selectedModification.generation.id);
        props.setFieldValue('bodyType', selectedModification.bodyTypes[0].id);
        props.setFieldValue('transmissionType', selectedModification.transmissionType);
        props.setFieldValue('engineFuelType', selectedModification.engineFuelType);
        props.setFieldValue('driveType', selectedModification.driveType);
      }
    }
  }, [props.values.modelModificationId]);

  return (
    <Form>
      <div className={classes.dataWrapper}>
        <div className={classes.carInfoSection}>
          <QueryCombobox<CarBrandWithPopularityDto>
            queryKey={['car-brand', 'list', 'with-rating']}
            value={props.values.brand}
            setValue={value => props.setFieldValue('brand', value)}
            placeholder={'Выберите бренд'}
            label={'Бренд *'}
            error={props.errors.brand}
            touched={!!props.touched.brand}
            request={name => CarDictionaryApi.carDictionaryControllerGetCarBrandsWithRating(name)}
            options={{
              select: mapPopularityCarBrandToOptionsWithName,
            }}
          />

          <QueryCombobox
            queryKey={['car-model', 'list', props.values.brand?.slug]}
            value={props.values.carModel}
            setValue={value => props.setFieldValue('carModel', value)}
            placeholder={'Выберите модель'}
            label={'Модель *'}
            error={props.errors.carModel as string}
            disabled={!props.values.brand}
            touched={!!props.touched.carModel}
            request={name =>
              CarDictionaryApi.carDictionaryControllerGetCarModelsByBrandSlug(
                props.values.brand?.slug || '',
                name
              )
            }
            options={{
              enabled: !!props.values.brand?.slug,
            }}
          />
          <Dropdown
            name="generation"
            value={
              generation
                ? generation.name
                  ? `${generation.name} (${generation.fromYear}-${generation.toYear ?? 'нв'})`
                  : `${generation.fromYear}-${generation.toYear ?? 'нв'}`
                : ''
            }
            setValue={value => props.setFieldValue('generation', value)}
            placeholder="Выберите поколение"
            label="Поколение"
            options={generations.map(generation => ({
              name: generation.name
                ? `${generation.name} (${generation.fromYear}-${generation.toYear ?? 'нв'})`
                : `${generation.fromYear}-${generation.toYear ?? 'нв'}`,
              id: generation.id,
            }))}
            error={props.errors.generation as string}
            touched={!!props.touched.generation}
            disabled={!generations.length}
          />
          <Dropdown
            name="bodyType"
            value={
              (modification?.bodyTypes ?? []).find(
                bodyType => props.values.bodyType === bodyType.id
              )?.name ?? ''
            }
            setValue={value => props.setFieldValue('bodyType', value)}
            placeholder="Выберите кузов"
            label="Кузов"
            options={modification?.bodyTypes ?? []}
            error={props.errors.bodyType as string}
            touched={!!props.touched.bodyType}
            disabled={!(modification && modification.bodyTypes.length)}
          />
          <Dropdown
            name="transmissionType"
            value={props.values.transmissionType}
            setValue={value => props.setFieldValue('transmissionType', value)}
            options={(modification?.transmissionTypes ?? []).map(item => ({
              name: TransmissionEnum[item],
              id: item,
            }))}
            placeholder="Выберите тип КПП"
            label="КПП"
            error={props.errors.transmissionType as string}
            touched={!!props.touched.transmissionType}
            disabled={!(modification && modification.transmissionTypes.length)}
          />
          <Dropdown
            name="engineFuelType"
            value={props.values.engineFuelType}
            setValue={value => props.setFieldValue('engineFuelType', value)}
            placeholder="Выберите тип двигателя"
            label="Тип двигателя"
            options={(modification?.engineFuelTypes ?? []).map(item => ({
              name: EngineTypeEnum[item],
              id: item,
            }))}
            error={props.errors.engineFuelType as string}
            touched={!!props.touched.engineFuelType}
            disabled={!(modification && modification.engineFuelTypes.length)}
          />
          <Dropdown
            name="driveType"
            value={props.values.driveType}
            setValue={value => props.setFieldValue('driveType', value)}
            placeholder="Выберите тип привода"
            label="Привод"
            options={(modification?.driveTypes ?? []).map(item => ({
              name: DriveTypeEnum[item],
              id: item,
            }))}
            error={props.errors.driveType as string}
            touched={!!props.touched.driveType}
            disabled={!(modification && modification.driveTypes.length)}
          />
          <Dropdown
            name="modelModificationId"
            value={
              (modification?.modifications ?? []).find(
                modification => props.values.modelModificationId === modification.id
              )?.name ?? ''
            }
            setValue={value => props.setFieldValue('modelModificationId', value)}
            placeholder="Выберите исполнение"
            label="Исполнение"
            options={modification?.modifications ?? []}
            error={props.errors.modelModificationId as string}
            touched={!!props.touched.modelModificationId}
            disabled={!(modification && modification.modifications.length)}
          />
          <ColorPicker label="Цвет" colors={COLORS} name="colorHex" />
        </div>
        <div className={classes.legalInfoSection}>
          <Input
            label="Гос. номер"
            error={props.errors.licensePlateNumber as string}
            touched={!!props.touched.licensePlateNumber}
          >
            <LicensePlateNumberInput
              name="licensePlateNumber"
              setField={v => props.setFieldValue('licensePlateNumber', v)}
            />
          </Input>
          <Input label="VIN" error={props.errors.vin as string} touched={!!props.touched.vin}>
            <Field name="vin" placeholder="Введите VIN" />
          </Input>
        </div>

        <div className={classes.addImageSection}>
          <h6 className={`${classes.addImageTitle} h6`}>Загрузите фото вашего автомобиля</h6>
          <DragAndDropCar
            photoUrl={props.photoUrl}
            setValue={photoS3Key => props.setFieldValue('photoS3Key', photoS3Key)}
            icon={<Camera />}
          />
        </div>
      </div>
      <div className={classes.bottomSection}>
        <button className="btn" type="submit" disabled={!props.isValid}>
          Сохранить
        </button>
      </div>
    </Form>
  );
};
