import { Helmet } from 'react-helmet';
import React, { FC } from 'react';

interface OwnProps {
  title: string;
  description?: string;
}

export const CoreHead: FC<OwnProps> = function CoreHead(props) {
  return (
    <Helmet>
      <title>{`Caready | ${props.title}`}</title>
      <meta property="og:title" content={`Caready | ${props.title}`} />
      {props.description && <meta name="description" content={props.description} />}
      {props.description && <meta property="og:description" content={props.description} />}
      <meta
        name="og:image"
        content="https://storage.yandexcloud.net/caready-staging/mocks/og/cover.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content="https://storage.yandexcloud.net/caready-staging/mocks/og/cover.png"
      />

      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#00428D" />

      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
      <link rel="manifest" href="/favicons/site.webmanifest" />
      <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#e38731" />

      <meta name="msapplication-TileColor" content="#2d89ef" />
      <meta name="theme-color" content="#ffffff"></meta>

      <meta property="og:image" content="/logo.png" />
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
};
