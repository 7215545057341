import { ImgHTMLAttributes } from 'react';

export type DynamicImgProps = {
  path: string;
  notLazy?: boolean;
} & ImgHTMLAttributes<HTMLImageElement>;

export function DynamicImg({ path, notLazy, ...rest }: DynamicImgProps) {
  return (
    <img {...rest} src={require(`assets/img/${path}`)} loading={notLazy ? undefined : 'lazy'} />
  );
}
