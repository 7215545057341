import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { Fragment } from 'react';
import classes from './bread-crumbs.module.css';
import { UTMLink } from '../../base/UTMLink/UTMLink';

const currentPage = '#';

export const BreadCrumbs = ({ links }: { links: { name: string; path?: string }[] }) => {
  return (
    <div>
      <div className={classes.crumbs}>
        {links.map(({ path = currentPage, name }, index) => (
          <Fragment key={index}>
            {index !== 0 && <ArrowRight />}
            <UTMLink
              className={`p3 ${classes.link} ${index === links.length - 1 ? classes.active : ''}`}
              to={path}
            >
              {name}
            </UTMLink>
          </Fragment>
        ))}
      </div>
      <div className={classes.mobileCrumbs}>
        {links.length === 2 ? (
          <>
            <UTMLink className={`p3 ${classes.link}`} to={links[0].path || currentPage}>
              {links[0].name}
            </UTMLink>
            <ArrowRight />
            <UTMLink
              className={`p3 ${classes.link} ${classes.active}`}
              to={links[links.length - 1].path || currentPage}
            >
              {links[links.length - 1].name}
            </UTMLink>
          </>
        ) : (
          <>
            <UTMLink className={`p3 ${classes.link}`} to={links[0].path || currentPage}>
              {links[0].name}
            </UTMLink>
            <ArrowRight />
            ...
            <ArrowRight />
            <UTMLink
              className={`p3 ${classes.link} ${classes.active}`}
              to={links[links.length - 1].path || currentPage}
            >
              {links[links.length - 1].name}
            </UTMLink>
          </>
        )}
      </div>
    </div>
  );
};
