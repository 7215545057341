import { format } from 'date-fns';
import styles from './card-new.module.css';
import type { NewsDto } from '../../../api/generated/caready';
import { UTMLink } from '../../base/UTMLink/UTMLink';

export const CardNew = ({ newsData }: { newsData: NewsDto }) => {
  return (
    <UTMLink to={`/news/${newsData.slug}/`} className={styles.cardWrap}>
      <div className={styles.imageWrap}>
        <div className={styles.imageSpace} />
        <img src={`${newsData.coverImageUrl}`} />
      </div>
      <div className={styles.contentBlock}>
        <h6 className={`${styles.cardTitle} h6`}>{newsData.headline}</h6>
        <div className="tags">
          {newsData.publishedAt && (
            <span className="tag p3">{format(new Date(newsData.publishedAt), 'dd.MM.yyyy')}</span>
          )}
        </div>
      </div>
    </UTMLink>
  );
};
