import { Field, FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { Input } from '../../base/Input';
import { PhoneNumber } from '../../base/Input/Input';
import React, { FC } from 'react';
import { AppointmentFormValues } from '../Appointment/Appointment';
import { Agreement } from '../../components/Agreement/Agreement';

interface OwnProps {
  clientPhoneNumber: string;
  errors: FormikErrors<AppointmentFormValues>;
  touched: FormikTouched<AppointmentFormValues>;
  setFieldTouched: FormikHelpers<AppointmentFormValues>['setFieldTouched'];
  setField: (v: string) => void;
}

export const Step5: FC<OwnProps> = function Step5(props) {
  return (
    <>
      <Input
        label="Имя *"
        error={props.errors.clientFirstName as string}
        touched={!!props.touched.clientFirstName}
      >
        <Field name="clientFirstName" placeholder="Введите имя" />
      </Input>
      <Input
        label="Фамилия *"
        error={props.errors.clientLastName as string}
        touched={!!props.touched.clientLastName}
      >
        <Field name="clientLastName" placeholder="Введите фамилию" />
      </Input>
      <Input
        label="Мобильный телефон *"
        error={props.errors.clientPhoneNumber as string}
        touched={!!props.touched.clientPhoneNumber}
      >
        <PhoneNumber
          name="clientPhoneNumber"
          clientPhoneNumber={props.clientPhoneNumber}
          onBlur={() => props.setFieldTouched('clientPhoneNumber', true)}
          setField={v => props.setField(v)}
        />
      </Input>
      <Input
        label="Адрес электронной почты *"
        error={props.errors.clientEmail as string}
        touched={props.touched.clientEmail}
      >
        <Field name="clientEmail" placeholder="Введите адрес почты" />
      </Input>
      <Input label="Комментарий">
        <Field
          as="textarea"
          name="clientComment"
          placeholder="Укажите дополнительную информацию"
          rows={7}
        />
      </Input>
      <Agreement error={props.errors.termsAgreement} touched={!!props.touched.termsAgreement}>
        <Field type={'checkbox'} name={'termsAgreement'} />
      </Agreement>
    </>
  );
};
