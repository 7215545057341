/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { NewsArticleDto } from '../dtos';
// @ts-ignore
import type { NewsCategoryDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { PaginatedNewsDto } from '../dtos';
/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetNewsCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get published news with filters and pagination
         * @summary Get news
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [category] Filter news by a category (by its slug)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetPublishedNews: async (perPage?: number, page?: number, category?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a published news article by its slug
         * @param {string} slug News slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetPublishedNewsArticleBySlug: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('newsControllerGetPublishedNewsArticleBySlug', 'slug', slug)
            const localVarPath = `/news/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerGetNewsCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerGetNewsCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewsApi.newsControllerGetNewsCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get published news with filters and pagination
         * @summary Get news
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [category] Filter news by a category (by its slug)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerGetPublishedNews(perPage?: number, page?: number, category?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerGetPublishedNews(perPage, page, category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewsApi.newsControllerGetPublishedNews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a published news article by its slug
         * @param {string} slug News slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsControllerGetPublishedNewsArticleBySlug(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsArticleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsControllerGetPublishedNewsArticleBySlug(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewsApi.newsControllerGetPublishedNewsArticleBySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get news categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetNewsCategories(options?: any): AxiosPromise<Array<NewsCategoryDto>> {
            return localVarFp.newsControllerGetNewsCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * Get published news with filters and pagination
         * @summary Get news
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [category] Filter news by a category (by its slug)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetPublishedNews(perPage?: number, page?: number, category?: string, options?: any): AxiosPromise<PaginatedNewsDto> {
            return localVarFp.newsControllerGetPublishedNews(perPage, page, category, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a published news article by its slug
         * @param {string} slug News slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsControllerGetPublishedNewsArticleBySlug(slug: string, options?: any): AxiosPromise<NewsArticleDto> {
            return localVarFp.newsControllerGetPublishedNewsArticleBySlug(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - interface
 * @export
 * @interface NewsApi
 */
export interface NewsApiInterface {
    /**
     * 
     * @summary Get news categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsControllerGetNewsCategories(options?: RawAxiosRequestConfig): AxiosPromise<Array<NewsCategoryDto>>;

    /**
     * Get published news with filters and pagination
     * @summary Get news
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {string} [category] Filter news by a category (by its slug)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsControllerGetPublishedNews(perPage?: number, page?: number, category?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedNewsDto>;

    /**
     * 
     * @summary Get a published news article by its slug
     * @param {string} slug News slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsControllerGetPublishedNewsArticleBySlug(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<NewsArticleDto>;

}

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI implements NewsApiInterface {
    /**
     * 
     * @summary Get news categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerGetNewsCategories(options?: RawAxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerGetNewsCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get published news with filters and pagination
     * @summary Get news
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {string} [category] Filter news by a category (by its slug)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerGetPublishedNews(perPage?: number, page?: number, category?: string, options?: RawAxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerGetPublishedNews(perPage, page, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a published news article by its slug
     * @param {string} slug News slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsControllerGetPublishedNewsArticleBySlug(slug: string, options?: RawAxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsControllerGetPublishedNewsArticleBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }
}

