import NoImageCar from 'assets/img/my-cars/no-image-car.svg';
import classes from './car-picker.module.css';
import { GarageCarDto } from '../../../api/generated/caready';

export const CarPicker = ({
  car,
  chosen,
  onClick,
}: {
  car: GarageCarDto;
  chosen?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      className={`${classes.carCard} ${chosen && classes.chosen}`}
      onClick={onClick}
      type="button"
    >
      <div className={classes.imageBlock}>
        <img src={car.photoUrl ?? NoImageCar} alt={`${car.model.brand.name} ${car.model.name}`} />
      </div>
      <div className={classes.dataBlock}>
        <h6 className="h6">
          {car.model.brand.name} {car.model.name}
        </h6>
        {car.licensePlateNumber && <p className="p4">{car.licensePlateNumber}</p>}
      </div>
    </button>
  );
};
