import { Banner } from '../../../ui/components/Banner/Banner';
import { CardNew } from '../../../ui/components/CardNew';
import { PrimaryService } from '../../../ui/components/ServiceCard/PrimaryService';
import { WYSIWYG } from '../../../ui/base/WYSIWYG';
import { format } from 'date-fns';
import { useOthersNews } from '../../../hooks/useNews';
import { useOnePromo } from '../../../hooks/usePromotions';
import s from './NewsPage.module.scss';
import { SlugWrapper } from '../../../ui/components/SlugWrapper/SlugWrapper';
import clsx from 'clsx';
import React, { FC } from 'react';
import { NewsArticleDto } from '../../../api/generated/caready';
import { NewsApi } from '../../../api';
import { MetaDescription } from '../../../ui/base/MetaTags/MetaDescription';

const PageTitle: FC<NewsArticleDto> = function PageTitle(props) {
  return (
    <>
      {props.shortDescription && <MetaDescription description={props.shortDescription} />}
      <h1 className={clsx('h2', s.mainTitle)}>{props.headline}</h1>
      <div className={clsx(s.tags, 'tags')}>
        <span className={clsx('tag', 'p3')}>{props.category.name}</span>
        <span className={clsx('tag', 'p3')}>
          {format(new Date(props.publishedAt), 'dd.MM.yyyy')}
        </span>
      </div>
    </>
  );
};

const PageContent: FC<NewsArticleDto> = function PageContent(props) {
  const news = useOthersNews('', 4, props?.id);
  const promo = useOnePromo('', 1);

  return (
    <>
      <div className={s.mainBlock}>
        <div className={s.pageContent}>
          <picture>
            <img src={props.coverImageUrl} />
          </picture>
          <WYSIWYG html={props.content} />
        </div>
        <div className={s.sideBlock}>
          <div className={s.otherNews}>
            <h2 className={'h4'}>Другие Новости</h2>
            <div className={s.otherCards}>
              {news.map(card => (
                <CardNew key={card.headline} newsData={card} />
              ))}
            </div>
          </div>
          {!!promo.length && promo.map(card => <Banner key={card.headline} category={card} />)}
        </div>
        {props.serviceCategories.length > 0 ? (
          <div className={s.lowerBlock}>
            <h2 className={'h4'}>Услуги</h2>
            <div className={s.lowerCards}>
              {props.serviceCategories.map((category, idx) => (
                <PrimaryService key={idx} category={category} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const parentPage = {
  name: 'Новости',
  path: '/news',
};

export const NewsPage = function NewsPage() {
  const getNewsArticleBySlug = async (slug: string): Promise<NewsArticleDto> => {
    const { data } = await NewsApi.newsControllerGetPublishedNewsArticleBySlug(slug);
    return data;
  };

  return (
    <SlugWrapper<NewsArticleDto, 'headline'>
      request={getNewsArticleBySlug}
      parentPage={parentPage}
      PageContent={PageContent}
      PageTitle={PageTitle}
      titleName={'headline'}
    />
  );
};
