/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CarBrandDto } from '../dtos';
// @ts-ignore
import type { CarBrandWithPopularityDto } from '../dtos';
// @ts-ignore
import type { CarModelDto } from '../dtos';
// @ts-ignore
import type { CarModelGenerationDto } from '../dtos';
// @ts-ignore
import type { CarModelModificationsSearchResultsDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
/**
 * CarDictionaryApi - axios parameter creator
 * @export
 */
export const CarDictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get car brands
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarBrands: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/car-dictionary/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get car brands
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarBrandsWithRating: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/car-dictionary/brands-with-rating`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get car model generations
         * @param {string} id ID of the car model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelGenerations: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('carDictionaryControllerGetCarModelGenerations', 'id', id)
            const localVarPath = `/car-dictionary/models/{id}/generations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get car model modifications
         * @param {string} id ID of the car model
         * @param {string} [generationId] Generation ID
         * @param {string} [bodyTypeId] Body type ID
         * @param {CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum} [transmissionType] Transmission type
         * @param {CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum} [engineFuelType] Engine fuel type
         * @param {CarDictionaryControllerGetCarModelModificationsDriveTypeEnum} [driveType] Drive type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelModifications: async (id: string, generationId?: string, bodyTypeId?: string, transmissionType?: CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum, engineFuelType?: CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum, driveType?: CarDictionaryControllerGetCarModelModificationsDriveTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('carDictionaryControllerGetCarModelModifications', 'id', id)
            const localVarPath = `/car-dictionary/models/{id}/modifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generationId !== undefined) {
                localVarQueryParameter['generationId'] = generationId;
            }

            if (bodyTypeId !== undefined) {
                localVarQueryParameter['bodyTypeId'] = bodyTypeId;
            }

            if (transmissionType !== undefined) {
                localVarQueryParameter['transmissionType'] = transmissionType;
            }

            if (engineFuelType !== undefined) {
                localVarQueryParameter['engineFuelType'] = engineFuelType;
            }

            if (driveType !== undefined) {
                localVarQueryParameter['driveType'] = driveType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get car models by brand\'s slug
         * @summary Get car models by brand
         * @param {string} id 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelsByBrandId: async (id: string, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('carDictionaryControllerGetCarModelsByBrandId', 'id', id)
            const localVarPath = `/car-dictionary/brands/id/{id}/models`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get car models by brand\'s slug
         * @summary Get car models by brand
         * @param {string} slug 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelsByBrandSlug: async (slug: string, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('carDictionaryControllerGetCarModelsByBrandSlug', 'slug', slug)
            const localVarPath = `/car-dictionary/brands/{slug}/models`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarDictionaryApi - functional programming interface
 * @export
 */
export const CarDictionaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarDictionaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get car brands
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carDictionaryControllerGetCarBrands(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarBrandDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carDictionaryControllerGetCarBrands(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarDictionaryApi.carDictionaryControllerGetCarBrands']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get car brands
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carDictionaryControllerGetCarBrandsWithRating(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarBrandWithPopularityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carDictionaryControllerGetCarBrandsWithRating(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarDictionaryApi.carDictionaryControllerGetCarBrandsWithRating']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get car model generations
         * @param {string} id ID of the car model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carDictionaryControllerGetCarModelGenerations(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarModelGenerationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carDictionaryControllerGetCarModelGenerations(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarDictionaryApi.carDictionaryControllerGetCarModelGenerations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get car model modifications
         * @param {string} id ID of the car model
         * @param {string} [generationId] Generation ID
         * @param {string} [bodyTypeId] Body type ID
         * @param {CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum} [transmissionType] Transmission type
         * @param {CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum} [engineFuelType] Engine fuel type
         * @param {CarDictionaryControllerGetCarModelModificationsDriveTypeEnum} [driveType] Drive type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carDictionaryControllerGetCarModelModifications(id: string, generationId?: string, bodyTypeId?: string, transmissionType?: CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum, engineFuelType?: CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum, driveType?: CarDictionaryControllerGetCarModelModificationsDriveTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarModelModificationsSearchResultsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carDictionaryControllerGetCarModelModifications(id, generationId, bodyTypeId, transmissionType, engineFuelType, driveType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarDictionaryApi.carDictionaryControllerGetCarModelModifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get car models by brand\'s slug
         * @summary Get car models by brand
         * @param {string} id 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carDictionaryControllerGetCarModelsByBrandId(id: string, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarModelDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carDictionaryControllerGetCarModelsByBrandId(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarDictionaryApi.carDictionaryControllerGetCarModelsByBrandId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get car models by brand\'s slug
         * @summary Get car models by brand
         * @param {string} slug 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carDictionaryControllerGetCarModelsByBrandSlug(slug: string, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarModelDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carDictionaryControllerGetCarModelsByBrandSlug(slug, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CarDictionaryApi.carDictionaryControllerGetCarModelsByBrandSlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CarDictionaryApi - factory interface
 * @export
 */
export const CarDictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarDictionaryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get car brands
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarBrands(name?: string, options?: any): AxiosPromise<Array<CarBrandDto>> {
            return localVarFp.carDictionaryControllerGetCarBrands(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get car brands
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarBrandsWithRating(name?: string, options?: any): AxiosPromise<CarBrandWithPopularityDto> {
            return localVarFp.carDictionaryControllerGetCarBrandsWithRating(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get car model generations
         * @param {string} id ID of the car model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelGenerations(id: string, options?: any): AxiosPromise<Array<CarModelGenerationDto>> {
            return localVarFp.carDictionaryControllerGetCarModelGenerations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get car model modifications
         * @param {string} id ID of the car model
         * @param {string} [generationId] Generation ID
         * @param {string} [bodyTypeId] Body type ID
         * @param {CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum} [transmissionType] Transmission type
         * @param {CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum} [engineFuelType] Engine fuel type
         * @param {CarDictionaryControllerGetCarModelModificationsDriveTypeEnum} [driveType] Drive type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelModifications(id: string, generationId?: string, bodyTypeId?: string, transmissionType?: CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum, engineFuelType?: CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum, driveType?: CarDictionaryControllerGetCarModelModificationsDriveTypeEnum, options?: any): AxiosPromise<CarModelModificationsSearchResultsDto> {
            return localVarFp.carDictionaryControllerGetCarModelModifications(id, generationId, bodyTypeId, transmissionType, engineFuelType, driveType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get car models by brand\'s slug
         * @summary Get car models by brand
         * @param {string} id 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelsByBrandId(id: string, name?: string, options?: any): AxiosPromise<Array<CarModelDto>> {
            return localVarFp.carDictionaryControllerGetCarModelsByBrandId(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get car models by brand\'s slug
         * @summary Get car models by brand
         * @param {string} slug 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carDictionaryControllerGetCarModelsByBrandSlug(slug: string, name?: string, options?: any): AxiosPromise<Array<CarModelDto>> {
            return localVarFp.carDictionaryControllerGetCarModelsByBrandSlug(slug, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarDictionaryApi - interface
 * @export
 * @interface CarDictionaryApi
 */
export interface CarDictionaryApiInterface {
    /**
     * 
     * @summary Get car brands
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApiInterface
     */
    carDictionaryControllerGetCarBrands(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CarBrandDto>>;

    /**
     * 
     * @summary Get car brands
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApiInterface
     */
    carDictionaryControllerGetCarBrandsWithRating(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<CarBrandWithPopularityDto>;

    /**
     * 
     * @summary Get car model generations
     * @param {string} id ID of the car model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApiInterface
     */
    carDictionaryControllerGetCarModelGenerations(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CarModelGenerationDto>>;

    /**
     * 
     * @summary Get car model modifications
     * @param {string} id ID of the car model
     * @param {string} [generationId] Generation ID
     * @param {string} [bodyTypeId] Body type ID
     * @param {CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum} [transmissionType] Transmission type
     * @param {CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum} [engineFuelType] Engine fuel type
     * @param {CarDictionaryControllerGetCarModelModificationsDriveTypeEnum} [driveType] Drive type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApiInterface
     */
    carDictionaryControllerGetCarModelModifications(id: string, generationId?: string, bodyTypeId?: string, transmissionType?: CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum, engineFuelType?: CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum, driveType?: CarDictionaryControllerGetCarModelModificationsDriveTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<CarModelModificationsSearchResultsDto>;

    /**
     * Get car models by brand\'s slug
     * @summary Get car models by brand
     * @param {string} id 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApiInterface
     */
    carDictionaryControllerGetCarModelsByBrandId(id: string, name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CarModelDto>>;

    /**
     * Get car models by brand\'s slug
     * @summary Get car models by brand
     * @param {string} slug 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApiInterface
     */
    carDictionaryControllerGetCarModelsByBrandSlug(slug: string, name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CarModelDto>>;

}

/**
 * CarDictionaryApi - object-oriented interface
 * @export
 * @class CarDictionaryApi
 * @extends {BaseAPI}
 */
export class CarDictionaryApi extends BaseAPI implements CarDictionaryApiInterface {
    /**
     * 
     * @summary Get car brands
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApi
     */
    public carDictionaryControllerGetCarBrands(name?: string, options?: RawAxiosRequestConfig) {
        return CarDictionaryApiFp(this.configuration).carDictionaryControllerGetCarBrands(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get car brands
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApi
     */
    public carDictionaryControllerGetCarBrandsWithRating(name?: string, options?: RawAxiosRequestConfig) {
        return CarDictionaryApiFp(this.configuration).carDictionaryControllerGetCarBrandsWithRating(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get car model generations
     * @param {string} id ID of the car model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApi
     */
    public carDictionaryControllerGetCarModelGenerations(id: string, options?: RawAxiosRequestConfig) {
        return CarDictionaryApiFp(this.configuration).carDictionaryControllerGetCarModelGenerations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get car model modifications
     * @param {string} id ID of the car model
     * @param {string} [generationId] Generation ID
     * @param {string} [bodyTypeId] Body type ID
     * @param {CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum} [transmissionType] Transmission type
     * @param {CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum} [engineFuelType] Engine fuel type
     * @param {CarDictionaryControllerGetCarModelModificationsDriveTypeEnum} [driveType] Drive type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApi
     */
    public carDictionaryControllerGetCarModelModifications(id: string, generationId?: string, bodyTypeId?: string, transmissionType?: CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum, engineFuelType?: CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum, driveType?: CarDictionaryControllerGetCarModelModificationsDriveTypeEnum, options?: RawAxiosRequestConfig) {
        return CarDictionaryApiFp(this.configuration).carDictionaryControllerGetCarModelModifications(id, generationId, bodyTypeId, transmissionType, engineFuelType, driveType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get car models by brand\'s slug
     * @summary Get car models by brand
     * @param {string} id 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApi
     */
    public carDictionaryControllerGetCarModelsByBrandId(id: string, name?: string, options?: RawAxiosRequestConfig) {
        return CarDictionaryApiFp(this.configuration).carDictionaryControllerGetCarModelsByBrandId(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get car models by brand\'s slug
     * @summary Get car models by brand
     * @param {string} slug 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarDictionaryApi
     */
    public carDictionaryControllerGetCarModelsByBrandSlug(slug: string, name?: string, options?: RawAxiosRequestConfig) {
        return CarDictionaryApiFp(this.configuration).carDictionaryControllerGetCarModelsByBrandSlug(slug, name, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum = {
    Mt: 'MT',
    At: 'AT',
    Amt: 'AMT',
    Cvt: 'CVT'
} as const;
export type CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum = typeof CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum[keyof typeof CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum];
/**
 * @export
 */
export const CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum = {
    Gasoline: 'GASOLINE',
    Diesel: 'DIESEL',
    Electric: 'ELECTRIC',
    Hybrid: 'HYBRID',
    EcoFuel: 'ECO_FUEL'
} as const;
export type CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum = typeof CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum[keyof typeof CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum];
/**
 * @export
 */
export const CarDictionaryControllerGetCarModelModificationsDriveTypeEnum = {
    Fwd: 'FWD',
    Rwd: 'RWD',
    Awd: 'AWD',
    _4Wd: '4WD'
} as const;
export type CarDictionaryControllerGetCarModelModificationsDriveTypeEnum = typeof CarDictionaryControllerGetCarModelModificationsDriveTypeEnum[keyof typeof CarDictionaryControllerGetCarModelModificationsDriveTypeEnum];
