import { useEffect, useState } from 'react';
import { MaintenanceAppointmentsApi } from '../api';
import { MaintenanceAppointmentRatingDto } from '../api/generated/caready';

export const useRatingsById = (id: string) => {
  const [data, setData] = useState<MaintenanceAppointmentRatingDto | null>(null);

  useEffect(() => {
    MaintenanceAppointmentsApi.maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(
      id
    ).then(({ data }) => {
      setData(data);
    });
  }, []);

  return data;
};
