/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { SubscribeUserToNewsletterDto } from '../dtos';
/**
 * NewslettersApi - axios parameter creator
 * @export
 */
export const NewslettersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Subscribe user to the specified newsletter
         * @summary Subscribe user to a newsletter
         * @param {SubscribeUserToNewsletterDto} subscribeUserToNewsletterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newslettersControllerGetPublishedNews: async (subscribeUserToNewsletterDto: SubscribeUserToNewsletterDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribeUserToNewsletterDto' is not null or undefined
            assertParamExists('newslettersControllerGetPublishedNews', 'subscribeUserToNewsletterDto', subscribeUserToNewsletterDto)
            const localVarPath = `/newsletters/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscribeUserToNewsletterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewslettersApi - functional programming interface
 * @export
 */
export const NewslettersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewslettersApiAxiosParamCreator(configuration)
    return {
        /**
         * Subscribe user to the specified newsletter
         * @summary Subscribe user to a newsletter
         * @param {SubscribeUserToNewsletterDto} subscribeUserToNewsletterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newslettersControllerGetPublishedNews(subscribeUserToNewsletterDto: SubscribeUserToNewsletterDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newslettersControllerGetPublishedNews(subscribeUserToNewsletterDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NewslettersApi.newslettersControllerGetPublishedNews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NewslettersApi - factory interface
 * @export
 */
export const NewslettersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewslettersApiFp(configuration)
    return {
        /**
         * Subscribe user to the specified newsletter
         * @summary Subscribe user to a newsletter
         * @param {SubscribeUserToNewsletterDto} subscribeUserToNewsletterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newslettersControllerGetPublishedNews(subscribeUserToNewsletterDto: SubscribeUserToNewsletterDto, options?: any): AxiosPromise<void> {
            return localVarFp.newslettersControllerGetPublishedNews(subscribeUserToNewsletterDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewslettersApi - interface
 * @export
 * @interface NewslettersApi
 */
export interface NewslettersApiInterface {
    /**
     * Subscribe user to the specified newsletter
     * @summary Subscribe user to a newsletter
     * @param {SubscribeUserToNewsletterDto} subscribeUserToNewsletterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApiInterface
     */
    newslettersControllerGetPublishedNews(subscribeUserToNewsletterDto: SubscribeUserToNewsletterDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * NewslettersApi - object-oriented interface
 * @export
 * @class NewslettersApi
 * @extends {BaseAPI}
 */
export class NewslettersApi extends BaseAPI implements NewslettersApiInterface {
    /**
     * Subscribe user to the specified newsletter
     * @summary Subscribe user to a newsletter
     * @param {SubscribeUserToNewsletterDto} subscribeUserToNewsletterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewslettersApi
     */
    public newslettersControllerGetPublishedNews(subscribeUserToNewsletterDto: SubscribeUserToNewsletterDto, options?: RawAxiosRequestConfig) {
        return NewslettersApiFp(this.configuration).newslettersControllerGetPublishedNews(subscribeUserToNewsletterDto, options).then((request) => request(this.axios, this.basePath));
    }
}

