import s from './Banner.module.scss';
import { PromotionDto } from '../../../api/generated/caready';
import { UTMLink } from '../../base/UTMLink/UTMLink';
import { FC } from 'react';
import { format } from 'date-fns';

interface OwnProps {
  category: PromotionDto;
}

export const Banner: FC<OwnProps> = function Banner(props) {
  return (
    <UTMLink className={s.banner} to={`/promo/${props.category.slug}/`}>
      <div className={s.content}>
        <div className={s.data}>
          <div className={s.titleWrapper}>
            <h4 className="h4">{props.category.headline}</h4>
            {props.category?.endsAt && (
              <span className={`tag p3 ${s.tag}`}>
                До {format(new Date(props.category.endsAt), 'dd.MM.yyyy')}
              </span>
            )}
          </div>
          {props.category.briefDescription && (
            <p className="p2">{props.category.briefDescription}</p>
          )}
        </div>
        <button className={`${s.btnMore} btn`}>Узнать подробнее</button>
      </div>
      <picture className={`${s.imageBg} ${s.darken}`}>
        <img src={props.category.coverImageUrl} alt={'Background image'} />
      </picture>
    </UTMLink>
  );
};
