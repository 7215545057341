import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CoreHead } from '../../ui/core';
import {
  PrimaryService,
  PrimaryServiceSkeleton,
} from '../../ui/components/ServiceCard/PrimaryService';
import { useMaintenanceServicesCategories } from '../../hooks/useMaintenanceServices';
import s from './ServiceListPage.module.scss';
import commonStyles from '../../styles/common/common-styles.module.scss';
import { SkeletonGroup } from '../../ui/base/Skeleton/SkeletonGroup';

export const ServicesListPage = function ServicesListPage() {
  const { data, isLoading } = useMaintenanceServicesCategories();

  const breadCrumbs = [
    BREAD_CRUMB_MAIN,
    {
      name: 'Услуги',
    },
  ];

  return (
    <>
      <CoreHead
        title={'Услуги'}
        description={
          'Caready предоставлет широкий спектр услуг по обслуживанию и ремонту автомобилей в различных регионах Россиий'
        }
      />
      <div className={'section'}>
        <BreadCrumbs links={breadCrumbs} />
        <h1 className={`h2 ${s.title}`}>Услуги</h1>
        <div className={commonStyles.cardsWrapper}>
          <SkeletonGroup isLoading={isLoading} quantity={4} skeleton={PrimaryServiceSkeleton}>
            {data?.map((category, idx) => <PrimaryService key={idx} category={category} />)}
          </SkeletonGroup>
        </div>
      </div>
    </>
  );
};
