import React from 'react';
import styles from './rating.module.css';

export const Rating = ({
  label,
  children,
}: {
  label?: string;
  children?: JSX.Element | JSX.Element[];
}) => {
  return (
    <div>
      <p className={styles.ratingLabel}>{label}</p>
      <div className={styles.ratingButtons}>
        {/* {buttons.map((button) => (
          <label key={button}>
            <input type="radio" name="testName" value={button} />
            <div>
              <span className="p2">{button}</span>
            </div>
          </label>
        ))} */}
        {children}
      </div>
    </div>
  );
};
