import { ReactComponent as Camera } from 'assets/svg/camera.svg';
import { ReactComponent as CrossMedium } from 'assets/svg/cross-medium.svg';
import { ReactComponent as Profile } from 'assets/svg/profile.svg';
import classes from './profile-picture.module.css';
import { UsersApi } from '../../../api';
import { useAuth } from '../../../context/AuthContext';

// todo: отрефакторить
export const ProfilePicture = ({
  isLarge,
  name,
  img,
}: {
  isLarge?: boolean;
  img?: string | null;
  name?: string | null;
}) => {
  const { setUser } = useAuth();
  const initials = name?.split(' ').map(str => str[0]);

  return (
    <div className={`${classes.btn} ${isLarge ? classes.large : ''}`}>
      {img ? (
        <img src={img} alt={`${name} avatar`} />
      ) : initials ? (
        <span className={`${classes.initials} ${isLarge ? 'h3' : 'h6'}`}>{initials}</span>
      ) : (
        <Profile className={classes.icon} />
      )}
      {isLarge && (
        <label className={classes.control}>
          {img ? (
            <button>
              <CrossMedium />
            </button>
          ) : (
            <>
              <Camera />
              <input
                type="file"
                accept=".png, jpeg, jpg"
                onChange={e => {
                  if (!!e.target.files) {
                    UsersApi.usersControllerUpdateAvatar(e.target.files[0]).then(({ data }) => {
                      if (data) {
                        setUser(data);
                      }
                    });
                  }
                }}
              />
            </>
          )}
        </label>
      )}
      {isLarge &&
        (img ? (
          <button
            className={classes.control}
            onClick={() => UsersApi.usersControllerRemoveAvatar().then(({ data }) => setUser(data))}
          >
            <CrossMedium />
          </button>
        ) : (
          <label className={classes.control}>
            <Camera />
            <input
              type="file"
              accept=".png, .jpeg, .jpg"
              onChange={e => {
                if (!!e.target.files) {
                  UsersApi.usersControllerUpdateAvatar(e.target.files[0]).then(({ data }) => {
                    if (data) {
                      setUser(data);
                    }
                  });
                }
              }}
            />
          </label>
        ))}
    </div>
  );
};
