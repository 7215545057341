import React from 'react';
import { MobileMap } from './mobile/MobileMap';
import { DesktopMap } from './desktop/DesktopMap';
import breakPoints from '../../../styles/breakpoints.module.scss';
import { useMatch } from '../../../hooks/useMatch';

export const MapComponent = function MapComponent() {
  const { isMatch } = useMatch(breakPoints.bp768);

  return isMatch ? <MobileMap /> : <DesktopMap />;
};
