export type ModalName =
  | 'forgotPassword'
  | 'sendCode'
  | 'newPassword'
  | 'create'
  | 'login'
  | 'personalInfo';

export type SignModalsType = Record<ModalName, ModalName>;

export const AuthModals = {
  forgotPassword: 'forgotPassword',
  sendCode: 'sendCode',
  newPassword: 'newPassword',
  create: 'create',
  login: 'login',
  personalInfo: 'personalInfo',
} as const satisfies SignModalsType;
