import { useEffect, useState } from 'react';
import { getDataFromUTM, UtmData } from '../utils/getDataFromUTM';
import { To, useLocation } from 'react-router-dom';
import { FilterType, getFilteredSearchParams } from '../utils/getFilteredSearchParams';

export const useUtm = () => {
  const [utmData, setUtmData] = useState<UtmData | null>(null);
  const location = useLocation();

  useEffect(() => {
    setUtmData(getDataFromUTM(location.search));
  }, [location.search]);

  const getLinkWithSearchParams = (filterType: FilterType, to: To = location.pathname) => {
    if (!utmData || typeof window === 'undefined') {
      return to;
    }

    const searchParams = getFilteredSearchParams(location.search, filterType);

    if (typeof to === 'string') return `${to}${searchParams && `?${searchParams}`}`;

    return {
      ...to,
      search:
        to.search || searchParams ? `?${[to.search, searchParams].filter(Boolean).join('&')}` : '',
    };
  };

  return { utmData, getLinkWithSearchParams };
};
