import { AuthError } from '../config/errorTexts';
import { AuthErrorEnum } from '../config/auth-error.enum';
import { AxiosError } from 'axios';

export function getErrorMessage(data: AxiosError<{ message: string }>) {
  const errorKey = data.response?.data?.message || AuthErrorEnum.SOMETHING_WENT_WRONG;

  // todo: убрать игнор
  // @ts-ignore
  return AuthError[errorKey] || AuthError.something_went_wrong;
}
