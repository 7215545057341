import { ReactComponent as EyeClosed } from 'assets/svg/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'assets/svg/eye-open.svg';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Input } from '../../../base/Input';
import { ModalClose } from '../../../base/ModalBase';
import { object } from 'yup';
import { password } from '../../../../config/validationSchemas';
import React, { useState } from 'react';
import classes from './new-password-modal.module.css';
import { AuthApi } from '../../../../api';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const initialValues: FormValues = {
  password: '',
  confirmPassword: '',
};

const validationSchema = object().shape({
  password,
  confirmPassword: password,
});

export const NewPassword = ({ closeModal }: { closeModal: () => void }) => {
  const [visible, setVisible] = useState({ password: true, confirm: true });

  const onSubmit = ({ password }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    AuthApi.authControllerUpdatePassword({ newPassword: password }).then(() => {
      formikHelpers.setSubmitting(false);
      closeModal();
    });
  };

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, isValid, errors, touched }) => (
        <>
          <header className="modal_header">
            <h4 className={`${classes.title} h4`}>Создать новый пароль</h4>
            <ModalClose />
          </header>
          <Form className="modal_container">
            <div className={`${classes.fields} modal_inner`}>
              <div className={classes.inputBlock}>
                <Input
                  icon={
                    <span
                      onClick={() =>
                        setVisible({
                          ...visible,
                          password: !visible.password,
                        })
                      }
                    >
                      {visible.password ? <EyeClosed /> : <EyeOpen />}
                    </span>
                  }
                  label="Пароль"
                  error={errors.password as string}
                  touched={touched.password}
                >
                  <Field
                    name="password"
                    type={visible.password ? 'password' : 'text'}
                    placeholder="Введите пароль"
                  />
                </Input>
                <p className={`${classes.help} p3`}>Пароль должен состоять минимум из 8 символов</p>
              </div>
              <Input
                icon={
                  <span
                    onClick={() =>
                      setVisible({
                        ...visible,
                        confirm: !visible.confirm,
                      })
                    }
                  >
                    {visible.confirm ? <EyeClosed /> : <EyeOpen />}
                  </span>
                }
                label="Подтвердите пароль"
                error={errors.confirmPassword as string}
                touched={touched.confirmPassword}
              >
                <Field
                  name="confirmPassword"
                  type={visible.confirm ? 'password' : 'text'}
                  placeholder="Повторите ввод нового пароля"
                />
              </Input>
            </div>
            <footer className={`${classes.footer} modal_footer`}>
              <button
                type="submit"
                className="btn"
                disabled={
                  !values.password ||
                  !values.confirmPassword ||
                  !(values.password === values.confirmPassword) ||
                  !isValid
                }
              >
                Сохранить
              </button>
            </footer>
          </Form>
        </>
      )}
    </Formik>
  );
};
