import { useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';
import { NewsDto, PromotionDto, SearchResultsDto } from '../api/generated/caready';
import { SearchApi } from '../api';

export const useSearch = (page: number, perPage?: number) => {
  const [searchResults, setSearchResults] = useState<SearchResultsDto>();

  const getSearchResults = (query: string) => {
    if (!!query) {
      SearchApi.searchControllerSearchEverything(query, perPage, page).then(({ data }) => {
        setSearchResults(data);
      });
    }
  };

  const debouncedGetSearchResults = useDebounce(getSearchResults);

  return { searchResults, getSearchResults, debouncedGetSearchResults };
};

// todo: поправить perPage, когда добавим пагинацию
export const useSearchNews = (page: number, query: string) => {
  const [results, setResults] = useState<NewsDto[]>([]);

  useEffect(() => {
    SearchApi.searchControllerSearchNews(query, 100, page).then(({ data }) => {
      setResults(data.items);
    });
  }, [query]);

  return results;
};

export const useSearchPromotions = (page: number, query: string) => {
  const [results, setResults] = useState<PromotionDto[]>([]);

  useEffect(() => {
    SearchApi.searchControllerSearchPromotions(query, 100, page).then(({ data }) => {
      setResults(data.items);
    });
  }, [query]);

  return results;
};
