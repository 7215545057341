/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ApplyForJobDto } from '../dtos';
// @ts-ignore
import type { JobApplicationAttachmentDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
/**
 * JobApplicationsApi - axios parameter creator
 * @export
 */
export const JobApplicationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authorization is optional
         * @summary Apply for a job
         * @param {ApplyForJobDto} applyForJobDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobApplicationsControllerApplyForJob: async (applyForJobDto: ApplyForJobDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyForJobDto' is not null or undefined
            assertParamExists('jobApplicationsControllerApplyForJob', 'applyForJobDto', applyForJobDto)
            const localVarPath = `/jobs/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyForJobDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload an attachment for a job application
         * @param {File} file Supported extensions: pdf, doc/docx. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobApplicationsControllerUploadAttachment: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('jobApplicationsControllerUploadAttachment', 'file', file)
            const localVarPath = `/jobs/applications/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobApplicationsApi - functional programming interface
 * @export
 */
export const JobApplicationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobApplicationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Authorization is optional
         * @summary Apply for a job
         * @param {ApplyForJobDto} applyForJobDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobApplicationsControllerApplyForJob(applyForJobDto: ApplyForJobDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobApplicationsControllerApplyForJob(applyForJobDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobApplicationsApi.jobApplicationsControllerApplyForJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload an attachment for a job application
         * @param {File} file Supported extensions: pdf, doc/docx. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobApplicationsControllerUploadAttachment(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobApplicationAttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobApplicationsControllerUploadAttachment(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobApplicationsApi.jobApplicationsControllerUploadAttachment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JobApplicationsApi - factory interface
 * @export
 */
export const JobApplicationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobApplicationsApiFp(configuration)
    return {
        /**
         * Authorization is optional
         * @summary Apply for a job
         * @param {ApplyForJobDto} applyForJobDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobApplicationsControllerApplyForJob(applyForJobDto: ApplyForJobDto, options?: any): AxiosPromise<void> {
            return localVarFp.jobApplicationsControllerApplyForJob(applyForJobDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload an attachment for a job application
         * @param {File} file Supported extensions: pdf, doc/docx. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobApplicationsControllerUploadAttachment(file: File, options?: any): AxiosPromise<JobApplicationAttachmentDto> {
            return localVarFp.jobApplicationsControllerUploadAttachment(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobApplicationsApi - interface
 * @export
 * @interface JobApplicationsApi
 */
export interface JobApplicationsApiInterface {
    /**
     * Authorization is optional
     * @summary Apply for a job
     * @param {ApplyForJobDto} applyForJobDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApplicationsApiInterface
     */
    jobApplicationsControllerApplyForJob(applyForJobDto: ApplyForJobDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Upload an attachment for a job application
     * @param {File} file Supported extensions: pdf, doc/docx. Maximum allowed file size is 5 MB.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApplicationsApiInterface
     */
    jobApplicationsControllerUploadAttachment(file: File, options?: RawAxiosRequestConfig): AxiosPromise<JobApplicationAttachmentDto>;

}

/**
 * JobApplicationsApi - object-oriented interface
 * @export
 * @class JobApplicationsApi
 * @extends {BaseAPI}
 */
export class JobApplicationsApi extends BaseAPI implements JobApplicationsApiInterface {
    /**
     * Authorization is optional
     * @summary Apply for a job
     * @param {ApplyForJobDto} applyForJobDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApplicationsApi
     */
    public jobApplicationsControllerApplyForJob(applyForJobDto: ApplyForJobDto, options?: RawAxiosRequestConfig) {
        return JobApplicationsApiFp(this.configuration).jobApplicationsControllerApplyForJob(applyForJobDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload an attachment for a job application
     * @param {File} file Supported extensions: pdf, doc/docx. Maximum allowed file size is 5 MB.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApplicationsApi
     */
    public jobApplicationsControllerUploadAttachment(file: File, options?: RawAxiosRequestConfig) {
        return JobApplicationsApiFp(this.configuration).jobApplicationsControllerUploadAttachment(file, options).then((request) => request(this.axios, this.basePath));
    }
}

