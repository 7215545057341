import { FC, ReactNode, useState } from 'react';
import s from './SelectedServiceStation.module.scss';
import { getServiceStationAddress } from '../../../../../utils/getServiceStationAddress';
import { getAggregatedServiceStationScheduleGenerator } from '../../../../../utils/getAggregatedServiceStationScheduleGenerator';
import { ReactComponent as Clock } from 'assets/svg/clock.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import clsx from 'clsx';
import { ServiceStationsApi } from '../../../../../api';
import { getShortWeekday } from '../../../../../utils/getShortWeekday';
import { ServiceStationWorkStatus } from '../../../ServiceStationWorkStatus/ServiceStationWorkStatus';
import { useQuery } from '@tanstack/react-query';
import { Appointment } from '../../../../modal/Appointment';
import { prettifyPhone } from '../../../../../utils/prettifyPhone';

interface OwnProps {
  id: string;
}

// todo: add loading
export const SelectedServiceStation: FC<OwnProps> = function SelectedServiceStation(props) {
  const { data: selectedServiceStation } = useQuery({
    queryKey: ['selected', 'station', props.id],
    queryFn: async () => {
      const { data } = await ServiceStationsApi.serviceStationsControllerGetById(props.id);

      return data;
    },
    staleTime: Infinity,
  });

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState<boolean>(false);

  if (!selectedServiceStation) return null;

  const schedule: ReactNode[] = [];

  for (const aggregatedSchedule of getAggregatedServiceStationScheduleGenerator(
    selectedServiceStation
  )) {
    const startWeekday = getShortWeekday(aggregatedSchedule.dateStart);
    const weekDays = aggregatedSchedule.dateEnd
      ? `${startWeekday} - ${getShortWeekday(aggregatedSchedule.dateEnd)}`
      : startWeekday;
    schedule.push(
      <p key={weekDays} className={'p3'}>{`${weekDays}: ${aggregatedSchedule.schedule}`}</p>
    );
  }

  return (
    <>
      <div className={s.serviceInfos}>
        <div className={s.servicePlace}>
          {selectedServiceStation.photoUrl && (
            <div className={s.photo}>
              <img src={selectedServiceStation.photoUrl} />
            </div>
          )}
          <h5 className="h5">{getServiceStationAddress(selectedServiceStation)}</h5>
          {/*todo: CAREADY-313, hid selected station comment*/}
          {/*{selectedServiceStation.comment && <p className="p2">{selectedServiceStation.comment}</p>}*/}
        </div>
        <div className={s.scheduleBlock}>
          <Clock />
          <div className={s.schedule}>
            <ServiceStationWorkStatus
              withAddress={false}
              serviceStation={selectedServiceStation}
              className={'pm1'}
            />
            {schedule}
          </div>
        </div>
        {selectedServiceStation.phone && (
          // todo: костыль для одинаковых номеров телефона calltouch
          <span
            className={clsx(
              selectedServiceStation.id === 'd317698d-6c7f-462b-b21c-fbf9e41102de' &&
                'call_phone_start',
              selectedServiceStation.id === 'e2c60d46-7a62-4247-b2cb-b834d6305366' &&
                'call_phone_optic'
            )}
          >
            <a href={`tel:${selectedServiceStation.phone}`} className={s.phone}>
              <Phone />
              <span className="pm1">{prettifyPhone(selectedServiceStation.phone)}</span>
            </a>
          </span>
        )}
        <div>
          {!!selectedServiceStation.supportedServiceCategories.length && (
            <>
              <h6 className={clsx('pm1', s.titleCategory)}>Сервисы:</h6>
              <div className="tags">
                {selectedServiceStation.supportedServiceCategories.map(serviceCat => (
                  <span className="tag" key={serviceCat.id}>
                    {serviceCat.name}
                  </span>
                ))}
              </div>
            </>
          )}
          {!!selectedServiceStation.supportedChargerTypes.length && (
            <>
              <h6 className={clsx('pm1', s.titleCategory)}>Зарядка электромобилей:</h6>
              <div className="tags">
                {selectedServiceStation.supportedChargerTypes.map(chargerType => (
                  <span className="tag" key={chargerType.id}>
                    {chargerType.name}
                  </span>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={s.enroll} onClick={() => setIsAppointmentModalOpen(true)}>
        <button className="btn block">Записаться на сервис</button>
      </div>
      <Appointment
        appointmentServiceStation={selectedServiceStation}
        onCloseButtonClick={() => setIsAppointmentModalOpen(false)}
        isOpen={isAppointmentModalOpen}
      />
    </>
  );
};
