import { ReactComponent as Check } from 'assets/svg/check.svg';
import { ReactComponent as EyeClosed } from 'assets/svg/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'assets/svg/eye-open.svg';
import { FocusEventHandler, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useIMask } from 'react-imask';
import classes from './input.module.css';

export const Input = ({
  children,
  icon,
  error,
  text,
  label,
  touched,
}: {
  children: JSX.Element;
  icon?: JSX.Element;
  text?: string;
  error?: string;
  label?: string;
  touched?: boolean;
}) => {
  return (
    <div>
      {label && <p className="label">{label}</p>}
      <label className={`${classes.label} ${error && touched ? classes.error : ''}`}>
        {children}
        {(!error || icon) && (
          <span className={`${classes.icon} ${classes.check}`}>{icon ?? <Check />}</span>
        )}
        {text && <span className={classes.text}>{text}</span>}
      </label>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
};

export const PasswordInput = () => {
  const [password, setPassword] = useState(true);

  return (
    <Input
      icon={
        <span onClick={() => setPassword(!password)}>{password ? <EyeClosed /> : <EyeOpen />}</span>
      }
    >
      <input type={password ? 'password' : 'text'} />
    </Input>
  );
};

// todo: поправить cursor: pointer
export const SelectInput = ({
  children,
  icons,
  error,
  text,
  label,
  touched,
}: {
  icons: ReactNode;
  text?: string;
  error?: string;
  label?: string;
  touched?: boolean;
} & PropsWithChildren) => {
  return (
    <div>
      {label && <p className="label">{label}</p>}
      <label className={`${classes.label} ${error && touched ? classes.error : ''}`}>
        {children}
        <span className={classes.icons}>{icons}</span>
        {text && <span className={classes.text}>{text}</span>}
      </label>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
};

export const PhoneNumber = ({
  name,
  setField,
  clientPhoneNumber,
  onBlur,
}: {
  name: string;
  setField: (v: string) => void;
  clientPhoneNumber?: string;
  onBlur?: FocusEventHandler;
}) => {
  const { ref, value, setValue, maskRef } = useIMask(
    { mask: '{+7} 000 000-00-00', lazy: true },
    {
      onAccept: (value, maskRef) => {
        setField(maskRef.unmaskedValue);
      },
    }
  );

  useEffect(() => {
    if (!!clientPhoneNumber && maskRef.current) {
      maskRef.current.unmaskedValue = clientPhoneNumber;
    }
  }, [clientPhoneNumber]);

  return (
    <input
      name={name}
      placeholder="Введите номер мобильного телефона"
      ref={ref}
      onBlur={onBlur}
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
    />
  );
};

export const LicensePlateNumberInput = ({
  name,
  setField,
}: {
  name: string;
  setField: (v: string) => void;
}) => {
  const { ref, value, setValue } = useIMask(
    {
      mask: 'a000aa 00[0]',
    },
    {
      onAccept: (value, maskRef) => {
        setField(maskRef.unmaskedValue.toUpperCase());
      },
    }
  );

  return (
    <input
      name={name}
      placeholder="Введите гос. номер"
      ref={ref}
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  );
};

export const CodeInput = ({
  error,
  setField,
  name,
}: {
  error?: string;
  touched?: boolean;
  name: string;
  setField: (v: string) => void;
}) => {
  const { ref, value, setValue } = useIMask(
    {
      mask: '000-000',
    },
    {
      onAccept: value => {
        setField(value);
      },
    }
  );
  return (
    <div>
      <p className="label">Код</p>
      <label className={`${classes.label} ${error ? classes.error : ''}`}>
        <input
          placeholder="Введите код"
          name={name}
          ref={ref}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </label>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export const DateInput = ({
  name,
  placeholder,
  setField,
}: {
  placeholder: string;
  name: string;
  setField: (v: string) => void;
}) => {
  const { ref, value, setValue } = useIMask(
    { mask: Date, lazy: false },
    {
      onAccept: (value, maskRef) => {
        setField(maskRef.unmaskedValue);
      },
    }
  );

  return (
    <input
      name={name}
      placeholder={placeholder}
      ref={ref}
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
    />
  );
};
