import { ReviewProps } from '../Review/Review';
import type Swiper from 'swiper';

export type Service = {
  slides: string[];
  serviceName: string;
  address: string;
  review: ReviewProps;
};

export type TSwiper = Swiper;

export const serviceData: Service[] = [
  {
    slides: [
      'franchise/experience-section/irkutsk/slider/1.webp',
      'franchise/experience-section/irkutsk/slider/2.webp',
      'franchise/experience-section/irkutsk/slider/3.webp',
    ],
    serviceName: 'ГАРАНТ-АВТО',
    address: 'г. Иркутск',
    review: {
      avatar: 'franchise/experience-section/irkutsk/review.webp',
      authorName: 'Назаров Алексей Анатольевич',
      authorPosition: 'Директор сервисной станции Caready в Иркутске',
      title: 'Благодарим команду CAREADY за поддержу',
      text: 'За тот потенциал, который нам вверен, которым мы будем с удовольствием управлять и распоряжаться, для того чтобы нашим клиентам было еще удобнее и приятнее обслуживать автомобили в сервисном центре Гарант Авто',
    },
  },
  {
    slides: [
      'franchise/experience-section/moscow/slider/1.webp',
      'franchise/experience-section/moscow/slider/2.webp',
      'franchise/experience-section/moscow/slider/3.webp',
    ],
    serviceName: 'КВАЗАР',
    address: 'г. Москва',
    review: {
      avatar: 'franchise/experience-section/moscow/review.webp',
      authorName: 'Константинов Андрей Константинович',
      authorPosition: 'Директор сервисной станции Caready в Москве',
      title: 'CAREADY специализируется на автомобилях всех марок',
      text: 'Мы работаем над тем, чтобы наши клиенты были уверены в персонале сервисной станции. CAREADY обладает собственным брендом запасных частей, запчасти качественные и доступные. У клиентов CAREADY имеется возможность продлить гарантию на свой автомобиль',
    },
  },
];
