import { ERROR_CYRILLIC, ERROR_PHONE, ERROR_REQUIRED } from './errorTexts';
import { string } from 'yup';

export const password = string()
  .required(ERROR_REQUIRED)
  .min(8, 'Пароль должен состоять минимум из 8 символов')
  .max(32, 'Пароль должен состоять максимум из 32 символов');

export const phoneNumber = string().matches(/^\+7[3,4,7,8,9]\d{9}$/, ERROR_PHONE);

export const cyrillic = string().matches(/^[а-яёА-ЯЁ]+$/g, ERROR_CYRILLIC);
