import { useLocation } from 'react-router-dom';

export const MapSearchHeader = function MapSearchHeader() {
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  return (
    <>
      {isHomePage && (
        <h2 className={'h4'}>
          {/*todo: hid electric-charger*/}
          {/*{types === 'electric-charger' ? 'Электрозаправки' : 'Наши СТО'}*/}
          Наши автосервисы
        </h2>
      )}
    </>
  );
};
