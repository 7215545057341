import { DynamicImg } from '../../base/DynamicImg';
import { FC, ReactNode } from 'react';
import s from './HeroSection.module.scss';

interface OwnProps {
  title: ReactNode;
  imgPath: string;
  footer?: ReactNode;
  className?: string;
  id?: string;
}

export const HeroSection: FC<OwnProps> = function HeroSection(props) {
  return (
    <section className={`section ${s.section} ${props.className}`} id={props.id}>
      <div className={`${s.content} hero-content`}>
        <div className={s.titleWrapper}>{props.title}</div>
        {props.footer && <div className={`${s.footer} hero-footer`}>{props.footer}</div>}
      </div>
      <div className={`${s.imgWrapper} hero-img-wrapper`}>
        <DynamicImg path={props.imgPath} className={s.img} />
      </div>
    </section>
  );
};
