import clsx from 'clsx';
import s from './NotFoundPage.module.scss';
import { Helmet } from 'react-helmet';

export const NotFoundPage = function NotFoundPage() {
  return (
    <>
      <Helmet>
        <meta name={'robots'} content={'noindex'} />
      </Helmet>
      <div className={clsx('section', s.wrapper)}>
        <p className={'h2'}>404</p>
        <p className={'h4'}>страница не найдена</p>
      </div>
    </>
  );
};
