import { FC, memo, ReactElement, useEffect, useState } from 'react';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { baseSectionId } from '../../../config/franchiseSections';
import { gsap } from 'gsap';
import styles from './side-navigation.module.css';

gsap.registerPlugin(ScrollToPlugin);

export const SideNavigation: FC = memo(function SideNavigation() {
  const [sections, setSections] = useState<NodeListOf<Element> | null>(null);

  useEffect(() => {
    setSections(document.querySelectorAll(`[id^='${baseSectionId}']`));
  }, []);

  useEffect(() => {
    if (!sections?.length) return;

    const navLinks = document.querySelectorAll('.gsap-navigation li');
    if (!navLinks.length) return;

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      gsap.to('.gsap-navigation', {
        scrollTrigger: {
          trigger: section,
          start: 'top center',
          end: 'bottom center',
          onEnter: () => {
            navLinks.forEach(e => {
              e.classList.remove('active');
            });
            navLinks[i].classList.add('active');
          },
          onEnterBack: () => {
            navLinks.forEach(e => {
              e.classList.remove('active');
            });
            navLinks[i].classList.add('active');
          },
        },
      });
    }
  }, [sections]);

  const linkElements: ReactElement[] = [];

  if (sections?.length) {
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      linkElements.push(
        <li
          key={section.id}
          onClick={() => {
            gsap.to(window, { duration: 0.5, scrollTo: !i ? 0 : `#${section.id}` });
          }}
        />
      );
    }
  }

  return <ul className={`${styles.ul} gsap-navigation`}>{linkElements}</ul>;
});
