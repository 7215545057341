import { AddNewCarModal } from '../../../modal/AddNewCar';
import { Button } from '../../../base/Button/Button';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as CarLeft } from 'assets/svg/car-left.svg';
import { useGarageCars } from '../../../../hooks/useGarage';
import { useModal } from '../../../../hooks/useModal';
import NoImageCar from 'assets/img/my-cars/no-image-car.svg';
import classes from './my-cars.module.css';
import { UTMLink } from '../../../base/UTMLink/UTMLink';

// TODO нужен будет свайпер для авто, правильное затемнение, если много авто

export const MyCarsBlock = () => {
  const cars = useGarageCars();
  const { isShowing, toggleModal } = useModal();

  return (
    <div className={classes.wrap}>
      <div className={classes.topBlock}>
        <div className={classes.myCars}>
          <div className={classes.carIcon}>
            <CarLeft />
          </div>
          <div className={classes.carsTextBox}>
            <h5 className="h5">Мои автомобили {cars.length > 0 && <span>{cars.length}</span>}</h5>
            {cars.length === 0 && (
              <p className="p2">Вы еще не добавляли информацию об автомобилях</p>
            )}
          </div>
        </div>
        {cars.length > 0 && (
          <Button plus color="orange" secondary onClick={toggleModal}>
            Добавить
          </Button>
        )}
      </div>
      {cars.length > 0 ? (
        <div>
          <div className={`${classes.carsWrapper} ${cars.length > 2 ? classes.gradient : ''}`}>
            <div className={classes.ownedCars}>
              {cars.map(car => (
                <UTMLink
                  className={classes.ownedCarWrap}
                  key={car.id}
                  to={`/profile/my-cars/${car.id}/`}
                >
                  <div className={classes.cardImageOuter}>
                    <div className={classes.cardImageInner} />
                    <img src={car.photoUrl ? car.photoUrl : NoImageCar} />
                  </div>
                  <div className={classes.carInfo}>
                    <h6 className="h6">
                      {car.model.brand.name} {car.model.name}
                    </h6>
                    <div className={classes.infoData}>
                      {car.vin && (
                        <p className={`${classes.vin} p3`}>
                          <span>VIN: </span>
                          {car.vin}
                        </p>
                      )}
                      {car.licensePlateNumber && (
                        <p className={`${classes.vin} p3`}>
                          <span>Гос. номер: </span>
                          {car.licensePlateNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </UTMLink>
              ))}
            </div>
          </div>
          <Button plus color="orange" secondary>
            Добавить
          </Button>
        </div>
      ) : (
        <button className={classes.addAutoButton} onClick={toggleModal}>
          <div className={classes.buttonInside}>
            <Plus />
            <h6 className="h6">Добавить</h6>
          </div>
        </button>
      )}
      {isShowing && <AddNewCarModal onCloseButtonClick={toggleModal} />}
    </div>
  );
};
