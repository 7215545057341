import { Helmet } from 'react-helmet';
import { env } from './core/env';

export function IndexHtmlConfig() {
  return (
    <Helmet>
      {env.ENVIRONMENT === 'prod' && env.YA_METRICS_ID && (
        // Yandex.Metrika counter
        <script async={true} type={'module'}>
          {`
            (function(m, e, t, r, i, k, a) {
              m[i] = m[i] || function() {
                (m[i].a = m[i].a || []).push(arguments);
              };
  
              m[i].l = 1 * new Date();
              
              for (var j = 0; j < document.scripts.length; j++) {
                if (document.scripts[j].src === r) {
                  return;
                }
              }
              k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
            })

            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            
            ym(${Number(env.YA_METRICS_ID)}, "init", {
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true
            });
          `}
        </script>
      )}
      {env.CALLTOUCH === 'true' && (
        // calltouch
        <script defer={true}>
          {
            '(function(w,d,n,c){w.CalltouchDataObject = n;w[n]=function(){w[n]["callbacks"].push(arguments)};if(!w[n]["callbacks"]){w[n]["callbacks"]=[]}w[n]["loaded"]=false;if(typeof c!=="object"){c=[c]}w[n]["counters"]=c;for(var i=0;i<c.length;i+=1){p(c[i])}function p(cId){var a=d.getElementsByTagName("script")[0],s=d.createElement("script"),i=function(){a.parentNode.insertBefore(s,a)},m=typeof Array.prototype.find === \'function\',n=m?"init-min.js":"init.js";s.async=true;s.src="https://mod.calltouch.ru/"+n+"?id="+cId;if(w.opera=="[object Opera]"){d.addEventListener("DOMContentLoaded",i,false)}else{i()}}})(window,document,"ct","x3u8z6dl");'
          }
        </script>
      )}
      {/*Mindbox*/}
      <script>{'window.PopMechanic = {watchLocation: false};'}</script>
      <script>
        {`mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
        mindbox.queue = mindbox.queue || [];
        mindbox('create', {endpointId: '${env.MINDBOX_ENDPOINT_ID}'});`}
      </script>
      <script src="https://api.mindbox.ru/scripts/v1/tracker.js" async></script>
    </Helmet>
  );
}
