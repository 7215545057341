import { AccordionItem } from '../../components/Accordion';
import { Root } from '@radix-ui/react-accordion';
import { useEffect } from 'react';
import {
  MaintenanceServiceCategoryTreeDto,
  MaintenanceServiceTreeDto,
} from '../../../api/generated/caready';

export const Step2 = ({
  categories,
  values,
  setServices,
}: {
  categories: MaintenanceServiceCategoryTreeDto[];
  values: string[];
  setServices: (v: string[]) => void;
}) => {
  useEffect(() => {
    let arr: MaintenanceServiceTreeDto[] = [];

    categories.map(c =>
      c.subcategories
        .map(sc => sc.services.filter(s => values.includes(s.slug)))
        .map(s => {
          arr = [...arr, ...s];
        })
    );

    setServices(arr.map(s => s.name));
  }, [values]);

  return (
    <Root type="multiple">
      {categories.map(
        (category, idx) =>
          !!category.subcategories.length && (
            <AccordionItem key={idx} category={category} values={values} />
          )
      )}
    </Root>
  );
};
