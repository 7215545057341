import { ReactComponent as News } from '../../../assets/svg/news.svg';
import { ReactComponent as Services } from '../../../assets/svg/services.svg';
import { ReactComponent as Promo } from '../../../assets/svg/promo.svg';
import s from './SearchResult.module.scss';
import { UTMLink } from '../../base/UTMLink/UTMLink';
import { SearchResultsDto } from '../../../api/generated/caready';
import { FC } from 'react';

interface OwnProps {
  results: SearchResultsDto;
  onMenuItemClick: () => void;
}

export const SearchResult: FC<OwnProps> = function SearchResult(props) {
  const { items: maintenanceServices } = props.results.maintenanceServices;
  const { items: promotions } = props.results.promotions;
  const { items: news } = props.results.news;

  return (
    <div className={s.result}>
      {!maintenanceServices.length && !promotions.length && !news.length ? (
        <span>Ничего не найдено</span>
      ) : (
        <ul>
          {maintenanceServices.map((item, idx) => (
            <li key={idx}>
              <UTMLink
                to={`/services/${item.category.slug}/${item.slug}/`}
                className={s.item}
                onClick={props.onMenuItemClick}
              >
                <span>
                  <Services />
                </span>
                <div>
                  <p className="p2">{item.name}</p>
                  <span className="p3">Услуги</span>
                </div>
              </UTMLink>
            </li>
          ))}
          {news.map((item, idx) => (
            <li key={idx}>
              <UTMLink
                to={`/news/${item.slug}/`}
                className={s.item}
                onClick={props.onMenuItemClick}
              >
                <span>
                  <News />
                </span>
                <div>
                  <p className="p2">{item.headline}</p>
                  <span className="p3">Новости</span>
                </div>
              </UTMLink>
            </li>
          ))}
          {promotions.map(item => (
            <li key={item.id}>
              <UTMLink
                to={`/promo/${item.slug}/`}
                className={s.item}
                onClick={props.onMenuItemClick}
              >
                <span>
                  <Promo />
                </span>
                <div>
                  <p className="p2">{item.headline}</p>
                  <span className="p3">Акции</span>
                </div>
              </UTMLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
