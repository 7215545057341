const sections = [
  'HeroSection',
  'WhoWeAre',
  'AdvantagesSection',
  'WorkGeographic',
  'Reviews',
  'ServiceSection',
  'EcoSection',
  'MobileAppsSection',
  'CostCalculation',
  'RequestSection',
] as const;

export const baseSectionId = 'section-';

type Section = (typeof sections)[number];

type SectionId = `${typeof baseSectionId}${number}`;

export const FranchiseSection = {
  HeroSection: 'section-1',
  WhoWeAre: 'section-2',
  AdvantagesSection: 'section-3',
  WorkGeographic: 'section-4',
  Reviews: 'section-5',
  ServiceSection: 'section-6',
  EcoSection: 'section-7',
  MobileAppsSection: 'section-8',
  CostCalculation: 'section-9',
  RequestSection: 'section-10',
} as const satisfies Record<Section, SectionId>;
