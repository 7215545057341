import styles from './wysiwig.module.css';

export const WYSIWYG = ({ html }: { html: string }) => {
  return (
    <div
      className={`${styles.content}`}
      dangerouslySetInnerHTML={{
        __html: html,
        // TODO зависит от того как будут заполнять контент
        // .replace(/(\r\n|\n|\r)/gm, "")
        // .replace(/<p>\s+<br><\/p>/gm, ""),
      }}
    />
  );
};
