/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { PaginatedMaintenanceServicesDto } from '../dtos';
// @ts-ignore
import type { PaginatedNewsDto } from '../dtos';
// @ts-ignore
import type { PaginatedPromotionsDto } from '../dtos';
// @ts-ignore
import type { SearchResultsDto } from '../dtos';
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search is performed by the published news, active promotions and maintenance services
         * @summary Search by news, promotions and maintenance services
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchEverything: async (query: string, perPage?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchControllerSearchEverything', 'query', query)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search by maintenance services
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchMaintenanceServices: async (query: string, perPage?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchControllerSearchMaintenanceServices', 'query', query)
            const localVarPath = `/search/maintenance-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search is performed by the published news only
         * @summary Search by news
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchNews: async (query: string, perPage?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchControllerSearchNews', 'query', query)
            const localVarPath = `/search/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search is performed by the active promotions only
         * @summary Search by promotions
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchPromotions: async (query: string, perPage?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchControllerSearchPromotions', 'query', query)
            const localVarPath = `/search/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * Search is performed by the published news, active promotions and maintenance services
         * @summary Search by news, promotions and maintenance services
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchControllerSearchEverything(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchControllerSearchEverything(query, perPage, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.searchControllerSearchEverything']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Search by maintenance services
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchControllerSearchMaintenanceServices(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMaintenanceServicesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchControllerSearchMaintenanceServices(query, perPage, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.searchControllerSearchMaintenanceServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Search is performed by the published news only
         * @summary Search by news
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchControllerSearchNews(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedNewsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchControllerSearchNews(query, perPage, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.searchControllerSearchNews']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Search is performed by the active promotions only
         * @summary Search by promotions
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchControllerSearchPromotions(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPromotionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchControllerSearchPromotions(query, perPage, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.searchControllerSearchPromotions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * Search is performed by the published news, active promotions and maintenance services
         * @summary Search by news, promotions and maintenance services
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchEverything(query: string, perPage?: number, page?: number, options?: any): AxiosPromise<SearchResultsDto> {
            return localVarFp.searchControllerSearchEverything(query, perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search by maintenance services
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchMaintenanceServices(query: string, perPage?: number, page?: number, options?: any): AxiosPromise<PaginatedMaintenanceServicesDto> {
            return localVarFp.searchControllerSearchMaintenanceServices(query, perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Search is performed by the published news only
         * @summary Search by news
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchNews(query: string, perPage?: number, page?: number, options?: any): AxiosPromise<PaginatedNewsDto> {
            return localVarFp.searchControllerSearchNews(query, perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Search is performed by the active promotions only
         * @summary Search by promotions
         * @param {string} query Search query
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearchPromotions(query: string, perPage?: number, page?: number, options?: any): AxiosPromise<PaginatedPromotionsDto> {
            return localVarFp.searchControllerSearchPromotions(query, perPage, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - interface
 * @export
 * @interface SearchApi
 */
export interface SearchApiInterface {
    /**
     * Search is performed by the published news, active promotions and maintenance services
     * @summary Search by news, promotions and maintenance services
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    searchControllerSearchEverything(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<SearchResultsDto>;

    /**
     * 
     * @summary Search by maintenance services
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    searchControllerSearchMaintenanceServices(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedMaintenanceServicesDto>;

    /**
     * Search is performed by the published news only
     * @summary Search by news
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    searchControllerSearchNews(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedNewsDto>;

    /**
     * Search is performed by the active promotions only
     * @summary Search by promotions
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    searchControllerSearchPromotions(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedPromotionsDto>;

}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI implements SearchApiInterface {
    /**
     * Search is performed by the published news, active promotions and maintenance services
     * @summary Search by news, promotions and maintenance services
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchControllerSearchEverything(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchControllerSearchEverything(query, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search by maintenance services
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchControllerSearchMaintenanceServices(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchControllerSearchMaintenanceServices(query, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search is performed by the published news only
     * @summary Search by news
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchControllerSearchNews(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchControllerSearchNews(query, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search is performed by the active promotions only
     * @summary Search by promotions
     * @param {string} query Search query
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchControllerSearchPromotions(query: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchControllerSearchPromotions(query, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }
}

