import { TertiaryService } from '../../../../ui/components/ServiceCard/TertiaryService';
import { SlugWrapper } from '../../../../ui/components/SlugWrapper/SlugWrapper';
import clsx from 'clsx';
import commonStyles from '../../../../styles/common/common-styles.module.scss';
import { FC } from 'react';
import { MaintenanceServicesApi } from '../../../../api';
import {
  MaintenanceServiceCategoryDto,
  MaintenanceServiceListDto,
} from '../../../../api/generated/caready';
import { MetaDescription } from '../../../../ui/base/MetaTags/MetaDescription';

type OwnProps = MaintenanceServiceCategoryDto & {
  services: MaintenanceServiceListDto[];
};

const PageTitle: FC<OwnProps> = function PageTitile(props) {
  return (
    <>
      <MetaDescription
        description={`Запишитесь в сервис на услуги категории ${props.name} и получите профессиональное обслуживание вашего автомобиля быстро, качественно и по приятным ценам`}
      />
      <h1 className={clsx('h2')}>
        {props.name} <span className={'p2'}>{props.servicesNumber}</span>
      </h1>
    </>
  );
};

const PageContent: FC<OwnProps> = function PageContent(props) {
  return (
    <div className={commonStyles.cardsWrapper}>
      {props.services.length ? (
        props.services.map((service, idx) => (
          <TertiaryService key={idx} link={`/services/${props.slug}`} service={service} />
        ))
      ) : (
        <p className={commonStyles.noData}>В выбранной категории нет услуг</p>
      )}
    </div>
  );
};

const parentPage = {
  name: 'Услуги',
  path: '/services/',
};

export const ServiceSubcategoryListPage = function ServiceSubcategoryListPage() {
  const getOwnProps = async (slug: string): Promise<OwnProps | null> => {
    const { data: category } =
      await MaintenanceServicesApi.maintenanceServicesControllerGetCategoryPageBySlug(slug);

    const {
      data: { items: services },
    } = await MaintenanceServicesApi.maintenanceServicesControllerGetServicesByCategorySlug(
      category.id
    );

    return {
      ...category,
      services,
    };
  };

  return (
    <SlugWrapper<OwnProps, 'name'>
      request={getOwnProps}
      parentPage={parentPage}
      PageContent={PageContent}
      PageTitle={PageTitle}
      titleName={'name'}
    />
  );
};
