import { useState } from 'react';

export const useModal = (initialIsShowing?: boolean) => {
  const [isShowing, setIsShowing] = useState(!!initialIsShowing);

  const toggleModal = () => {
    setIsShowing(!isShowing);
  };

  return { isShowing, toggleModal };
};

export const useModalByName = (initialIsShowing?: string) => {
  const [modal, setModal] = useState(initialIsShowing ?? '');

  return { modal, changeModal: (name: string) => setModal(name), closeModal: () => setModal('') };
};
