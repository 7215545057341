import { SectionHeader } from '../../../../ui/base/SectionHeader';
import { useMaintenanceServicesCategories } from '../../../../hooks/useMaintenanceServices';
import {
  PrimaryService,
  PrimaryServiceSkeleton,
} from '../../../../ui/components/ServiceCard/PrimaryService';
import { SkeletonGroup } from '../../../../ui/base/Skeleton/SkeletonGroup';
import homePageStyles from '../../HomePage.module.scss';
import s from './ServicesSection.module.scss';
import { UTMLink } from '../../../../ui/base/UTMLink/UTMLink';

export const ServicesSection = function ServicesSection() {
  const { data, isLoading } = useMaintenanceServicesCategories();

  return (
    <section className={'section'}>
      <SectionHeader title={'Услуги'} />
      <div className={homePageStyles.grid}>
        <SkeletonGroup isLoading={isLoading} quantity={4} skeleton={PrimaryServiceSkeleton}>
          {data?.map((category, index) => <PrimaryService key={index} category={category} />)}
        </SkeletonGroup>
      </div>
      <UTMLink className={`${s.btnAll} h6 btn secondary black`} to={'/services/'}>
        Посмотреть все
      </UTMLink>
    </section>
  );
};
