import 'swiper/css';
import 'swiper/css/pagination';
import { DynamicImg } from '../../base/DynamicImg';
import { Pagination } from 'swiper';
import { Review } from '../Review';
import { Service } from '../ExperienceSection/data';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './experience-tab-section.module.css';

export const ExperienceTabSection = ({ service }: { service: Service }) => {
  return (
    <div className={styles.tabContent}>
      <div className={`${styles.swiperBlock} experienceSlider`}>
        <Swiper
          className="experience-swiper"
          slidesPerView={1}
          spaceBetween={16}
          loop
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          {service.slides.map((slide, idx) => (
            <SwiperSlide key={`slide-swiperContainer-${idx}`}>
              <picture className={styles.slideImage}>
                <DynamicImg path={slide} />
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.tabInformation}>
        <h4 className={`${styles.tabLabel} h4-26`}>
          {service.serviceName} ({service.address})
        </h4>
        <Review {...service.review} />
      </div>
    </div>
  );
};
