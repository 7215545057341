import { ERROR_REQUIRED } from '../../../../config/errorTexts';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Input } from '../../../base/Input';
import { ModalClose } from '../../../base/ModalBase';
import { PhoneNumber } from 'ui/base/Input/Input';
import { object } from 'yup';
import { cyrillic, phoneNumber } from '../../../../config/validationSchemas';
import classes from './personal-info.module.css';
import { UsersApi } from '../../../../api';

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

const validationSchema = object().shape({
  firstName: cyrillic.required(ERROR_REQUIRED),
  lastName: cyrillic.required(ERROR_REQUIRED),
  phoneNumber: phoneNumber.optional(),
});

export const PersonalInfo = ({ closeModal }: { closeModal: () => void }) => {
  const onSubmit = (
    { firstName, lastName, phoneNumber }: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    Promise.all([
      UsersApi.usersControllerUpdateName({ firstName, lastName }),
      phoneNumber && UsersApi.usersControllerUpdatePhoneNumber({ newPhoneNumber: phoneNumber }),
    ]).then(res => {
      if (res) {
        formikHelpers.setSubmitting(false);
        closeModal();
      }
    });
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values, errors, touched, setFieldValue, setFieldTouched }) => (
        <>
          <header className="modal_header">
            <h4 className={`${classes.title} h4`}>Расскажите нам о себе</h4>
            <ModalClose />
          </header>
          <Form className="modal_container">
            <div className={`${classes.formSection} modal_inner`}>
              <Input label="Имя *" error={errors.firstName} touched={!!touched.firstName}>
                <Field name="firstName" placeholder="Введите имя" />
              </Input>
              <Input label="Фамилия *" error={errors.lastName} touched={!!touched.lastName}>
                <Field name="lastName" placeholder="Введите фамилию" />
              </Input>
              <Input
                label="Номер телефона"
                error={errors.phoneNumber}
                touched={!!touched.phoneNumber}
              >
                <PhoneNumber
                  name="phoneNumber"
                  // todo: попробовать переписать без использования onBlur
                  onBlur={() => setFieldTouched('phoneNumber', true)}
                  setField={v => setFieldValue('phoneNumber', v)}
                />
              </Input>
            </div>
            <footer className={`${classes.footer} modal_footer`}>
              <button
                disabled={!(isValid && values.firstName && values.lastName)}
                type="submit"
                className="btn"
              >
                Сохранить
              </button>
              <button className="btn" onClick={closeModal}>
                Пропустить
              </button>
            </footer>
          </Form>
        </>
      )}
    </Formik>
  );
};
