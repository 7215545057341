import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { MaintenanceHistoryModal } from '../../modal/MaintenanceHistory';
import { format } from 'date-fns';
import { useModal } from '../../../hooks/useModal';
import classes from './maintenance-history.module.css';
import { MaintenanceHistoryItemDto } from '../../../api/generated/caready';
import { prettifyPhone } from '../../../utils/prettifyPhone';

export const MaintenanceHistory = ({ historyData }: { historyData: MaintenanceHistoryItemDto }) => {
  const { isShowing, toggleModal } = useModal();

  return (
    <div className={classes.history_block}>
      <h6 className="h6">{format(new Date(historyData.appointmentDate), 'dd.MM.yyyy')}</h6>
      <div className={classes.history_text}>
        <div className={classes.history_text_line}>
          <p className={`p2 ${classes.history_key}`}>Станция обслуживания:</p>
          <p className={`p2 ${classes.history_value}`}>
            {historyData.serviceStationAddress.city.name},{' '}
            {historyData.serviceStationAddress.address}
          </p>
        </div>
        <div className={classes.history_text_line}>
          <p className={`p2 ${classes.history_key}`}>Номер:</p>
          <p className={`p2 ${classes.history_value}`}>
            {historyData.serviceStationPhoneNumber
              ? prettifyPhone(historyData.serviceStationPhoneNumber)
              : '–'}
          </p>
        </div>
        <div className={classes.history_text_line}>
          <p className={`p2 ${classes.history_key}`}>VIN:</p>
          <p className={`p2 ${classes.history_value}`}>{historyData.carVin || '–'}</p>
        </div>
        {/* <div className={classes.history_text_line}>
          <p className={`p2 ${classes.history_key}`}>Цена:</p>
          <h6 className={`h6 ${classes.history_value}`}>
            {historyData.totalMinPriceRub} <span className="ruble_back alt" />
          </h6>
        </div>*/}
      </div>
      <button className={classes.see_more} onClick={toggleModal}>
        <h6 className="h6">Подробнее</h6>
        <ArrowRight />
      </button>
      {isShowing && (
        <MaintenanceHistoryModal historyData={historyData} onCloseButtonClick={toggleModal} />
      )}
    </div>
  );
};
