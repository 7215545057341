import { Banner } from '../../components/Banner/Banner';
import { useSearchPromotions } from '../../../hooks/useSearch';
import classes from './search-sections.module.css';

export const SearchPromotions = ({ query }: { query: string }) => {
  const results = useSearchPromotions(1, query);
  return (
    <div className={`${classes.largeCards}`}>
      {results.map(card => (
        <Banner key={card.id} category={card} />
      ))}
    </div>
  );
};
