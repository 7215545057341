import {
  ServiceStationDto,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../api/generated/caready';

const aroundTheClock = '00:00-23:59';

export const isAroundTheClock = (openHours: string[]) => openHours.join('-') === aroundTheClock;

export const getServiceStationOpeningHours = (
  serviceStation: ServiceStationWithDetailWorkingScheduleDto | ServiceStationDto
) => {
  const { openingHours } = serviceStation;

  if (!openingHours?.isOpen && openingHours?.nextOpeningHoursInterval) {
    return `Откроется в ${openingHours.nextOpeningHoursInterval.openingHours[0]}`;
  }

  if (!!openingHours?.isOpen && openingHours.currentOpeningHoursInterval) {
    return isAroundTheClock(openingHours.currentOpeningHoursInterval.openingHours)
      ? 'Работает круглосуточно'
      : `Закроется в ${openingHours.currentOpeningHoursInterval.openingHours[1]}`;
  }

  return null;
};
