import { MapComponent } from './MapComponent';
import { Search } from '../../components/Search/Search';
import { FC, useEffect, useRef, useState } from 'react';
import classes from './appointment.module.css';
import {
  ServiceStationDto,
  ServiceStationsControllerGetServiceStationsTypesEnum,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../../../api/generated/caready';
import { useFilteredServiceStations } from '../../../hooks/useFilteredServiceStations';
import { ServiceStationList } from '../../components/Map/components/servicesStationList/ServiceStationList';
import clsx from 'clsx';

interface OwnProps {
  selectedServiceStation?: ServiceStationWithDetailWorkingScheduleDto;
  services: string[];
  setSelectedServiceStationId: (id: string) => void;
}

export const Step3: FC<OwnProps> = function Step3(props) {
  const [address, setAddress] = useState('');
  const [isList, setIsList] = useState(false);
  const [selectedStationIdOnMap, setSelectedStationIdOnMap] = useState('');
  const [selectedStationOnList, setSelectedStationOnList] = useState<ServiceStationDto | null>(
    null
  );

  const { current: types } = useRef<ServiceStationsControllerGetServiceStationsTypesEnum[]>([
    'service-station',
    'electric-charger',
  ]);

  const serviceStations = useFilteredServiceStations({
    types,
    services: props.services,
    address,
  });

  useEffect(() => {
    if (!!selectedStationIdOnMap && !!serviceStations.length) {
      props.setSelectedServiceStationId(selectedStationIdOnMap);
    }
  }, [selectedStationIdOnMap]);

  return (
    <>
      <div className={classes.controls}>
        <Search clear={() => setAddress('')} isFilled={!!address}>
          <input placeholder="Поиск" value={address} onChange={e => setAddress(e.target.value)} />
        </Search>
        <button
          className={clsx('btn', 'tab', isList && 'secondary black')}
          type="button"
          onClick={() => setIsList(false)}
        >
          Карта
        </button>
        <button
          className={`btn tab ${!isList ? 'secondary black' : ''}`}
          type="button"
          onClick={() => setIsList(true)}
        >
          Список
        </button>
      </div>
      {/*todo: сделать появление списка поверх карты вместо условного рендеринга*/}
      {isList ? (
        <div className={classes.list}>
          <ServiceStationList
            serviceStations={serviceStations}
            setSelectedServiceStation={value => {
              setSelectedStationOnList(value);
              setIsList(false);
            }}
          />
        </div>
      ) : (
        <div className={classes.map}>
          <MapComponent
            serviceStations={serviceStations}
            selectedServiceStation={
              serviceStations.find(it => it.id === selectedStationOnList?.id) ||
              serviceStations.find(it => it.id === props.selectedServiceStation?.id) ||
              null
            }
            setSelect={setSelectedStationIdOnMap}
          />
        </div>
      )}
    </>
  );
};
