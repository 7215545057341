import { useEffect, useState } from 'react';
import { CarDictionaryApi } from '../api';
import {
  CarBrandDto,
  CarDictionaryControllerGetCarModelModificationsDriveTypeEnum,
  CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum,
  CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum,
  CarModelDto,
  CarModelGenerationDto,
  CarModelModificationsSearchResultsDto,
} from '../api/generated/caready';

export const useCarBrands = () => {
  const [brands, setBrands] = useState<CarBrandDto[]>([]);

  useEffect(() => {
    CarDictionaryApi.carDictionaryControllerGetCarBrands().then(({ data }) => {
      setBrands(data);
    });
  }, []);

  return brands;
};

export const useCarModels = (slug: string) => {
  const [models, setModels] = useState<CarModelDto[]>([]);

  useEffect(() => {
    if (!!slug) {
      CarDictionaryApi.carDictionaryControllerGetCarModelsByBrandSlug(slug).then(({ data }) => {
        setModels(data);
      });
    } else {
      setModels([]);
    }
  }, [slug]);

  return models;
};

export const useCarModelGenerations = (id: string) => {
  const [generations, setGenerations] = useState<CarModelGenerationDto[]>([]);

  useEffect(() => {
    if (!!id) {
      CarDictionaryApi.carDictionaryControllerGetCarModelGenerations(id).then(({ data }) => {
        setGenerations(data);
      });
    } else {
      setGenerations([]);
    }
  }, [id]);

  return generations;
};

export const useCarModelModification = (
  modelId: string,
  generationId: string,
  bodyTypeId: string,
  transmissionType: string,
  engineFuelType: string,
  driveType: string
) => {
  const [modification, setModification] = useState<CarModelModificationsSearchResultsDto | null>(
    null
  );

  useEffect(() => {
    if (!!modelId) {
      CarDictionaryApi.carDictionaryControllerGetCarModelModifications(
        modelId,
        generationId || undefined,
        bodyTypeId || undefined,
        (CarDictionaryControllerGetCarModelModificationsTransmissionTypeEnum as any)[
          transmissionType
        ],
        (CarDictionaryControllerGetCarModelModificationsEngineFuelTypeEnum as any)[engineFuelType],
        (CarDictionaryControllerGetCarModelModificationsDriveTypeEnum as any)[driveType]
      ).then(({ data }) => {
        setModification(data);
      });
    } else {
      setModification(null);
    }
  }, [modelId, generationId, bodyTypeId, transmissionType, engineFuelType, driveType]);

  return modification;
};
