/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { JobLocationTypeDto } from '../dtos';
// @ts-ignore
import type { JobPageDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { PaginatedJobsDto } from '../dtos';
/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get job location types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetJobLocationTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/jobs/location-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a published job page by its slug
         * @param {string} slug Job\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetPublishedJobPageBySlug: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('jobsControllerGetPublishedJobPageBySlug', 'slug', slug)
            const localVarPath = `/jobs/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get published jobs with filters and pagination
         * @summary Get jobs
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [locationType] Filter jobs by a location type (by its slug)
         * @param {string} [searchQuery] Search by job titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetPublishedJobs: async (perPage?: number, page?: number, locationType?: string, searchQuery?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (locationType !== undefined) {
                localVarQueryParameter['locationType'] = locationType;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get job location types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsControllerGetJobLocationTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobLocationTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsControllerGetJobLocationTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.jobsControllerGetJobLocationTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a published job page by its slug
         * @param {string} slug Job\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsControllerGetPublishedJobPageBySlug(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsControllerGetPublishedJobPageBySlug(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.jobsControllerGetPublishedJobPageBySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get published jobs with filters and pagination
         * @summary Get jobs
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [locationType] Filter jobs by a location type (by its slug)
         * @param {string} [searchQuery] Search by job titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsControllerGetPublishedJobs(perPage?: number, page?: number, locationType?: string, searchQuery?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedJobsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsControllerGetPublishedJobs(perPage, page, locationType, searchQuery, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.jobsControllerGetPublishedJobs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get job location types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetJobLocationTypes(options?: any): AxiosPromise<Array<JobLocationTypeDto>> {
            return localVarFp.jobsControllerGetJobLocationTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a published job page by its slug
         * @param {string} slug Job\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetPublishedJobPageBySlug(slug: string, options?: any): AxiosPromise<JobPageDto> {
            return localVarFp.jobsControllerGetPublishedJobPageBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Get published jobs with filters and pagination
         * @summary Get jobs
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [locationType] Filter jobs by a location type (by its slug)
         * @param {string} [searchQuery] Search by job titles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerGetPublishedJobs(perPage?: number, page?: number, locationType?: string, searchQuery?: string, options?: any): AxiosPromise<PaginatedJobsDto> {
            return localVarFp.jobsControllerGetPublishedJobs(perPage, page, locationType, searchQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobsApi - interface
 * @export
 * @interface JobsApi
 */
export interface JobsApiInterface {
    /**
     * 
     * @summary Get job location types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApiInterface
     */
    jobsControllerGetJobLocationTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<JobLocationTypeDto>>;

    /**
     * 
     * @summary Get a published job page by its slug
     * @param {string} slug Job\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApiInterface
     */
    jobsControllerGetPublishedJobPageBySlug(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<JobPageDto>;

    /**
     * Get published jobs with filters and pagination
     * @summary Get jobs
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {string} [locationType] Filter jobs by a location type (by its slug)
     * @param {string} [searchQuery] Search by job titles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApiInterface
     */
    jobsControllerGetPublishedJobs(perPage?: number, page?: number, locationType?: string, searchQuery?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedJobsDto>;

}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI implements JobsApiInterface {
    /**
     * 
     * @summary Get job location types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsControllerGetJobLocationTypes(options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).jobsControllerGetJobLocationTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a published job page by its slug
     * @param {string} slug Job\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsControllerGetPublishedJobPageBySlug(slug: string, options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).jobsControllerGetPublishedJobPageBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get published jobs with filters and pagination
     * @summary Get jobs
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {string} [locationType] Filter jobs by a location type (by its slug)
     * @param {string} [searchQuery] Search by job titles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsControllerGetPublishedJobs(perPage?: number, page?: number, locationType?: string, searchQuery?: string, options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).jobsControllerGetPublishedJobs(perPage, page, locationType, searchQuery, options).then((request) => request(this.axios, this.basePath));
    }
}

