import { CoreHead } from '../../ui/core';

export const RulesOfUsingSite = () => {
  return (
    <>
      <CoreHead title="Правила пользования сайтом" />
      <div className={'section'}>
        <h1 className="h3">Правила пользования сайтом</h1>
        <p className="p3">
          ПОСЕТИТЕЛИ ОФИЦИАЛЬНОГО САЙТА АО «ЧЕРИ АВТОМОБИЛИ РУС» СЧИТАЮТСЯ ОЗНАКОМЛЕННЫМИ И
          ПРИНЯВШИМИ НАСТОЯЩИЕ ПРАВИЛА ПОЛЬЗОВАНИЯ ОФИЦИАЛЬНЫМ САЙТОМ АО «ЧЕРИ АВТОМОБИЛИ РУС».
        </p>
        <div>
          <p className="p3">
            <br />
            Вся информация, представленная на официальном сайте АО «ЧЕРИ АВТОМОБИЛИ РУС», в том
            числе сведения о ценах на автомобили, детали, дополнительное оборудование марки CHERY,
            моделях, комплектациях, технических характеристиках, цветах и иных параметрах
            автомобилей марки CHERY представлена исключительно для ознакомления, не является
            офертой, не является исчерпывающей, носит исключительно уведомительный характер, не
            является полной (в частности, данные о расходе топлива автомобиля всегда носят
            приблизительный характер, поскольку рассчитаны исходя из идеальных условий; реальный
            расход топлива для конкретного автомобиля может быть рассчитан только опытным путем
            исходя из манеры вождения, дорожных условий и иных характеристик) и может отличаться от
            цен, комплектации, технических характеристик, цветов и иных параметров автомобилей,
            деталей и дополнительного оборудования марки CHERY, фактически реализуемых на территории
            Российской Федерации. Для получения более подробной информации об автомобилях, деталях и
            дополнительном оборудовании марки CHERY, Пользователи могут обращаться официальным
            дилерам АО «ЧЕРИ АВТОМОБИЛИ РУС». При этом АО «ЧЕРИ АВТОМОБИЛИ РУС» оставляет за собой
            право в любой момент, без предварительного уведомления Пользователей сайта, вносить
            изменения в размещенную на сайте информацию.
          </p>
          <br />
          <p className="p3">
            Цены автомобилей, указанные на сайте являются усредненными показателями рыночных цен,
            применяемыми для целей демонстрации расчетов. Сведения, приведенные на сайте (включая
            указанные цены) не являются офертой (в том числе, не являются офертой в соответствии со
            ст.ст.432, 437, 494 Гражданского кодекса России), приводятся исключительно для
            информационных целей. За подробной информацией для целей заключения договоров
            купли-продажи автомобилей, просим обращаться к официальным дилерам.
          </p>
          <br />
          <p className="p3">
            Все полученные от Пользователей в процессе использования ими настоящего информационного
            ресурса сведения, в том числе сведения персонального характера, обрабатываются, хранятся
            и используются АО «ЧЕРИ АВТОМОБИЛИ РУС» исключительно в соответствии с требованиями
            действующего российского законодательства. При этом АО «ЧЕРИ АВТОМОБИЛИ РУС» имеет право
            проверять информацию, предоставленную Пользователями, а также вносить в нее изменения и
            удалять без согласия и предварительного уведомления Пользователей.
          </p>
          <br />
          <p className="p3">
            Размещенная на сайте информация содержит объекты интеллектуальных прав АО «ЧЕРИ
            АВТОМОБИЛИ РУС» или третьих лиц, предоставивших право использования объектов своих
            интеллектуальных прав АО «ЧЕРИ АВТОМОБИЛИ РУС». Использование указанных объектов
            неуполномоченными лицами запрещается в любой форме. Размещение указанных объектов на
            сайте в изобразительной, текстовой и любой иной форме не является разрешением на их
            использование. Использование иной, не охраняемой законодательством об интеллектуальных
            правах, информации размещенной на сайте допускается только в личных некоммерческих
            целях, при этом Пользователи не имеют право изменять, воспроизводить, рассылать или иным
            образом использовать указанную информацию без предварительного письменного согласия АО
            «ЧЕРИ АВТОМОБИЛИ РУС»; цитирование и копирование указанной информации, допускается
            только при условии указании источника заимствования.
            <br />
          </p>
          <br />
          <p className="p3">
            АО «ЧЕРИ АВТОМОБИЛИ РУС» не отвечает за достоверность, точность и полноту информации,
            расположенной на других сайтах, в том числе на сайтах, на которые Пользователь может
            перейти по гиперссылке, расположенной на официальном сайте АО «ЧЕРИ АВТОМОБИЛИ РУС». АО
            «ЧЕРИ АВТОМОБИЛИ РУС» не проверяет указанную информацию и не несет за нее
            ответственность.
            <br />
          </p>
          <br />
          <p className="p3">
            АО «ЧЕРИ АВТОМОБИЛИ РУС» не несет ответственности за непрерывность и качество работы
            сайта, а также не отвечает за любые убытки в имущественной сфере Пользователя, которые
            могут возникнуть в результате посещения сайта, в частности:
            <ul>
              <li>- за ограничение доступа к сайту,</li>
              <li>- повреждение компьютерного оборудования,</li>
              <li>- программного обеспечения,</li>
              <li>- информации Пользователя компьютерными вирусами,</li>
              <li>- а также за иные убытки.</li>
            </ul>
          </p>
          <br />
          <p className="p3">
            АО «ЧЕРИ АВТОМОБИЛИ РУС» имеет право в любое время, без предварительного уведомления,
            вносить изменения в настоящие правила. Если иное специально не оговорено, указанные
            изменения вступают в силу с момента их опубликования на сайте. При этом Пользователи
            сайта считаются, ознакомившимися и принявшими условия изменений, с момента начала
            использования материалов сайта после вступления указанных изменений в силу.
            <br />
          </p>
        </div>
      </div>
    </>
  );
};
