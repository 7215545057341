import { AuthModals } from '../../../config/signModals';
import { ERROR_REQUIRED } from 'config/errorTexts';
import { ReactComponent as EyeClosed } from 'assets/svg/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'assets/svg/eye-open.svg';
import { Field, Form, Formik } from 'formik';
import { Input } from '../../base/Input';
import { ModalClose, ModalOverlay } from '../../base/ModalBase/ModalBase.v2';
import { SignModals } from '../SignModals';
import { object, string } from 'yup';
import { useState } from 'react';
import classes from './change-password.module.css';

export const ChangePasswordModal = ({ onCloseButtonClick }: { onCloseButtonClick: () => void }) => {
  const [passwords, setPasswords] = useState({ old: true, new: true, confirm: true });

  const validationSchema = object().shape({
    oldPassword: string().required(ERROR_REQUIRED).min(8),
    newPassword: string().required(ERROR_REQUIRED).min(8),
    confirmPassword: string().required(ERROR_REQUIRED).min(8),
  });

  return (
    <>
      <ModalOverlay onCloseButtonClick={onCloseButtonClick}>
        <>
          <header className={classes.header}>
            <h4 className="h4">Сменить пароль</h4>
            <ModalClose onCloseButtonClick={onCloseButtonClick} />
          </header>
          <Formik
            initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              if (values.oldPassword && values.newPassword && values.confirmPassword) {
                // todo: поправить логику смены пароля
                // postUpdatePassword(values.newPassword, values.oldPassword).then(res => {
                //   if (res) {
                //     onCloseButtonClick();
                //   } else {
                //     formikHelpers.setErrors({ oldPassword: 'Ошибка' });
                //   }
                // });
                formikHelpers.setSubmitting(false);
              }
            }}
          >
            {({ errors, values, isValid }) => (
              <Form>
                <div className={`${classes.inner}`}>
                  <div className={classes.fieldsSection}>
                    <div className={classes.inputBlock}>
                      <Input
                        icon={
                          <span
                            onClick={() =>
                              setPasswords({
                                ...passwords,
                                old: !passwords.old,
                              })
                            }
                          >
                            {passwords.confirm ? <EyeClosed /> : <EyeOpen />}
                          </span>
                        }
                        label="Текущий пароль"
                        error={errors.oldPassword}
                      >
                        <Field
                          placeholder="Введите пароль"
                          name="oldPassword"
                          type={passwords.old ? 'password' : 'text'}
                        />
                      </Input>
                      <SignModals
                        modalName={AuthModals.forgotPassword}
                        trigger={<button className="btn-text orange">Забыли пароль?</button>}
                      />
                    </div>
                    <div className={classes.inputBlock}>
                      <Input
                        icon={
                          <span
                            onClick={() =>
                              setPasswords({
                                ...passwords,
                                new: !passwords.new,
                              })
                            }
                          >
                            {passwords.confirm ? <EyeClosed /> : <EyeOpen />}
                          </span>
                        }
                        label="Новый пароль"
                        error={errors.newPassword}
                      >
                        <Field
                          placeholder="Введите новый пароль"
                          name="newPassword"
                          type={passwords.new ? 'password' : 'text'}
                        />
                      </Input>
                      <p className="p3">Пароль должен состоять минимум из 8 символов</p>
                    </div>
                    <Input
                      icon={
                        <span
                          onClick={() =>
                            setPasswords({
                              ...passwords,
                              confirm: !passwords.confirm,
                            })
                          }
                        >
                          {passwords.confirm ? <EyeClosed /> : <EyeOpen />}
                        </span>
                      }
                      label="Подтвердите новый пароль"
                      error={errors.confirmPassword}
                    >
                      <Field
                        placeholder="Повторите ввод нового пароля"
                        name="confirmPassword"
                        type={passwords.confirm ? 'password' : 'text'}
                      />
                    </Input>
                  </div>
                  <div className={classes.buttonSection}>
                    <button
                      className="btn"
                      type="submit"
                      disabled={
                        !values.oldPassword ||
                        !values.newPassword ||
                        !(values.confirmPassword === values.newPassword) ||
                        !isValid
                      }
                    >
                      Сменить пароль
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </ModalOverlay>
    </>
  );
};
