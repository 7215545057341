import { ChangePhone } from './ChangePhone';
import { ModalOverlay } from '../../base/ModalBase/ModalBase.v2';

export const ChangePhoneModal = ({
  phone,
  onCloseButtonClick,
}: {
  phone: string | null;
  onCloseButtonClick: () => void;
}) => {
  return (
    <ModalOverlay onCloseButtonClick={onCloseButtonClick}>
      <>
        <ChangePhone closeModal={onCloseButtonClick} phone={phone} />
      </>
    </ModalOverlay>
  );
};
