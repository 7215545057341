import { ReactComponent as CrossMedium } from 'assets/svg/cross-medium.svg';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import classes from './drag-and-drop.module.css';
import { GarageApi } from '../../../api';

export const DragAndDropCar = ({
  setValue,
  photoUrl,
  icon,
}: {
  photoUrl?: string | null;
  setValue: (photoS3Key: string) => void;
  icon: JSX.Element;
}) => {
  const [preview, setPreview] = useState(photoUrl ?? '');
  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
    onDrop: acceptedFiles => {
      GarageApi.garageControllerControllerUploadCarPhoto(acceptedFiles[0]).then(({ data }) => {
        if (data) {
          setPreview(data.url);
          setValue(data.s3Key);
        }
      });
    },
  });

  return (
    <div
      {...getRootProps({
        className: `${classes.container} ${preview ? classes.havePreview : ''} ${
          isDragReject ? classes.error : ''
        }`,
      })}
    >
      <input {...getInputProps()} />
      {!!preview ? (
        <img
          src={preview}
          onLoad={() => {
            URL.revokeObjectURL(preview);
          }}
        />
      ) : (
        <div className={classes.text}>
          <span className={classes.icon}>{icon}</span>
          <p className={'pm1'}>
            {'Перетяните медиа в эту облать или'} <span>выбрать</span>
          </p>
          <p className="p4">JPEG или PNG</p>
        </div>
      )}
      {!!preview && (
        <button
          className={classes.clear}
          onClick={() => {
            setPreview('');
            setValue('');
          }}
        >
          <CrossMedium />
        </button>
      )}
    </div>
  );
};
