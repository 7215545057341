/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CityDto } from '../dtos';
// @ts-ignore
import type { CountryDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
/**
 * GeoDictionaryApi - axios parameter creator
 * @export
 */
export const GeoDictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all the countries
         * @summary Get countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoDictionaryControllerGetAllCountries: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geo-dictionary/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the first 100 cities by the specified parameters.        If the query parameters are not specified, the cities of Russia will be returned by default.
         * @summary Get cities
         * @param {string} [query] Cities search query
         * @param {string} [country] Filter cities by country (alpha-2 code)
         * @param {string} [abbreviation] Filter cities by abbreviation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoDictionaryControllerGetCities: async (query?: string, country?: string, abbreviation?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/geo-dictionary/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (abbreviation !== undefined) {
                localVarQueryParameter['abbreviation'] = abbreviation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeoDictionaryApi - functional programming interface
 * @export
 */
export const GeoDictionaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeoDictionaryApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all the countries
         * @summary Get countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoDictionaryControllerGetAllCountries(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoDictionaryControllerGetAllCountries(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeoDictionaryApi.geoDictionaryControllerGetAllCountries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the first 100 cities by the specified parameters.        If the query parameters are not specified, the cities of Russia will be returned by default.
         * @summary Get cities
         * @param {string} [query] Cities search query
         * @param {string} [country] Filter cities by country (alpha-2 code)
         * @param {string} [abbreviation] Filter cities by abbreviation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async geoDictionaryControllerGetCities(query?: string, country?: string, abbreviation?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.geoDictionaryControllerGetCities(query, country, abbreviation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeoDictionaryApi.geoDictionaryControllerGetCities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeoDictionaryApi - factory interface
 * @export
 */
export const GeoDictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeoDictionaryApiFp(configuration)
    return {
        /**
         * Get all the countries
         * @summary Get countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoDictionaryControllerGetAllCountries(options?: any): AxiosPromise<Array<CountryDto>> {
            return localVarFp.geoDictionaryControllerGetAllCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the first 100 cities by the specified parameters.        If the query parameters are not specified, the cities of Russia will be returned by default.
         * @summary Get cities
         * @param {string} [query] Cities search query
         * @param {string} [country] Filter cities by country (alpha-2 code)
         * @param {string} [abbreviation] Filter cities by abbreviation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        geoDictionaryControllerGetCities(query?: string, country?: string, abbreviation?: string, options?: any): AxiosPromise<Array<CityDto>> {
            return localVarFp.geoDictionaryControllerGetCities(query, country, abbreviation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeoDictionaryApi - interface
 * @export
 * @interface GeoDictionaryApi
 */
export interface GeoDictionaryApiInterface {
    /**
     * Get all the countries
     * @summary Get countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoDictionaryApiInterface
     */
    geoDictionaryControllerGetAllCountries(options?: RawAxiosRequestConfig): AxiosPromise<Array<CountryDto>>;

    /**
     * Get the first 100 cities by the specified parameters.        If the query parameters are not specified, the cities of Russia will be returned by default.
     * @summary Get cities
     * @param {string} [query] Cities search query
     * @param {string} [country] Filter cities by country (alpha-2 code)
     * @param {string} [abbreviation] Filter cities by abbreviation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoDictionaryApiInterface
     */
    geoDictionaryControllerGetCities(query?: string, country?: string, abbreviation?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CityDto>>;

}

/**
 * GeoDictionaryApi - object-oriented interface
 * @export
 * @class GeoDictionaryApi
 * @extends {BaseAPI}
 */
export class GeoDictionaryApi extends BaseAPI implements GeoDictionaryApiInterface {
    /**
     * Get all the countries
     * @summary Get countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoDictionaryApi
     */
    public geoDictionaryControllerGetAllCountries(options?: RawAxiosRequestConfig) {
        return GeoDictionaryApiFp(this.configuration).geoDictionaryControllerGetAllCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the first 100 cities by the specified parameters.        If the query parameters are not specified, the cities of Russia will be returned by default.
     * @summary Get cities
     * @param {string} [query] Cities search query
     * @param {string} [country] Filter cities by country (alpha-2 code)
     * @param {string} [abbreviation] Filter cities by abbreviation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeoDictionaryApi
     */
    public geoDictionaryControllerGetCities(query?: string, country?: string, abbreviation?: string, options?: RawAxiosRequestConfig) {
        return GeoDictionaryApiFp(this.configuration).geoDictionaryControllerGetCities(query, country, abbreviation, options).then((request) => request(this.axios, this.basePath));
    }
}

