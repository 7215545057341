import { useEffect, useState } from 'react';
import { NewsApi } from '../api';
import { NewsDto } from '../api/generated/caready';

export const useNews = (page: number, perPage?: number, category?: string) => {
  const [news, setNews] = useState<NewsDto[]>([]);
  const [totals, setTotals] = useState<number>(0);

  useEffect(() => {
    NewsApi.newsControllerGetPublishedNews(perPage, page, category).then(({ data }) => {
      setNews(data.items);
      setTotals(data.pagination.total);
    });
  }, [page, perPage, category]);

  return { news, totals };
};

export const useOthersNews = (category?: string, perPage?: number, filteredID?: string) => {
  const [news, setNews] = useState<NewsDto[]>([]);

  useEffect(() => {
    NewsApi.newsControllerGetPublishedNews(perPage, 1, category).then(({ data }) => {
      setNews(data.items.filter(item => item.id !== filteredID));
    });
  }, [category]);

  return news;
};
