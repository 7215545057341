import { useEffect, useState } from 'react';
import { GarageApi } from '../api';
import { GarageCarDto } from '../api/generated/caready';

export const useGarageCars = (trigger?: boolean) => {
  const [cars, setCars] = useState<GarageCarDto[]>([]);

  useEffect(() => {
    if (!trigger) {
      GarageApi.garageControllerControllerGetGarageCars().then(({ data }) => {
        setCars(data);
      });
    }
  }, [trigger]);

  return cars;
};

export const useCarById = (id?: string, trigger?: boolean) => {
  const [carData, setCarData] = useState<GarageCarDto | null>(null);

  useEffect(() => {
    if (!!id) {
      if (!trigger) {
        GarageApi.garageControllerControllerGetGarageCar(id)
          .then(({ data }) => {
            setCarData(data);
          })
          .catch(() => {
            setCarData(null);
          });
      }
    } else {
      setCarData(null);
    }
  }, [id, trigger]);

  return carData;
};
