import s from './MobileAppSection.module.scss';
import { FC } from 'react';
import { ReactComponent as QrIcon } from 'assets/img/home/app/qr.svg';
import { DynamicImg } from '../../base/DynamicImg';

export const MobileAppSection: FC = function MobileAppSection() {
  return (
    <section className={`section ${s.section}`}>
      <div className={s.content}>
        <h2 className={`${s.title} h3`}>Скачивайте мобильное приложение на свой смартфон</h2>
        <div className={s.wrapper}>
          <div className={s.links}>
            <div className={s.qr}>
              <QrIcon />
            </div>
            <div className={s.stores}>
              <a
                href={'https://play.google.com/store/apps/details?id=com.kodix.chery.caready&hl=ru'}
                className={s.storeLink}
                target={'_blank'}
              >
                <DynamicImg path={'home/app/google-play.webp'} />
              </a>
              <a
                href={'https://apps.apple.com/ru/app/caready-сеть-автосервисов/id6504248234?l=ru'}
                className={s.storeLink}
                target={'_blank'}
              >
                <DynamicImg path={'home/app/app-store.webp'} />
              </a>
            </div>
          </div>
          <p className={'p1'}>Держите автосервис всегда под рукой</p>
        </div>
      </div>
      <div className={s.img}>
        <DynamicImg path={'home/app/bg.webp'} />
      </div>
    </section>
  );
};
