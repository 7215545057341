import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CoreHead } from '../../core';
import { BreadCrumbs } from '../BreadCrumbs';
import s from './SlugWrapper.module.scss';
import { BREAD_CRUMB_MAIN } from '../../../config/breadCrumbMain';
import clsx from 'clsx';

type TitleKey = 'headline' | 'name';
type OtherArgs = Record<string, any>;

type EntitledObj<T extends OtherArgs, V extends TitleKey> = {
  [K in V]: string;
} & T;

type OwnProps<T extends OtherArgs, V extends TitleKey> = {
  request: (slug: string) => Promise<EntitledObj<T, V> | null>;
  PageContent: FC<EntitledObj<T, V>>;
  skeleton?: ReactNode;
  parentPage?: {
    name: string;
    path: string;
  };
  PageTitle?: FC<EntitledObj<T, V>>;
  titleName: V;
};

export const SlugWrapper = function SlugWrapper<
  T extends OtherArgs = OtherArgs,
  V extends TitleKey = TitleKey,
>({ PageTitle, PageContent, skeleton, ...props }: OwnProps<T, V>) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<EntitledObj<T, V> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getData = async () => {
    if (!slug) return;

    setIsLoading(true);
    try {
      const data = await props.request(slug);
      setData(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [slug]);

  if (!data && !isLoading) navigate('*');
  // todo: skeleton || loader
  if (isLoading || !data) return skeleton;

  return (
    <>
      <CoreHead title={data[props.titleName]} />
      <div className={clsx('section', s.pageWrapper)}>
        <div className={s.titleWrapper}>
          {props.parentPage && (
            <BreadCrumbs
              links={[BREAD_CRUMB_MAIN, props.parentPage, { name: data[props.titleName] }]}
            />
          )}
          {PageTitle ? (
            <PageTitle {...data} />
          ) : (
            <h1 className={clsx('h2', s.title)}>{data[props.titleName]}</h1>
          )}
        </div>
        <PageContent {...data} isLoading={isLoading} />
      </div>
    </>
  );
};
