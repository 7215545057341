import { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { InvocationControlOptions } from '../types/common-types';

interface UseDebounceParams extends InvocationControlOptions {
  options?: Parameters<typeof debounce>[2];
}

export function useDebounce(onChange: (...rest: any) => any, params: UseDebounceParams = {}) {
  const debouncedOnChange = useMemo(() => {
    return debounce(onChange, params.wait ?? 300, params.options);
  }, params.deps ?? []);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return debouncedOnChange;
}
