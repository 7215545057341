import { useAuth } from '../../context/AuthContext';
import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CoreHead } from '../../ui/core';
import { ReactComponent as LogOut } from 'assets/svg/log-out.svg';
import { MyCarsBlock } from '../../ui/components/CardProfile/MyCarsBlock';
import { PersonalData } from '../../ui/components/CardProfile/PersonalData';
import classes from './profile.module.css';

export default function Profile() {
  const { user, logout } = useAuth();

  return (
    <>
      <CoreHead title="Профиль" />
      <div className="section">
        <BreadCrumbs links={[BREAD_CRUMB_MAIN, { name: 'Профиль' }]} />
        <h1 className={`${classes.pageTitle} h2`}>Профиль</h1>
        <div className={classes.profile_main}>
          {user && <PersonalData user={user} />}
          <MyCarsBlock />
          <button className={classes.logout} onClick={() => logout()}>
            <p className="h6">Выйти</p>
            <LogOut />
          </button>
        </div>
      </div>
    </>
  );
}
