import { useAuth } from 'context/AuthContext';
import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { ChangeEmailModal } from '../../ui/modal/ChangeEmailModal';
import { ChangeNameModal } from '../../ui/modal/ChangeNameModal/ChangeNameModal';
import { ChangePasswordModal } from 'ui/modal/ChangePasswordModal';
import { ChangePhoneModal } from '../../ui/modal/ChangePhoneModal';
import { CoreHead } from '../../ui/core';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { ProfilePicture } from '../../ui/components/ProfilePicture';
import { useModalByName } from '../../hooks/useModal';
import classes from './personal-info.module.css';
import { prettifyPhone } from '../../utils/prettifyPhone';

export default function PersonalInfo() {
  const { user } = useAuth();
  const { modal, changeModal, closeModal } = useModalByName();

  // const [popupText, setPopupText] = useState(false);
  // const changeInfo = () => {
  //   setPopupText(true);
  //   setTimeout(() => setPopupText(false), 5000);
  // };

  return (
    <>
      <CoreHead title="Персональная информация" />
      <div className="section">
        <BreadCrumbs
          links={[
            BREAD_CRUMB_MAIN,
            { name: 'Профиль', path: '/profile/' },
            { name: 'Данные пользователя' },
          ]}
        />
        <div className={classes.personal_info}>
          <h1 className={`h2 ${classes.personal_head}`}>Данные пользователя</h1>
          <div className={classes.section_line}></div>
          <ProfilePicture
            // todo: сделать поля необязательными
            name={user?.lastName && user?.firstName && `${user.lastName} ${user.firstName}`}
            img={user?.avatarUrl}
            isLarge
          />
          <div className={classes.info}>
            <div className={classes.about_block}>
              <p className="p2">ФИО</p>
              {user?.firstName || user?.lastName ? (
                <div className={classes.about_info}>
                  <h5 className="h5">
                    {user?.lastName} {user?.firstName}
                  </h5>
                  <button className={classes.edit} onClick={() => changeModal('edit')}>
                    <Edit />
                  </button>
                </div>
              ) : (
                <button className={classes.about_btn} onClick={() => changeModal('edit')}>
                  <h6 className="h6">Указать данные</h6>
                </button>
              )}
            </div>
            <div className={classes.about_block}>
              <p className="p2">Телефон</p>
              <div className={classes.about_info}>
                {user?.phoneNumber && <h5 className="h5">{prettifyPhone(user.phoneNumber)}</h5>}
                {user?.phoneNumber ? (
                  <button className={classes.edit} onClick={() => changeModal('phone')}>
                    <Edit />
                  </button>
                ) : (
                  <button className={classes.about_btn} onClick={() => changeModal('phone')}>
                    <h6 className="h6">Добавить номер</h6>
                  </button>
                )}
              </div>
            </div>
            {/*todo: hid change email*/}
            {/*<div className={classes.about_block}>*/}
            {/*  <p className="p2">Email</p>*/}
            {/*  <div className={classes.about_info}>*/}
            {/*    {user?.email && <h5 className="h5">{user.email}</h5>}*/}
            {/*    {user?.email ? (*/}
            {/*      <button className={classes.edit} onClick={() => changeModal('email')}>*/}
            {/*        <Edit />*/}
            {/*      </button>*/}
            {/*    ) : (*/}
            {/*      <button className={classes.about_btn} onClick={() => changeModal('email')}>*/}
            {/*        <h6 className="h6">Редактирование Email</h6>*/}
            {/*      </button>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*todo: hid change password*/}
            {/*<div className={classes.about_block}>*/}
            {/*  <p className="p2">Пароль</p>*/}
            {/*  <button className={classes.about_btn} onClick={() => changeModal('password')}>*/}
            {/*    <h6 className="h6">Сменить пароль</h6>*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      {user && modal === 'edit' && (
        <ChangeNameModal
          firstName={user.firstName}
          lastName={user.lastName}
          onCloseButtonClick={closeModal}
        />
      )}
      {/*{!!popupText && <PopUp />}*/}

      {user && modal === 'phone' && (
        <ChangePhoneModal onCloseButtonClick={closeModal} phone={user.phoneNumber} />
      )}

      {user && modal === 'email' && <ChangeEmailModal onCloseButtonClick={closeModal} />}

      {user && modal === 'password' && <ChangePasswordModal onCloseButtonClick={closeModal} />}
    </>
  );
}
