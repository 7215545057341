import classes from './warranty-section.module.css';

export const WarrantySection = () => {
  return (
    <div className={`${classes.Warranty} section`}>
      <div className={classes.Text}>
        <h2 className="h3">
          Партнеры и поставщики сети Caready – мировые производители автокомпонентов оригинального
          качества.
        </h2>
        <p className="h5">
          Также Caready сотрудничает с поставщиками запчастей на сборочных конвейерах крупных
          автомобильных заводов.
        </p>
      </div>
      <div className={classes.Cards}>
        <div className={classes.Guarantee}>
          <h2 className="h4">Стандартная гарантия</h2>
          <div className={classes.GuaranteeInfo}>
            <p className="pm1">До</p>
            <div className={classes.Quantity}>
              <p className="h3">12</p>
              <p className="pm1">месяцев</p>
            </div>
            <p className="pm1">или&nbsp;до</p>
            <div className={classes.Quantity}>
              <p className="h3">30&nbsp;000</p>
              <p className="pm1">км&nbsp;пробега</p>
            </div>
          </div>
          <p className="p2">в зависимости от того, что наступит ранее</p>
        </div>
        {/*todo: hid guarantee details*/}
        {/*<div className={classes.Details}>*/}
        {/*  <h5 className="h5">Подробнее о гарантии</h5>*/}
        {/*  <button className={classes.Button}>*/}
        {/*    <ArrowRight />*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
