/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { GarageControllerControllerAddCarToGarage409Response } from '../dtos';
// @ts-ignore
import type { InitiateEmailChangeDto } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating401Response } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating403Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { UpdateNameDto } from '../dtos';
// @ts-ignore
import type { UpdatePhoneNumberDto } from '../dtos';
// @ts-ignore
import type { UserProfileDto } from '../dtos';
// @ts-ignore
import type { VerifyNewEmailDto } from '../dtos';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The method returns a profile of the authenticated user.
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method initiates a change of the authenticated user\'s email.      The user will receive an email with a verification code to the new address.      Verification code is valid for 5 minutes.
         * @summary Change user email
         * @param {InitiateEmailChangeDto} initiateEmailChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInitiateEmailChange: async (initiateEmailChangeDto: InitiateEmailChangeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiateEmailChangeDto' is not null or undefined
            assertParamExists('usersControllerInitiateEmailChange', 'initiateEmailChangeDto', initiateEmailChangeDto)
            const localVarPath = `/users/profile/change-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiateEmailChangeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveAvatar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/profile/avatar/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method resends a new email verification code to the new user\'s email. The code is valid for 5 minutes.     User can request a new code only once in a minute. If the user tries to request a new code more than once in a minute,     the method returns an error with the code 403.
         * @summary Resend new email verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendEmailVerificationCode: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/profile/change-email/resend-verification-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update avatar
         * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateAvatar: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('usersControllerUpdateAvatar', 'file', file)
            const localVarPath = `/users/profile/avatar/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update name
         * @param {UpdateNameDto} updateNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateName: async (updateNameDto: UpdateNameDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNameDto' is not null or undefined
            assertParamExists('usersControllerUpdateName', 'updateNameDto', updateNameDto)
            const localVarPath = `/users/profile/name/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update phone number
         * @param {UpdatePhoneNumberDto} updatePhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePhoneNumber: async (updatePhoneNumberDto: UpdatePhoneNumberDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePhoneNumberDto' is not null or undefined
            assertParamExists('usersControllerUpdatePhoneNumber', 'updatePhoneNumberDto', updatePhoneNumberDto)
            const localVarPath = `/users/profile/phone/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePhoneNumberDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method verifies a new user\'s email. The user must provide a verification code that was sent to the new email address.
         * @summary Verify new email
         * @param {VerifyNewEmailDto} verifyNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyNewEmail: async (verifyNewEmailDto: VerifyNewEmailDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyNewEmailDto' is not null or undefined
            assertParamExists('usersControllerVerifyNewEmail', 'verifyNewEmailDto', verifyNewEmailDto)
            const localVarPath = `/users/profile/change-email/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyNewEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * The method returns a profile of the authenticated user.
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerGetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method initiates a change of the authenticated user\'s email.      The user will receive an email with a verification code to the new address.      Verification code is valid for 5 minutes.
         * @summary Change user email
         * @param {InitiateEmailChangeDto} initiateEmailChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerInitiateEmailChange(initiateEmailChangeDto: InitiateEmailChangeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerInitiateEmailChange(initiateEmailChangeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerInitiateEmailChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRemoveAvatar(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRemoveAvatar(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerRemoveAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method resends a new email verification code to the new user\'s email. The code is valid for 5 minutes.     User can request a new code only once in a minute. If the user tries to request a new code more than once in a minute,     the method returns an error with the code 403.
         * @summary Resend new email verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResendEmailVerificationCode(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResendEmailVerificationCode(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerResendEmailVerificationCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update avatar
         * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateAvatar(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateAvatar(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdateAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update name
         * @param {UpdateNameDto} updateNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateName(updateNameDto: UpdateNameDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateName(updateNameDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdateName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update phone number
         * @param {UpdatePhoneNumberDto} updatePhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdatePhoneNumber(updatePhoneNumberDto: UpdatePhoneNumberDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdatePhoneNumber(updatePhoneNumberDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerUpdatePhoneNumber']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method verifies a new user\'s email. The user must provide a verification code that was sent to the new email address.
         * @summary Verify new email
         * @param {VerifyNewEmailDto} verifyNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerVerifyNewEmail(verifyNewEmailDto: VerifyNewEmailDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerVerifyNewEmail(verifyNewEmailDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersControllerVerifyNewEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * The method returns a profile of the authenticated user.
         * @summary Get user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetProfile(options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.usersControllerGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * The method initiates a change of the authenticated user\'s email.      The user will receive an email with a verification code to the new address.      Verification code is valid for 5 minutes.
         * @summary Change user email
         * @param {InitiateEmailChangeDto} initiateEmailChangeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInitiateEmailChange(initiateEmailChangeDto: InitiateEmailChangeDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerInitiateEmailChange(initiateEmailChangeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove avatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveAvatar(options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.usersControllerRemoveAvatar(options).then((request) => request(axios, basePath));
        },
        /**
         * The method resends a new email verification code to the new user\'s email. The code is valid for 5 minutes.     User can request a new code only once in a minute. If the user tries to request a new code more than once in a minute,     the method returns an error with the code 403.
         * @summary Resend new email verification code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendEmailVerificationCode(options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResendEmailVerificationCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update avatar
         * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateAvatar(file: File, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.usersControllerUpdateAvatar(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update name
         * @param {UpdateNameDto} updateNameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateName(updateNameDto: UpdateNameDto, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.usersControllerUpdateName(updateNameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update phone number
         * @param {UpdatePhoneNumberDto} updatePhoneNumberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdatePhoneNumber(updatePhoneNumberDto: UpdatePhoneNumberDto, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.usersControllerUpdatePhoneNumber(updatePhoneNumberDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The method verifies a new user\'s email. The user must provide a verification code that was sent to the new email address.
         * @summary Verify new email
         * @param {VerifyNewEmailDto} verifyNewEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerVerifyNewEmail(verifyNewEmailDto: VerifyNewEmailDto, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.usersControllerVerifyNewEmail(verifyNewEmailDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * The method returns a profile of the authenticated user.
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerGetProfile(options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

    /**
     * The method initiates a change of the authenticated user\'s email.      The user will receive an email with a verification code to the new address.      Verification code is valid for 5 minutes.
     * @summary Change user email
     * @param {InitiateEmailChangeDto} initiateEmailChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerInitiateEmailChange(initiateEmailChangeDto: InitiateEmailChangeDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Remove avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerRemoveAvatar(options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

    /**
     * The method resends a new email verification code to the new user\'s email. The code is valid for 5 minutes.     User can request a new code only once in a minute. If the user tries to request a new code more than once in a minute,     the method returns an error with the code 403.
     * @summary Resend new email verification code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerResendEmailVerificationCode(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Update avatar
     * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerUpdateAvatar(file: File, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

    /**
     * 
     * @summary Update name
     * @param {UpdateNameDto} updateNameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerUpdateName(updateNameDto: UpdateNameDto, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

    /**
     * 
     * @summary Update phone number
     * @param {UpdatePhoneNumberDto} updatePhoneNumberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerUpdatePhoneNumber(updatePhoneNumberDto: UpdatePhoneNumberDto, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

    /**
     * The method verifies a new user\'s email. The user must provide a verification code that was sent to the new email address.
     * @summary Verify new email
     * @param {VerifyNewEmailDto} verifyNewEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    usersControllerVerifyNewEmail(verifyNewEmailDto: VerifyNewEmailDto, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * The method returns a profile of the authenticated user.
     * @summary Get user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetProfile(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method initiates a change of the authenticated user\'s email.      The user will receive an email with a verification code to the new address.      Verification code is valid for 5 minutes.
     * @summary Change user email
     * @param {InitiateEmailChangeDto} initiateEmailChangeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerInitiateEmailChange(initiateEmailChangeDto: InitiateEmailChangeDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerInitiateEmailChange(initiateEmailChangeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove avatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRemoveAvatar(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerRemoveAvatar(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method resends a new email verification code to the new user\'s email. The code is valid for 5 minutes.     User can request a new code only once in a minute. If the user tries to request a new code more than once in a minute,     the method returns an error with the code 403.
     * @summary Resend new email verification code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerResendEmailVerificationCode(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerResendEmailVerificationCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update avatar
     * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateAvatar(file: File, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateAvatar(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update name
     * @param {UpdateNameDto} updateNameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateName(updateNameDto: UpdateNameDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateName(updateNameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update phone number
     * @param {UpdatePhoneNumberDto} updatePhoneNumberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdatePhoneNumber(updatePhoneNumberDto: UpdatePhoneNumberDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdatePhoneNumber(updatePhoneNumberDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method verifies a new user\'s email. The user must provide a verification code that was sent to the new email address.
     * @summary Verify new email
     * @param {VerifyNewEmailDto} verifyNewEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerVerifyNewEmail(verifyNewEmailDto: VerifyNewEmailDto, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerVerifyNewEmail(verifyNewEmailDto, options).then((request) => request(this.axios, this.basePath));
    }
}

