import { ExperienceSection } from '../ExperienceSection';
import { FranchiseSection } from '../../../config/franchiseSections';
import styles from './reviews.module.css';

export const Reviews = () => {
  return (
    <section className={`${styles.sectionWrapper}`} id={FranchiseSection.Reviews}>
      <h2 className={`${styles.sectionHeader} h6-16 section-title`}>Отзывы</h2>
      <ExperienceSection />
      {/*todo: hid franchisee reviews*/}
      {/*<FranchiseeReviews />*/}
    </section>
  );
};
