import { ReactComponent as Profile } from 'assets/svg/profile.svg';
import classes from './persona-card.module.css';
import { UserProfileDto } from '../../../../api/generated/caready';
import { UTMLink } from '../../../base/UTMLink/UTMLink';

export const PersonalData = ({ user }: { user: UserProfileDto }) => {
  return (
    <UTMLink className={classes.wrap} to="/profile/personal-info/">
      <div className={classes.personBlock}>
        <div className={classes.avatarIcon}>
          <Profile />
        </div>
        <div className={classes.info}>
          <h5 className={'h5'}>
            {user.firstName} {user.lastName}
          </h5>
          <p className={'p2'}>{user.email}</p>
        </div>
      </div>
      {user.phoneNumber && (
        <div className={classes.contacts}>
          <p className={`${classes.contactType} p2`}>E-mail</p>
          <h6 className={`${classes.contactData} h6`}>{user.email}</h6>
        </div>
      )}
    </UTMLink>
  );
};
