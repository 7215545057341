import { useEffect, useState } from 'react';

export function useHtmlDocument(fileName: string) {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(`/documents/${fileName}.html`)
      .then(response => response.text())
      .then(html => {
        setHtmlContent(html);
      });
  }, []);

  return { htmlContent };
}
