/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CityDto } from '../dtos';
// @ts-ignore
import type { ElectricChargerTypeDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { ServiceStationDto } from '../dtos';
// @ts-ignore
import type { ServiceStationWithDetailWorkingScheduleDto } from '../dtos';
/**
 * ServiceStationsApi - axios parameter creator
 * @export
 */
export const ServiceStationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get service station by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('serviceStationsControllerGetById', 'id', id)
            const localVarPath = `/service-stations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all electric charger types
         * @summary Get electric charger types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetElectricChargerTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-stations/charger-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * By default, it returns only the service stations that provide some services (\'service-station\' type).
         * @summary Get service stations
         * @param {string} [address] Filter service stations by address. Search is performed by address line, city and country name. The query is case insensitive.
         * @param {Array<ServiceStationsControllerGetServiceStationsTypesEnum>} [types] Filter service stations by type
         * @param {Array<string>} [serviceCategories] Filter service stations by supported service categories (slugs)
         * @param {Array<string>} [services] Filter service stations by supported services (slugs)
         * @param {Array<string>} [electricChargerTypes] Filter service stations by supported electric charger types (slugs)
         * @param {Array<string>} [serviceCategoryIds] Filter service stations by supported service categories (id)
         * @param {Array<string>} [serviceIds] Filter service stations by supported services (id)
         * @param {string} [cityAbbreviation] Filter service station by city abbreviation
         * @param {string} [slug] Filter service station by address slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetServiceStations: async (address?: string, types?: Array<ServiceStationsControllerGetServiceStationsTypesEnum>, serviceCategories?: Array<string>, services?: Array<string>, electricChargerTypes?: Array<string>, serviceCategoryIds?: Array<string>, serviceIds?: Array<string>, cityAbbreviation?: string, slug?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (serviceCategories) {
                localVarQueryParameter['serviceCategories'] = serviceCategories;
            }

            if (services) {
                localVarQueryParameter['services'] = services;
            }

            if (electricChargerTypes) {
                localVarQueryParameter['electricChargerTypes'] = electricChargerTypes;
            }

            if (serviceCategoryIds) {
                localVarQueryParameter['serviceCategoryIds'] = serviceCategoryIds;
            }

            if (serviceIds) {
                localVarQueryParameter['serviceIds'] = serviceIds;
            }

            if (cityAbbreviation !== undefined) {
                localVarQueryParameter['cityAbbreviation'] = cityAbbreviation;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cities that have service stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetServiceStationsCities: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-stations/locations/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceStationsApi - functional programming interface
 * @export
 */
export const ServiceStationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceStationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get service station by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceStationsControllerGetById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceStationWithDetailWorkingScheduleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceStationsControllerGetById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceStationsApi.serviceStationsControllerGetById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all electric charger types
         * @summary Get electric charger types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceStationsControllerGetElectricChargerTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ElectricChargerTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceStationsControllerGetElectricChargerTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceStationsApi.serviceStationsControllerGetElectricChargerTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * By default, it returns only the service stations that provide some services (\'service-station\' type).
         * @summary Get service stations
         * @param {string} [address] Filter service stations by address. Search is performed by address line, city and country name. The query is case insensitive.
         * @param {Array<ServiceStationsControllerGetServiceStationsTypesEnum>} [types] Filter service stations by type
         * @param {Array<string>} [serviceCategories] Filter service stations by supported service categories (slugs)
         * @param {Array<string>} [services] Filter service stations by supported services (slugs)
         * @param {Array<string>} [electricChargerTypes] Filter service stations by supported electric charger types (slugs)
         * @param {Array<string>} [serviceCategoryIds] Filter service stations by supported service categories (id)
         * @param {Array<string>} [serviceIds] Filter service stations by supported services (id)
         * @param {string} [cityAbbreviation] Filter service station by city abbreviation
         * @param {string} [slug] Filter service station by address slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceStationsControllerGetServiceStations(address?: string, types?: Array<ServiceStationsControllerGetServiceStationsTypesEnum>, serviceCategories?: Array<string>, services?: Array<string>, electricChargerTypes?: Array<string>, serviceCategoryIds?: Array<string>, serviceIds?: Array<string>, cityAbbreviation?: string, slug?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceStationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceStationsControllerGetServiceStations(address, types, serviceCategories, services, electricChargerTypes, serviceCategoryIds, serviceIds, cityAbbreviation, slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceStationsApi.serviceStationsControllerGetServiceStations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get cities that have service stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceStationsControllerGetServiceStationsCities(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceStationsControllerGetServiceStationsCities(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceStationsApi.serviceStationsControllerGetServiceStationsCities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServiceStationsApi - factory interface
 * @export
 */
export const ServiceStationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceStationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get service station by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetById(id: string, options?: any): AxiosPromise<ServiceStationWithDetailWorkingScheduleDto> {
            return localVarFp.serviceStationsControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all electric charger types
         * @summary Get electric charger types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetElectricChargerTypes(options?: any): AxiosPromise<Array<ElectricChargerTypeDto>> {
            return localVarFp.serviceStationsControllerGetElectricChargerTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * By default, it returns only the service stations that provide some services (\'service-station\' type).
         * @summary Get service stations
         * @param {string} [address] Filter service stations by address. Search is performed by address line, city and country name. The query is case insensitive.
         * @param {Array<ServiceStationsControllerGetServiceStationsTypesEnum>} [types] Filter service stations by type
         * @param {Array<string>} [serviceCategories] Filter service stations by supported service categories (slugs)
         * @param {Array<string>} [services] Filter service stations by supported services (slugs)
         * @param {Array<string>} [electricChargerTypes] Filter service stations by supported electric charger types (slugs)
         * @param {Array<string>} [serviceCategoryIds] Filter service stations by supported service categories (id)
         * @param {Array<string>} [serviceIds] Filter service stations by supported services (id)
         * @param {string} [cityAbbreviation] Filter service station by city abbreviation
         * @param {string} [slug] Filter service station by address slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetServiceStations(address?: string, types?: Array<ServiceStationsControllerGetServiceStationsTypesEnum>, serviceCategories?: Array<string>, services?: Array<string>, electricChargerTypes?: Array<string>, serviceCategoryIds?: Array<string>, serviceIds?: Array<string>, cityAbbreviation?: string, slug?: string, options?: any): AxiosPromise<Array<ServiceStationDto>> {
            return localVarFp.serviceStationsControllerGetServiceStations(address, types, serviceCategories, services, electricChargerTypes, serviceCategoryIds, serviceIds, cityAbbreviation, slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cities that have service stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceStationsControllerGetServiceStationsCities(options?: any): AxiosPromise<Array<CityDto>> {
            return localVarFp.serviceStationsControllerGetServiceStationsCities(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceStationsApi - interface
 * @export
 * @interface ServiceStationsApi
 */
export interface ServiceStationsApiInterface {
    /**
     * 
     * @summary Get service station by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApiInterface
     */
    serviceStationsControllerGetById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ServiceStationWithDetailWorkingScheduleDto>;

    /**
     * Get all electric charger types
     * @summary Get electric charger types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApiInterface
     */
    serviceStationsControllerGetElectricChargerTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<ElectricChargerTypeDto>>;

    /**
     * By default, it returns only the service stations that provide some services (\'service-station\' type).
     * @summary Get service stations
     * @param {string} [address] Filter service stations by address. Search is performed by address line, city and country name. The query is case insensitive.
     * @param {Array<ServiceStationsControllerGetServiceStationsTypesEnum>} [types] Filter service stations by type
     * @param {Array<string>} [serviceCategories] Filter service stations by supported service categories (slugs)
     * @param {Array<string>} [services] Filter service stations by supported services (slugs)
     * @param {Array<string>} [electricChargerTypes] Filter service stations by supported electric charger types (slugs)
     * @param {Array<string>} [serviceCategoryIds] Filter service stations by supported service categories (id)
     * @param {Array<string>} [serviceIds] Filter service stations by supported services (id)
     * @param {string} [cityAbbreviation] Filter service station by city abbreviation
     * @param {string} [slug] Filter service station by address slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApiInterface
     */
    serviceStationsControllerGetServiceStations(address?: string, types?: Array<ServiceStationsControllerGetServiceStationsTypesEnum>, serviceCategories?: Array<string>, services?: Array<string>, electricChargerTypes?: Array<string>, serviceCategoryIds?: Array<string>, serviceIds?: Array<string>, cityAbbreviation?: string, slug?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ServiceStationDto>>;

    /**
     * 
     * @summary Get cities that have service stations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApiInterface
     */
    serviceStationsControllerGetServiceStationsCities(options?: RawAxiosRequestConfig): AxiosPromise<Array<CityDto>>;

}

/**
 * ServiceStationsApi - object-oriented interface
 * @export
 * @class ServiceStationsApi
 * @extends {BaseAPI}
 */
export class ServiceStationsApi extends BaseAPI implements ServiceStationsApiInterface {
    /**
     * 
     * @summary Get service station by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApi
     */
    public serviceStationsControllerGetById(id: string, options?: RawAxiosRequestConfig) {
        return ServiceStationsApiFp(this.configuration).serviceStationsControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all electric charger types
     * @summary Get electric charger types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApi
     */
    public serviceStationsControllerGetElectricChargerTypes(options?: RawAxiosRequestConfig) {
        return ServiceStationsApiFp(this.configuration).serviceStationsControllerGetElectricChargerTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * By default, it returns only the service stations that provide some services (\'service-station\' type).
     * @summary Get service stations
     * @param {string} [address] Filter service stations by address. Search is performed by address line, city and country name. The query is case insensitive.
     * @param {Array<ServiceStationsControllerGetServiceStationsTypesEnum>} [types] Filter service stations by type
     * @param {Array<string>} [serviceCategories] Filter service stations by supported service categories (slugs)
     * @param {Array<string>} [services] Filter service stations by supported services (slugs)
     * @param {Array<string>} [electricChargerTypes] Filter service stations by supported electric charger types (slugs)
     * @param {Array<string>} [serviceCategoryIds] Filter service stations by supported service categories (id)
     * @param {Array<string>} [serviceIds] Filter service stations by supported services (id)
     * @param {string} [cityAbbreviation] Filter service station by city abbreviation
     * @param {string} [slug] Filter service station by address slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApi
     */
    public serviceStationsControllerGetServiceStations(address?: string, types?: Array<ServiceStationsControllerGetServiceStationsTypesEnum>, serviceCategories?: Array<string>, services?: Array<string>, electricChargerTypes?: Array<string>, serviceCategoryIds?: Array<string>, serviceIds?: Array<string>, cityAbbreviation?: string, slug?: string, options?: RawAxiosRequestConfig) {
        return ServiceStationsApiFp(this.configuration).serviceStationsControllerGetServiceStations(address, types, serviceCategories, services, electricChargerTypes, serviceCategoryIds, serviceIds, cityAbbreviation, slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cities that have service stations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceStationsApi
     */
    public serviceStationsControllerGetServiceStationsCities(options?: RawAxiosRequestConfig) {
        return ServiceStationsApiFp(this.configuration).serviceStationsControllerGetServiceStationsCities(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ServiceStationsControllerGetServiceStationsTypesEnum = {
    ServiceMinusStation: 'service-station',
    ElectricMinusCharger: 'electric-charger'
} as const;
export type ServiceStationsControllerGetServiceStationsTypesEnum = typeof ServiceStationsControllerGetServiceStationsTypesEnum[keyof typeof ServiceStationsControllerGetServiceStationsTypesEnum];
