import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CardNew } from '../../ui/components/CardNew';
import { CoreHead } from '../../ui/core';
import { useNews } from '../../hooks/useNews';
import { useState } from 'react';
import s from './NewsListPage.module.scss';
import commonStyles from '../../styles/common/common-styles.module.scss';
import clsx from 'clsx';

export const NewsListPage = function NewsListPage() {
  const [activeTab] = useState('');
  const [pages, setPages] = useState(1);
  const allNews = useNews(1, pages * 8, activeTab ? activeTab : '');
  const breadCrumbs = [
    BREAD_CRUMB_MAIN,
    {
      name: 'Новости',
    },
  ];
  return (
    <>
      <CoreHead
        title={'Новости'}
        description={
          'Будьте в курсе всех новостей Caready о расширении сети автосервисов по всей России, специальных предложениях для наших клиентов и других интересных событиях'
        }
      />
      <div className={'section'}>
        <BreadCrumbs links={breadCrumbs} />
        <h1 className={clsx('h2', s.title)}>Новости</h1>
        <div className={commonStyles.cardsWrapper}>
          {allNews.news.map(card => (
            <CardNew newsData={card} key={card.headline} />
          ))}
        </div>
        {!(allNews.totals <= pages * 8) && (
          <button
            className={clsx(s.loadMore, 'btn', 'secondary', 'black')}
            onClick={() => setPages(pages + 1)}
          >
            Показать ещё
          </button>
        )}
      </div>
    </>
  );
};
