import { ReactComponent as FilterIcon } from '../../../../../assets/svg/filter.svg';
import { useMap } from '../../context/MapProvider';
import { FC } from 'react';
import s from './FiltersButton.module.scss';
import clsx from 'clsx';

interface OwnProps {
  toggleFilter: () => void;
}

export const FiltersButton: FC<OwnProps> = function FiltersButton(props) {
  const { selectedCategories, setSelectedCategories } = useMap();

  return (
    <div className={s.filter}>
      <button
        onClick={props.toggleFilter}
        className={clsx(s.filterButton, selectedCategories.length && s.activeButton)}
      >
        <FilterIcon />
        <p className={s.title}>Фильтры</p>
        {selectedCategories.length > 0 && (
          <span className={s.count}>{selectedCategories.length}</span>
        )}
      </button>
      {selectedCategories.length > 0 && (
        <button className={`p3 ${s.reset}`} onClick={() => setSelectedCategories([])}>
          Сбросить
        </button>
      )}
    </div>
  );
};
