import React, { FC, ReactNode } from 'react';
import { Placemark } from '@pbe/react-yandex-maps';
import { placemarkDefaultOptions } from '../../config/placemarkDefaultOptions';
import { ServiceStationDto } from '../../../../../api/generated/caready';

interface OnwProps {
  selectedServiceStation: ServiceStationDto | null;
  serviceStations: ServiceStationDto[];
  balloonId?: string;
  onClick?: (value: ServiceStationDto) => void;
}

export const PlacemarkList: FC<OnwProps> = function PlacemarkList(props) {
  const filteredPlacemarks: ReactNode[] = [];

  for (const serviceStation of props.serviceStations) {
    if (serviceStation.id === props.selectedServiceStation?.id) continue;

    filteredPlacemarks.push(
      <Placemark
        modules={['geoObject.addon.balloon']}
        key={serviceStation.id}
        defaultGeometry={[
          serviceStation.fullAddress.coordinates.latitude,
          serviceStation.fullAddress.coordinates.longitude,
        ]}
        options={{
          ...placemarkDefaultOptions,
          balloonOffset: [0, -24],
          hideIconOnBalloonOpen: false,
        }}
        onClick={() => {
          props.onClick?.(serviceStation);
        }}
        properties={{
          balloonContent: props.balloonId
            ? `<div id=${props.balloonId} class="balloon"/>`
            : undefined,
        }}
      />
    );
  }

  return filteredPlacemarks;
};
