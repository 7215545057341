import {
  createContext,
  FC,
  lazy,
  PropsWithChildren,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CityDto, ServiceStationDto } from '../api/generated/caready';
import { throwError } from '../utils/errorThrower';
import { useNavigate } from 'react-router-dom';
import { useYMaps } from '@pbe/react-yandex-maps';
import { GeoDictionaryApi } from '../api';
import { useCities } from '../hooks/useGeoDictionary';
import { filterCityByGeo } from '../utils/filterCityByGeo';
import { useServiceStations } from '../hooks/useServiceStations';
import { useUtm } from '../hooks/useUtm';

type Modal = 'select-city' | 'trade-in';

type GeoContextType = {
  modal: Modal | null;
  setModal: (value: Modal | null) => void;

  city: string | null;
  cities: CityDto[];
  updateAbbreviationCity: (value?: string) => void;
  bounds?: number[][];
  abbreviationCity?: string;
  isGeoCity: boolean;
  setIsGeoCity: (value: boolean) => void;
  serviceStation: ServiceStationDto | null;
  removeSlug: () => void;
};

export const GeoContext = createContext<GeoContextType | null>(null);

export const useGeo = () =>
  useContext(GeoContext) ?? throwError('useGeo can be used only inside GeoProvider');

export const DefaultCity = {
  name: 'Москва',
  abbreviation: 'moskva',
  bounds: [
    [55.142226, 36.803268],
    [56.021286, 37.967799],
  ],
};

const TradeInModal = lazy(() =>
  import('../ui/modal/TradeIn/TradeInModal').then(module => ({
    default: module.TradeInModal,
  }))
);

const LocationModal = lazy(() =>
  import('../ui/modal/LocationModal/LocationModal').then(module => ({
    default: module.LocationModal,
  }))
);

export const GeoProvider: FC<PropsWithChildren> = function GeoProvider(props) {
  // todo: вынести в отдельный провайдер
  const [modal, setModal] = useState<Modal | null>(null);

  const navigate = useNavigate();
  const { utmData, getLinkWithSearchParams } = useUtm();

  const [serviceStation, setServiceStation] = useState<ServiceStationDto | null>(null);

  const [city, setCity] = useState<string | null>(null);

  const [abbreviationCity, setAbbreviationCity] = useState<string>();
  const [slug, setSlug] = useState<string>();

  const [isGeoCity, setIsGeoCity] = useState(false);

  const [bounds, setBounds] = useState<number[][]>();

  const cities = useCities();
  const ymaps = useYMaps(['geocode']);

  const { data: serviceStations } = useServiceStations(
    { cityAbbreviation: abbreviationCity, slug: slug },
    {
      enabled: !!abbreviationCity || !!slug,
    }
  );

  useEffect(() => {
    if (utmData?.slugs.length === 1) {
      setSlug(utmData.slugs[0]);
    }

    if (utmData?.city) {
      updateAbbreviationCity(utmData.city);
      return;
    }

    const abbreviationCity = localStorage.getItem('city');

    if (abbreviationCity) {
      setAbbreviationCity(abbreviationCity);
      return;
    }

    if (!ymaps || !cities.length) return;

    updateByYmapsGeo();
  }, [utmData, ymaps, cities]);

  const updateByYmapsGeo = () => {
    if (!ymaps) return;

    navigator.geolocation.getCurrentPosition(
      async ({ coords }) => {
        const result = await ymaps.geocode([coords.latitude, coords.longitude]);
        // @ts-ignore
        const areaName: string = result.geoObjects
          .get(0)
          .properties.get(
            'metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.AdministrativeAreaName',
            {}
          );
        const city = areaName === 'Ленинградская область' ? 'Санкт-Петербург' : areaName;

        const { data } = await GeoDictionaryApi.geoDictionaryControllerGetCities(
          filterCityByGeo(city, cities)
        );
        updateAbbreviationCity(data[0].abbreviation);
        setIsGeoCity(true);
      },
      () => {
        setDefaultCity();
      }
    );
  };

  useEffect(() => {
    if (serviceStations === undefined) return;

    if (!serviceStations.length) {
      GeoDictionaryApi.geoDictionaryControllerGetCities(
        undefined,
        undefined,
        abbreviationCity
      ).then(({ data }) => {
        if (!data.length) {
          setDefaultCity();
          return;
        }

        setCity(data[0].name);
        updateBounds(data[0].name);
        setServiceStation(null);
      });
      return;
    }

    const city = serviceStations[0].fullAddress.city.name;
    setCity(city);

    if (serviceStations?.length === 1) {
      setServiceStation(serviceStations[0]);
      updateBounds(city);

      return;
    }

    updateBounds(city);
    setServiceStation(null);
  }, [serviceStations, ymaps]);

  const updateBounds = async (city?: string) => {
    if (!ymaps || !city) {
      setBounds(DefaultCity.bounds);
      return;
    }
    const result = await ymaps.geocode(city);
    setBounds(result.geoObjects.get(0).properties.get('boundedBy', {}) as number[][]);
  };

  const updateAbbreviationCity = (abbreviation?: string) => {
    if (!abbreviation) {
      setDefaultCity();
      return;
    }

    setAbbreviationCity(abbreviation);
    localStorage.setItem('city', abbreviation);
  };

  const setDefaultCity = () => {
    setAbbreviationCity(DefaultCity.abbreviation);
    localStorage.setItem('city', DefaultCity.abbreviation);

    navigate(getLinkWithSearchParams('params'));
  };

  const removeSlug = () => setSlug(undefined);

  return (
    <GeoContext.Provider
      value={{
        modal,
        setModal,

        city,
        abbreviationCity,
        cities,
        bounds,
        isGeoCity,
        updateAbbreviationCity,
        setIsGeoCity,
        serviceStation,
        removeSlug,
      }}
    >
      {props.children}
      {modal === 'trade-in' && (
        <Suspense>
          <TradeInModal />
        </Suspense>
      )}
      {modal === 'select-city' && (
        <Suspense>
          <LocationModal />
        </Suspense>
      )}
    </GeoContext.Provider>
  );
};
