import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as CrossMedium } from 'assets/svg/cross-medium.svg';
import { Content, Root, Trigger } from '@radix-ui/react-popover';
import { SelectInput } from '../Input/Input';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import s from './Combobox.module.scss';
import { useDebounce } from '../../../hooks/useDebounce';
import clsx from 'clsx';
import { SkeletonGroup } from '../Skeleton/SkeletonGroup';
import { ComboboxOption, ComboboxOptionSkeleton } from './components/comboboxOption/ComboboxOption';
import { Option } from './types/Option';
import { OptionWithName } from './types/OptionWithName';

export interface ComboboxProps {
  label?: string;
  setValue: (v: Option | null) => void;
  value: Option | null;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  touched?: boolean;
  options?: Option[] | OptionWithName[];
  isLoading?: boolean;
  setQuery?: (value: string) => void;
}

export const Combobox: FC<ComboboxProps> = function Combox(props) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [searchString, setSearchString] = useState<string>(props.value?.name || '');
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading || false);

  const updateQuery = useDebounce(
    value => {
      if (!props.setQuery) return;

      setIsLoading(false);
      props.setQuery(value);
    },
    { wait: 1000 }
  );

  const updateSelectedOption = (value: Option | null) => {
    setOpen(false);
    props.setValue(value);
  };

  useEffect(() => {
    if (!selectedOption) {
      setSearchString('');
      return;
    }

    setSearchString(selectedOption.name);
  }, [selectedOption]);

  useEffect(() => {
    if (typeof props.isLoading === 'undefined') return;

    setIsLoading(props.isLoading);
  }, [props.isLoading, props.options]);

  useEffect(() => {
    setSelectedOption(props.value);
  }, [props.value]);

  const clearField = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    updateSelectedOption(null);
    props.setValue(null);
  };

  const toggleDropdown = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    setOpen(prevState => !prevState);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    setOpen(true);
    setIsLoading(true);

    updateQuery(e.target.value);
    props.setValue(null);
  };

  return (
    <div className={s.combobox}>
      <Root open={open} onOpenChange={setOpen}>
        <div>
          <Trigger
            className={clsx(
              props.error && props.touched && s.error,
              !props.value?.id && s.placeholder
            )}
            asChild={true}
          >
            <div>
              <SelectInput
                label={props.label}
                error={props.error}
                touched={props.touched}
                icons={
                  !props.disabled && (
                    <div className={clsx(s.icons, open && s.iconsOpen)}>
                      {!!props.value && <CrossMedium className={s.clear} onClick={clearField} />}
                      <ChevronDown className={`${s.arrow}`} onClick={toggleDropdown} />
                    </div>
                  )
                }
              >
                <input
                  disabled={props.disabled}
                  readOnly={!props.setQuery}
                  placeholder={props.placeholder}
                  value={!props.disabled ? searchString : ''}
                  onChange={onInputChange}
                />
              </SelectInput>
            </div>
          </Trigger>
        </div>
        <Content
          collisionPadding={0}
          className={s.content}
          align={'start'}
          onOpenAutoFocus={e => e.preventDefault()}
          onCloseAutoFocus={e => e.preventDefault()}
        >
          <SkeletonGroup isLoading={isLoading} quantity={4} skeleton={ComboboxOptionSkeleton}>
            {props.options?.map(option =>
              'options' in option ? (
                !!option.options.length && (
                  <div key={option.name}>
                    <p className={`${s.groupTitle} h6`}>{option.name}</p>
                    <div>
                      {option.options.map((it: Option) => (
                        <ComboboxOption
                          option={it}
                          key={it.id}
                          isSelected={it.id === props.value?.id}
                          setSelectedOption={updateSelectedOption}
                        />
                      ))}
                    </div>
                  </div>
                )
              ) : (
                <ComboboxOption
                  option={option}
                  key={option.id}
                  isSelected={option.id === props.value?.id}
                  setSelectedOption={updateSelectedOption}
                />
              )
            )}
          </SkeletonGroup>
        </Content>
      </Root>
    </div>
  );
};
