import { FC } from 'react';
import clsx from 'clsx';
import { getServiceStationOpeningHours } from '../../../utils/getServiceStationOpeningHours';
import {
  ServiceStationDto,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../../../api/generated/caready';
import s from './ServiceStationWorkStatus.module.scss';
import commonStyles from '../../../styles/common/common-styles.module.scss';
import { getServiceStationAddress } from '../../../utils/getServiceStationAddress';

interface OwnProps {
  serviceStation: ServiceStationWithDetailWorkingScheduleDto | ServiceStationDto;
  withAddress?: boolean;
  className?: string;
}

export const ServiceStationWorkStatus: FC<OwnProps> = function ServiceStationWorkStatus(props) {
  const openingHour = getServiceStationOpeningHours(props.serviceStation);

  return (
    <div className={`${s.wrapper} ${props.className}`}>
      {(props.withAddress ?? true) && (
        <p className={'h6'}>{getServiceStationAddress(props.serviceStation)}</p>
      )}
      <div className={`${s.content} p3`}>
        <span className={clsx(!props.serviceStation.openingHours?.isOpen && s.closed)}>
          {props.serviceStation.openingHours?.isOpen ? 'Открыто' : 'Закрыто'}
        </span>
        {openingHour && (
          <>
            <span className={s.dot}></span>
            <span>{openingHour}</span>
            {'hasElectricCharger' in props.serviceStation &&
              props.serviceStation.hasElectricCharger && (
                <span className={commonStyles.electricIcon} />
              )}
          </>
        )}
      </div>
    </div>
  );
};
