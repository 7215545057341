import { AddFromMyCars } from '../TradeIn/AddFromMyCars';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { useAuth } from '../../../context/AuthContext';
import { ERROR_EMAIL, ERROR_REQUIRED, ERROR_VIN, ERROR_YEAR } from '../../../config/errorTexts';
import { Form, Formik } from 'formik';
import { ModalClose } from '../../base/ModalBase/ModalBase.v2';
import { Step1 } from '../TradeIn/Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from '../TradeIn/Step5';
import { Success } from './Success';
import { array, bool, date, number, object, string } from 'yup';
import { format } from 'date-fns';
import { getServiceStationAddress } from '../../../utils/getServiceStationAddress';
import { getServiceText } from '../../../utils/getServiceText';
import { ru } from 'date-fns/locale';
import { useGarageCars } from '../../../hooks/useGarage';
import { useMaintenanceServicesTree } from '../../../hooks/useMaintenanceServices';
import React, { Fragment, useState } from 'react';
import classes from '../../../ui/modal/TradeIn/tradein.module.css';
import {
  type CarBrandDto,
  CarModelDto,
  DayPartIntervalDto,
  GarageCarDto,
  MaintenanceServiceDto,
  MaintenanceServicePageDto,
  ScheduleAppointmentDto,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../../../api/generated/caready';
import { MaintenanceAppointmentsApi, ServiceStationsApi } from '../../../api';
import { DayPart } from '../../../types/DayPart';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { ModalOverlay } from '../../base/ModalBase';
import { cyrillic, phoneNumber } from '../../../config/validationSchemas';
import { sendMetrics } from '../../../utils/sendMetrics';

const titles = [
  'Информация об автомобиле',
  'Выберите услуги',
  'Выберите сервисный центр',
  'Выберите дату и время',
  'Личные данные',
];

export interface AppointmentFormValues {
  brand: CarBrandDto | null;
  carModel: CarModelDto | null;
  carYear?: string;
  carVin?: string;
  maintenanceServices: string[];
  serviceStation: string;
  date: Date | null;
  dayPart: DayPartIntervalDto | null;
  clientFirstName: string;
  clientLastName: string;
  clientPhoneNumber: string;
  clientEmail: string;
  clientComment: string;
  termsAgreement: boolean;
}

const validationSchema = [
  object().shape({
    brand: object().required(ERROR_REQUIRED),
    carModel: object().required(ERROR_REQUIRED),
    carYear: number().min(1885, ERROR_YEAR).max(new Date().getFullYear(), ERROR_YEAR).optional(),
    carVin: string().length(17, ERROR_VIN).optional(),
  }),
  object().shape({
    maintenanceServices: array().min(1, ERROR_REQUIRED),
  }),
  object().shape({
    serviceStation: string().required(ERROR_REQUIRED),
  }),
  object().shape({
    date: date().required(ERROR_REQUIRED),
    dayPart: object().required(ERROR_REQUIRED),
  }),
  object().shape({
    clientFirstName: cyrillic.required(ERROR_REQUIRED),
    clientLastName: cyrillic.required(ERROR_REQUIRED),
    clientPhoneNumber: phoneNumber.required(ERROR_REQUIRED),
    clientEmail: string().email(ERROR_EMAIL).required(ERROR_REQUIRED),
    termsAgreement: bool().oneOf([true], ERROR_REQUIRED),
  }),
];

export const Appointment = ({
  onCloseButtonClick,
  appointmentService,
  appointmentServiceStation,
  promotedServices,
  car,
  isOpen,
}: {
  onCloseButtonClick: () => void;
  car?: GarageCarDto;
  appointmentService?: MaintenanceServicePageDto;
  appointmentServiceStation?: ServiceStationWithDetailWorkingScheduleDto;
  promotedServices?: MaintenanceServiceDto[];
  isOpen: boolean;
}) => {
  const { user } = useAuth();
  const categories = useMaintenanceServicesTree(appointmentServiceStation?.supportedServices);

  const [activeStep, setActiveStep] = useState(car && car.vin ? 1 : 0);
  const [success, setSuccess] = useState(false);
  const [isManually, setIsManually] = useState(!user);

  const [services, setServices] = useState<string[]>(
    appointmentService
      ? [appointmentService.name]
      : promotedServices
        ? promotedServices.map(promotedService => promotedService.name)
        : []
  );

  const [selectedPoint, setSelectedPoint] = useState<
    ServiceStationWithDetailWorkingScheduleDto | undefined
  >(appointmentServiceStation);

  const cars = useGarageCars(!user);

  const isLastStep = activeStep === 4;

  const initialValues: AppointmentFormValues = {
    brand: car?.model.brand || null,
    carModel: car?.model || null,
    carYear: (car?.modelModification?.generation.fromYear ?? '').toString(),
    carVin: car?.vin ?? '',

    maintenanceServices: appointmentService
      ? [appointmentService.slug]
      : promotedServices
        ? promotedServices.map(promotedService => promotedService.slug)
        : [],

    serviceStation: appointmentServiceStation ? appointmentServiceStation.id : '',

    date: null,
    dayPart: null,

    clientFirstName: user?.firstName ?? '',
    clientLastName: user?.lastName ?? '',
    clientPhoneNumber: user?.phoneNumber ?? '',
    clientEmail: user?.email ?? '',
    clientComment: '',
    termsAgreement: false,
  };

  const updateSelectedStation = async (id: string) => {
    const { data } = await ServiceStationsApi.serviceStationsControllerGetById(id);
    setSelectedPoint(data);
  };

  const [error, setError] = useState<string | null>(null);

  const onModalClose = () => {
    onCloseButtonClick();
    setActiveStep(0);
  };

  return (
    <ModalOverlay open={isOpen} onOpenChange={onModalClose}>
      <Formik<AppointmentFormValues>
        validationSchema={
          !isManually && activeStep === 0 ? undefined : validationSchema[activeStep]
        }
        validateOnMount={true}
        initialValues={initialValues}
        onSubmit={async (values, formikHelpers) => {
          if (isLastStep) {
            const { carYear, carVin, date, dayPart, carModel, brand, ...rest } = values;

            if (!dayPart || !carModel || !date || !selectedPoint || !brand) return;

            const data: ScheduleAppointmentDto = {
              ...rest,
              carModel: carModel.id,
              date: format(date, 'yyyy-MM-dd'),
              dayPart: dayPart.name,
              termsAgreement: true,
            };

            if (!!carVin?.length) {
              data.carVin = carVin;
            }

            if (!!carYear) {
              data.carYear = parseInt(carYear);
            }

            try {
              await MaintenanceAppointmentsApi.maintenanceAppointmentsControllerScheduleMaintenanceAppointment(
                'web_app',
                data
              );

              formikHelpers.setSubmitting(false);
              setSuccess(true);
              sendMetrics('service_request');
            } catch (e) {
              setError('Произошла ошибка, попробуйте позднее');
            }
          } else {
            setActiveStep(activeStep + 1);
            formikHelpers.setTouched({});
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {({ values, isValid, setFieldValue, errors, touched, isSubmitting, setFieldTouched }) => (
          <>
            <header
              className="modal_header"
              style={activeStep === 2 ? { borderBottom: 'none', paddingBottom: 0 } : undefined}
            >
              <div>
                {!success ? (
                  <>
                    <p className="h6">Записаться на сервис</p>
                    {activeStep === 0 && isManually && !!user ? (
                      <span
                        className={`p3 ${classes.manually}`}
                        onClick={() => setIsManually(false)}
                      >
                        <ArrowLeft />
                        Выбрать из своих машин
                      </span>
                    ) : (
                      activeStep > 0 && (
                        <div className="p3 modal_breadcrumbs">
                          {[
                            `${values.brand?.name} ${values.carModel?.name}`,
                            `${getServiceText(values.maintenanceServices.length)}`,
                            !!selectedPoint ? getServiceStationAddress(selectedPoint) : '',
                            values.date && values.dayPart
                              ? `${format(new Date(values.date), 'dd MMM', { locale: ru })}, ${DayPart[values.dayPart.name].toLowerCase()}`
                              : '',
                          ].map(
                            (link, idx) =>
                              link &&
                              idx < activeStep &&
                              !(appointmentService && idx === 1) &&
                              !(appointmentServiceStation && idx === 2) && (
                                <Fragment key={idx}>
                                  <span
                                    className={idx === 2 ? 'modal_breadcrumb_address' : ''}
                                    onClick={() => setActiveStep(idx)}
                                  >
                                    {link}
                                  </span>
                                  <ArrowRight />
                                </Fragment>
                              )
                          )}
                        </div>
                      )
                    )}
                    <h4 className="h4">
                      {activeStep === 0 && !isManually
                        ? 'Информация об автомобиле'
                        : titles[activeStep]}
                    </h4>
                  </>
                ) : (
                  <>
                    <h4 className="h4">Запись на сервис успешно создана</h4>
                    <p className="h6">Менеджер СТО свяжется с вами в ближайшее время</p>
                  </>
                )}
              </div>
              <ModalClose onCloseButtonClick={onModalClose} />
            </header>
            {error && (
              <div className={classes.error}>
                <ErrorMessage message={error} />
              </div>
            )}
            {success && selectedPoint && values.dayPart && values.date ? (
              <Success
                selectedModel={`${values.brand?.name} ${values.carModel?.name}`}
                serviceStationAddress={getServiceStationAddress(selectedPoint)}
                services={services}
                date={values.date}
                dayPart={values.dayPart.name}
              />
            ) : (
              <Form className="modal_container">
                <div className={`${classes.form} modal_inner`}>
                  {!isManually && activeStep === 0 && !!user ? (
                    <AddFromMyCars
                      setValue={setFieldValue}
                      nextStep={(vin, year) => {
                        setIsManually(!vin || !year);
                        setActiveStep(!!vin && !!year ? 1 : 0);
                      }}
                      values={values}
                      cars={cars}
                    />
                  ) : activeStep === 0 ? (
                    <Step1
                      errors={errors}
                      touched={touched}
                      values={values}
                      setValue={setFieldValue}
                      appointment
                    />
                  ) : activeStep === 1 ? (
                    <Step2
                      values={values.maintenanceServices}
                      setServices={setServices}
                      categories={categories}
                    />
                  ) : activeStep === 2 ? (
                    <Step3
                      selectedServiceStation={selectedPoint}
                      services={values.maintenanceServices}
                      setSelectedServiceStationId={id => {
                        setFieldValue('serviceStation', id);
                        updateSelectedStation(id);
                        setActiveStep(3);
                      }}
                    />
                  ) : activeStep === 3 ? (
                    <Step4
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                      openingHours={selectedPoint?.openingHours}
                    />
                  ) : (
                    <Step5
                      clientPhoneNumber={user?.phoneNumber ?? ''}
                      errors={errors}
                      touched={touched}
                      setField={v => setFieldValue('clientPhoneNumber', v)}
                      setFieldTouched={setFieldTouched}
                    />
                  )}
                </div>
                {activeStep !== 2 && (
                  <footer className="modal_footer">
                    {!isManually && activeStep === 0 && (
                      <button
                        type="button"
                        className="btn secondary block"
                        onClick={() => {
                          setActiveStep(0);
                          setIsManually(true);
                        }}
                      >
                        Добавить машину вручную
                      </button>
                    )}
                    {(isManually || activeStep > 0) && (
                      <button
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          (activeStep === 1 && !values.maintenanceServices.length)
                        }
                        type="submit"
                        className={`btn ${classes.btn}`}
                      >
                        {isLastStep ? 'Отправить заявку' : 'Далее'}
                        {isLastStep ? undefined : <ArrowRight />}
                      </button>
                    )}
                  </footer>
                )}
              </Form>
            )}
          </>
        )}
      </Formik>
    </ModalOverlay>
  );
};
