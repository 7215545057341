import { ERROR_EMAIL, ERROR_REQUIRED } from '../../../../config/errorTexts';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Input } from '../../../base/Input';
import { ModalClose } from '../../../base/ModalBase';
import { bool, object, string } from 'yup';
import { getErrorMessage } from '../../../../utils/getErrorMessage';
import { password } from '../../../../config/validationSchemas';
import React, { useState } from 'react';
import classes from './create.module.css';
import { AuthApi } from '../../../../api';
import { Agreement } from '../../../components/Agreement/Agreement';

interface FormValues {
  email: string;
  password: string;
  agreedWithTerms: boolean;
}

const initialValues: FormValues = {
  email: '',
  password: '',
  agreedWithTerms: false,
};

const validationSchema = object().shape({
  email: string().required(ERROR_REQUIRED).email(ERROR_EMAIL),
  password,
  agreedWithTerms: bool().oneOf([true], ERROR_REQUIRED).required(ERROR_REQUIRED),
});

interface OwnProps {
  toVerifyEmail: () => void;
  setEmail: (e: string) => void;
  toLogin: () => void;
}

export const Create = ({ toVerifyEmail, setEmail, toLogin }: OwnProps) => {
  const [error, setError] = useState('');

  const onSubmit = ({ email, password }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    AuthApi.authControllerCreateAccount({ email, password })
      .then(() => {
        setEmail(email);
        formikHelpers.setSubmitting(false);
        toVerifyEmail();
      })
      .catch(data => {
        const error = getErrorMessage(data);
        error && setError(error);
      });
  };

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, isValid }) => (
        <>
          <header className="modal_header">
            <div className={classes.titleBlock}>
              <h4 className={`${classes.title} h4`}>Создать учетную запись</h4>
              <p className="p3">Код подтверждения придёт на указанную почту</p>
            </div>
            <ModalClose />
          </header>
          <Form className="modal_container">
            <div className={`${classes.form} modal_inner`}>
              {error && <ErrorMessage message={error} />}
              <Input label="Электронная почта *" error={errors.email} touched={!!touched.email}>
                <Field name="email" placeholder="Введите вашу электронную почту" />
              </Input>
              <Input label="Пароль *" error={errors.password} touched={!!touched.password}>
                <Field name="password" placeholder="Введите пароль" />
              </Input>
              <Agreement error={errors.agreedWithTerms} touched={!!touched.agreedWithTerms}>
                <Field type={'checkbox'} name={'agreedWithTerms'} />
              </Agreement>
            </div>
            <footer className={`${classes.footer} modal_footer`}>
              <div className={classes.textBlock}>
                <h6 className="h6">Уже есть учетная запись?</h6>
                <button className="btn-text orange" type="button" onClick={toLogin}>
                  Войти
                </button>
              </div>
              <button
                disabled={!(values.email && values.password && isValid)}
                className="btn"
                type="submit"
              >
                Выслать код
              </button>
            </footer>
          </Form>
        </>
      )}
    </Formik>
  );
};
