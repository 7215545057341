import { env } from '../core/env';

const yaMetricsId = env.YA_METRICS_ID;

export function sendMetrics(targetName: string) {
  if (env.ENVIRONMENT !== 'prod') return;

  if (yaMetricsId && Number(yaMetricsId) && ym) {
    ym(Number(yaMetricsId), 'reachGoal', targetName);
  }
}
