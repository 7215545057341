import { useEffect, useState } from 'react';
import { GeoDictionaryApi } from '../api';
import { CityDto, CountryDto } from '../api/generated/caready';

export const useCities = (query?: string) => {
  const [allCities, setAllCities] = useState<CityDto[]>([]);
  const [cities, setCities] = useState<CityDto[]>([]);

  useEffect(() => {
    GeoDictionaryApi.geoDictionaryControllerGetCities().then(({ data }) => {
      const moscow = data.filter(city => city.name.includes('Москва'));
      const spb = data.filter(city => city.name.includes('Санкт-'));
      const allCities = [
        ...moscow,
        ...spb,
        ...data.filter(city => !city.name.includes('Москва') && !city.name.includes('Санкт-')),
      ];
      setCities(allCities);
      setAllCities(allCities);
    });
  }, []);

  useEffect(() => {
    setCities(
      !!query ? allCities.filter(city => city.name.toLowerCase().includes(query)) : allCities
    );
  }, [query]);

  return cities;
};

export const useCitiesByCountry = (country: string) => {
  const [cities, setCities] = useState<CityDto[]>([]);

  useEffect(() => {
    if (!!country) {
      GeoDictionaryApi.geoDictionaryControllerGetCities(undefined, country).then(({ data }) => {
        setCities(data);
      });
    } else {
      setCities([]);
    }
  }, [country]);

  return cities;
};

export const useCountries = () => {
  const [countries, setCountries] = useState<CountryDto[]>([]);

  useEffect(() => {
    GeoDictionaryApi.geoDictionaryControllerGetAllCountries().then(({ data }) => {
      setCountries(data);
    });
  }, []);

  return countries;
};
