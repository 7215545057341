import { CardNew } from '../../components/CardNew';
import { useSearchNews } from '../../../hooks/useSearch';
import commonStyles from '../../../styles/common/common-styles.module.scss';

export const SearchNews = ({ query }: { query: string }) => {
  const results = useSearchNews(1, query);
  return (
    <div className={commonStyles.cardsWrapper}>
      {results.map(card => (
        <CardNew key={card.id} newsData={card} />
      ))}
    </div>
  );
};
