import { AuthError, ERROR_EMAIL, ERROR_REQUIRED } from '../../../../config/errorTexts';
import { AuthErrorEnum } from '../../../../config/auth-error.enum';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { ReactComponent as EyeClosed } from 'assets/svg/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'assets/svg/eye-open.svg';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Input } from '../../../base/Input';
import { ModalClose } from '../../../base/ModalBase';
import { object, string } from 'yup';
import { password } from '../../../../config/validationSchemas';
import { useAuth } from '../../../../context/AuthContext';
import React, { FC, useState } from 'react';
import classes from './login.module.css';

interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = object().shape({
  password: password,
  email: string().email(ERROR_EMAIL).required(ERROR_REQUIRED),
});

interface OwnProps {
  toForgotPassword: () => void;
  toCreate: () => void;
  toVerifyEmail: () => void;
  closeModal: () => void;
}

export const Login: FC<OwnProps> = function Login({
  toForgotPassword,
  toCreate,
  toVerifyEmail,
  closeModal,
}) {
  const [password, setPassword] = useState(true);
  const { login } = useAuth();
  const [errorType, setErrorType] = useState<string>();

  const onSubmit = ({ email, password }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    login({ credential: email, password }).then(response => {
      if (!response.isSignIn) {
        setErrorType(response.error);
        return;
      }

      formikHelpers.setSubmitting(false);
      closeModal();
    });
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <>
          <header className="modal_header">
            <h4 className={`${classes.title} h4`}>Вход в личный кабинет</h4>
            <ModalClose />
          </header>
          <Form className="modal_container">
            <div className={`${classes.form} modal_inner`}>
              {errorType && (
                // todo: убрать игнор
                // @ts-ignore
                <ErrorMessage message={AuthError[errorType] || AuthError.something_went_wrong} />
              )}
              <div className={classes.fields}>
                <Input
                  label="Адрес электронной почты"
                  error={errors.email as string}
                  touched={touched.email}
                >
                  <Field name="email" placeholder="Введите адрес почты" />
                </Input>
                <div className={classes.inputBlock}>
                  <Input
                    icon={
                      <span onClick={() => setPassword(!password)}>
                        {password ? <EyeClosed /> : <EyeOpen />}
                      </span>
                    }
                    label="Пароль"
                    error={errors.password as string}
                    touched={touched.password}
                  >
                    <Field
                      name="password"
                      type={password ? 'password' : 'text'}
                      placeholder="Введите пароль"
                    />
                  </Input>
                  <button className="btn-text orange" onClick={toForgotPassword}>
                    Забыли пароль?
                  </button>
                </div>
              </div>
            </div>
            <footer className={`${classes.footer} modal_footer`}>
              <div className={classes.textBlock}>
                <h6 className="h6">Впервые здесь?</h6>
                <button className="btn-text orange" onClick={toCreate}>
                  Создать учетную запись
                </button>
              </div>
              {errorType && errorType === AuthErrorEnum.USER_IS_NOT_ACTIVE ? (
                <button className="btn" onClick={toVerifyEmail}>
                  Подтвердить
                </button>
              ) : (
                <button type="submit" className="btn">
                  Войти
                </button>
              )}
            </footer>
          </Form>
        </>
      )}
    </Formik>
  );
};
