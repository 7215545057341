import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function Mindbox() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.PopMechanic && window.PopMechanic.update && window.PopMechanic.update();
  }, [pathname]);

  return null;
}
