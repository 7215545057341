import { MutableRefObject, useEffect } from 'react';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

export const useGeoObjectsEvent = (
  eventName: string | string[],
  onCloseBalloon: () => void,
  mapRef: MutableRefObject<ymaps.Map | undefined | null>,
  ymaps: YMapsApi | undefined
) => {
  useEffect(() => {
    if (!ymaps || !mapRef.current) return;

    const geoObjectsEvents = mapRef.current.geoObjects.events;

    geoObjectsEvents.add(eventName, onCloseBalloon);

    return () => {
      geoObjectsEvents.remove(eventName, onCloseBalloon);
    };
  }, [ymaps]);
};
