import { ReactComponent as Check } from 'assets/svg/check.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as CrossMedium } from 'assets/svg/cross-medium.svg';
import { Content, Item, Root, Trigger, Value, Viewport } from '@radix-ui/react-select';
import classes from './dropdown.module.css';

type Options = {
  id: string;
  name: string;
  [key: string]: any;
};
export const Dropdown = ({
  label,
  options,
  value,
  setValue,
  placeholder,
  error,
  disabled,
  touched,
}: {
  name?: string;
  label?: string;
  options: Options[] | string[];
  setValue: (v: string) => void;
  value: string;
  placeholder: string;
  error?: string;
  disabled?: boolean;
  touched?: boolean;
}) => {
  return (
    <div>
      <label className="label" htmlFor="select">
        {label}
      </label>
      <Root key={value} value={value} onValueChange={setValue}>
        <Trigger
          id="select"
          className={`${classes.trigger} ${error && touched ? classes.error : ''} ${
            value === '' ? classes.placeholder : ''
          }`}
          disabled={disabled}
        >
          <Value aria-label={value} placeholder={placeholder}>
            {value === '' ? placeholder : value}
          </Value>
          <div className={classes.icons}>
            {!!value && (
              <span
                className={classes.clear}
                onPointerDown={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setValue('');
                }}
              >
                <CrossMedium />
              </span>
            )}
            <ChevronDown />
          </div>
        </Trigger>
        <Content position="popper" collisionPadding={0} className={classes.content}>
          <Viewport>
            {options.map(option => (
              <Item
                key={typeof option === 'string' ? option : option.id}
                className={`${classes.item} ${
                  (typeof option === 'string' ? option : option.id) === value
                    ? classes.selected
                    : ''
                }`}
                value={typeof option === 'string' ? option : option.id}
              >
                {typeof option === 'string' ? option : option.name}
                {(typeof option === 'string' ? option : option.id) === value ? <Check /> : ''}
              </Item>
            ))}
          </Viewport>
        </Content>
      </Root>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
};
