import { Field } from 'formik';
import { Tooltip } from '../Tooltip';
import classes from './color-picker.module.css';

export const ColorPicker = ({
  label,
  colors,
  name,
}: {
  label: string;
  name: string;
  colors: { value: string; name: string }[];
}) => {
  return (
    <div className={classes.wrapper}>
      <p className="p3">{label}</p>
      <div className={classes.colorButtons}>
        {colors.map(button => (
          <Tooltip key={button.value} side="top" text={button.name}>
            <label className={classes.label}>
              <Field type="radio" name={name} value={button.value.toLowerCase()} />
              <div
                className={`${classes.buttonOuter} ${
                  button.value.toLowerCase() === '#ffffff' ? classes.lightColor : ''
                }`}
                style={{ '--colorVariable': button.value } as React.CSSProperties}
              />
            </label>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
