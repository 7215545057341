import { Cities } from '../Cities';
import { FranchiseSection } from '../../../config/franchiseSections';
import { ReactComponent as Mail } from 'assets/svg/mail.svg';
import { Map } from '../Map';
import { mapOpenedPoints, MapPoint } from './data';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as Point } from 'assets/svg/point.svg';
import { RefObject, useEffect, useRef, useState } from 'react';
import { Video } from '../Video';
import styles from './work-geographic.module.css';

const Tooltip = ({
  idx,
  openPoint,
  point,
  setOpenPoint,
  refLastPoint,
  pointsInView,
}: {
  idx: number;
  openPoint: number | undefined;
  point: MapPoint;
  setOpenPoint: (v: number | undefined) => void;
  refLastPoint: RefObject<HTMLButtonElement> | null;
  pointsInView: boolean;
}) => {
  const [tooltipPosition, setTooltipPosition] = useState({
    top: point.top,
    left: point.left,
  });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpenPoint(undefined);
      }
    };

    if (openPoint === idx) {
      window.addEventListener('click', handleClick);
    }

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [openPoint]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        setTooltipPosition({ top: point.top, left: point.left });
      } else {
        setTooltipPosition({ top: 100, left: 0 });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.pointWrap} ref={ref}>
      <button
        onClick={() => setOpenPoint(idx === openPoint ? undefined : idx)}
        className={`${styles.point} ${pointsInView ? styles.pointInView : ''} ${
          idx === openPoint ? styles.active : ''
        }`}
        style={{
          top: `${point.top}%`,
          left: `${point.left}%`,
          transitionDelay: `${idx / 6}s`,
        }}
        ref={refLastPoint}
      />
      {openPoint === idx && (
        <div
          className={styles.tooltip}
          style={{
            top: `${tooltipPosition.top}%`,
            left: `${tooltipPosition.left}%`,
          }}
        >
          <div className={styles.tooltipInner}>
            <button onClick={() => setOpenPoint(undefined)} />
            <h5 className="h5-20">{point.city}</h5>
            <div className={styles.servicesWrapper}>
              {point.services.map((service, index) => (
                <div className={styles.service} key={index}>
                  <h6 className="h6-16">Сервисный центр «{service.name}»</h6>
                  {service.contacts.map((contact, index) => (
                    <>
                      {contact.address && (
                        <p className={styles.contact}>
                          <Point />
                          <span className="p3">{contact.address}</span>
                        </p>
                      )}
                      {contact.phone && (
                        <a href={`tel:${contact.phone}`} className={styles.contact}>
                          <Phone />
                          <span className="p3">{contact.phone}</span>
                        </a>
                      )}
                      {contact.mail && (
                        <a href={`mailto:${contact.mail}`} className={styles.contact}>
                          <Mail />
                          <span className="p3">{contact.mail}</span>
                        </a>
                      )}
                      {index !== service.contacts.length - 1 && <p className={styles.separate}></p>}
                    </>
                  ))}
                </div>
              ))}
            </div>
            {/*todo: hid cost calculation*/}
            {/*<a className={styles.costLink} href={`#${FranchiseSection.CostCalculation}`}>*/}
            {/*  Рассчитать стоимость*/}
            {/*</a>*/}
          </div>
        </div>
      )}
    </div>
  );
};

export const WorkGeographic = ({ openModal }: { openModal?: () => void }) => {
  const [openPoint, setOpenPoint] = useState<number | undefined>();
  const mapBottomRef = useRef<HTMLButtonElement>(null);
  const [pointsInView, setPointsInView] = useState(false);

  useEffect(() => {
    const handlerMap = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting && mapBottomRef.current) {
          setPointsInView(true);
        }
      }
    };

    const mapObserver = new IntersectionObserver(handlerMap, {
      threshold: 0,
      rootMargin: '-20% 0px 0px 0px',
    });
    if (mapBottomRef.current) {
      mapObserver.observe(mapBottomRef.current);
    }
  }, []);

  return (
    <section id={FranchiseSection.WorkGeographic}>
      <div className={`container ${styles.section}`}>
        <h2 className={`${styles.sectionTitle} h6-16 section-title`}>География работы</h2>
        <div className={styles.map}>
          <Map className={styles.mapBg} />
          {mapOpenedPoints.map((point, idx) => (
            <Tooltip
              key={idx}
              point={point}
              setOpenPoint={setOpenPoint}
              idx={idx}
              openPoint={openPoint}
              refLastPoint={idx === mapOpenedPoints.length - 1 ? mapBottomRef : null}
              pointsInView={pointsInView}
            />
          ))}
        </div>
      </div>
      <Cities openModal={openModal} />
      <Video />
    </section>
  );
};
