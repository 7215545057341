import { ReactComponent as Check } from 'assets/svg/check.svg';
import React, { PropsWithChildren } from 'react';
import styles from './checkbox.module.css';

// children are input tag
export const Checkbox = ({
  error,
  touched,
  children,
  text,
}: PropsWithChildren<{
  error?: string;
  touched?: boolean;
  text: string | JSX.Element;
}>) => {
  return (
    <div>
      <label className={styles.label}>
        {children}
        <div className={styles.wrap}>
          <div className={`${styles.box} ${error && touched && styles.errorBox}`}>
            <Check />
          </div>
          <span className="p2">{text}</span>
        </div>
      </label>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
};
