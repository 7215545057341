import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { useAuth } from '../../../context/AuthContext';
import { Input } from '../../base/Input';
import { ModalClose } from '../../base/ModalBase/ModalBase.v2';
import { formatTime } from '../../../utils/formatTimer';
import { useState } from 'react';
import classes from './change-email.module.css';
import { UsersApi } from '../../../api';

export const EmailVerification = ({
  changeState,
  email,
  closeModal,
  timer,
  resetTimer,
}: {
  changeState: () => void;
  email: string;
  closeModal: () => void;
  timer: number;
  resetTimer: () => void;
}) => {
  const { setUser } = useAuth();
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);

  const verifyHandle = () => {
    if (code.length === 6) {
      UsersApi.usersControllerVerifyNewEmail({ code }).then(({ data }) => {
        if (data) {
          setUser(data);
          closeModal();
        } else {
          setError(true);
        }
      });
    }
  };

  return (
    <>
      <header className={classes.header}>
        <div className={classes.headerButtons}>
          <button className="btn-text gray" onClick={changeState}>
            <ArrowLeft />
            Go back
          </button>
          <ModalClose onCloseButtonClick={closeModal} />
        </div>
        <div className={classes.headerText}>
          <h4 className="h4">Check your Email</h4>
          <p className="p3">
            We just have sent a code to <span className="pm1">{email}</span>
          </p>
        </div>
      </header>
      <div className={`${classes.inner}`}>
        <div className={`${classes.fieldSection} ${classes.codeField}`}>
          <Input label="Code" error={error ? 'Неверный код' : ''}>
            <input placeholder="Enter code" value={code} onChange={e => setCode(e.target.value)} />
          </Input>
        </div>
        <div className={classes.buttonSection}>
          {code.length === 6 ? (
            <button className="btn" onClick={verifyHandle}>
              Отправить
            </button>
          ) : (
            <button
              className={`btn ${!timer ? 'black secondary' : ''}`}
              type="button"
              disabled={!!timer}
              onClick={() => {
                if (!timer) {
                  UsersApi.usersControllerResendEmailVerificationCode().then(res => {
                    if (res) {
                      resetTimer();
                    } else {
                      setError(true);
                    }
                  });
                }
              }}
            >
              {`Выслать код ${timer ? `через ${formatTime(timer)}` : ''}`}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
