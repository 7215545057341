import { useHtmlDocument } from '../../hooks/useHtmlDocument';
import document from 'styles/common/document.module.scss';
import { CoreHead } from '../../ui/core';

export function PersonalDataAgreement() {
  const { htmlContent } = useHtmlDocument('personalDataAgreement');

  return (
    <>
      <CoreHead title={'Согласие на обработку персональных данных'} />
      <article
        className={`${document.wrapper} section`}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
}
