import { CoreHead } from '../../ui/core';

export const GuaranteePolicy = function GuaranteePolicy() {
  return (
    <>
      <CoreHead
        title={'Гарантийная политика для запасных частей CAREADY'}
        description={'Ознакомьтесь с гарантийная политика для запасных частей CAREADY'}
      />
      <div className={'section'}>
        <h1 className={'h3'}>Гарантийная политика для запасных частей CAREADY</h1>
        <br />
        <div>
          <p className={'p3'}>
            <b>
              1. Гарантия на проданную запасную часть CAREADY предоставляется конечному потребителю
              (автовладельцу) сроком до <span style={{ color: '#d60505' }}>12&nbsp;месяцев</span>{' '}
              или на пробег автомобиля до{' '}
              <span style={{ color: '#d60505' }}>30&nbsp;000&nbsp;км</span> (в зависимости от того,
              что наступит ранее).
            </b>
            <br /> <br />
            <b>
              2. Данные условия гарантии распространяются на все запасные части, приобретенные
              сервисным центром / конечным потребителем (автовладельцем) в официальных каналах
              продаж сети CAREADY или напрямую у официального партнера сети, кроме запасных частей,
              указанных в списке ограничений и исключений ниже.
            </b>
            <br /> <br />
            <b>
              3. Ограниченный гарантийный срок -{' '}
              <span style={{ color: '#d60505' }}>3&nbsp;месяца </span>
              или <span style={{ color: '#d60505' }}>5000&nbsp;км</span> (в зависимости от того, что
              наступит ранее) - действует на следующие группы запасных частей и автокомпонентов:
            </b>
            <ul>
              <li>– Фильтры (масляные, топливные, фильтры АКПП, воздушные, салонные);</li>
              <li>– Свечи зажигания;</li>
              <li>– Элементы питания электронных блоков и компонентов;</li>
              <li>– Элементы остекления кузова, зеркала;</li>
              <li>– Щетки стеклоочистителей;</li>
              <li>– Механизм сцепления МКП.</li>
            </ul>
            <br />
            <b>
              4. Ограниченный гарантийный срок -{' '}
              <span style={{ color: '#d60505' }}>12&nbsp;месяцев</span> или{' '}
              <span style={{ color: '#d60505' }}>20&nbsp;000&nbsp;км</span> (в зависимости от того,
              что наступит ранее) - действует на следующие группы запасных частей и автокомпонентов:
            </b>
            <ul>
              <li>– Тормозные колодки, накладки и диски;</li>
              <li>– Стартерные аккумуляторные батареи;</li>
              <li>– Шины.</li>
            </ul>
            <br />
            <b>
              5. Гарантия предоставляется только в случае соблюдения условий эксплуатации,
              заявленных заводом-изготовителем автомобиля, а также при выполнении работ по замене
              смежных деталей (совместно с дефектной деталью), которые также не пригодны к
              эксплуатации, либо рекомендованы к замене заводом-изготовителем.
            </b>
            <br /> <br />
            <b>6. Гарантия на запасные части CAREADY не распространяется:</b>
            <br />
            <b>6.1. на следующие виды автокомпонентов:</b>
            <ul>
              <li>– Лампы накаливания;</li>
              <li>– Технологические жидкости, смазочные материалы, автокосметику;</li>
              <li>– Предохранители;</li>
            </ul>
            <b>
              6.2. на эксплуатационный износ и естественное изменение состояния (в том числе
              старение) таких запасных частей, как приводные ремни, тормозные колодки, тормозные
              диски и барабаны, диски сцепления, свечи зажигания и т.п.;
            </b>
            <br />
            <b>
              6.3. на повреждение детали в результате ДТП или небрежной эксплуатации, либо любое
              другое механическое повреждение;
            </b>
            <br />
            <b>
              6.4. если причиной является преждевременный износ (выход из строя / ненадлежащее
              состояние) других смежных элементов / агрегатов / узлов / систем автомобиля;
            </b>
            <br />
            <b>
              6.5. на неисправности, возникшие в результате несвоевременного устранения других,
              ранее обнаруженных неисправностей или рекомендаций сервисных станций сети CAREADY.
            </b>
            <br />
            <b>
              6.6. на случаи установки запасной части с видимыми или заранее известными дефектами и
              недостатками;
            </b>
            <br />
            <b>
              6.7. если неисправность запасных частей топливной системы или системы выпуска
              отработанных газов возникла вследствие использования некачественного топлива или
              моторного масла, непредусмотренных заводом-изготовителем автомобиля, а также при иных
              технически несовместимых/непредусмотренных деталей;
            </b>
            <br />
            <b>
              6.8. повреждения (в особенности деталей подвески и рулевого управления), возникшие
              из-за неаккуратного вождения на неровностях дороги или вследствие существенных
              дефектов дорожного покрытия;
            </b>
            <br />
            <b>
              6.9. если дефект, неисправность или коррозия запасной части возникли в результате
              воздействия промышленных и химических выбросов, кислотного или щелочного воздействия,
              растительного сока, продуктов жизнедеятельности птиц и животных, химически активных
              веществ, в том числе применяемых для борьбы с обледенением дорог и прочими природными
              явлениями (град, молния и т.д.);
            </b>
            <br />
            <b>
              6.10. на нестандартные условия эксплуатации (автомобиль для спортивных состязаний,
              спецтехника и т. д.) или нарушение рекомендованных условий эксплуатации автомобиля
              (перегруз и т. д.); преодоление водных преград;
            </b>
            <br />
            <b>
              6.11. при переоборудовании автомобиля (учебный автомобиль; автомобиль, оснащенный
              газобалонным оборудованием и т.д.);
            </b>
            <br />
            <b>
              6.12. если отсутствуют сопроводительные документы, подтверждающие факт покупки
              запасных частей в официальных каналах продаж сети CAREADY или документально
              подтверждённый факт установки данной детали в сервисе сети CAREADY;
            </b>
            <br />
            <b>
              6.13. на неисправности, возникшие по причине несоблюдения условий эксплуатации
              автомобиля, изложенных в руководстве завода-изготовителя;
            </b>
            <br />
            <b>
              6.14. на транспортные средства с незарегистрированными изменениями показаний одометра,
              приведших к изменению текущих значений;
            </b>
            <br />
            <b>
              6.15. на косвенные последствия, понесенные клиентом в связи с недостатком автомобиля
              (невозможность эксплуатации автомобиля в течение времени проведения гарантийного
              ремонта, упущенная прибыль и т. д.).
            </b>
            <br />
          </p>
        </div>
      </div>
    </>
  );
};
