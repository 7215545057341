import { Arrow, Content, Provider, Root, Trigger } from '@radix-ui/react-tooltip';
import { ReactComponent as CrossMedium } from 'assets/svg/cross-medium.svg';
import { useState } from 'react';
import classes from './tooltip.module.css';

// children is trigger for trigger tooltip
export const Tooltip = ({
  children,
  side,
  text,
}: {
  children: JSX.Element;
  side?: 'top' | 'right' | 'bottom' | 'left';
  text: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Provider>
      <Root open={open} onOpenChange={setOpen}>
        <Trigger onMouseEnter={() => setOpen(true)} asChild>
          {children}
        </Trigger>
        <Content side={side ? side : 'bottom'} sideOffset={10} className={classes.content}>
          <p className="p4">{text}</p>
          <button className={classes.close} onClick={() => setOpen(false)}>
            <CrossMedium />
          </button>
          <Arrow width={16} height={8} asChild>
            <svg
              width="16"
              height="8"
              viewBox="0 0 16 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.arrow}
            >
              <path d="M9.41421 1.41421L16 8L0 8L6.58579 1.41421C7.36684 0.633164 8.63316 0.633163 9.41421 1.41421Z" />
            </svg>
          </Arrow>
        </Content>
      </Root>
    </Provider>
  );
};
