import { FC } from 'react';
import { Skeleton } from '../../../Skeleton/Skeleton';
import { Option } from '../../types/Option';
import { ReactComponent as Check } from '../../../../../assets/svg/check.svg';
import s from './ComboboxOption.module.scss';

interface OwnProps {
  option: Option;
  isSelected: boolean;
  setSelectedOption: (option: Option) => void;
}

export const ComboboxOption: FC<OwnProps> = function ComboboxOption(props) {
  return (
    <div
      className={s.option}
      data-state={props.isSelected ? 'checked' : ''}
      onClick={() => props.setSelectedOption(props.option)}
    >
      {props.option.name}
      {props.isSelected ? <Check /> : ''}
    </div>
  );
};

export const ComboboxOptionSkeleton = function ComboboxOptionSkeleton() {
  return (
    <div className={`${s.option} ${s.skeleton}`}>
      <Skeleton borderRadius={12} />
    </div>
  );
};
