import { CarBrandWithPopularityDto } from '../api/generated/caready';
import { OptionWithName } from '../ui/base/Combobox/types/OptionWithName';

export const mapPopularityCarBrandToOptionsWithName = (data: CarBrandWithPopularityDto) => {
  const result: OptionWithName[] = [];

  if (data.popular) {
    result.push({
      name: 'Популярные',
      options: data.popular,
    });
  }

  result.push({
    name: 'Все',
    options: data.general,
  });

  return result;
};
