import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CoreHead } from '../../ui/core';
import { MaintenanceHistory } from '../../ui/components/MaintenanceHistory';
import { useMaintenanceHistoryByCarId } from 'hooks/useMaintenanceHistory';
import { useParams } from 'react-router-dom';
import classes from './maintenance-history.module.css';

export default function MaintenanceHistoryPages() {
  const { id } = useParams(); // id машины пользователя
  const histories = useMaintenanceHistoryByCarId(id);

  return (
    <>
      <CoreHead title="История обслуживания" />
      <div className="section">
        <BreadCrumbs
          links={[
            BREAD_CRUMB_MAIN,
            { name: 'Профиль', path: '/profile/' },
            { name: 'Мои автомобили', path: '/profile/my-cars/' },
            { name: 'История обслуживания' },
          ]}
        />
        <h1 className={'h2'}>История обслуживания</h1>
        <div className={classes.header_line}></div>
        <div className={classes.history_main}>
          <div className={classes.history_wrap}>
            {/* date filter */}
            {/* <h4 className="h4">2023</h4> */}
            <div className={classes.history_blocks}>
              {histories.map((history, index) => (
                <MaintenanceHistory key={index} historyData={history} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
