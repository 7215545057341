import React, { FC, useEffect, useState } from 'react';
import { ModalClose } from '../../../../base/ModalBase/ModalBase.v2';
import { Input } from '../../../../base/Input';
import { QueryCombobox } from '../../../../base/Combobox/QueryCombobox';
import { MaintenanceAppointmentsApi, ServiceStationsApi } from '../../../../../api';
import { Combobox } from '../../../../base/Combobox/Combobox';
import { PhoneNumber } from '../../../../base/Input/Input';
import s from './QuickAppointment.module.scss';
import { useAuth } from '../../../../../context/AuthContext';
import { useFormik } from 'formik';
import { CityDto, ScheduleQuickAppointmentDto } from '../../../../../api/generated/caready';
import { Option } from '../../../../base/Combobox/types/Option';
import { bool, object, string } from 'yup';
import { ERROR_EMAIL, ERROR_REQUIRED } from '../../../../../config/errorTexts';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { cyrillic, phoneNumber } from '../../../../../config/validationSchemas';
import { useServiceStations } from '../../../../../hooks/useServiceStations';
import { ErrorMessage } from '../../../../components/ErrorMessage/ErrorMessage';
import { sendMetrics } from '../../../../../utils/sendMetrics';
import { Agreement } from '../../../../components/Agreement/Agreement';

const validationSchema = object().shape({
  clientFirstName: cyrillic.required(ERROR_REQUIRED),
  city: object().required(ERROR_REQUIRED),
  serviceStation: object().required(ERROR_REQUIRED),
  clientPhoneNumber: phoneNumber.required(ERROR_REQUIRED),
  clientEmail: string().email(ERROR_EMAIL).required(ERROR_REQUIRED),
  agreedWithTerms: bool().oneOf([true], ERROR_REQUIRED),
});

interface QuickAppointmentFormValues {
  clientFirstName: string | null;
  clientPhoneNumber: string | null;
  clientEmail: string | null;
  city: CityDto | null;
  serviceStation: Option | null;
  agreedWithTerms: boolean;
}

const initialValues: Pick<
  QuickAppointmentFormValues,
  'agreedWithTerms' | 'city' | 'serviceStation'
> = {
  agreedWithTerms: false,
  city: null,
  serviceStation: null,
};

interface OwnProps {
  onOpenChange: (value: boolean) => void;
  onSubmissionSuccess: () => void;
}

export const QuickAppointmentForm: FC<OwnProps> = function QuickAppointmentForm(props) {
  const { user } = useAuth();

  const [error, setError] = useState('');

  const {
    touched,
    errors,
    values,
    handleChange,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    ...formik
  } = useFormik<QuickAppointmentFormValues>({
    initialValues: {
      ...initialValues,
      clientFirstName: user?.firstName ?? null,
      clientPhoneNumber: user?.phoneNumber ?? null,
      clientEmail: user?.email ?? null,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      sendMetrics('quick_button');

      const { serviceStation, clientEmail, clientFirstName, clientPhoneNumber, city } = values;

      if (!serviceStation || !clientEmail || !clientFirstName || !clientPhoneNumber || !city)
        return;

      const data: ScheduleQuickAppointmentDto = {
        serviceStationId: serviceStation.id,
        clientEmail: clientEmail,
        clientFirstName: clientFirstName,
        clientPhoneNumber: clientPhoneNumber,
      };

      try {
        await MaintenanceAppointmentsApi.maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(
          'web_app',
          data
        );
        props.onSubmissionSuccess();
        sendMetrics('quick_form');
        formik.resetForm();
      } catch (e) {
        setError('Что-то пошло не так, попробуйте позднее');
      }
    },
  });

  const { data: serviceStations, isLoading: isServiceStationsLoading } = useServiceStations<
    Option[]
  >(
    { cityAbbreviation: values.city?.abbreviation },
    {
      select: data =>
        data.map(it => ({
          id: it.id,
          name: it.fullAddress.address,
        })),
      enabled: !!values.city?.abbreviation,
    }
  );

  useEffect(() => {
    setFieldValue('serviceStation', null);
  }, [values.city, setFieldValue]);

  return (
    <>
      <header className={`${s.header} modal_header`}>
        <h4 className={'h4'}>Быстрая запись на сервис</h4>
        <ModalClose onCloseButtonClick={() => props.onOpenChange(false)} />
      </header>
      <form className={'modal_container'} onSubmit={handleSubmit}>
        <div className={`${s.form} modal_inner`}>
          {error && <ErrorMessage message={error} />}
          <Input label={'Имя'} error={errors.clientFirstName} touched={!!touched.clientFirstName}>
            <input
              name={'clientFirstName'}
              value={values.clientFirstName || ''}
              placeholder={'Введите имя'}
              onBlur={() => setFieldTouched('clientFirstName')}
              onChange={handleChange}
            />
          </Input>
          <QueryCombobox
            queryKey={['city', 'list', 'with-service-station']}
            value={values.city}
            setValue={value => setFieldValue('city', value)}
            placeholder={'Выберите город'}
            label={'Город'}
            error={errors.city}
            touched={!!touched.city}
            request={() => ServiceStationsApi.serviceStationsControllerGetServiceStationsCities()}
            readOnly={true}
          />
          <Combobox
            value={values.serviceStation}
            setValue={value => setFieldValue('serviceStation', value)}
            placeholder={'Выберите СТО'}
            label={'Ближайшее СТО'}
            error={errors.serviceStation}
            disabled={!values.city}
            touched={!!touched.serviceStation}
            options={serviceStations}
            isLoading={isServiceStationsLoading}
          />
          <Input
            label={'Телефон'}
            error={errors.clientPhoneNumber}
            touched={!!touched.clientPhoneNumber}
          >
            <PhoneNumber
              name={'clientPhoneNumber'}
              clientPhoneNumber={values.clientPhoneNumber || ''}
              onBlur={() => setFieldTouched('clientPhoneNumber', true)}
              setField={value => setFieldValue('clientPhoneNumber', value)}
            />
          </Input>
          <Input
            label={'Введите электронную почту'}
            error={errors.clientEmail}
            touched={!!touched.clientEmail}
          >
            <input
              name={'clientEmail'}
              value={values.clientEmail || ''}
              onBlur={() => setFieldTouched('clientEmail')}
              placeholder={'Введите электронную почту'}
              onChange={handleChange}
            />
          </Input>
          <Agreement error={errors.agreedWithTerms} touched={!!touched.agreedWithTerms}>
            <input type={'checkbox'} name={'agreedWithTerms'} onChange={handleChange} />
          </Agreement>
        </div>
        <footer className={`${s.footer} modal_footer`}>
          <button type={'submit'} className={'btn'} disabled={!formik.isValid}>
            Свяжитесь со мной <ArrowRight />
          </button>
        </footer>
      </form>
    </>
  );
};
