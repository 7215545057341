import { ReactComponent as Cross } from 'assets/svg/cross.svg';
import React, { useEffect, useRef } from 'react';
import classes from './modal.module.css';

export const ModalClose = ({ onCloseButtonClick }: { onCloseButtonClick: () => void }) => (
  <button className={classes.close} onClick={onCloseButtonClick}>
    <Cross />
  </button>
);

export const ModalOverlay = ({
  children,
  onCloseButtonClick,
}: {
  children: JSX.Element;
  onCloseButtonClick: () => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.classList.add('hidden');

    return () => {
      document.body.classList.remove('hidden');
    };
  }, []);

  return (
    <div
      className={classes.overlay}
      onClick={e => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
          onCloseButtonClick();
        }
      }}
    >
      <div ref={ref} className={classes.wrap}>
        {children}
      </div>
    </div>
  );
};
