import { ReactComponent as ErrorIcon } from '../../../assets/svg/error.svg';
import classes from './error.module.css';

interface OwnProps {
  message: string;
}

export const ErrorMessage = (props: OwnProps) => {
  return (
    <div className={classes.error}>
      <ErrorIcon />
      <p className="p2">{props.message}</p>
    </div>
  );
};
