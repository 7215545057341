import { MaintenanceServiceCategoryDto } from '../../../api/generated/caready';
import s from './services-card.module.scss';
import { getServiceText } from '../../../utils/getServiceText';
import { FC } from 'react';
import { Skeleton } from '../../base/Skeleton/Skeleton';
import clsx from 'clsx';
import { UTMLink } from '../../base/UTMLink/UTMLink';

interface OwnProps {
  category: MaintenanceServiceCategoryDto;
}

export const PrimaryService: FC<OwnProps> = function PrimaryService(props) {
  return (
    <UTMLink to={`/services/${props.category.slug}/`} className={`${s.card} ${s.primary}`}>
      <div className={s.textBlock}>
        <h4 className={'h4'}>{props.category.name}</h4>
        <span className={'p3 tag'}>{getServiceText(props.category.servicesNumber || 0)}</span>
      </div>
      <div className={s.image}>
        <img src={props.category.iconUrl} alt={props.category.name} />
      </div>
    </UTMLink>
  );
};

export const PrimaryServiceSkeleton = function PrimaryServiceSkeleton() {
  return (
    <div className={`${s.card} ${s.primary} ${s.primarySkeleton}`}>
      <div className={s.textBlock}>
        <Skeleton className={'h4'} />
        <Skeleton className={'p3'} width={'30%'} />
      </div>
      <div className={clsx(s.primarySkeletonImageWrapper, s.image)}>
        <Skeleton width={'100%'} height={'100%'} borderRadius={20} />
      </div>
    </div>
  );
};
