import { Dropdown } from '../../base/Dropdown';
import { FormikErrors, FormikTouched } from 'formik';
import { add, eachDayOfInterval, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FC, useEffect, useState } from 'react';
import {
  DayPartIntervalDto,
  ServiceStationWithDetailWorkingScheduleDto,
} from '../../../api/generated/caready';
import s from './appointment.module.css';
import { DayPart } from '../../../types/DayPart';
import clsx from 'clsx';
import { AppointmentFormValues } from './Appointment';

interface OwnProps {
  values: AppointmentFormValues;
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<AppointmentFormValues>;
  touched: FormikTouched<AppointmentFormValues>;
  openingHours?: ServiceStationWithDetailWorkingScheduleDto['openingHours'];
}

export const Step4: FC<OwnProps> = function Step4(props) {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [dates, setDates] = useState<Date[]>([]);
  const [dayPartIntervals, setDayPartIntervals] = useState<DayPartIntervalDto[]>([]);

  useEffect(() => {
    if (!props.openingHours) return;

    const { workSchedule } = props.openingHours;

    const today = new Date();

    const start = props.openingHours.isOpen ? today : add(today, { days: 1 });
    const end = add(start, { weeks: 2 });
    const dates = eachDayOfInterval({
      start,
      end,
    });
    const filterDates = dates.filter(
      date => !!workSchedule.find(schedule => schedule.weekday === parseInt(format(date, 'i')))
    );
    setDates(filterDates);
  }, [props.openingHours]);

  useEffect(() => {
    if (!props.openingHours || !selectedDate) {
      props.setFieldValue('date', '');
      return;
    }

    const { workSchedule } = props.openingHours;

    const date = new Date(selectedDate);
    const dateWeek = parseInt(format(date, 'i'));

    const schedule = workSchedule.find(ws => ws.weekday === dateWeek);

    if (schedule?.dayPartIntervals) setDayPartIntervals(schedule?.dayPartIntervals);
    props.setFieldValue('date', date);
  }, [selectedDate, props.openingHours]);

  return (
    <>
      <Dropdown
        value={!!selectedDate ? format(new Date(selectedDate), 'dd MMM', { locale: ru }) : ''}
        setValue={value => setSelectedDate(value)}
        placeholder="Выбрать дату"
        label="Дата"
        options={dates.map(date => ({
          id: format(date, 'yyyy-MM-dd'),
          name: format(date, 'dd MMM', { locale: ru }),
        }))}
        error={props.errors.date as string}
        touched={!!props.touched.date}
      />
      {selectedDate && (
        <div className={s.dayPartSelect}>
          <div className={s.dayPartTitleWrapper}>
            <p className={`label ${s.dayPartTitle}`}>Желаемое время записи</p>
            <ul>
              <li className={`label ${s.timeDisclaimer}`}>
                - временные слоты сопоставлены со временем работы сервиса
              </li>
              <li className={`label ${s.timeDisclaimer}`}>
                - выбранные дата и время записи на сервис являются предварительными и будут уточнены
                менеджером
              </li>
            </ul>
          </div>
          <ul className={s.time}>
            {dayPartIntervals.map(dayPart => (
              <li key={dayPart.name}>
                <button
                  className={clsx(
                    'btn',
                    'tab',
                    s.dayPart,
                    dayPart.name !== props.values.dayPart?.name && 'black secondary'
                  )}
                  onClick={() => props.setFieldValue('dayPart', dayPart)}
                  type={'button'}
                >
                  {DayPart[dayPart.name]} (
                  {dayPart.interval.map(it => `с ${it[0]} до ${it[1]}`).join(', ')})
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
