import {
  AuthApi as BaseAuthApi,
  CarDictionaryApi as BaseCarDictionaryApi,
  Configuration,
  GarageApi as BaseGarageApi,
  GeoDictionaryApi as BaseGeoDictionaryApi,
  JobApplicationsApi as BaseJobApplicationsApi,
  JobsApi as BaseJobsApi,
  MaintenanceAppointmentsApi as BaseMaintenanceAppointmentsApi,
  MaintenanceServicesApi as BaseMaintenanceServicesApi,
  NewsApi as BaseNewsApi,
  NewslettersApi as BaseNewslettersApi,
  PromotionsApi as BasePromotionsApi,
  SearchApi as BaseSearchApi,
  ServiceStationsApi as BaseServiceStationsApi,
  TradeInRequestsApi as BaseTradeInRequestsApi,
  UsersApi as BaseUsersApi,
} from './generated/caready';
import { env } from '../core/env';
import { AUTH_TOKEN_KEY } from '../config/auth';
import axios from 'axios';
import { getCookie } from '../utils/getCookie';

const instance = axios.create({
  withCredentials: true,
  headers: {
    'X-Platform': 'WebSite',
    'X-Device-UUID': getCookie('mindboxDeviceUUID'),
  },
});

instance.interceptors.response.use(undefined, async error => {
  if (error.response.status === 401 && !error.config.isTokenRefreshed) {
    try {
      const { data } = await axios.post(`${env.API_URL}/auth/refresh`, undefined, {
        withCredentials: true,
      });
      localStorage.setItem(AUTH_TOKEN_KEY, data.accessToken);
      error.config.headers['Authorization'] = `Bearer ${data.accessToken}`;
    } catch (e) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
    }
    error.config.isTokenRefreshed = true;
    return axios(error.config);
  } else {
    throw error;
  }
});

export const ApiConfigWithAuth = new Configuration({
  basePath: env.API_URL,
  accessToken: () => {
    return localStorage.getItem(AUTH_TOKEN_KEY) || '';
  },
});

export const ApiConfig = new Configuration({
  basePath: env.API_URL,
});

export const NewsApi = new BaseNewsApi(ApiConfig, undefined, instance);
export const PromotionsApi = new BasePromotionsApi(ApiConfig, undefined, instance);
export const SearchApi = new BaseSearchApi(ApiConfig, undefined, instance);
export const ServiceStationsApi = new BaseServiceStationsApi(ApiConfig, undefined, instance);
export const JobsApi = new BaseJobsApi(ApiConfig, undefined, instance);
export const GeoDictionaryApi = new BaseGeoDictionaryApi(ApiConfig, undefined, instance);
export const CarDictionaryApi = new BaseCarDictionaryApi(ApiConfig, undefined, instance);
export const NewslettersApi = new BaseNewslettersApi(ApiConfig, undefined, instance);
export const TradeInRequestsApi = new BaseTradeInRequestsApi(ApiConfig, undefined, instance);

export const UsersApi = new BaseUsersApi(ApiConfigWithAuth, undefined, instance);
export const GarageApi = new BaseGarageApi(ApiConfigWithAuth, undefined, instance);
export const AuthApi = new BaseAuthApi(ApiConfigWithAuth, undefined, instance);
export const MaintenanceAppointmentsApi = new BaseMaintenanceAppointmentsApi(
  ApiConfigWithAuth,
  undefined,
  instance
);
export const MaintenanceServicesApi = new BaseMaintenanceServicesApi(
  ApiConfigWithAuth,
  undefined,
  instance
);
export const JobApplicationsApi = new BaseJobApplicationsApi(
  ApiConfigWithAuth,
  undefined,
  instance
);
