import { useAuth } from '../../../context/AuthContext';
import { ERROR_REQUIRED } from 'config/errorTexts';
import { Field, Form, Formik } from 'formik';
import { Input } from '../../base/Input';
import { ModalClose, ModalOverlay } from '../../base/ModalBase/ModalBase.v2';
import { object } from 'yup';
import { UsersApi } from '../../../api';
import s from './ChangeName.module.scss';
import { FC } from 'react';
import { cyrillic } from '../../../config/validationSchemas';

interface OwnProps {
  onCloseButtonClick: () => void;
  firstName: string | null;
  lastName: string | null;
}

export const ChangeNameModal: FC<OwnProps> = function ChangeNameModal(props) {
  const { setUser, user } = useAuth();

  const validationSchema = object().shape({
    firstName: cyrillic.required(ERROR_REQUIRED),
    lastName: cyrillic.required(ERROR_REQUIRED),
  });

  return (
    <ModalOverlay onCloseButtonClick={props.onCloseButtonClick}>
      <>
        <header className={'modal_header'}>
          <h4 className={`${s.title} h4`}>Редактирование имени</h4>
          <ModalClose onCloseButtonClick={props.onCloseButtonClick} />
        </header>
        <Formik
          initialValues={{ firstName: user?.firstName ?? '', lastName: user?.lastName ?? '' }}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={(values, formikHelpers) => {
            if (values.firstName === props.firstName && values.lastName === props.lastName) {
              props.onCloseButtonClick();
            } else {
              UsersApi.usersControllerUpdateName({
                firstName: values.firstName,
                lastName: values.lastName,
              }).then(({ data }) => {
                if (data) {
                  props.onCloseButtonClick();
                  setUser(data);
                } else {
                  formikHelpers.setErrors({ firstName: 'Ошибка' });
                }
              });
              formikHelpers.setSubmitting(false);
            }
          }}
        >
          {({ errors, isValid, touched }) => (
            <Form className={'modal_container'}>
              <div className={`${s.form} modal_inner`}>
                <Input label="Имя" error={errors.firstName} touched={touched.firstName}>
                  <Field placeholder="Введите имя" name="firstName" />
                </Input>
                <Input label="Фамилия" error={errors.lastName} touched={touched.lastName}>
                  <Field placeholder="Введите фамилию" name="lastName" />
                </Input>
              </div>
              <footer className={'modal_footer'}>
                <button className="btn" type="submit" disabled={!isValid}>
                  Сохранить
                </button>
              </footer>
            </Form>
          )}
        </Formik>
      </>
    </ModalOverlay>
  );
};
