export type FilterType = 'utm' | 'params';

export const getFilteredSearchParams = (search: string, type: FilterType) => {
  const searchParams = new URLSearchParams(search);
  const safeSearchParams = new URLSearchParams(search);

  for (const [key, value] of safeSearchParams.entries()) {
    if (type === 'utm' ? !value : !!value) continue;

    searchParams.delete(key);
  }

  return searchParams;
};
