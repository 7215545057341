import { useAuth } from '../../../context/AuthContext';
import { ChangeEmail } from './ChangeEmail';
import { EmailVerification } from './EmailVerification';
import { ModalOverlay } from '../../base/ModalBase/ModalBase.v2';
import { useState } from 'react';
import { useTimer } from '../../../hooks/useTimer';

export const ChangeEmailModal = ({ onCloseButtonClick }: { onCloseButtonClick: () => void }) => {
  const { user } = useAuth();
  const [state, setState] = useState('start');
  const [email, setEmail] = useState(user?.email ?? '');

  const { seconds, resetTimer } = useTimer(60);

  return (
    <ModalOverlay onCloseButtonClick={onCloseButtonClick}>
      <>
        {state === 'start' && (
          <ChangeEmail
            oldEmail={user?.email}
            email={email}
            setEmail={setEmail}
            changeState={() => setState('verification')}
            resetTimer={resetTimer}
            closeModal={onCloseButtonClick}
          />
        )}
        {state === 'verification' && (
          <EmailVerification
            email={email}
            changeState={() => setState('start')}
            closeModal={onCloseButtonClick}
            timer={seconds}
            resetTimer={resetTimer}
          />
        )}
      </>
    </ModalOverlay>
  );
};
