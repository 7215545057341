import { ReactComponent as Burger } from 'assets/svg/burger.svg';
import { ReactComponent as Location } from 'assets/svg/location.svg';
import { ReactComponent as Pin } from 'assets/svg/pin.svg';
import { ReactComponent as Profile } from 'assets/svg/profile.svg';
import { useAuth } from 'context/AuthContext';
import { AuthModals } from '../../../config/signModals';
import { BurgerMenu } from './BurgerMenu';
import { Logo } from '../Logo';
import { ProfilePicture } from '../ProfilePicture';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SignModals } from 'ui/modal/SignModals';
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import s from './Header.module.scss';
import { HeaderSearch } from './components/headerSearch/HeaderSearch';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import clsx from 'clsx';
import { UTMLink } from '../../base/UTMLink/UTMLink';
import { useGeo } from '../../../context/GeoProvider';

gsap.registerPlugin(ScrollTrigger);

const headerLinks = [
  {
    title: 'Услуги',
    to: '/services/',
  },
  {
    title: 'Сервисные станции',
    to: '/map/',
  },
  {
    title: 'Запасные части',
    to: '/spare-parts/',
  },
  {
    title: 'Новости',
    to: '/news/',
  },
  {
    title: 'Франшиза',
    to: '/franchise/',
    specialLink: true,
  },
  // // todo: hid promo
  // {
  //   title: 'Акции',
  //   to: '/promo/',
  // },
  // // todo: hid about
  // {
  //   title: 'О компании',
  //   to: '/about/',
  // },
  // // todo: hid additional-services
  // {
  //   title: 'Дополнительные услуги',
  //   to: '/additional-services/',
  // },
  // // todo: hid electric-charger
  // {
  //   title: 'Электрозаправки',
  //   to: '/map/?stations=electric-charger/',
  // },
];

export const Header = () => {
  const { city, isGeoCity, setModal, setIsGeoCity } = useGeo();
  const { user } = useAuth();

  const [openSearch, setOpenSearch] = useState(false);
  const [hide, setHide] = useState(false);
  const [, setPopup] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !(buttonRef.current as HTMLButtonElement).contains(event.target as Node)
      ) {
        setPopup(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        start: 'top -100px',
        end: 99999,
        onToggle: s => {
          setScrolled(s.isActive);
        },
        onUpdate: s => {
          setHide(s.direction !== -1);
        },
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <header
      className={clsx(
        'popover',
        'header',
        s.header,
        scrolled && s.headerScrolled,
        hide && s.headerHide
      )}
    >
      <div className={s.top}>
        <button onClick={() => setModal('select-city')} className={`${s.link} ${s.geo}`}>
          <Location /> {city}
        </button>
        <div className={s.navLinks}>
          {headerLinks.map((it, index) => (
            <UTMLink
              key={index}
              className={clsx(s.link, it?.specialLink && s.specialLink)}
              to={it.to}
            >
              {it.title}
            </UTMLink>
          ))}
          {/* <span className={`${s.link} `} onClick={() => setModal('trade-in')}>
          Trade-in
          </span> */}
          {/*<div className={s.popupBlock}>*/}
          {/*  <button className={s.menuButton} onClick={() => setPopup(!popup)} ref={buttonRef}>*/}
          {/*    <MenuDots />*/}
          {/*  </button>*/}
          {/*  {popup && (*/}
          {/*    <div className={s.popupWrap} ref={ref}>*/}
          {/*      /!*todo: hid electric-charger*!/*/}
          {/*      /!*<UTMLink className={`${s.popupLink}`} to="/map?stations=electric-charger">*!/*/}
          {/*      /!*  Электрозаправки*!/*/}
          {/*      /!*</UTMLink>*!/*/}
          {/*      <UTMLink className={`${s.popupLink}`} to="/map/">*/}
          {/*        Сервисные станции*/}
          {/*      </UTMLink>*/}
          {/*      /!*todo: hid additional-services*!/*/}
          {/*      /!*<UTMLink className={`${s.popupLink}`} to="/additional-services">*!/*/}
          {/*      /!*  Дополнительные услуги*!/*/}
          {/*      /!*</UTMLink>*!/*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
        {/*todo: hid caready phone*/}
        {/*<a*/}
        {/*  href={`tel:+7 (657) 685-48-56`}*/}
        {/*  className={`${s.link} ${s.dedicated} ${s.phone}`}*/}
        {/*>*/}
        {/*  +7 (657) 685-48-56*/}
        {/*</a>*/}
      </div>
      <div className={clsx(s.bottom, openSearch && s.openSearch)}>
        <Logo className={s.logo} />
        <UTMLink className={`btn ${s.services}`} to="/services/">
          Выбрать услугу
        </UTMLink>
        <button className={s.back} onClick={() => setOpenSearch(false)}>
          <ArrowLeft />
        </button>
        <HeaderSearch openSearch={openSearch} />
        <button className={s.mobileBtn} onClick={() => setOpenSearch(true)}>
          <SearchIcon />
        </button>
        <UTMLink className={`h6 ${s.btn} ${s.linkToMap}`} to="/map/">
          <Pin /> Найти сервис
        </UTMLink>
        {user && (
          <UTMLink to="/profile/" className={`${s.btn} ${s.profile}`}>
            <ProfilePicture
              // todo: сделать поля необязательными
              name={user?.lastName && user?.firstName && `${user.lastName} ${user.firstName}`}
              img={user.avatarUrl}
            />
          </UTMLink>
        )}
        {/*todo: подумать, как можно избавиться от условия с user*/}
        {/*todo: пофиксить пролаг*/}
        <SignModals
          trigger={
            !user ? (
              <button className={`${s.btn} ${s.profile}`}>
                <Profile />
              </button>
            ) : (
              <></>
            )
          }
          modalName={AuthModals.login}
        />
        <BurgerMenu
          trigger={
            <button className={`${s.mobileBtn} ${s.burger}`}>
              <Burger />
            </button>
          }
        />
      </div>
      {isGeoCity && (
        <div className="popover-content">
          <span className="popover-arrow" />
          <div>
            <p className="h6">Вы находитесь в {city}?</p>
            <div className={s.popoverBtns}>
              <button className="btn" onClick={() => setIsGeoCity(false)}>
                Да
              </button>
              <button
                className="btn black secondary"
                onClick={() => {
                  setIsGeoCity(false);
                  setModal('select-city');
                }}
              >
                Выбрать другой город
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};
