/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { MaintenanceAppointmentRatingDto } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating401Response } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating403Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { RateMaintenanceAppointmentDto } from '../dtos';
// @ts-ignore
import type { ScheduleAppointmentDto } from '../dtos';
// @ts-ignore
import type { ScheduleQuickAppointmentDto } from '../dtos';
/**
 * MaintenanceAppointmentsApi - axios parameter creator
 * @export
 */
export const MaintenanceAppointmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a maintenance appointment rating
         * @param {string} id ID of the maintenance appointment request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerGetMaintenanceAppointmentRating: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('maintenanceAppointmentsControllerGetMaintenanceAppointmentRating', 'id', id)
            const localVarPath = `/maintenance/appointments/requests/{id}/rating`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The user can only rate a maintenance appointment which has been completed. If the user has already rated the appointment, the rating will be updated.
         * @summary Rate a maintenance appointment
         * @param {string} id ID of the maintenance appointment request
         * @param {RateMaintenanceAppointmentDto} rateMaintenanceAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerRateMaintenanceAppointment: async (id: string, rateMaintenanceAppointmentDto: RateMaintenanceAppointmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('maintenanceAppointmentsControllerRateMaintenanceAppointment', 'id', id)
            // verify required parameter 'rateMaintenanceAppointmentDto' is not null or undefined
            assertParamExists('maintenanceAppointmentsControllerRateMaintenanceAppointment', 'rateMaintenanceAppointmentDto', rateMaintenanceAppointmentDto)
            const localVarPath = `/maintenance/appointments/requests/{id}/rating`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rateMaintenanceAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authorization is optional
         * @summary Schedule a maintenance appointment
         * @param {MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
         * @param {ScheduleAppointmentDto} scheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerScheduleMaintenanceAppointment: async (requestSource: MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum, scheduleAppointmentDto: ScheduleAppointmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestSource' is not null or undefined
            assertParamExists('maintenanceAppointmentsControllerScheduleMaintenanceAppointment', 'requestSource', requestSource)
            // verify required parameter 'scheduleAppointmentDto' is not null or undefined
            assertParamExists('maintenanceAppointmentsControllerScheduleMaintenanceAppointment', 'scheduleAppointmentDto', scheduleAppointmentDto)
            const localVarPath = `/maintenance/appointments/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestSource != null) {
                localVarHeaderParameter['Request-Source'] = String(requestSource);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authorization is optional
         * @summary Schedule a quick maintenance appointment
         * @param {MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
         * @param {ScheduleQuickAppointmentDto} scheduleQuickAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment: async (requestSource: MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum, scheduleQuickAppointmentDto: ScheduleQuickAppointmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestSource' is not null or undefined
            assertParamExists('maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment', 'requestSource', requestSource)
            // verify required parameter 'scheduleQuickAppointmentDto' is not null or undefined
            assertParamExists('maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment', 'scheduleQuickAppointmentDto', scheduleQuickAppointmentDto)
            const localVarPath = `/maintenance/appointments/quick-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (requestSource != null) {
                localVarHeaderParameter['Request-Source'] = String(requestSource);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleQuickAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceAppointmentsApi - functional programming interface
 * @export
 */
export const MaintenanceAppointmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceAppointmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a maintenance appointment rating
         * @param {string} id ID of the maintenance appointment request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceAppointmentRatingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceAppointmentsApi.maintenanceAppointmentsControllerGetMaintenanceAppointmentRating']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The user can only rate a maintenance appointment which has been completed. If the user has already rated the appointment, the rating will be updated.
         * @summary Rate a maintenance appointment
         * @param {string} id ID of the maintenance appointment request
         * @param {RateMaintenanceAppointmentDto} rateMaintenanceAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceAppointmentsControllerRateMaintenanceAppointment(id: string, rateMaintenanceAppointmentDto: RateMaintenanceAppointmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceAppointmentRatingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceAppointmentsControllerRateMaintenanceAppointment(id, rateMaintenanceAppointmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceAppointmentsApi.maintenanceAppointmentsControllerRateMaintenanceAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Authorization is optional
         * @summary Schedule a maintenance appointment
         * @param {MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
         * @param {ScheduleAppointmentDto} scheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceAppointmentsControllerScheduleMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum, scheduleAppointmentDto: ScheduleAppointmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceAppointmentsControllerScheduleMaintenanceAppointment(requestSource, scheduleAppointmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceAppointmentsApi.maintenanceAppointmentsControllerScheduleMaintenanceAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Authorization is optional
         * @summary Schedule a quick maintenance appointment
         * @param {MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
         * @param {ScheduleQuickAppointmentDto} scheduleQuickAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum, scheduleQuickAppointmentDto: ScheduleQuickAppointmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(requestSource, scheduleQuickAppointmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceAppointmentsApi.maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MaintenanceAppointmentsApi - factory interface
 * @export
 */
export const MaintenanceAppointmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceAppointmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a maintenance appointment rating
         * @param {string} id ID of the maintenance appointment request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(id: string, options?: any): AxiosPromise<MaintenanceAppointmentRatingDto> {
            return localVarFp.maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The user can only rate a maintenance appointment which has been completed. If the user has already rated the appointment, the rating will be updated.
         * @summary Rate a maintenance appointment
         * @param {string} id ID of the maintenance appointment request
         * @param {RateMaintenanceAppointmentDto} rateMaintenanceAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerRateMaintenanceAppointment(id: string, rateMaintenanceAppointmentDto: RateMaintenanceAppointmentDto, options?: any): AxiosPromise<MaintenanceAppointmentRatingDto> {
            return localVarFp.maintenanceAppointmentsControllerRateMaintenanceAppointment(id, rateMaintenanceAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Authorization is optional
         * @summary Schedule a maintenance appointment
         * @param {MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
         * @param {ScheduleAppointmentDto} scheduleAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerScheduleMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum, scheduleAppointmentDto: ScheduleAppointmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceAppointmentsControllerScheduleMaintenanceAppointment(requestSource, scheduleAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Authorization is optional
         * @summary Schedule a quick maintenance appointment
         * @param {MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
         * @param {ScheduleQuickAppointmentDto} scheduleQuickAppointmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum, scheduleQuickAppointmentDto: ScheduleQuickAppointmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(requestSource, scheduleQuickAppointmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceAppointmentsApi - interface
 * @export
 * @interface MaintenanceAppointmentsApi
 */
export interface MaintenanceAppointmentsApiInterface {
    /**
     * 
     * @summary Get a maintenance appointment rating
     * @param {string} id ID of the maintenance appointment request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApiInterface
     */
    maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(id: string, options?: RawAxiosRequestConfig): AxiosPromise<MaintenanceAppointmentRatingDto>;

    /**
     * The user can only rate a maintenance appointment which has been completed. If the user has already rated the appointment, the rating will be updated.
     * @summary Rate a maintenance appointment
     * @param {string} id ID of the maintenance appointment request
     * @param {RateMaintenanceAppointmentDto} rateMaintenanceAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApiInterface
     */
    maintenanceAppointmentsControllerRateMaintenanceAppointment(id: string, rateMaintenanceAppointmentDto: RateMaintenanceAppointmentDto, options?: RawAxiosRequestConfig): AxiosPromise<MaintenanceAppointmentRatingDto>;

    /**
     * Authorization is optional
     * @summary Schedule a maintenance appointment
     * @param {MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
     * @param {ScheduleAppointmentDto} scheduleAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApiInterface
     */
    maintenanceAppointmentsControllerScheduleMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum, scheduleAppointmentDto: ScheduleAppointmentDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * Authorization is optional
     * @summary Schedule a quick maintenance appointment
     * @param {MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
     * @param {ScheduleQuickAppointmentDto} scheduleQuickAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApiInterface
     */
    maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum, scheduleQuickAppointmentDto: ScheduleQuickAppointmentDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * MaintenanceAppointmentsApi - object-oriented interface
 * @export
 * @class MaintenanceAppointmentsApi
 * @extends {BaseAPI}
 */
export class MaintenanceAppointmentsApi extends BaseAPI implements MaintenanceAppointmentsApiInterface {
    /**
     * 
     * @summary Get a maintenance appointment rating
     * @param {string} id ID of the maintenance appointment request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApi
     */
    public maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(id: string, options?: RawAxiosRequestConfig) {
        return MaintenanceAppointmentsApiFp(this.configuration).maintenanceAppointmentsControllerGetMaintenanceAppointmentRating(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The user can only rate a maintenance appointment which has been completed. If the user has already rated the appointment, the rating will be updated.
     * @summary Rate a maintenance appointment
     * @param {string} id ID of the maintenance appointment request
     * @param {RateMaintenanceAppointmentDto} rateMaintenanceAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApi
     */
    public maintenanceAppointmentsControllerRateMaintenanceAppointment(id: string, rateMaintenanceAppointmentDto: RateMaintenanceAppointmentDto, options?: RawAxiosRequestConfig) {
        return MaintenanceAppointmentsApiFp(this.configuration).maintenanceAppointmentsControllerRateMaintenanceAppointment(id, rateMaintenanceAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authorization is optional
     * @summary Schedule a maintenance appointment
     * @param {MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
     * @param {ScheduleAppointmentDto} scheduleAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApi
     */
    public maintenanceAppointmentsControllerScheduleMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum, scheduleAppointmentDto: ScheduleAppointmentDto, options?: RawAxiosRequestConfig) {
        return MaintenanceAppointmentsApiFp(this.configuration).maintenanceAppointmentsControllerScheduleMaintenanceAppointment(requestSource, scheduleAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authorization is optional
     * @summary Schedule a quick maintenance appointment
     * @param {MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum} requestSource This header is intended to analyze where the request comes from
     * @param {ScheduleQuickAppointmentDto} scheduleQuickAppointmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceAppointmentsApi
     */
    public maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(requestSource: MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum, scheduleQuickAppointmentDto: ScheduleQuickAppointmentDto, options?: RawAxiosRequestConfig) {
        return MaintenanceAppointmentsApiFp(this.configuration).maintenanceAppointmentsControllerScheduleQuickMaintenanceAppointment(requestSource, scheduleQuickAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum = {
    WebApp: 'web_app',
    MobileApp: 'mobile_app'
} as const;
export type MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum = typeof MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum[keyof typeof MaintenanceAppointmentsControllerScheduleMaintenanceAppointmentRequestSourceEnum];
/**
 * @export
 */
export const MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum = {
    WebApp: 'web_app',
    MobileApp: 'mobile_app'
} as const;
export type MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum = typeof MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum[keyof typeof MaintenanceAppointmentsControllerScheduleQuickMaintenanceAppointmentRequestSourceEnum];
