import { ModalOverlay } from '../../../../../base/ModalBase';
import { ReactComponent as ArrowLeft } from '../../../../../../assets/svg/arrow-left.svg';
import { FC, PropsWithChildren, ReactNode } from 'react';
import s from './MapModal.module.scss';

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  footer?: ReactNode;
  title?: string;
  header?: ReactNode;
};

export const MapModal: FC<PropsWithChildren<OwnProps>> = function MapModal(props) {
  return (
    <ModalOverlay open={props.isOpen}>
      <div className={s.wrapper}>
        <header className={s.header}>
          <button className={s.back} onClick={props.onClose}>
            <ArrowLeft />
            Назад
          </button>
          {props.title && <p className={s.title}>{props.title}</p>}
          {props.header && props.header}
        </header>
        <div className={s.content}>{props.children}</div>
      </div>
      <footer>{props.footer}</footer>
    </ModalOverlay>
  );
};
