import { DynamicImg } from '../../base/DynamicImg';
import { UTMLink } from '../../base/UTMLink/UTMLink';

export const Logo = ({ className }: { className: string }) => {
  return (
    <UTMLink to={'/'} className={className}>
      <DynamicImg path={'logo/logo.png'} alt="Логотип" notLazy={true} />
    </UTMLink>
  );
};
