import { AUTH_TOKEN_KEY } from '../../config/auth';
import { useAuth } from '../../context/AuthContext';
import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const ProtectedLayout = function ProtectedLayout() {
  const [userId, setUserId] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const { checkAuth } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    setLoading(!!token);

    if (!!token) {
      checkAuth().then(res => {
        setUserId(res);
        setLoading(false);
      });
    }
  }, []);

  if (loading) {
    return <div />;
  }

  if (userId) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};
