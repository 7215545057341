import { DynamicImg } from '../../base/DynamicImg';
import { FC } from 'react';
import { FranchiseSection } from '../../../config/franchiseSections';
import styles from './WhoWeAre.module.sass';

const cards = [
  'Собственный учебный центр подготовки персонала',
  'Техническая поддержка на большинство популярных китайских производителей',
  'Доступ к каталогам и мануалам автомобилей различных брендов',
  'Доступ к порталу Caready с уникальным предложением по запчастям и аксессуарам',
];

export const WhoWeAre: FC = function WhoWeAre() {
  return (
    <section className={styles.section} id={FranchiseSection.WhoWeAre}>
      <div className={styles.container}>
        <h2 className={`${styles.sectionTitle} h6-16 section-title`}>Преимущества</h2>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <DynamicImg path={'franchise/who-we-are/we-are-image.webp'} alt={'Background'} />
            <DynamicImg path={'franchise/who-we-are/man.webp'} alt={'Mechanic'} />
          </div>
          <h3 className={`${styles.contentTitle} h3-32`}>
            Экспертиза обслуживания китайских автомобилей
          </h3>
          {cards.map((card, index) => (
            <div key={card}>
              <div className={styles.cardNumber}>
                <span>{index + 1}</span>
              </div>
              <p>{card}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
