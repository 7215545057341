import React, { memo, useEffect, useId, useRef, useState } from 'react';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';
import { Clusterer, Map, Placemark } from '@pbe/react-yandex-maps';
import { DefaultCity, useGeo } from '../../../../context/GeoProvider';
import { useMap } from '../context/MapProvider';
import { MapSearch } from './components/mapSearch/MapSearch';
import mapStyles from '../map.module.scss';
import s from './MobileMap.module.scss';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { Balloon } from './components/balloon/Balloon';
import { MapModal } from './components/mapModal/MapModal';
import { SelectedServiceStation } from '../components/selectedServiceStation/SelectedServiceStation';
import { useMatch } from '../../../../hooks/useMatch';
import breakPoints from '../../../../styles/breakpoints.module.scss';
import { MapControls } from '../components/mapControls/MapControls';
import { useMapZoom } from '../hooks/useMapZoom';
import { PlacemarkList } from '../components/PlacemarkList/PlacemarkList';
import { placemarkDefaultOptions } from '../config/placemarkDefaultOptions';
import { useGeoObjectsEvent } from '../hooks/useGeoObjectsEvent';

export const MobileMap = memo(function MobileMap() {
  const { bounds, serviceStation } = useGeo();
  const { serviceStationsBySlugs, selectedServiceStation, setSelectedServiceStation } = useMap();
  const { isMatch } = useMatch(breakPoints.bp576);

  const [ymaps, setYmaps] = useState<YMapsApi | undefined>();
  const mapRef = useRef<ymaps.Map | undefined>(undefined);

  const { zoom, setZoom } = useMapZoom(mapRef, ymaps);
  const [center, setCenter] = useState<number[] | undefined>();

  const balloonId = useId();
  const balloonElement = document.getElementById(balloonId);

  const [isBalloonShow, setIsBalloonShow] = useState(true);
  const [isBalloonOpen, setIsBalloonOpen] = useState(false);

  const searchRef = useRef<HTMLDivElement | null>(null);

  const [isShowSelectedServiceStation, setIsShowSelectedServiceStation] = useState<boolean>(false);

  useEffect(() => {
    const filteredServiceStation =
      serviceStationsBySlugs.find(it => it.id === serviceStation?.id) || null;

    if (!filteredServiceStation) {
      setCenter(undefined);
      setZoom(9);
    }

    setIsBalloonOpen(false);
    setIsBalloonShow(true);
    setSelectedServiceStation(filteredServiceStation);
  }, [serviceStation, serviceStationsBySlugs]);

  useEffect(() => {
    if (!selectedServiceStation) {
      return;
    }

    const { latitude, longitude } = selectedServiceStation.fullAddress.coordinates;

    setZoom(12);
    setCenter([latitude, longitude]);
  }, [selectedServiceStation]);

  function onCloseBalloon() {
    setIsBalloonOpen(false);
    setIsBalloonShow(false);
    setSelectedServiceStation(null);
  }

  useGeoObjectsEvent('balloonclose', onCloseBalloon, mapRef, ymaps);

  return (
    <div className={`${mapStyles.wrapper} ${s.wrapper}`}>
      <MapSearch
        ref={searchRef}
        setIsShowSelectedServiceStation={setIsShowSelectedServiceStation}
      />
      <MapModal
        isOpen={isShowSelectedServiceStation && !!selectedServiceStation}
        onClose={() => {
          setIsShowSelectedServiceStation(false);
          setSelectedServiceStation(
            selectedServiceStation?.id === serviceStation?.id ? serviceStation : null
          );
        }}
      >
        {selectedServiceStation && <SelectedServiceStation id={selectedServiceStation.id} />}
      </MapModal>
      <div className={clsx(mapStyles.map, isBalloonOpen && s.balloonOpen, 'map')}>
        <Map
          width={'100%'}
          height={'100%'}
          instanceRef={mapRef}
          state={{
            zoom: zoom,
            center: center,
            bounds: center ? undefined : bounds || DefaultCity.bounds,
            behaviors: ['drag', 'dblClickZoom'],
          }}
          options={{
            suppressMapOpenBlock: true,
            // @ts-ignore
            balloonPanelMaxMapArea: isMatch ? 'Infinity' : '0',
          }}
          modules={['templateLayoutFactory', 'layout.ImageWithContent', 'util.bounds']}
          onLoad={setYmaps}
        >
          {ymaps && selectedServiceStation && center && (
            <Placemark
              modules={['geoObject.addon.balloon']}
              key={selectedServiceStation.id}
              defaultGeometry={[
                selectedServiceStation.fullAddress.coordinates.latitude,
                selectedServiceStation.fullAddress.coordinates.longitude,
              ]}
              instanceRef={ref => {
                if (!ref || isBalloonOpen || !isBalloonShow) return;

                ref.balloon.open().then(() => setIsBalloonOpen(true));
              }}
              onClick={() => {
                setIsShowSelectedServiceStation(prevValue => !prevValue);
              }}
              options={{
                ...placemarkDefaultOptions,
                balloonOffset: [0, -24],
                openBalloonOnClick: false,
                hideIconOnBalloonOpen: false,
              }}
              properties={{
                balloonContent: `<div id=${balloonId} class="balloon"/>`,
              }}
            />
          )}
          {ymaps && (
            <>
              <Clusterer
                options={{
                  clusterIcons: [
                    {
                      size: [40, 40],
                      offset: [-20, -40],
                    },
                  ],
                  clusterIconContentLayout: ymaps?.templateLayoutFactory.createClass(
                    `<div class='${mapStyles.cluster}'>{{ properties.geoObjects.length }}</div>`
                  ),
                }}
              >
                <PlacemarkList
                  selectedServiceStation={selectedServiceStation}
                  serviceStations={serviceStationsBySlugs}
                  onClick={value => {
                    setIsShowSelectedServiceStation(prevValue => !prevValue);
                    setSelectedServiceStation(value);
                  }}
                />
              </Clusterer>
              <MapControls ymaps={ymaps} zoom={zoom} setZoom={setZoom} setCenter={setCenter} />
            </>
          )}
        </Map>
        {selectedServiceStation &&
          balloonElement &&
          createPortal(
            <Balloon
              selectedServiceStation={selectedServiceStation}
              onClick={value => {
                setSelectedServiceStation(value);
                setIsShowSelectedServiceStation(prevValue => !prevValue);
              }}
            />,
            balloonElement
          )}
      </div>
    </div>
  );
});
