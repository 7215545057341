import { AdvantagesSection } from '../../ui/franchiseComponents/AdvantagesSection';
import { CoreHead } from '../../ui/core';
import { FranchiseSection } from '../../config/franchiseSections';
import { HeroSection } from '../../ui/components/HeroSection/HeroSection';
import { ReactComponent as Mail } from 'assets/svg/mail.svg';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { Reviews } from '../../ui/franchiseComponents/Reviews';
import { SideNavigation } from '../../ui/franchiseComponents/SideNavigation';
import { WhoWeAre } from '../../ui/franchiseComponents/WhoWeAre';
import { WorkGeographic } from '../../ui/franchiseComponents/WorkGeographic';
import { FC, useEffect, useRef, useState } from 'react';
import s from './Franchise.module.scss';
import { prettifyPhone } from '../../utils/prettifyPhone';

const franchisePhoneNumber = '79251063808';

export const Franchise: FC = function Franchise() {
  const pageRef = useRef<HTMLDivElement>(null);

  const [modal, setModal] = useState<string | null>(null);

  useEffect(() => {
    if (modal) {
      document.body.classList.add('hidden');
    } else {
      document.body.classList.remove('hidden');
    }
  }, [modal]);

  return (
    <>
      <CoreHead
        title={'Франшиза'}
        description={
          'Федеральная сеть мультибрендовых автосервисов с акцентом на китайские автомобили и собственным брендом запчастей'
        }
      />
      <div ref={pageRef} className={s.app}>
        {/*<div className={`app ${s.topSectionWrapper}`}>*/}
        {/*  <div className={`section ${s.topSection}`}>*/}
        {/*    <BreadCrumbs links={[BREAD_CRUMB_MAIN, { name: 'Франшиза' }]} />*/}
        {/*    <h2 className={'h2'}>Франшиза</h2>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <SideNavigation />
        <div className={`app ${s.topSectionWrapper}`}>
          <HeroSection
            title={
              <h1 className="h3">
                CAREADY – федеральная сеть мультибрендовых автосервисов с акцентом на китайские
                автомобили и собственным брендом запчастей
              </h1>
            }
            imgPath={'franchise/hero/img.webp'}
            className={s.heroSection}
            id={FranchiseSection.HeroSection}
            footer={
              <div className={s.contactsWrapper}>
                <a href={`tel${franchisePhoneNumber}`} className={s.contact}>
                  <Phone />
                  <span className="h6">{prettifyPhone(franchisePhoneNumber)}</span>
                </a>
                <a href={'mailto:franchise@caready.ru'} className={s.contact}>
                  <Mail />
                  <span className="h6"> franchise@caready.ru</span>
                </a>
              </div>
            }
          />
        </div>
        <WhoWeAre />
        <AdvantagesSection />
        <WorkGeographic openModal={() => setModal('form-modal')} />
        <Reviews />
        {/*todo: hid service section*/}
        {/*<ServicesSection />*/}
        {/*todo: hid eco section*/}
        {/*<EcoSection />*/}
        {/*todo: hid cost calculation*/}
        {/*<CostCalculation />*/}
        {/*before change mail*/}
        {/*<RequestSection />*/}
      </div>
      {/*{modal === 'form-modal' &&*/}
      {/*  // before change mail*/}
      {/*  // <FormModal closeHandle={() => setModal(undefined)} />*/}
      {/*  null}*/}
    </>
  );
};
