/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { MaintenanceServiceCategoryDto } from '../dtos';
// @ts-ignore
import type { MaintenanceServiceCategoryPageDto } from '../dtos';
// @ts-ignore
import type { MaintenanceServiceCategoryTreeDto } from '../dtos';
// @ts-ignore
import type { MaintenanceServicePageDto } from '../dtos';
// @ts-ignore
import type { MaintenanceServiceSubcategoryPageDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { PaginatedMaintenanceServiceDto } from '../dtos';
/**
 * MaintenanceServicesApi - axios parameter creator
 * @export
 */
export const MaintenanceServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all maintenance service categories
         * @summary Get categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetAllCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance/services/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a category with subcategories
         * @summary Get a category
         * @param {string} slug Category\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetCategoryPageBySlug: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('maintenanceServicesControllerGetCategoryPageBySlug', 'slug', slug)
            const localVarPath = `/maintenance/services/categories/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a service page
         * @summary Get a service
         * @param {string} slug Service\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetServicePageBySlug: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('maintenanceServicesControllerGetServicePageBySlug', 'slug', slug)
            const localVarPath = `/maintenance/services/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a services by category id
         * @param {string} categoryId 
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetServicesByCategorySlug: async (categoryId: string, perPage?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('maintenanceServicesControllerGetServicesByCategorySlug', 'categoryId', categoryId)
            const localVarPath = `/maintenance/services/by-category-id/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all services with their categories and subcategories hierarchically
         * @summary Get services tree
         * @param {string} [serviceStationId] 
         * @param {Array<string>} [serviceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetServicesTree: async (serviceStationId?: string, serviceIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance/services/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serviceStationId !== undefined) {
                localVarQueryParameter['serviceStationId'] = serviceStationId;
            }

            if (serviceIds) {
                localVarQueryParameter['serviceIds'] = serviceIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a subcategory with services
         * @summary Get a subcategory
         * @param {string} categorySlug Category\&#39;s slug
         * @param {string} subcategorySlug Subcategory\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetSubcategoryPageBySlug: async (categorySlug: string, subcategorySlug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorySlug' is not null or undefined
            assertParamExists('maintenanceServicesControllerGetSubcategoryPageBySlug', 'categorySlug', categorySlug)
            // verify required parameter 'subcategorySlug' is not null or undefined
            assertParamExists('maintenanceServicesControllerGetSubcategoryPageBySlug', 'subcategorySlug', subcategorySlug)
            const localVarPath = `/maintenance/services/categories/{categorySlug}/{subcategorySlug}`
                .replace(`{${"categorySlug"}}`, encodeURIComponent(String(categorySlug)))
                .replace(`{${"subcategorySlug"}}`, encodeURIComponent(String(subcategorySlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceServicesApi - functional programming interface
 * @export
 */
export const MaintenanceServicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceServicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all maintenance service categories
         * @summary Get categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceServicesControllerGetAllCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceServiceCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceServicesControllerGetAllCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceServicesApi.maintenanceServicesControllerGetAllCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a category with subcategories
         * @summary Get a category
         * @param {string} slug Category\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceServicesControllerGetCategoryPageBySlug(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceServiceCategoryPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceServicesControllerGetCategoryPageBySlug(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceServicesApi.maintenanceServicesControllerGetCategoryPageBySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a service page
         * @summary Get a service
         * @param {string} slug Service\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceServicesControllerGetServicePageBySlug(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceServicePageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceServicesControllerGetServicePageBySlug(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceServicesApi.maintenanceServicesControllerGetServicePageBySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a services by category id
         * @param {string} categoryId 
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceServicesControllerGetServicesByCategorySlug(categoryId: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMaintenanceServiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceServicesControllerGetServicesByCategorySlug(categoryId, perPage, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceServicesApi.maintenanceServicesControllerGetServicesByCategorySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all services with their categories and subcategories hierarchically
         * @summary Get services tree
         * @param {string} [serviceStationId] 
         * @param {Array<string>} [serviceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceServicesControllerGetServicesTree(serviceStationId?: string, serviceIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceServiceCategoryTreeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceServicesControllerGetServicesTree(serviceStationId, serviceIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceServicesApi.maintenanceServicesControllerGetServicesTree']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a subcategory with services
         * @summary Get a subcategory
         * @param {string} categorySlug Category\&#39;s slug
         * @param {string} subcategorySlug Subcategory\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceServicesControllerGetSubcategoryPageBySlug(categorySlug: string, subcategorySlug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceServiceSubcategoryPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceServicesControllerGetSubcategoryPageBySlug(categorySlug, subcategorySlug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceServicesApi.maintenanceServicesControllerGetSubcategoryPageBySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MaintenanceServicesApi - factory interface
 * @export
 */
export const MaintenanceServicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceServicesApiFp(configuration)
    return {
        /**
         * Get all maintenance service categories
         * @summary Get categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetAllCategories(options?: any): AxiosPromise<Array<MaintenanceServiceCategoryDto>> {
            return localVarFp.maintenanceServicesControllerGetAllCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a category with subcategories
         * @summary Get a category
         * @param {string} slug Category\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetCategoryPageBySlug(slug: string, options?: any): AxiosPromise<MaintenanceServiceCategoryPageDto> {
            return localVarFp.maintenanceServicesControllerGetCategoryPageBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a service page
         * @summary Get a service
         * @param {string} slug Service\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetServicePageBySlug(slug: string, options?: any): AxiosPromise<MaintenanceServicePageDto> {
            return localVarFp.maintenanceServicesControllerGetServicePageBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a services by category id
         * @param {string} categoryId 
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetServicesByCategorySlug(categoryId: string, perPage?: number, page?: number, options?: any): AxiosPromise<PaginatedMaintenanceServiceDto> {
            return localVarFp.maintenanceServicesControllerGetServicesByCategorySlug(categoryId, perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all services with their categories and subcategories hierarchically
         * @summary Get services tree
         * @param {string} [serviceStationId] 
         * @param {Array<string>} [serviceIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetServicesTree(serviceStationId?: string, serviceIds?: Array<string>, options?: any): AxiosPromise<Array<MaintenanceServiceCategoryTreeDto>> {
            return localVarFp.maintenanceServicesControllerGetServicesTree(serviceStationId, serviceIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a subcategory with services
         * @summary Get a subcategory
         * @param {string} categorySlug Category\&#39;s slug
         * @param {string} subcategorySlug Subcategory\&#39;s slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceServicesControllerGetSubcategoryPageBySlug(categorySlug: string, subcategorySlug: string, options?: any): AxiosPromise<MaintenanceServiceSubcategoryPageDto> {
            return localVarFp.maintenanceServicesControllerGetSubcategoryPageBySlug(categorySlug, subcategorySlug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceServicesApi - interface
 * @export
 * @interface MaintenanceServicesApi
 */
export interface MaintenanceServicesApiInterface {
    /**
     * Get all maintenance service categories
     * @summary Get categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApiInterface
     */
    maintenanceServicesControllerGetAllCategories(options?: RawAxiosRequestConfig): AxiosPromise<Array<MaintenanceServiceCategoryDto>>;

    /**
     * Get a category with subcategories
     * @summary Get a category
     * @param {string} slug Category\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApiInterface
     */
    maintenanceServicesControllerGetCategoryPageBySlug(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<MaintenanceServiceCategoryPageDto>;

    /**
     * Get a service page
     * @summary Get a service
     * @param {string} slug Service\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApiInterface
     */
    maintenanceServicesControllerGetServicePageBySlug(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<MaintenanceServicePageDto>;

    /**
     * 
     * @summary Get a services by category id
     * @param {string} categoryId 
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApiInterface
     */
    maintenanceServicesControllerGetServicesByCategorySlug(categoryId: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedMaintenanceServiceDto>;

    /**
     * Get all services with their categories and subcategories hierarchically
     * @summary Get services tree
     * @param {string} [serviceStationId] 
     * @param {Array<string>} [serviceIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApiInterface
     */
    maintenanceServicesControllerGetServicesTree(serviceStationId?: string, serviceIds?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<MaintenanceServiceCategoryTreeDto>>;

    /**
     * Get a subcategory with services
     * @summary Get a subcategory
     * @param {string} categorySlug Category\&#39;s slug
     * @param {string} subcategorySlug Subcategory\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApiInterface
     */
    maintenanceServicesControllerGetSubcategoryPageBySlug(categorySlug: string, subcategorySlug: string, options?: RawAxiosRequestConfig): AxiosPromise<MaintenanceServiceSubcategoryPageDto>;

}

/**
 * MaintenanceServicesApi - object-oriented interface
 * @export
 * @class MaintenanceServicesApi
 * @extends {BaseAPI}
 */
export class MaintenanceServicesApi extends BaseAPI implements MaintenanceServicesApiInterface {
    /**
     * Get all maintenance service categories
     * @summary Get categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApi
     */
    public maintenanceServicesControllerGetAllCategories(options?: RawAxiosRequestConfig) {
        return MaintenanceServicesApiFp(this.configuration).maintenanceServicesControllerGetAllCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a category with subcategories
     * @summary Get a category
     * @param {string} slug Category\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApi
     */
    public maintenanceServicesControllerGetCategoryPageBySlug(slug: string, options?: RawAxiosRequestConfig) {
        return MaintenanceServicesApiFp(this.configuration).maintenanceServicesControllerGetCategoryPageBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a service page
     * @summary Get a service
     * @param {string} slug Service\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApi
     */
    public maintenanceServicesControllerGetServicePageBySlug(slug: string, options?: RawAxiosRequestConfig) {
        return MaintenanceServicesApiFp(this.configuration).maintenanceServicesControllerGetServicePageBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a services by category id
     * @param {string} categoryId 
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApi
     */
    public maintenanceServicesControllerGetServicesByCategorySlug(categoryId: string, perPage?: number, page?: number, options?: RawAxiosRequestConfig) {
        return MaintenanceServicesApiFp(this.configuration).maintenanceServicesControllerGetServicesByCategorySlug(categoryId, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all services with their categories and subcategories hierarchically
     * @summary Get services tree
     * @param {string} [serviceStationId] 
     * @param {Array<string>} [serviceIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApi
     */
    public maintenanceServicesControllerGetServicesTree(serviceStationId?: string, serviceIds?: Array<string>, options?: RawAxiosRequestConfig) {
        return MaintenanceServicesApiFp(this.configuration).maintenanceServicesControllerGetServicesTree(serviceStationId, serviceIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a subcategory with services
     * @summary Get a subcategory
     * @param {string} categorySlug Category\&#39;s slug
     * @param {string} subcategorySlug Subcategory\&#39;s slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceServicesApi
     */
    public maintenanceServicesControllerGetSubcategoryPageBySlug(categorySlug: string, subcategorySlug: string, options?: RawAxiosRequestConfig) {
        return MaintenanceServicesApiFp(this.configuration).maintenanceServicesControllerGetSubcategoryPageBySlug(categorySlug, subcategorySlug, options).then((request) => request(this.axios, this.basePath));
    }
}

