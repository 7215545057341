import { AuthModals, ModalName } from '../../../config/signModals';
import { Create } from './Create';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { ModalOverlay } from '../../base/ModalBase';
import { NewPassword } from './NewPassword';
import { PersonalInfo } from './PersonalInfo';
import { SendCode } from './SendCode';
import { ReactNode, useState } from 'react';

interface OwnProps {
  trigger: ReactNode;
  modalName: ModalName;
}

export const SignModals = function SignModals(props: OwnProps) {
  const [stepList, setStepList] = useState<ModalName[]>([props.modalName]);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [afterSendCode, setAfterSendCode] = useState<ModalName | null>(null);

  const closeModal = () => {
    setOpen(false);
    setStepList([props.modalName]);
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
    if (open) setStepList([props.modalName]);
  };

  const isCurrentStep = (stepName: ModalName) => stepList[stepList.length - 1] === stepName;
  const toStart = () => setStepList([props.modalName]);

  const addNewStep = (stepName: ModalName) => {
    setStepList(prevState => prevState.concat(stepName));
  };

  const removeCurrentStep = () => {
    setStepList(prevState => prevState.slice(0, stepList.length - 1));
  };

  return (
    <ModalOverlay
      trigger={props.trigger}
      open={open}
      defaultOpen={false}
      onOpenChange={onOpenChange}
    >
      <>
        {isCurrentStep(AuthModals.login) && (
          <Login
            toForgotPassword={() => addNewStep(AuthModals.forgotPassword)}
            toCreate={() => addNewStep(AuthModals.create)}
            toVerifyEmail={() => addNewStep(AuthModals.sendCode)}
            closeModal={closeModal}
          />
        )}
        {isCurrentStep(AuthModals.forgotPassword) && (
          <ForgotPassword
            toPrevStep={removeCurrentStep}
            setEmail={(e: string) => setEmail(e)}
            toVerifyPassword={() => {
              setAfterSendCode(AuthModals.newPassword);
              addNewStep(AuthModals.sendCode);
            }}
          />
        )}
        {isCurrentStep(AuthModals.sendCode) && (
          <SendCode
            toLogin={toStart}
            toNextStep={() => (afterSendCode ? addNewStep(afterSendCode) : closeModal())}
            toPrevStep={removeCurrentStep}
            email={email}
          />
        )}
        {isCurrentStep(AuthModals.newPassword) && <NewPassword closeModal={closeModal} />}
        {isCurrentStep(AuthModals.create) && (
          <Create
            toLogin={toStart}
            setEmail={(e: string) => setEmail(e)}
            toVerifyEmail={() => {
              setAfterSendCode(AuthModals.personalInfo);
              addNewStep(AuthModals.sendCode);
            }}
          />
        )}
        {isCurrentStep(AuthModals.personalInfo) && <PersonalInfo closeModal={closeModal} />}
      </>
    </ModalOverlay>
  );
};
