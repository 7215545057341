/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { MaintenanceRecommendationDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { PaginatedPromotionsDto } from '../dtos';
// @ts-ignore
import type { PromotionCategoryDto } from '../dtos';
// @ts-ignore
import type { PromotionPageDto } from '../dtos';
/**
 * PromotionsApi - axios parameter creator
 * @export
 */
export const PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get promotion categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPromotionCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get published maintenance recommendations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPublishedMaintenanceRecommendations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/maintenance-recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a published promotion page content by its slug
         * @param {string} slug Promotion slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPublishedPromotionPageBySlug: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('promotionsControllerGetPublishedPromotionPageBySlug', 'slug', slug)
            const localVarPath = `/promotions/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get published promotions with filters and pagination
         * @summary Get promotions
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [category] Filter promotions by a category (by its slug)
         * @param {boolean} [archived] Display either active or archived promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPublishedPromotions: async (perPage?: number, page?: number, category?: string, archived?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsApi - functional programming interface
 * @export
 */
export const PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get promotion categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerGetPromotionCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromotionCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerGetPromotionCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerGetPromotionCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get published maintenance recommendations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerGetPublishedMaintenanceRecommendations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceRecommendationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerGetPublishedMaintenanceRecommendations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerGetPublishedMaintenanceRecommendations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a published promotion page content by its slug
         * @param {string} slug Promotion slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerGetPublishedPromotionPageBySlug(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerGetPublishedPromotionPageBySlug(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerGetPublishedPromotionPageBySlug']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get published promotions with filters and pagination
         * @summary Get promotions
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [category] Filter promotions by a category (by its slug)
         * @param {boolean} [archived] Display either active or archived promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerGetPublishedPromotions(perPage?: number, page?: number, category?: string, archived?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedPromotionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerGetPublishedPromotions(perPage, page, category, archived, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PromotionsApi.promotionsControllerGetPublishedPromotions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PromotionsApi - factory interface
 * @export
 */
export const PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get promotion categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPromotionCategories(options?: any): AxiosPromise<Array<PromotionCategoryDto>> {
            return localVarFp.promotionsControllerGetPromotionCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get published maintenance recommendations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPublishedMaintenanceRecommendations(options?: any): AxiosPromise<Array<MaintenanceRecommendationDto>> {
            return localVarFp.promotionsControllerGetPublishedMaintenanceRecommendations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a published promotion page content by its slug
         * @param {string} slug Promotion slug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPublishedPromotionPageBySlug(slug: string, options?: any): AxiosPromise<PromotionPageDto> {
            return localVarFp.promotionsControllerGetPublishedPromotionPageBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * Get published promotions with filters and pagination
         * @summary Get promotions
         * @param {number} [perPage] Number of items per page
         * @param {number} [page] Page number
         * @param {string} [category] Filter promotions by a category (by its slug)
         * @param {boolean} [archived] Display either active or archived promotions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPublishedPromotions(perPage?: number, page?: number, category?: string, archived?: boolean, options?: any): AxiosPromise<PaginatedPromotionsDto> {
            return localVarFp.promotionsControllerGetPublishedPromotions(perPage, page, category, archived, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsApi - interface
 * @export
 * @interface PromotionsApi
 */
export interface PromotionsApiInterface {
    /**
     * 
     * @summary Get promotion categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    promotionsControllerGetPromotionCategories(options?: RawAxiosRequestConfig): AxiosPromise<Array<PromotionCategoryDto>>;

    /**
     * 
     * @summary Get published maintenance recommendations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    promotionsControllerGetPublishedMaintenanceRecommendations(options?: RawAxiosRequestConfig): AxiosPromise<Array<MaintenanceRecommendationDto>>;

    /**
     * 
     * @summary Get a published promotion page content by its slug
     * @param {string} slug Promotion slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    promotionsControllerGetPublishedPromotionPageBySlug(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<PromotionPageDto>;

    /**
     * Get published promotions with filters and pagination
     * @summary Get promotions
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {string} [category] Filter promotions by a category (by its slug)
     * @param {boolean} [archived] Display either active or archived promotions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApiInterface
     */
    promotionsControllerGetPublishedPromotions(perPage?: number, page?: number, category?: string, archived?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedPromotionsDto>;

}

/**
 * PromotionsApi - object-oriented interface
 * @export
 * @class PromotionsApi
 * @extends {BaseAPI}
 */
export class PromotionsApi extends BaseAPI implements PromotionsApiInterface {
    /**
     * 
     * @summary Get promotion categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerGetPromotionCategories(options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerGetPromotionCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get published maintenance recommendations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerGetPublishedMaintenanceRecommendations(options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerGetPublishedMaintenanceRecommendations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a published promotion page content by its slug
     * @param {string} slug Promotion slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerGetPublishedPromotionPageBySlug(slug: string, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerGetPublishedPromotionPageBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get published promotions with filters and pagination
     * @summary Get promotions
     * @param {number} [perPage] Number of items per page
     * @param {number} [page] Page number
     * @param {string} [category] Filter promotions by a category (by its slug)
     * @param {boolean} [archived] Display either active or archived promotions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsApi
     */
    public promotionsControllerGetPublishedPromotions(perPage?: number, page?: number, category?: string, archived?: boolean, options?: RawAxiosRequestConfig) {
        return PromotionsApiFp(this.configuration).promotionsControllerGetPublishedPromotions(perPage, page, category, archived, options).then((request) => request(this.axios, this.basePath));
    }
}

