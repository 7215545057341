import { Helmet } from 'react-helmet';
import { FC } from 'react';

interface OwnProps {
  description: string;
}

export const MetaDescription: FC<OwnProps> = function MetaDescription(props) {
  return (
    <Helmet>
      <meta name={'description'} content={props.description} />
      <meta name={'og:description'} content={props.description} />
    </Helmet>
  );
};
