import { ReactComponent as Location } from 'assets/svg/location.svg';
import { ReactComponent as Pin } from 'assets/svg/pin.svg';
import { ReactComponent as Profile } from 'assets/svg/profile.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { ReactComponent as Cross } from 'assets/svg/cross.svg';
import { ReactComponent as Services } from 'assets/svg/services.svg';
import { useAuth } from 'context/AuthContext';
import { AuthModals } from '../../../config/signModals';
import { Close, Content, Overlay, Portal, Root, Trigger } from '@radix-ui/react-dialog';
import { SignModals } from '../../modal/SignModals';
import React, { useState } from 'react';
import classes from './burger-menu.module.css';
import { UTMLink } from '../../base/UTMLink/UTMLink';
import { useGeo } from '../../../context/GeoProvider';

const navLinks = [
  {
    label: 'Сервисные станции',
    address: '/map/',
  },
  {
    label: 'Запасные части',
    address: '/spare-parts/',
  },
  {
    label: 'Новости',
    address: '/news/',
  },
  {
    label: 'Франшиза',
    address: '/franchise/',
  },
  // todo: hid promo
  // {
  //   label: 'Акции',
  //   address: '/promo',
  // },
  // todo: hid electric-charger
  // {
  //   label: 'Электрозаправки',
  //   address: '/map?stations=electric-charger',
  // },
  // todo: hid additional-services
  // {
  //   label: 'Дополнительные услуги',
  //   address: '/additional-services',
  // },
  // todo: hid about
  // {
  //   label: 'О компании',
  //   address: '/about',
  // },
];

export const BurgerMenu = ({ trigger }: { open?: boolean; trigger: JSX.Element }) => {
  const { setModal, city } = useGeo();
  // todo: hid caready phone
  // const phone = '+7 (657) 685-48-56';
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const onCloseMenu = () => {
    setOpen(false);
  };

  return (
    <Root open={open} onOpenChange={open => setOpen(open)}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Overlay className={classes.overlay}>
          <Content className={classes.wrap}>
            <div className={classes.menuHeader}>
              {/*todo: hid caready phone*/}
              {/*<a href={`tel:+7 (657) 685-48-56`} className={`${classes.phone} h6`}>*/}
              {/*  {phone}*/}
              {/*</a>*/}
              <div></div>
              <Close asChild>
                <button className={classes.close}>
                  <Cross />
                </button>
              </Close>
            </div>
            <div className={classes.menuMain}>
              <div className={classes.groupButtons}>
                <button
                  className={`${classes.button} btn black secondary`}
                  onClick={() => setModal('select-city')}
                >
                  <Location /> {city}
                </button>
                {user ? (
                  <UTMLink to={'/profile/'} className={'btn black secondary'} onClick={onCloseMenu}>
                    <Profile /> Профиль
                  </UTMLink>
                ) : (
                  <SignModals
                    trigger={
                      <button className="btn black secondary">
                        <Profile />
                        Войти
                      </button>
                    }
                    modalName={AuthModals.login}
                  />
                )}
              </div>
              <div className={classes.servicesButtons}>
                <UTMLink
                  to={'/services/'}
                  className={`${classes.servicesButton} btn`}
                  onClick={onCloseMenu}
                >
                  <Services /> Выбрать услугу
                </UTMLink>
                <UTMLink
                  to={'/map/'}
                  className={`${classes.servicesButton} btn black secondary`}
                  onClick={onCloseMenu}
                >
                  <Pin /> Найти сервис
                </UTMLink>
              </div>
              <nav className={classes.navigation}>
                <>
                  {/* <button
                    className={`${classes.navButton} h6`}
                    onClick={() => setModal('trade-in')}
                  >
                    Trade-in{' '}
                    <div className={classes.buttonIcon}>
                      <ArrowRight />
                    </div>
                  </button> */}
                  {navLinks.map(link => (
                    <UTMLink
                      className={`${classes.navButton} h6`}
                      to={link.address}
                      key={link.label}
                      onClick={onCloseMenu}
                    >
                      {link.label}
                      <div className={classes.buttonIcon}>
                        <ArrowRight />
                      </div>
                    </UTMLink>
                  ))}
                </>
              </nav>
            </div>
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
