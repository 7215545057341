import { ReactComponent as CrossMedium } from 'assets/svg/cross-medium.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import s from './Search.module.scss';
import { FC, PropsWithChildren } from 'react';

interface OwnProps {
  clear?: () => void;
  isInHeader?: boolean;
  isFilled: boolean;
}

export const Search: FC<PropsWithChildren<OwnProps>> = function Search(props) {
  return (
    <div className={`${s.label} ${props.isInHeader ? s.labelInHeader : ''}`}>
      <SearchIcon />
      {props.children}
      {props.isFilled && (
        <span className={s.clear} onClick={props.clear}>
          <CrossMedium />
        </span>
      )}
    </div>
  );
};
