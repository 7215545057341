import { AUTH_TOKEN_KEY } from '../../../../config/auth';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { CodeInput } from 'ui/base/Input/Input';
import { ModalClose } from '../../../base/ModalBase';
import { formatTime } from '../../../../utils/formatTimer';
import { useAuth } from 'context/AuthContext';
import { useTimer } from '../../../../hooks/useTimer';
import React, { FC, useEffect, useState } from 'react';
import classes from './send-code-modal.module.css';
import { AuthApi } from '../../../../api';

interface OwnProps {
  toLogin: () => void;
  toPrevStep: () => void;
  toNextStep: () => void;
  email: string;
}

export const SendCode: FC<OwnProps> = function SideNavigation({
  toLogin,
  toPrevStep,
  toNextStep,
  email,
}) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const { setUser, user, checkAuth } = useAuth();
  const { seconds, resetTimer } = useTimer(60);

  useEffect(() => {
    // todo: обработать ошибку
    AuthApi.authControllerSendCode({ notifyType: 'email' });
  }, []);

  const confirmCode = () => {
    const sendCode = code.replaceAll(/-/g, '');

    AuthApi.authControllerConfirmCode({ code: sendCode })
      .then(res => {
        user && setUser({ ...user, isEmailVerified: true });
        localStorage.setItem(AUTH_TOKEN_KEY, res.data.accessToken);
        checkAuth();
        toNextStep();
      })
      .catch(() => setError('Неверный код'));
  };

  const resendCode = () => {
    // todo: обработать ошибку
    AuthApi.authControllerSendCode({ notifyType: 'email' });
    resetTimer();
  };

  const isCodeCorrect = code.length === 7;

  return (
    <>
      <header className="modal_header">
        <div className={classes.titleBlock}>
          <button className="btn-text p3 gray" onClick={toPrevStep}>
            <ArrowLeft />
            Назад
          </button>
          <h4 className={`${classes.title} h4`}>Проверьте ваш Email</h4>
          <p className="p3">
            Мы отправили код на почту <span className="pm1">{email}</span>
          </p>
        </div>
        <ModalClose />
      </header>
      <div className={`${classes.form} modal_inner`}>
        <CodeInput name="code" setField={v => setCode(v)} error={error} />
      </div>
      <footer className={`${classes.footer} modal_footer`}>
        <div className={classes.textBlock}>
          <h6 className="h6">Уже есть учетная запись?</h6>
          <button className="btn-text orange" onClick={toLogin}>
            Войти
          </button>
        </div>
        {isCodeCorrect ? (
          <button className="btn" onClick={confirmCode}>
            Продолжить <ArrowRight />
          </button>
        ) : (
          <button
            className={`btn ${!seconds ? 'black secondary' : ''} ${
              seconds ? classes.sendButton : ''
            }`}
            type="button"
            disabled={!!seconds}
            onClick={resendCode}
          >
            {`Выслать код ${seconds ? `через ${formatTime(seconds)}` : ''}`}
          </button>
        )}
      </footer>
    </>
  );
};
