/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AuthControllerLogin403Response } from '../dtos';
// @ts-ignore
import type { AuthCredentialsDto } from '../dtos';
// @ts-ignore
import type { CreateUserAccountDto } from '../dtos';
// @ts-ignore
import type { IdentificationTokenDto } from '../dtos';
// @ts-ignore
import type { InitiateRecoveryAccessDto } from '../dtos';
// @ts-ignore
import type { JwtDto } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating401Response } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating403Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { RefreshTokenDto } from '../dtos';
// @ts-ignore
import type { SendCodeDto } from '../dtos';
// @ts-ignore
import type { UpdatePasswordDto } from '../dtos';
// @ts-ignore
import type { UserIdDto } from '../dtos';
// @ts-ignore
import type { VerifyCodeDto } from '../dtos';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm code
         * @param {VerifyCodeDto} verifyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerConfirmCode: async (verifyCodeDto: VerifyCodeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyCodeDto' is not null or undefined
            assertParamExists('authControllerConfirmCode', 'verifyCodeDto', verifyCodeDto)
            const localVarPath = `/auth/confirm-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Identification cookie are intended only for sending validation code.
         * @summary Create the new user and sets identification token in JSON Web Token format in Identification cookie
         * @param {CreateUserAccountDto} createUserAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateAccount: async (createUserAccountDto: CreateUserAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserAccountDto' is not null or undefined
            assertParamExists('authControllerCreateAccount', 'createUserAccountDto', createUserAccountDto)
            const localVarPath = `/auth/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End all sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEndAllSessions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/end-all-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get id of the authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * After successful authentication, the method returns an authorization token in JSON Web Token       format in the response body and a refresh token in the same format in the Refresh cookie.       The authorization token is valid for 1 hour, the refresh token is valid for 2 week.    If the user has not confirmed email, an error will be returned along with the identification cookie
         * @summary Authenticate user
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (authCredentialsDto: AuthCredentialsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authCredentialsDto' is not null or undefined
            assertParamExists('authControllerLogin', 'authCredentialsDto', authCredentialsDto)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method invalidates the current refresh token and resets the cookie value.
         * @summary Log out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method allows to get the new authorization tokens as long as the refresh token is valid.
         * @summary Refresh authorization token
         * @param {RefreshTokenDto} [refreshTokenDto] You must pass the refresh token in the body of the request or in the Refresh cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh: async (refreshTokenDto?: RefreshTokenDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookie required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate recovery access. Sets identification token in JSON Web Token format in Identification cookie
         * @param {InitiateRecoveryAccessDto} initiateRecoveryAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword: async (initiateRecoveryAccessDto: InitiateRecoveryAccessDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiateRecoveryAccessDto' is not null or undefined
            assertParamExists('authControllerResetPassword', 'initiateRecoveryAccessDto', initiateRecoveryAccessDto)
            const localVarPath = `/auth/recovery-access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiateRecoveryAccessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verification code lifetime is 5 minutes
         * @summary Sends code for creating account or recovery access
         * @param {SendCodeDto} sendCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSendCode: async (sendCodeDto: SendCodeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendCodeDto' is not null or undefined
            assertParamExists('authControllerSendCode', 'sendCodeDto', sendCodeDto)
            const localVarPath = `/auth/send-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates the user\'s password
         * @summary Update password
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdatePassword: async (updatePasswordDto: UpdatePasswordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordDto' is not null or undefined
            assertParamExists('authControllerUpdatePassword', 'updatePasswordDto', updatePasswordDto)
            const localVarPath = `/auth/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm code
         * @param {VerifyCodeDto} verifyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerConfirmCode(verifyCodeDto: VerifyCodeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerConfirmCode(verifyCodeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerConfirmCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Identification cookie are intended only for sending validation code.
         * @summary Create the new user and sets identification token in JSON Web Token format in Identification cookie
         * @param {CreateUserAccountDto} createUserAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCreateAccount(createUserAccountDto: CreateUserAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentificationTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCreateAccount(createUserAccountDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerCreateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary End all sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEndAllSessions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerEndAllSessions(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerEndAllSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get id of the authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserIdDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerGetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * After successful authentication, the method returns an authorization token in JSON Web Token       format in the response body and a refresh token in the same format in the Refresh cookie.       The authorization token is valid for 1 hour, the refresh token is valid for 2 week.    If the user has not confirmed email, an error will be returned along with the identification cookie
         * @summary Authenticate user
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(authCredentialsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This method invalidates the current refresh token and resets the cookie value.
         * @summary Log out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerLogout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This method allows to get the new authorization tokens as long as the refresh token is valid.
         * @summary Refresh authorization token
         * @param {RefreshTokenDto} [refreshTokenDto] You must pass the refresh token in the body of the request or in the Refresh cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefresh(refreshTokenDto?: RefreshTokenDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefresh(refreshTokenDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerRefresh']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Initiate recovery access. Sets identification token in JSON Web Token format in Identification cookie
         * @param {InitiateRecoveryAccessDto} initiateRecoveryAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPassword(initiateRecoveryAccessDto: InitiateRecoveryAccessDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentificationTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPassword(initiateRecoveryAccessDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verification code lifetime is 5 minutes
         * @summary Sends code for creating account or recovery access
         * @param {SendCodeDto} sendCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSendCode(sendCodeDto: SendCodeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSendCode(sendCodeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerSendCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This method updates the user\'s password
         * @summary Update password
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerUpdatePassword(updatePasswordDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authControllerUpdatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm code
         * @param {VerifyCodeDto} verifyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerConfirmCode(verifyCodeDto: VerifyCodeDto, options?: any): AxiosPromise<JwtDto> {
            return localVarFp.authControllerConfirmCode(verifyCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Identification cookie are intended only for sending validation code.
         * @summary Create the new user and sets identification token in JSON Web Token format in Identification cookie
         * @param {CreateUserAccountDto} createUserAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateAccount(createUserAccountDto: CreateUserAccountDto, options?: any): AxiosPromise<IdentificationTokenDto> {
            return localVarFp.authControllerCreateAccount(createUserAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End all sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEndAllSessions(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerEndAllSessions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get id of the authorized user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetProfile(options?: any): AxiosPromise<UserIdDto> {
            return localVarFp.authControllerGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * After successful authentication, the method returns an authorization token in JSON Web Token       format in the response body and a refresh token in the same format in the Refresh cookie.       The authorization token is valid for 1 hour, the refresh token is valid for 2 week.    If the user has not confirmed email, an error will be returned along with the identification cookie
         * @summary Authenticate user
         * @param {AuthCredentialsDto} authCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: any): AxiosPromise<JwtDto> {
            return localVarFp.authControllerLogin(authCredentialsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * This method invalidates the current refresh token and resets the cookie value.
         * @summary Log out
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * This method allows to get the new authorization tokens as long as the refresh token is valid.
         * @summary Refresh authorization token
         * @param {RefreshTokenDto} [refreshTokenDto] You must pass the refresh token in the body of the request or in the Refresh cookie
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(refreshTokenDto?: RefreshTokenDto, options?: any): AxiosPromise<JwtDto> {
            return localVarFp.authControllerRefresh(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate recovery access. Sets identification token in JSON Web Token format in Identification cookie
         * @param {InitiateRecoveryAccessDto} initiateRecoveryAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword(initiateRecoveryAccessDto: InitiateRecoveryAccessDto, options?: any): AxiosPromise<IdentificationTokenDto> {
            return localVarFp.authControllerResetPassword(initiateRecoveryAccessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Verification code lifetime is 5 minutes
         * @summary Sends code for creating account or recovery access
         * @param {SendCodeDto} sendCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSendCode(sendCodeDto: SendCodeDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerSendCode(sendCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates the user\'s password
         * @summary Update password
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerUpdatePassword(updatePasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * 
     * @summary Confirm code
     * @param {VerifyCodeDto} verifyCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerConfirmCode(verifyCodeDto: VerifyCodeDto, options?: RawAxiosRequestConfig): AxiosPromise<JwtDto>;

    /**
     * Identification cookie are intended only for sending validation code.
     * @summary Create the new user and sets identification token in JSON Web Token format in Identification cookie
     * @param {CreateUserAccountDto} createUserAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerCreateAccount(createUserAccountDto: CreateUserAccountDto, options?: RawAxiosRequestConfig): AxiosPromise<IdentificationTokenDto>;

    /**
     * 
     * @summary End all sessions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerEndAllSessions(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get id of the authorized user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerGetProfile(options?: RawAxiosRequestConfig): AxiosPromise<UserIdDto>;

    /**
     * After successful authentication, the method returns an authorization token in JSON Web Token       format in the response body and a refresh token in the same format in the Refresh cookie.       The authorization token is valid for 1 hour, the refresh token is valid for 2 week.    If the user has not confirmed email, an error will be returned along with the identification cookie
     * @summary Authenticate user
     * @param {AuthCredentialsDto} authCredentialsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: RawAxiosRequestConfig): AxiosPromise<JwtDto>;

    /**
     * This method invalidates the current refresh token and resets the cookie value.
     * @summary Log out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerLogout(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * This method allows to get the new authorization tokens as long as the refresh token is valid.
     * @summary Refresh authorization token
     * @param {RefreshTokenDto} [refreshTokenDto] You must pass the refresh token in the body of the request or in the Refresh cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerRefresh(refreshTokenDto?: RefreshTokenDto, options?: RawAxiosRequestConfig): AxiosPromise<JwtDto>;

    /**
     * 
     * @summary Initiate recovery access. Sets identification token in JSON Web Token format in Identification cookie
     * @param {InitiateRecoveryAccessDto} initiateRecoveryAccessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerResetPassword(initiateRecoveryAccessDto: InitiateRecoveryAccessDto, options?: RawAxiosRequestConfig): AxiosPromise<IdentificationTokenDto>;

    /**
     * Verification code lifetime is 5 minutes
     * @summary Sends code for creating account or recovery access
     * @param {SendCodeDto} sendCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerSendCode(sendCodeDto: SendCodeDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * This method updates the user\'s password
     * @summary Update password
     * @param {UpdatePasswordDto} updatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * 
     * @summary Confirm code
     * @param {VerifyCodeDto} verifyCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerConfirmCode(verifyCodeDto: VerifyCodeDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerConfirmCode(verifyCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Identification cookie are intended only for sending validation code.
     * @summary Create the new user and sets identification token in JSON Web Token format in Identification cookie
     * @param {CreateUserAccountDto} createUserAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCreateAccount(createUserAccountDto: CreateUserAccountDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCreateAccount(createUserAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End all sessions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerEndAllSessions(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerEndAllSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get id of the authorized user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGetProfile(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * After successful authentication, the method returns an authorization token in JSON Web Token       format in the response body and a refresh token in the same format in the Refresh cookie.       The authorization token is valid for 1 hour, the refresh token is valid for 2 week.    If the user has not confirmed email, an error will be returned along with the identification cookie
     * @summary Authenticate user
     * @param {AuthCredentialsDto} authCredentialsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(authCredentialsDto: AuthCredentialsDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(authCredentialsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method invalidates the current refresh token and resets the cookie value.
     * @summary Log out
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogout(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method allows to get the new authorization tokens as long as the refresh token is valid.
     * @summary Refresh authorization token
     * @param {RefreshTokenDto} [refreshTokenDto] You must pass the refresh token in the body of the request or in the Refresh cookie
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRefresh(refreshTokenDto?: RefreshTokenDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRefresh(refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate recovery access. Sets identification token in JSON Web Token format in Identification cookie
     * @param {InitiateRecoveryAccessDto} initiateRecoveryAccessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResetPassword(initiateRecoveryAccessDto: InitiateRecoveryAccessDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResetPassword(initiateRecoveryAccessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verification code lifetime is 5 minutes
     * @summary Sends code for creating account or recovery access
     * @param {SendCodeDto} sendCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSendCode(sendCodeDto: SendCodeDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSendCode(sendCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates the user\'s password
     * @summary Update password
     * @param {UpdatePasswordDto} updatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerUpdatePassword(updatePasswordDto: UpdatePasswordDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerUpdatePassword(updatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}

