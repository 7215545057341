import classes from './section-header.module.css';
import { UTMLink } from '../UTMLink/UTMLink';

export const SectionHeader = ({ title, link }: { title: string; link?: string }) => (
  <header className={classes.header}>
    <h2 className={'h3'}>{title}</h2>
    {link && (
      <UTMLink className={'btn secondary black'} to={link}>
        Посмотреть&nbsp;все
      </UTMLink>
    )}
  </header>
);
