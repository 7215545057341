import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { Banner } from '../../ui/components/Banner/Banner';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CoreHead } from '../../ui/core';
import { usePromotions, usePromotionsCategories } from '../../hooks/usePromotions';
import { useState } from 'react';
import s from './PromoList.module.scss';
import commonStyles from '../../styles/common/common-styles.module.scss';

export const PromoListPage = function PromoListPage() {
  const categories = usePromotionsCategories();
  const breadCrumbs = [
    BREAD_CRUMB_MAIN,
    {
      name: 'Акции',
    },
  ];

  const [activeTab, setActiveTab] = useState('');
  const promotions = usePromotions(
    1,
    undefined,
    activeTab === 'archived' ? '' : activeTab,
    activeTab === 'archived'
  );

  return (
    <>
      <CoreHead
        title={'Акции'}
        description={
          'Ознакомьтесь с актуальными акциями и специальными предложениями Caready. Не упустите возможность сэкономить и получить качественное обслуживание для вашего автомобиля!'
        }
      />
      <div className={'section'}>
        <BreadCrumbs links={breadCrumbs} />
        <h1 className={`h2 ${s.title}`}>Акции</h1>
        <div className={s.tabsWrapper}>
          <div className={s.tabs}>
            <button
              onClick={() => setActiveTab('')}
              className={`btn tab ${activeTab === '' ? '' : 'secondary black'}`}
            >
              Все
            </button>
            {categories.map(button => (
              <button
                key={button.slug}
                onClick={() => setActiveTab(button.slug)}
                className={`btn tab ${activeTab === button.slug ? '' : 'secondary black'}`}
              >
                {button.name}
              </button>
            ))}
            <button
              onClick={() => setActiveTab('archived')}
              className={`btn tab ${activeTab === 'archived' ? '' : 'secondary black'}`}
            >
              Архивные
            </button>
          </div>
        </div>
        {promotions.length ? (
          <div className={s.bannersGroup}>
            {promotions.map((banner, idx) => (
              <Banner key={idx} category={banner} />
            ))}
          </div>
        ) : (
          <p className={commonStyles.noData}>На данный момент нет акций из выбранной категории</p>
        )}
      </div>
    </>
  );
};
