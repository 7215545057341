import { Appointment } from '../../../../../ui/modal/Appointment';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Banner } from '../../../../../ui/components/Banner/Banner';
import { ReactComponent as Info } from 'assets/svg/info.svg';
import { MapComponent } from '../../../../../ui/components/Map/MapComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { WYSIWYG } from '../../../../../ui/base/WYSIWYG';
import { useModal } from '../../../../../hooks/useModal';
import { usePromotions } from '../../../../../hooks/usePromotions';
import s from './ServicePage.module.scss';
import { SlugWrapper } from '../../../../../ui/components/SlugWrapper/SlugWrapper';
import { BREAD_CRUMB_MAIN } from '../../../../../config/breadCrumbMain';
import { BreadCrumbs } from '../../../../../ui/components/BreadCrumbs';
import { FC } from 'react';
import clsx from 'clsx';
import { MaintenanceServicesApi } from '../../../../../api';
import { MaintenanceServicePageDto } from '../../../../../api/generated/caready';
import { MetaDescription } from '../../../../../ui/base/MetaTags/MetaDescription';
import { MapProvider } from '../../../../../ui/components/Map/context/MapProvider';

const PageTitle: FC<MaintenanceServicePageDto> = function PageTitle(props) {
  const breadCrumbs = props
    ? [
        BREAD_CRUMB_MAIN,
        {
          name: 'Услуги',
          path: '/services/',
        },
        {
          name: props.category.name,
          path: `/services/${props.category.slug}/`,
        },
        {
          name: props.name,
        },
      ]
    : [];

  return (
    <>
      <MetaDescription
        description={`Записаться в сервис на услугу ${props.name} по цене от ${props.minPriceRub} рублей на сервисные станции Caready`}
      />
      <BreadCrumbs links={breadCrumbs} />
      <h1 className={clsx('h2', s.mainTitle)}>{props.name}</h1>
      <div className={s.priceBlock}>
        <h3 className={'h3'}>
          от {props.minPriceRub} <span className="ruble_back" />
        </h3>
        {!!props.bonusPoints && (
          <p className={clsx(s.bonus, 'p2')}>+{props.bonusPoints} баллов по программе Лояльности</p>
        )}
      </div>
      <div className={s.info}>
        <Info />
        <p className="p2">Цена может варьироваться в зависимости от модели автомобиля</p>
      </div>
    </>
  );
};

const PageContent: FC<MaintenanceServicePageDto> = function PageContent(props) {
  const { isShowing, toggleModal } = useModal();
  const promos = usePromotions(1, 4);

  return (
    <>
      <div className={s.mainBlock}>
        <img className={s.SideImg} src={props.iconUrl} />
        <div className={s.pageContent}>
          <WYSIWYG html={props.pageContent} />
          <button className={'btn'} onClick={toggleModal}>
            Записаться на сервис
          </button>
        </div>
        <div className={clsx('promo-swiper', s.promosBlock)}>
          <div className={s.swiperBlock}>
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              navigation={{
                prevEl: '.promo-swiper .prev',
                nextEl: '.promo-swiper .next',
              }}
              pagination={{
                clickable: true,
                el: '.promo-swiper .pagination',
              }}
              modules={[Pagination, Navigation, Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loop
            >
              {promos.length > 0 ? (
                promos.map(promo => (
                  <SwiperSlide key={promo.id}>
                    <Banner key={promo.headline} category={promo} />
                  </SwiperSlide>
                ))
              ) : (
                <div className={s.emptyPromos} />
              )}
            </Swiper>
            <div className={`navigation ${s.navigation}`}>
              <button className={'prev'} />
              <button className={'next'} />
            </div>
          </div>
          <div className={clsx('pagination', s.pagination)} />
        </div>
        <div className={s.promosContainer}>
          {promos.length > 0 ? (
            promos.map(promo => <Banner key={promo.headline} category={promo} />)
          ) : (
            <div className={s.emptyPromos} />
          )}
        </div>
      </div>
      <div className={s.mapSection}>
        <MapProvider serviceCategories={[props.category.slug]}>
          <MapComponent />
        </MapProvider>
      </div>
      <Appointment isOpen={isShowing} appointmentService={props} onCloseButtonClick={toggleModal} />
    </>
  );
};

export const ServicePage = function ServicePage() {
  const getMaintenanceServicePageBySlug = async (
    slug: string
  ): Promise<MaintenanceServicePageDto> => {
    const { data } =
      await MaintenanceServicesApi.maintenanceServicesControllerGetServicePageBySlug(slug);
    return data;
  };

  return (
    <SlugWrapper<MaintenanceServicePageDto, 'name'>
      request={getMaintenanceServicePageBySlug}
      PageContent={PageContent}
      PageTitle={PageTitle}
      titleName={'name'}
    />
  );
};
