import { useEffect, useState } from 'react';
import {
  MaintenanceServiceCategoryDto,
  MaintenanceServiceCategoryTreeDto,
  MaintenanceServiceDto,
} from '../api/generated/caready';
import { MaintenanceServicesApi } from '../api';
import { useQuery } from '@tanstack/react-query';
import { UseQueryOptionsParam } from '../types/UseQueryOptionsParam';

export const useMaintenanceServicesCategories = (
  options?: UseQueryOptionsParam<MaintenanceServiceCategoryDto[]>
) => {
  return useQuery({
    queryKey: ['maintenance', 'categories'],
    queryFn: async () => {
      const { data } = await MaintenanceServicesApi.maintenanceServicesControllerGetAllCategories();

      return data;
    },
    staleTime: Infinity,
    ...options,
  });
};

export const useMaintenanceServicesTree = (servicesStations?: MaintenanceServiceDto[]) => {
  const [categories, setCategories] = useState<MaintenanceServiceCategoryTreeDto[]>([]);

  useEffect(() => {
    MaintenanceServicesApi.maintenanceServicesControllerGetServicesTree().then(({ data }) => {
      setCategories(
        servicesStations
          ? data
              .map(c => ({
                ...c,
                subcategories: c.subcategories
                  .map(sc => ({
                    ...sc,
                    services: sc.services.filter(
                      s => !!servicesStations.find(ss => ss.slug === s.slug)
                    ),
                  }))
                  .filter(sc => !!sc.services.length),
              }))
              .filter(c => !!c.subcategories.length)
          : data.filter(s => !!s.servicesNumber)
      );
    });
  }, []);

  return categories;
};
