import { FC, useState } from 'react';
import { ServiceStationList } from '../../../components/servicesStationList/ServiceStationList';
import { MapSearchHeader } from '../../../components/mapSearchHeader/MapSearchHeader';
import { ReactComponent as ChevronDown } from '../../../../../../assets/svg/chevron-down.svg';
import { FilterList } from '../../../components/filterList/FilterList';
import s from './MapSearch.module.scss';
import clsx from 'clsx';
import { FiltersButton } from '../../../components/mapFilters/FiltersButton';
import { Search } from '../../../../Search/Search';
import { useMap } from '../../../context/MapProvider';

export const MapSearch: FC = function MapSearch() {
  const { address, setAddress, serviceStations, setSelectedServiceStation } = useMap();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const toggleFilter = () => {
    setIsFiltersOpen(prevValue => !prevValue);
  };

  return (
    <>
      <MapSearchHeader />
      <Search clear={() => setAddress('')} isFilled={!!address}>
        <input
          placeholder={'Введите адрес или город'}
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
      </Search>
      <div className={clsx(s.filterButton, isFiltersOpen && s.filterButtonOpen)}>
        <FiltersButton toggleFilter={toggleFilter} />
        <span className={s.arrow} onClick={toggleFilter}>
          <ChevronDown />
        </span>
      </div>
      <div className={s.list}>
        {isFiltersOpen && <FilterList />}
        <ServiceStationList
          serviceStations={serviceStations}
          setSelectedServiceStation={setSelectedServiceStation}
        />
      </div>
    </>
  );
};
