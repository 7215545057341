import { Footer } from '../components/Footer';
import { Header } from '../components/Header/Header';
import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { ScrollToTop } from '../components/ScrollToTop/ScrollToTop';
import { Mindbox } from '../components/Mindbox/Mindbox';

interface OwnProps {
  className?: string;
}

export const MainLayout: FC<OwnProps & PropsWithChildren> = function MainLayout(props) {
  return (
    <>
      <ScrollToTop />
      <Mindbox />
      <div className={props.className}>
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
};
