import { AuthProvider } from '../../context/AuthContext';
import { GeoProvider } from '../../context/GeoProvider';
import { YMaps } from '@pbe/react-yandex-maps';
import { IndexHtmlConfig } from '../../indexHtmlConfig';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet } from 'react-router-dom';

const queryClient = new QueryClient();

export const RootLayout = function RootLayout() {
  return (
    <YMaps
      query={{
        // todo: move to envs
        apikey: 'f9c27c8e-002c-4b8f-8c93-a2ec84ae5898',
        lang: 'ru_RU',
      }}
    >
      <IndexHtmlConfig />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GeoProvider>
            <Outlet />
          </GeoProvider>
        </AuthProvider>
      </QueryClientProvider>
    </YMaps>
  );
};
