export interface UtmData {
  city: string | null;
  slugs: string[];
}

export const getDataFromUTM = (search: string): UtmData | null => {
  const searchParams = new URLSearchParams(search);
  const utm: string[] = [];

  for (const [key, value] of searchParams.entries()) {
    if (value) continue;

    utm.push(key);

    if (utm.length >= 3) break;
  }

  if (!utm.length) return null;

  return {
    city: utm[0] || null,
    slugs: utm.slice(1),
  };
};
