import { Logo } from '../Logo';
import classes from './footer.module.css';
import { UTMLink } from '../../base/UTMLink/UTMLink';
import { prettifyPhone } from '../../../utils/prettifyPhone';

const clientsNav = [
  // todo: hid promo
  // {
  //   address: '/promo',
  //   label: 'Акции',
  // },
  // todo: hid electric-charger
  // {
  //   address: '/map?stations=service-station',
  //   label: 'Сервисные центры',
  // },
  // {
  //   address: '/map?stations=electric-charger',
  //   label: 'Электрозаправки',
  // },
  {
    address: '/map/',
    label: 'Сервисные станции',
  },
  // todo: hid additional-services
  // {
  //   address: '/additional-services',
  //   label: 'Дополнительные услуги',
  // },
  // todo: hid about
  // {
  //   address: '/about',
  //   label: 'О компании',
  // },
  {
    address: '/news/',
    label: 'Новости',
  },
];

const currentYear = new Date().getFullYear();

const careadyPhoneNumber = '+78001010177';

const documents = [
  {
    text: 'Политика конфиденциальности',
    to: '/policy/',
  },
  {
    text: 'Согласия на обработку персональных данных',
    to: '/personal-data-agreement/',
  },
  {
    text: 'Условия использования',
    to: '/rules-using-official-site/',
  },
  {
    text: 'Гарантийная политика для оптовых клиентов',
    to: '/guarantee-policy/',
  },
];

export const Footer = () => {
  // todo: hid subscription
  // const { formik, sent, back } = useSubscribe('NEWSLETTER');

  // const { setModal } = useContext(CareadyContext);

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.footerMain}>
          <Logo className={classes.logo} />
          <nav className={classes.navBlocks}>
            <div className={classes.navBlock}>
              <p className="p2">Для клиентов</p>
              <UTMLink className={'h6'} to={'/services/'}>
                Услуги
              </UTMLink>
              {/* <button className="h6" onClick={() => setModal('trade-in')}>
                Trade-in
              </button> */}
              {clientsNav.map((link, idx) => (
                <UTMLink key={idx} to={link.address} className={'h6'}>
                  {link.label}
                </UTMLink>
              ))}
            </div>
            <div className={classes.navBlock}>
              <p className="p2">Для партнеров</p>
              <UTMLink className={'h6'} to={'/franchise/'}>
                Франшиза
              </UTMLink>
            </div>
            <div className={`${classes.navBlock} ${classes.phone}`}>
              <p className="p2">Горячая линия Caready</p>
              <a className="h6" href={`tel:${careadyPhoneNumber}`}>
                {prettifyPhone(careadyPhoneNumber)}
              </a>
            </div>
          </nav>
          {/*todo: hid subscription*/}
          {/*<div className={classes.subscribeCard}>*/}
          {/*  <h5 className="h5">*/}
          {/*    {!!sent*/}
          {/*      ? 'Спасибо за подписку!'*/}
          {/*      : sent === false*/}
          {/*      ? 'Упс!'*/}
          {/*      : 'Подпишитесь на рассылку'}*/}
          {/*  </h5>*/}
          {/*  {sent ? (*/}
          {/*    <div className={classes.succesBlock}>*/}
          {/*      <p className="p2">*/}
          {/*        Теперь вы будете получать самые интересные предложения первыми.*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  ) : sent === false ? (*/}
          {/*    <div className={classes.errorBlock}>*/}
          {/*      <p className="p2">Возникла ошибка. Попробуйте, пожалуйста, еще раз.</p>*/}
          {/*      <button className="btn block" onClick={back}>*/}
          {/*        Попробовать еще раз*/}
          {/*      </button>*/}
          {/*    </div>*/}
          {/*  ) : (*/}
          {/*    <>*/}
          {/*      <form className={classes.subscribeForm} onSubmit={formik.handleSubmit} noValidate>*/}
          {/*        <div className={classes.inputWrap}>*/}
          {/*          <Input error={formik.errors.email} touched={formik.touched.email}>*/}
          {/*            <input*/}
          {/*              type="email"*/}
          {/*              name="email"*/}
          {/*              value={formik.values.email}*/}
          {/*              onChange={formik.handleChange}*/}
          {/*              placeholder="E-mail"*/}
          {/*            />*/}
          {/*          </Input>*/}
          {/*          <ButtonIcon color="orange" />*/}
          {/*        </div>*/}
          {/*        <button type="submit" className="btn">*/}
          {/*          Подписаться*/}
          {/*        </button>*/}
          {/*      </form>*/}
          {/*      <p className="p3">*/}
          {/*        Нажимая на кнопку вы соглашаетесь с <a>политикой конфиденциальности</a>*/}
          {/*      </p>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*</div>*/}
          {/*todo: hid vk and tg*/}
          {/*<div className={classes.socials}>*/}
          {/*  <a href={env.VK_LINK} target="_blank" className={classes.socialLink}>*/}
          {/*    <VK />*/}
          {/*  </a>*/}
          {/*  <a href={env.TG_LINK} target="_blank" className={classes.socialLink}>*/}
          {/*    <Telegram />*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
        <div className={classes.bottom}>
          <div className={classes.legalInfo}>
            <p className="p2">CAREADY© 2012 - {currentYear}</p>
            <div className={classes.legals}>
              {documents.map((it, index) => (
                <UTMLink className={'p2'} to={it.to} key={index}>
                  {it.text}
                </UTMLink>
              ))}
            </div>
          </div>
          {/*todo: hid vk and tg*/}
          {/*<div className={classes.socials}>*/}
          {/*  <a href={env.VK_LINK} target="_blank" className={classes.socialLink}>*/}
          {/*    <VK />*/}
          {/*  </a>*/}
          {/*  <a href={env.TG_LINK} target="_blank" className={classes.socialLink}>*/}
          {/*    <Telegram />*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
      </div>
    </footer>
  );
};
