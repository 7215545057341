import { AddNewCarModal } from '../../ui/modal/AddNewCar';
import { Appointment } from '../../ui/modal/Appointment';
import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CoreHead } from '../../ui/core';
import { ReactComponent as Delete } from 'assets/svg/delete.svg';
import { DeleteCarModal } from '../../ui/modal/DeleteCar';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { MaintenanceHistory } from 'ui/components/MaintenanceHistory';
import { ReactComponent as PlusStrong } from 'assets/svg/plus-strong.svg';
import { PrimaryService } from '../../ui/components/ServiceCard/PrimaryService';
import { useCarById, useGarageCars } from 'hooks/useGarage';
import { useEffect, useState } from 'react';
import { useMaintenanceHistoryByCarId } from 'hooks/useMaintenanceHistory';
import { useMaintenanceServicesCategories } from '../../hooks/useMaintenanceServices';
import { useModalByName } from '../../hooks/useModal';
import { useRecommendationsPromo } from '../../hooks/usePromotions';
import NoImageCar from 'assets/img/my-cars/no-image-car.svg';
import NoImageMiniCar from 'assets/img/my-cars/no-image-mini-car.svg';
import classes from './my-cars.module.css';
import { MaintenanceServiceDto } from '../../api/generated/caready';
import { UTMLink } from '../../ui/base/UTMLink/UTMLink';

export default function MyCarsPage() {
  const [selectPromo, setSelectPromo] = useState<MaintenanceServiceDto | undefined>();
  const { modal, changeModal, closeModal } = useModalByName();

  const { id } = useParams();
  const cars = useGarageCars(!!modal);
  const recommendations = useRecommendationsPromo();
  const histories = useMaintenanceHistoryByCarId(id);
  const carInfo = useCarById(id, !!modal);
  const { data } = useMaintenanceServicesCategories();
  const navigate = useNavigate();

  useEffect(() => {
    if (carInfo === null) {
      navigate(`/profile/my-cars/${cars[0] ? `${cars[0].id}/` : ''}`);
    }
  }, [carInfo, cars]);

  return (
    <>
      <CoreHead title="Мои автомобили" />
      <div className="section">
        <BreadCrumbs
          links={[
            BREAD_CRUMB_MAIN,
            { name: 'Профиль', path: '/profile/' },
            { name: 'Мои автомобили' },
          ]}
        />
        {cars.length > 0 ? (
          <div className={classes.mini_cars_wrap}>
            <div className={classes.mini_cars}>
              {cars &&
                cars.map(car => (
                  <UTMLink
                    className={`${classes.button} ${car.id === id && classes.active}`}
                    to={`/profile/my-cars/${car.id}`}
                    key={car.id}
                  >
                    <picture className={`${classes.pic_mini}`}>
                      <img src={car.photoUrl ? car.photoUrl : NoImageMiniCar} />
                    </picture>
                    <div className={classes.mini_text}>
                      <p className="pm1">
                        {car.model.brand.name} {car.model.name}
                      </p>
                      <p className="p4">{car.licensePlateNumber}</p>
                    </div>
                  </UTMLink>
                ))}
            </div>
            <button className={classes.add_mini} onClick={() => changeModal('add')}>
              <PlusStrong />
              <p className="h6">Добавить</p>
            </button>
          </div>
        ) : (
          <div className={classes.noCarsBlock}>
            <div className={classes.noCarsTextBlock}>
              <h1 className="h2">Мои автомобили</h1>
              <p>Вы ещё не добавили ни один автомобиль</p>
            </div>
            <button className={classes.add_mini} onClick={() => changeModal('add')}>
              <PlusStrong />
              <p className="h6">Добавить</p>
            </button>
          </div>
        )}
        {carInfo && (
          <div className={classes.carsWrap}>
            <div className={classes.cars}>
              <picture className={classes.cars_pic}>
                <img src={carInfo.photoUrl ? carInfo.photoUrl : NoImageCar} />
              </picture>
              <div className={classes.cars_main}>
                <div className={classes.mainInfo}>
                  <h1 className="h2">
                    {carInfo.model.brand.name} {carInfo.model.name}
                  </h1>
                  <div className={classes.cars_text}>
                    {carInfo.modelModification && (
                      <p className="p2">
                        {carInfo.modelModification.name}{' '}
                        {carInfo.modelModification.bodyTypes
                          .map(bodyType => bodyType.name)
                          .join(', ')}{' '}
                        ({carInfo.modelModification.generation.fromYear}-
                        {carInfo.modelModification.generation.toYear ?? 'нв'})
                      </p>
                    )}
                    {carInfo.vin && (
                      <div className={classes.cars_personal}>
                        <p className="p2">VIN:</p>
                        <p className="p2">{carInfo.vin}</p>
                      </div>
                    )}
                    {carInfo.licensePlateNumber && (
                      <div className={classes.cars_personal}>
                        <p className="p2">Гос.номер:</p>
                        <p className="p2">{carInfo.licensePlateNumber}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.groupButtons}>
                  <button className="btn" onClick={() => changeModal('appointment')}>
                    <p className="h6">Записаться на сервис</p>
                  </button>
                  <div className={classes.cars_edit}>
                    <button
                      className={classes.cars_edit_button}
                      onClick={() => changeModal('edit')}
                    >
                      <Edit />
                    </button>
                    <button
                      className={classes.cars_delete_button}
                      onClick={() => changeModal('delete')}
                    >
                      <Delete />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {cars.length > 0 && histories.length > 0 && (
          <div className={classes.maintenance_history}>
            <div className={classes.history_header}>
              <h2 className="h4">История обслуживания</h2>
              <UTMLink
                to={`/profile/maintenance-history/${carInfo?.id}`}
                className={`${classes.see_all} h6`}
              >
                Посмотреть все
              </UTMLink>
            </div>
            <div className={classes.history_main}>
              {histories.slice(0, 3).map((history, index) => (
                <MaintenanceHistory key={index} historyData={history} />
              ))}
            </div>
          </div>
        )}
        {recommendations.length > 0 && (
          <div className={classes.maintenance_recommendations}>
            <h2 className="h4">Рекомендации</h2>
            <div className={classes.recommendation_main}>
              {recommendations.map(promo => (
                <div
                  className={`${classes.recommendation_block}`}
                  style={{ backgroundColor: promo.backgroundColor ?? '#d60505' }}
                >
                  <div className={classes.recommendation_text}>
                    <h3 className="h5">{promo.headline}</h3>
                    {promo.briefDescription && <p className="p2">{promo.briefDescription}</p>}
                  </div>
                  <button
                    className={`${classes.recomendation_btn} h6`}
                    onClick={() => {
                      changeModal('appointment');
                      setSelectPromo(promo.promotedService);
                    }}
                  >
                    Записаться на сервис
                  </button>
                  <picture className={classes.recommendation_pic}>
                    <img src={promo.iconUrl} />
                  </picture>
                </div>
              ))}
            </div>
          </div>
        )}
        {cars.length > 0 && (
          <div className={classes.services}>
            <h2 className="h4">Услуги</h2>
            <div className={classes.services_main}>
              {data?.map((category, idx) => <PrimaryService category={category} key={idx} />)}
            </div>
          </div>
        )}
      </div>

      {(modal === 'add' || modal === 'edit') && (
        <AddNewCarModal
          carInfo={modal === 'edit' && carInfo ? carInfo : undefined}
          onCloseButtonClick={closeModal}
        />
      )}
      {modal === 'delete' && carInfo && (
        <DeleteCarModal
          carName={`${carInfo.model.brand.name} ${carInfo.model.name}`}
          carId={carInfo.id}
          onCloseButtonClick={closeModal}
        />
      )}

      {modal === 'appointment' && carInfo && (
        <Appointment
          car={carInfo}
          isOpen={modal === 'appointment' && !!carInfo}
          promotedServices={selectPromo ? [selectPromo] : undefined}
          onCloseButtonClick={() => {
            closeModal();
            setSelectPromo(undefined);
          }}
        />
      )}
    </>
  );
}
