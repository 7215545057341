import { useAuth } from '../../../context/AuthContext';
import { Form, Formik } from 'formik';
import { Input } from '../../base/Input';
import { ModalClose } from '../../base/ModalBase/ModalBase.v2';
import { PhoneNumber } from 'ui/base/Input/Input';
import { object } from 'yup';
import { UsersApi } from '../../../api';
import s from './ChangePhone.module.scss';
import { FC } from 'react';
import { phoneNumber } from '../../../config/validationSchemas';
import { ERROR_REQUIRED } from '../../../config/errorTexts';

interface OwnProps {
  closeModal: () => void;
  phone: string | null;
}

export const ChangePhone: FC<OwnProps> = function ChangePhone(props) {
  const { setUser, user } = useAuth();

  return (
    <>
      <header className={'modal_header'}>
        <h4 className={`${s.title} h4`}>Изменить телефон</h4>
        <ModalClose onCloseButtonClick={props.closeModal} />
      </header>
      <Formik
        initialValues={{ newPhoneNumber: user?.phoneNumber ?? '' }}
        validationSchema={object().shape({
          newPhoneNumber: phoneNumber.required(ERROR_REQUIRED),
        })}
        onSubmit={(values, formikHelpers) => {
          if (values.newPhoneNumber === props.phone) {
            props.closeModal();
          } else {
            UsersApi.usersControllerUpdatePhoneNumber({
              newPhoneNumber: values.newPhoneNumber,
            }).then(({ data }) => {
              if (data) {
                props.closeModal();
                setUser(data);
              } else {
                formikHelpers.setErrors({ newPhoneNumber: 'Ошибка' });
              }
            });
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {props => (
          <Form className={'modal_container'}>
            <div className={`${s.form} modal_inner`}>
              <Input
                label="Новый номер телефона"
                error={props.errors.newPhoneNumber}
                touched={props.touched.newPhoneNumber}
              >
                <PhoneNumber
                  name="newPhoneNumber"
                  setField={e => props.setFieldValue('newPhoneNumber', e)}
                />
              </Input>
            </div>
            <footer className={'modal_footer'}>
              <button
                className="btn"
                type="submit"
                disabled={!(props.values.newPhoneNumber && props.isValid)}
              >
                Сохранить
              </button>
            </footer>
          </Form>
        )}
      </Formik>
    </>
  );
};
