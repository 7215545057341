export enum AuthErrorEnum {
  USER_IS_NOT_ACTIVE = 'user_is_not_active',

  INVALID_REFRESH_TOKEN = 'invalid_refresh_token',

  USED_REFRESH_TOKEN = 'used_refresh_token',

  INVALID_ACCESS_TOKEN = 'invalid_access_token',

  EMAIL_IS_ALREADY_IN_USE = 'email_is_already_in_use',

  USER_NOT_FOUND = 'user_not_found',

  LOGIN_OR_PASSWORD_IS_WRONG = 'login_or_password_is_wrong',

  SOMETHING_WENT_WRONG = 'something_went_wrong',
}
