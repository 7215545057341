/* tslint:disable */
/* eslint-disable */
/**
 * CAREADY API
 * CAREADY API v1 documentation
 *
 * The version of the OpenAPI document: 2.5.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AddCarToGarageDto } from '../dtos';
// @ts-ignore
import type { EditCarInGarageDto } from '../dtos';
// @ts-ignore
import type { GarageCarDto } from '../dtos';
// @ts-ignore
import type { GarageControllerControllerAddCarToGarage409Response } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating401Response } from '../dtos';
// @ts-ignore
import type { MaintenanceAppointmentsControllerGetMaintenanceAppointmentRating403Response } from '../dtos';
// @ts-ignore
import type { MaintenanceHistoryItemDto } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews400Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNews429Response } from '../dtos';
// @ts-ignore
import type { NewsControllerGetPublishedNewsArticleBySlug404Response } from '../dtos';
// @ts-ignore
import type { UploadedGarageCarPhotoDto } from '../dtos';
/**
 * GarageApi - axios parameter creator
 * @export
 */
export const GarageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The method adds a car to the garage of the authenticated user.
         * @summary Add car to garage
         * @param {AddCarToGarageDto} addCarToGarageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerAddCarToGarage: async (addCarToGarageDto: AddCarToGarageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addCarToGarageDto' is not null or undefined
            assertParamExists('garageControllerControllerAddCarToGarage', 'addCarToGarageDto', addCarToGarageDto)
            const localVarPath = `/garage/cars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCarToGarageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method returns a car from the garage of the authenticated user.
         * @summary Get car from garage
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerGetGarageCar: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('garageControllerControllerGetGarageCar', 'id', id)
            const localVarPath = `/garage/cars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method returns a maintenance history generated from the maintenance appointment requests of the authenticated user.
         * @summary Get car maintenance history
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerGetGarageCarMaintenanceHistory: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('garageControllerControllerGetGarageCarMaintenanceHistory', 'id', id)
            const localVarPath = `/garage/cars/{id}/maintenance-history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method returns a list of cars in the garage of the authenticated user.
         * @summary Get garage cars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerGetGarageCars: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/garage/cars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method removes a car from the garage of the authenticated user.
         * @summary Remove car from garage
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerRemoveCarFromGarage: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('garageControllerControllerRemoveCarFromGarage', 'id', id)
            const localVarPath = `/garage/cars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The method updates a car in the garage of the authenticated user.
         * @summary Update car in garage
         * @param {string} id ID of the car in the garage
         * @param {EditCarInGarageDto} editCarInGarageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerUpdateCarInGarage: async (id: string, editCarInGarageDto: EditCarInGarageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('garageControllerControllerUpdateCarInGarage', 'id', id)
            // verify required parameter 'editCarInGarageDto' is not null or undefined
            assertParamExists('garageControllerControllerUpdateCarInGarage', 'editCarInGarageDto', editCarInGarageDto)
            const localVarPath = `/garage/cars/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCarInGarageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a car photo to attach to a car in the garage
         * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerUploadCarPhoto: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('garageControllerControllerUploadCarPhoto', 'file', file)
            const localVarPath = `/garage/cars/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GarageApi - functional programming interface
 * @export
 */
export const GarageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GarageApiAxiosParamCreator(configuration)
    return {
        /**
         * The method adds a car to the garage of the authenticated user.
         * @summary Add car to garage
         * @param {AddCarToGarageDto} addCarToGarageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async garageControllerControllerAddCarToGarage(addCarToGarageDto: AddCarToGarageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GarageCarDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.garageControllerControllerAddCarToGarage(addCarToGarageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GarageApi.garageControllerControllerAddCarToGarage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method returns a car from the garage of the authenticated user.
         * @summary Get car from garage
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async garageControllerControllerGetGarageCar(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GarageCarDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.garageControllerControllerGetGarageCar(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GarageApi.garageControllerControllerGetGarageCar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method returns a maintenance history generated from the maintenance appointment requests of the authenticated user.
         * @summary Get car maintenance history
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async garageControllerControllerGetGarageCarMaintenanceHistory(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaintenanceHistoryItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.garageControllerControllerGetGarageCarMaintenanceHistory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GarageApi.garageControllerControllerGetGarageCarMaintenanceHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method returns a list of cars in the garage of the authenticated user.
         * @summary Get garage cars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async garageControllerControllerGetGarageCars(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GarageCarDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.garageControllerControllerGetGarageCars(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GarageApi.garageControllerControllerGetGarageCars']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method removes a car from the garage of the authenticated user.
         * @summary Remove car from garage
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async garageControllerControllerRemoveCarFromGarage(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.garageControllerControllerRemoveCarFromGarage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GarageApi.garageControllerControllerRemoveCarFromGarage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * The method updates a car in the garage of the authenticated user.
         * @summary Update car in garage
         * @param {string} id ID of the car in the garage
         * @param {EditCarInGarageDto} editCarInGarageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async garageControllerControllerUpdateCarInGarage(id: string, editCarInGarageDto: EditCarInGarageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GarageCarDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.garageControllerControllerUpdateCarInGarage(id, editCarInGarageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GarageApi.garageControllerControllerUpdateCarInGarage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload a car photo to attach to a car in the garage
         * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async garageControllerControllerUploadCarPhoto(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedGarageCarPhotoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.garageControllerControllerUploadCarPhoto(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GarageApi.garageControllerControllerUploadCarPhoto']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GarageApi - factory interface
 * @export
 */
export const GarageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GarageApiFp(configuration)
    return {
        /**
         * The method adds a car to the garage of the authenticated user.
         * @summary Add car to garage
         * @param {AddCarToGarageDto} addCarToGarageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerAddCarToGarage(addCarToGarageDto: AddCarToGarageDto, options?: any): AxiosPromise<GarageCarDto> {
            return localVarFp.garageControllerControllerAddCarToGarage(addCarToGarageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * The method returns a car from the garage of the authenticated user.
         * @summary Get car from garage
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerGetGarageCar(id: string, options?: any): AxiosPromise<GarageCarDto> {
            return localVarFp.garageControllerControllerGetGarageCar(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The method returns a maintenance history generated from the maintenance appointment requests of the authenticated user.
         * @summary Get car maintenance history
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerGetGarageCarMaintenanceHistory(id: string, options?: any): AxiosPromise<Array<MaintenanceHistoryItemDto>> {
            return localVarFp.garageControllerControllerGetGarageCarMaintenanceHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The method returns a list of cars in the garage of the authenticated user.
         * @summary Get garage cars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerGetGarageCars(options?: any): AxiosPromise<Array<GarageCarDto>> {
            return localVarFp.garageControllerControllerGetGarageCars(options).then((request) => request(axios, basePath));
        },
        /**
         * The method removes a car from the garage of the authenticated user.
         * @summary Remove car from garage
         * @param {string} id ID of the car in the garage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerRemoveCarFromGarage(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.garageControllerControllerRemoveCarFromGarage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * The method updates a car in the garage of the authenticated user.
         * @summary Update car in garage
         * @param {string} id ID of the car in the garage
         * @param {EditCarInGarageDto} editCarInGarageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerUpdateCarInGarage(id: string, editCarInGarageDto: EditCarInGarageDto, options?: any): AxiosPromise<GarageCarDto> {
            return localVarFp.garageControllerControllerUpdateCarInGarage(id, editCarInGarageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a car photo to attach to a car in the garage
         * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        garageControllerControllerUploadCarPhoto(file: File, options?: any): AxiosPromise<UploadedGarageCarPhotoDto> {
            return localVarFp.garageControllerControllerUploadCarPhoto(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GarageApi - interface
 * @export
 * @interface GarageApi
 */
export interface GarageApiInterface {
    /**
     * The method adds a car to the garage of the authenticated user.
     * @summary Add car to garage
     * @param {AddCarToGarageDto} addCarToGarageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApiInterface
     */
    garageControllerControllerAddCarToGarage(addCarToGarageDto: AddCarToGarageDto, options?: RawAxiosRequestConfig): AxiosPromise<GarageCarDto>;

    /**
     * The method returns a car from the garage of the authenticated user.
     * @summary Get car from garage
     * @param {string} id ID of the car in the garage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApiInterface
     */
    garageControllerControllerGetGarageCar(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GarageCarDto>;

    /**
     * The method returns a maintenance history generated from the maintenance appointment requests of the authenticated user.
     * @summary Get car maintenance history
     * @param {string} id ID of the car in the garage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApiInterface
     */
    garageControllerControllerGetGarageCarMaintenanceHistory(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<MaintenanceHistoryItemDto>>;

    /**
     * The method returns a list of cars in the garage of the authenticated user.
     * @summary Get garage cars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApiInterface
     */
    garageControllerControllerGetGarageCars(options?: RawAxiosRequestConfig): AxiosPromise<Array<GarageCarDto>>;

    /**
     * The method removes a car from the garage of the authenticated user.
     * @summary Remove car from garage
     * @param {string} id ID of the car in the garage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApiInterface
     */
    garageControllerControllerRemoveCarFromGarage(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * The method updates a car in the garage of the authenticated user.
     * @summary Update car in garage
     * @param {string} id ID of the car in the garage
     * @param {EditCarInGarageDto} editCarInGarageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApiInterface
     */
    garageControllerControllerUpdateCarInGarage(id: string, editCarInGarageDto: EditCarInGarageDto, options?: RawAxiosRequestConfig): AxiosPromise<GarageCarDto>;

    /**
     * 
     * @summary Upload a car photo to attach to a car in the garage
     * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApiInterface
     */
    garageControllerControllerUploadCarPhoto(file: File, options?: RawAxiosRequestConfig): AxiosPromise<UploadedGarageCarPhotoDto>;

}

/**
 * GarageApi - object-oriented interface
 * @export
 * @class GarageApi
 * @extends {BaseAPI}
 */
export class GarageApi extends BaseAPI implements GarageApiInterface {
    /**
     * The method adds a car to the garage of the authenticated user.
     * @summary Add car to garage
     * @param {AddCarToGarageDto} addCarToGarageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApi
     */
    public garageControllerControllerAddCarToGarage(addCarToGarageDto: AddCarToGarageDto, options?: RawAxiosRequestConfig) {
        return GarageApiFp(this.configuration).garageControllerControllerAddCarToGarage(addCarToGarageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method returns a car from the garage of the authenticated user.
     * @summary Get car from garage
     * @param {string} id ID of the car in the garage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApi
     */
    public garageControllerControllerGetGarageCar(id: string, options?: RawAxiosRequestConfig) {
        return GarageApiFp(this.configuration).garageControllerControllerGetGarageCar(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method returns a maintenance history generated from the maintenance appointment requests of the authenticated user.
     * @summary Get car maintenance history
     * @param {string} id ID of the car in the garage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApi
     */
    public garageControllerControllerGetGarageCarMaintenanceHistory(id: string, options?: RawAxiosRequestConfig) {
        return GarageApiFp(this.configuration).garageControllerControllerGetGarageCarMaintenanceHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method returns a list of cars in the garage of the authenticated user.
     * @summary Get garage cars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApi
     */
    public garageControllerControllerGetGarageCars(options?: RawAxiosRequestConfig) {
        return GarageApiFp(this.configuration).garageControllerControllerGetGarageCars(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method removes a car from the garage of the authenticated user.
     * @summary Remove car from garage
     * @param {string} id ID of the car in the garage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApi
     */
    public garageControllerControllerRemoveCarFromGarage(id: string, options?: RawAxiosRequestConfig) {
        return GarageApiFp(this.configuration).garageControllerControllerRemoveCarFromGarage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The method updates a car in the garage of the authenticated user.
     * @summary Update car in garage
     * @param {string} id ID of the car in the garage
     * @param {EditCarInGarageDto} editCarInGarageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApi
     */
    public garageControllerControllerUpdateCarInGarage(id: string, editCarInGarageDto: EditCarInGarageDto, options?: RawAxiosRequestConfig) {
        return GarageApiFp(this.configuration).garageControllerControllerUpdateCarInGarage(id, editCarInGarageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a car photo to attach to a car in the garage
     * @param {File} file Supported extensions: jpg/jpeg, png, svg. Maximum allowed file size is 5 MB.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GarageApi
     */
    public garageControllerControllerUploadCarPhoto(file: File, options?: RawAxiosRequestConfig) {
        return GarageApiFp(this.configuration).garageControllerControllerUploadCarPhoto(file, options).then((request) => request(this.axios, this.basePath));
    }
}

