import { data } from './data';
import classes from './advantagesSection.module.css';
import styles from './advantagesSection.module.css';

export const AdvantagesSection = () => {
  return (
    // todo: gsap ищет по gsap.utils.toArray('section'), исправить
    <div className={styles.section}>
      <div className={styles.container}>
        <ul className={styles.cardList}>
          {data.map((it, index) => (
            <li key={index} className={styles.card}>
              {it.icon}
              <p className={'h5'}>{it.title}</p>
              <ul className={classes.textList}>
                {it.textList.map((text, index) => (
                  <li key={index} className={`p3 ${classes.text}`}>
                    {text}
                  </li>
                ))}
              </ul>
              <p className={`p3 ${classes.additionalText}`}>{it.additionalText}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
