import { useEffect, useState } from 'react';
import { PromotionsApi } from '../api';
import type {
  MaintenanceRecommendationDto,
  PromotionCategoryDto,
  PromotionDto,
} from '../api/generated/caready';

export const usePromotionsCategories = () => {
  const [categories, setCategories] = useState<PromotionCategoryDto[]>([]);

  useEffect(() => {
    PromotionsApi.promotionsControllerGetPromotionCategories().then(({ data }) => {
      setCategories(data);
    });
  }, []);

  return categories;
};

export const usePromotions = (
  page: number,
  perPage?: number,
  category?: string,
  archived?: boolean
) => {
  const [promotions, setPromotions] = useState<PromotionDto[]>([]);

  useEffect(() => {
    PromotionsApi.promotionsControllerGetPublishedPromotions(
      perPage,
      page,
      category,
      archived
    ).then(({ data }) => {
      setPromotions(data.items);
    });
  }, [page, perPage, category, archived]);

  return promotions;
};

export const useOnePromo = (category?: string, perPage?: number) => {
  const [promo, setPromo] = useState<PromotionDto[]>([]);
  useEffect(() => {
    PromotionsApi.promotionsControllerGetPublishedPromotions(perPage, 1, category, false).then(
      ({ data }) => {
        setPromo(data.items);
      }
    );
  }, []);

  return promo;
};

export const useRecommendationsPromo = () => {
  const [recommendations, setRecommendations] = useState<MaintenanceRecommendationDto[] | []>([]);
  useEffect(() => {
    PromotionsApi.promotionsControllerGetPublishedMaintenanceRecommendations().then(({ data }) => {
      setRecommendations(data);
    });
  }, []);

  return recommendations;
};
