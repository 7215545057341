import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { FC } from 'react';
import classes from './appointment.module.css';
import { DayPart } from '../../../types/DayPart';
import { DayPartEnum } from '../../../api/generated/caready';

interface OwnProps {
  date: Date;
  dayPart: DayPartEnum;
  serviceStationAddress?: string;
  selectedModel: string;
  services: string[];
}

export const Success: FC<OwnProps> = function Success(props) {
  return (
    <div className={`modal_inner ${classes.success}`}>
      <div>
        <span className="p2">Услуга</span>
        {props.services.map((service: string, idx: number) => (
          <p className="h6" key={idx}>
            {service}
          </p>
        ))}
      </div>
      <div>
        <span className="p2">Ваш автомобиль</span>
        <p className="h6">{props.selectedModel}</p>
      </div>
      <div>
        <span className="p2">СТО</span>
        <p className="h6">{props.serviceStationAddress}</p>
      </div>
      <div>
        <span className="p2">Дата и время</span>
        <p className="h6">
          {format(props.date, 'dd MMM', { locale: ru })}, {DayPart[props.dayPart]}
        </p>
      </div>
    </div>
  );
};
