import { FC, PropsWithChildren } from 'react';

type OwnProps = {
  skeleton: FC<any>;
  isLoading?: boolean;
  quantity?: number;
} & PropsWithChildren;

export const SkeletonGroup: FC<OwnProps> = function SkeletonGroup({
  skeleton: Skeleton,
  ...props
}) {
  return (
    <>
      {props.isLoading
        ? Array(props.quantity)
            .fill(null)
            .map((_, index) => <Skeleton key={index} />)
        : props.children}
    </>
  );
};
