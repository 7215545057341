import { DynamicImg } from '../../base/DynamicImg';
import { useEffect, useRef, useState } from 'react';
import styles from './review.module.css';

export interface ReviewProps {
  avatar: string;
  authorName: string;
  authorPosition: string;
  title: string;
  text: string;
}

const maxRows = 3;

export const Review = (props: ReviewProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowCollapseButton, setIsShowCollapseButton] = useState(false);
  const reviewRef = useRef<HTMLParagraphElement>(null);

  const updateIsShowCollapseButton = () => {
    if (!reviewRef.current) return;

    setIsShowCollapseButton(
      reviewRef.current.scrollHeight /
        Number(getComputedStyle(reviewRef.current).lineHeight.replace('px', '')) >
        maxRows
    );
  };

  useEffect(updateIsShowCollapseButton, []);

  return (
    <div className={styles.review}>
      <div className={styles.author}>
        <picture className={styles.avatar}>
          <DynamicImg path={props.avatar} alt={props.authorName} />
        </picture>
        <div>
          <p className="h6-16">{props.authorName}</p>
          <p>{props.authorPosition}</p>
        </div>
      </div>
      <div className={styles.content}>
        <div className={`${styles.text}`}>
          <h6 className="h5-20">{props.title}</h6>
          <p
            ref={reviewRef}
            className={`${styles.reviewText} ${!isExpanded ? styles.textShowed : ''}`}
          >
            {props.text}
          </p>
        </div>
        {isShowCollapseButton && (
          <button onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Свернуть' : 'Читать полностью'}
          </button>
        )}
      </div>
    </div>
  );
};
