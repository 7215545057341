import { FC } from 'react';
import s from './Skeleton.module.scss';
import { Property } from 'csstype';
import clsx from 'clsx';

interface OwnProps {
  borderRadius?: number;
  height?: Property.Height<string | number>;
  width?: Property.Width<string | number>;
  className?: string;
  quantity?: number;
}

export const Skeleton: FC<OwnProps> = function Skeleton(props) {
  return Array(props?.quantity)
    .fill(null)
    .map((it, index) => (
      <div
        key={index}
        className={clsx(s.item, props.className)}
        style={{
          width: props.width,
          height: props.height,
          borderRadius: props.borderRadius ?? 8,
        }}
      >
        &nbsp;
      </div>
    ));
};
