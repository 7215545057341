import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { ERROR_EMAIL, ERROR_REQUIRED } from '../../../config/errorTexts';
import { Field, Formik } from 'formik';
import { Input } from '../../base/Input';
import { ModalClose } from '../../base/ModalBase/ModalBase.v2';
import { object, string } from 'yup';
import classes from './change-email.module.css';
import { UsersApi } from '../../../api';

// todo: добавить классы модалки
export const ChangeEmail = ({
  changeState,
  email,
  oldEmail,
  setEmail,
  resetTimer,
  closeModal,
}: {
  changeState: () => void;
  email: string;
  oldEmail?: string;
  setEmail: (v: string) => void;
  resetTimer: () => void;
  closeModal: () => void;
}) => {
  return (
    <>
      <header className={classes.simpleHeader}>
        <h4 className="h4">Редактирование Email</h4>
        <ModalClose onCloseButtonClick={closeModal} />
      </header>
      <Formik
        initialValues={{ email }}
        validationSchema={object().shape({
          email: string().email(ERROR_EMAIL).required(ERROR_REQUIRED),
        })}
        onSubmit={(values, formikHelpers) => {
          if (values.email === email) {
            if (values.email === oldEmail) {
              formikHelpers.setErrors({ email: 'E-mail добавлен' });
            } else {
              changeState();
            }
          } else {
            UsersApi.usersControllerInitiateEmailChange({ newEmail: values.email }).then(res => {
              if (res) {
                setEmail(values.email);
                resetTimer();
                changeState();
              } else {
                formikHelpers.setErrors({ email: 'Ошибка' });
              }
            });
            formikHelpers.setSubmitting(false);
          }
        }}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <div className={`${classes.inner}`}>
              <div className={classes.fieldSection}>
                <Input label="Email" touched={props.touched.email} error={props.errors.email}>
                  <Field placeholder="Enter E-mail" name="email" />
                </Input>
              </div>
              <div className={classes.buttonSection}>
                <button className="btn" disabled={!props.isValid}>
                  Продолжить <ArrowRight />
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
