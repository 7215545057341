import { MutableRefObject, useEffect, useState } from 'react';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

export function useMapZoom(
  mapRef: MutableRefObject<ymaps.Map | undefined | null>,
  ymaps: YMapsApi | undefined
) {
  const [zoom, setZoom] = useState<number>(9);

  useEffect(() => {
    if (!ymaps || !mapRef.current) return;

    const events = mapRef.current.events;

    function updateZoomState(e: ymaps.IEvent | object) {
      const newZoom = (e as ymaps.IEvent).get('newZoom');
      if (!newZoom || newZoom === (e as ymaps.IEvent).get('oldZoom')) return;
      setZoom(Math.round(newZoom));
    }
    events?.add('boundschange', updateZoomState);

    return () => {
      events?.remove('boundschange', updateZoomState);
    };
  }, [ymaps]);

  return {
    zoom,
    setZoom,
  };
}
