import { CarPicker } from '../../components/CarPicker';
import { FormikValues } from 'formik';
import classes from './tradein.module.css';
import { GarageCarDto } from '../../../api/generated/caready';

export const AddFromMyCars = ({
  values,
  setValue,
  nextStep,
  cars,
}: {
  values: FormikValues;
  setValue: (field: string, value?: any) => void;
  nextStep: (vin: null | string, year: number | undefined) => void;
  cars: GarageCarDto[];
}) => {
  return (
    <div className={classes.carPicker}>
      {cars.map((car, idx) => (
        <CarPicker
          car={car}
          key={idx}
          chosen={
            car.model.id === values.carModel &&
            (car.modelModification?.generation.fromYear ?? '').toString() === values.carYear &&
            car.model.brand.id === values.brand &&
            (car.vin ?? '') === values.carVin
          }
          onClick={() => {
            setValue('carModel', car.model);
            setValue('carYear', (car.modelModification?.generation.fromYear ?? '').toString());
            setValue('brand', car.model.brand);
            setValue('carVin', !!car.vin ? car.vin : '');
            nextStep(car.vin, car.modelModification?.generation.fromYear);
          }}
        />
      ))}
    </div>
  );
};
