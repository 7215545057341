import { ServiceStationWithDetailWorkingScheduleDto } from '../api/generated/caready';
import { addDays, daysInWeek, startOfISOWeek } from 'date-fns';

type ServiceStationAggregatedSchedule = {
  // ISO format - Monday = 1, Sunday = 7
  dayOfWeek: number;
  dateStart: Date;
  dateEnd?: Date;
  schedule: string;
};

const startOfMonday = startOfISOWeek(new Date());

export function* getAggregatedServiceStationScheduleGenerator(
  service: Pick<ServiceStationWithDetailWorkingScheduleDto, 'openingHours'>
): Generator<ServiceStationAggregatedSchedule> {
  const workSchedule = service.openingHours.workSchedule;

  const dayScheduleRecord: Record<string, string> = {};
  for (const schedule of workSchedule) {
    if (schedule.weekday === undefined) continue;

    const concatenatedSchedule = schedule.openingHours.reduce(
      (accumulator: string, currentValue: string[]) => {
        const interval = currentValue.join(' - ');
        return accumulator ? `${accumulator}, ${interval}` : interval;
      },
      ''
    );
    const existingSchedule = dayScheduleRecord[schedule.weekday];
    dayScheduleRecord[schedule.weekday] = existingSchedule
      ? `${existingSchedule}, ${concatenatedSchedule}`
      : concatenatedSchedule;
  }

  let groupStartDay: Date | null = null;
  let groupSchedule = '';

  for (let i = 1; i <= daysInWeek; i++) {
    let daySchedule = dayScheduleRecord[i];

    if (!daySchedule) {
      daySchedule = 'Закрыто';
    }

    let nextSchedule = dayScheduleRecord[i + 1];

    if (!nextSchedule && i !== daysInWeek) {
      nextSchedule = 'Закрыто';
    }

    const currentDay = addDays(startOfMonday, i - 1);
    if (daySchedule === nextSchedule) {
      // We want to include this day in the current group of week days (wth the same schedule)
      if (groupSchedule && groupStartDay) continue;

      groupSchedule = daySchedule;
      groupStartDay = currentDay;
    } else if (groupSchedule && groupStartDay) {
      // This schedule is the end of grouped schedules
      // We need to finish a current days group, release it and start a new one
      yield {
        dateStart: groupStartDay,
        dateEnd: currentDay,
        schedule: groupSchedule,
        dayOfWeek: i,
      };
      groupSchedule = '';
      groupStartDay = null;
    } else {
      // This schedule is single, we want to release this only day
      yield {
        dateStart: currentDay,
        schedule: daySchedule,
        dayOfWeek: i,
      };
    }
  }
}
