import document from 'styles/common/document.module.scss';
import { useHtmlDocument } from '../../hooks/useHtmlDocument';
import { CoreHead } from '../../ui/core';

export const Policy = () => {
  const { htmlContent } = useHtmlDocument('policy');

  return (
    <>
      <CoreHead
        title={'Политика конфиденциальности'}
        description={
          'Ознакомьтесь с политикой конфиденциальности нашего сайта. Узнайте, как мы обрабатываем и защищаем ваши персональные данные в соответствии с законодательством'
        }
      />
      <article
        className={`${document.wrapper} section`}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
};
