import { ReactComponent as Document } from '../../../assets/img/franchise/advantages/document.svg';
import { ReactComponent as Gear } from '../../../assets/img/franchise/advantages/gear.svg';
import { ReactComponent as Handshake } from '../../../assets/img/franchise/advantages/handshake.svg';
import { ReactComponent as Headphones } from '../../../assets/img/franchise/advantages/headphones.svg';

export const data = [
  {
    title: 'Запасные части собственного бренда',
    icon: <Gear />,
    textList: [
      'Широкий ассортимент на автомобили различных брендов, включая китайские автомобили',
      'Высокая доходность для сервисного партнера в среднем 60%',
      'Уникальные цены и программы стимулирования продаж на запасные части собственного бренда Caready и моторные масла и жидкости от ключевых партнеров',
    ],
  },
  {
    title: 'Программа «Продленной гарантии»',
    icon: <Document />,
    textList: [
      'Специальный продукт продления гарантии, обеспечивающий покрытие дорогостоящего ремонта автомобилей, который может возникнуть после окончания заводской гарантии',
      '3 разных тарифа для клиентов со страховым покрытием двигателя и КПП от 9000 рублей',
    ],
  },
  {
    title: 'Синергия с PANGO CARS*',
    icon: <Handshake />,
    textList: [
      'Застрахованные автомобили Pango Cars ремонтируются и обслуживаются в Caready. Программа удержания включает бонусы и маркетинговые акции',
      'Специальные финансовые продукты от Pango Finance',
    ],
    additionalText: '*Pango Cars - федеральная автомобильная экосистема',
  },
  {
    title: 'Маркетинговая поддержка',
    icon: <Headphones />,
    textList: [
      'Федеральный маркетинг',
      'Лидогенерация и привлечение дополнительного траффика на СТО',
      'Программы лояльности В2В',
      'Клиентское мобильное приложение',
      'Call-center',
    ],
  },
];
