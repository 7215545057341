import { FC } from 'react';
import { MaintenanceServiceDto, MaintenanceServiceListDto } from '../../../api/generated/caready';
import s from './services-card.module.scss';
import { Skeleton } from '../../base/Skeleton/Skeleton';
import { ReactComponent as Ruble } from 'assets/svg/ruble.svg';
import { UTMLink } from '../../base/UTMLink/UTMLink';

interface OwnProps {
  service: MaintenanceServiceDto | MaintenanceServiceListDto;
  link: string;
}

export const TertiaryService: FC<OwnProps> = function TertiaryService(props) {
  return (
    <UTMLink to={`${props.link}/${props.service.slug}/`} className={`${s.card} ${s.tertiary}`}>
      <div className={s.image}>
        <img src={props.service.iconUrl} alt={props.service.name} />
      </div>
      <div className={s.textBlock}>
        <h4 className={`h4 ${s.tertiaryTitle}`}>
          от {props.service.minPriceRub} <Ruble className={s.currencyIcon} />
        </h4>
        {!!props.service.bonusPoints && (
          <p className={`${s.bonus} p4`}>
            +{props.service.bonusPoints} баллов по программе Лояльности
          </p>
        )}
        <p className={'p3'}>{props.service.name}</p>
      </div>
    </UTMLink>
  );
};

export const TertiaryServiceSkeleton = function TertiaryServiceSkeleton() {
  return (
    <div className={`${s.card} ${s.tertiary}  ${s.tertiarySkeleton}`}>
      <Skeleton className={s.image} width={'100%'} />
      <div className={s.textBlock}>
        <h4 className={`h4 ${s.tertiaryTitle}`}>
          <Skeleton width={'40%'} />
        </h4>
        <Skeleton className={'p3'} width={'100%'} />
      </div>
    </div>
  );
};
