import React, { useState } from 'react';
import styles from './cities.module.css';

const cities = [
  'Москва',
  'Нижний Новгород',
  'Ростов-на-Дону',
  'Краснодар',
  'Екатеринбург',
  'Казань',
  'Новосибирск',
  'Санкт-Петербург',
  'Уфа',
  'Красноярск',
  'Чебоксары',
  'Саратов',
  'Волгоград',
  'Самара',
  'Пермь',
  'Тюмень',
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Cities = ({ openModal }: { openModal?: () => void }) => {
  const [showAllCities, setShowAllCities] = useState(false);

  return (
    <div className={`container ${styles.wrapper}`}>
      <h5 className={`h5-20 ${styles.title}`}>Ищем партнеров в городах:</h5>
      <div
        className={styles.cities}
        style={{
          height: `calc((var(--height) * (${
            showAllCities
              ? `${
                  cities.length % 2 === 1 ? `(${cities.length} + var(--remainder))` : cities.length
                } / var(--divider)`
              : 'var(--count)'
          }) + 1px)`,
        }}
      >
        {cities.map(city => (
          <div className={styles.city} key={city}>
            <span className="h5-20">{city}</span>
            {/* before change mail */}
            {/* <button className={styles.cityButton} onClick={openModal}>
              <span>Подать заявку</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.23052 2.46973L14.7609 8.00006L9.23052 13.5304L8.16986 12.4697L11.8895 8.75006H1.2002V7.25006H11.8895L8.16986 3.53039L9.23052 2.46973Z"
                />
              </svg>
            </button> */}
          </div>
        ))}
      </div>
      <button
        className={`${styles.showBtn} ${showAllCities ? styles.showBtnActive : ''}`}
        onClick={() => setShowAllCities(!showAllCities)}
      >
        {showAllCities ? 'Скрыть города' : 'Показать все города'}
      </button>
      {/* before change mail */}
      {/* <div className={styles.cityOffer}>
        <p className="h3-32">
          Вы можете подать заявку, даже если вашего города нет в списке
        </p>
        <button className={styles.requestButton} onClick={openModal}>
          <span>Подать заявку</span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.23052 2.46973L14.7609 8.00006L9.23052 13.5304L8.16986 12.4697L11.8895 8.75006H1.2002V7.25006H11.8895L8.16986 3.53039L9.23052 2.46973Z"
            />
          </svg>
        </button>
      </div> */}
    </div>
  );
};
