import { Autoplay } from 'swiper';
import { DynamicImg } from '../../base/DynamicImg';
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './partners-section.module.css';

// todo: hid car brand logos
// const logos = [
//   <Volkswagen />,
//   <Cherry />,
//   <Audi />,
//   <Bmw />,
//   <Haval />,
//   <Skoda />,
//   <Exeed />,
//   <Mercedes />,
//   <Toyota />,
//   <Geely />,
//   <Hyndai />,
//   <Kia />,
//   <Reanult />,
//   <Nissan />,
// ];

const sliderItems = [
  'spare-parts/carousel/1.webp',
  'spare-parts/carousel/2.webp',
  'spare-parts/carousel/3.webp',
  'spare-parts/carousel/4.webp',
];

export const PartnersSection = () => {
  return (
    <div className={classes.Partners}>
      {/*todo: added carousel*/}
      {/*<picture className={classes.Img}>*/}
      {/*  <ImageSource media="(max-width:575px)" path="spare-parts/partners-575.png" />*/}
      {/*  <ImageSource media="(max-width:1023px)" path="spare-parts/partners-1024.png" />*/}
      {/*  <ImageSource media="(max-width:1199px)" path="spare-parts/partners-1200.png" />*/}
      {/*  <ImageSource media="(min-width:1200px)" path="spare-parts/partners.png" />*/}
      {/*  <DynamicImg path="spare-parts/partners.png" />*/}
      {/*</picture>*/}
      <div className={classes.Carousel}>
        <Swiper
          slidesPerView={1}
          loop={true}
          modules={[Autoplay]}
          allowTouchMove={false}
          spaceBetween={32}
          speed={2000}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
        >
          {sliderItems.map((it, index) => (
            <SwiperSlide key={index} className={classes.CarouselImg}>
              <DynamicImg path={it} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={classes.Main}>
        <div className={classes.Text}>
          <h2 className="h4">
            Широкий ассортимент на автомобили различных брендов, включая китайские автомобили.
          </h2>
          <h2 className="h4">
            Все запасные части обладают сертификатами качества на соответствие мировым стандартам.
          </h2>
        </div>
        {/*todo: hid car brand logos*/}
        {/*<div className={classes.Logos}>*/}
        {/*  {logos.map((logo) => (*/}
        {/*    <div>{logo}</div>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
