import React, { FC, useEffect, useId, useState } from 'react';
import { ReactComponent as Location } from 'assets/svg/location.svg';
import { ReactComponent as Minus } from 'assets/svg/minus.svg';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import s from './MapControls.module.scss';
import { ZoomControl } from '@pbe/react-yandex-maps';
import { createPortal } from 'react-dom';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

interface OwnProps {
  zoom: number;
  setZoom: (value: number) => void;
  setCenter: (value: [number, number]) => void;
  ymaps: YMapsApi;
}

export const MapControls: FC<OwnProps> = function MapControls(props) {
  const controlsId = useId();
  const [controlsElement, setControlsElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    function updateControlsElement() {
      setControlsElement(document.getElementById(controlsId));
    }

    const timer = setTimeout(updateControlsElement, 1);

    return () => {
      clearTimeout(timer);
    };
  }, [props.ymaps]);

  return (
    <>
      <ZoomControl
        options={{
          position: {
            right: 0,
            top: '50%',
          },
          // @ts-ignore
          layout: props.ymaps.templateLayoutFactory.createClass(`<div id="${controlsId}"/>`),
        }}
      />
      {controlsElement &&
        createPortal(
          <Controls zoom={props.zoom} setZoom={props.setZoom} setCenter={props.setCenter} />,
          controlsElement
        )}
    </>
  );
};

const Controls: FC<Omit<OwnProps, 'ymaps'>> = function Controls(props) {
  const setGeoPosition = () => {
    navigator.geolocation.getCurrentPosition(position => {
      props.setCenter([position.coords.latitude, position.coords.longitude]);
      props.setZoom(12);
    });
  };

  return (
    <div className={s.controls}>
      <button
        className={s.plus}
        onClick={() => props.setZoom(Math.min(props.zoom + 1, 18))}
        type={'button'}
      >
        <Plus />
      </button>
      <button
        className={s.minus}
        onClick={() => props.setZoom(Math.max(props.zoom - 1, 4))}
        type={'button'}
      >
        <Minus />
      </button>
      <button className={s.location} onClick={setGeoPosition} type={'button'}>
        <Location />
      </button>
    </div>
  );
};
