export enum TransmissionEnum {
  MT = 'Механическая',
  AT = 'Автомат',
  AMT = 'Робот',
  CVT = 'Вариатор',
}

export enum EngineTypeEnum {
  GASOLINE = 'Бензин',
  DIESEL = 'Дизель',
  ELECTRIC = 'Электрический',
  HYBRID = 'Гибрид',
  ECO_FUEL = 'Эко',
}

export enum DriveTypeEnum {
  FWD = 'Передний',
  RWD = 'Задний',
  AWD = 'Полный AWD',
  '4WD' = 'Полный 4WD',
}
