import { ServiceStationsApi } from '../api';
import { UseQueryOptionsParam } from '../types/UseQueryOptionsParam';
import {
  ServiceStationDto,
  ServiceStationsControllerGetServiceStationsTypesEnum,
} from '../api/generated/caready';
import { useQuery } from '@tanstack/react-query';

interface ServiceStationParams {
  address?: string;
  types?: ServiceStationsControllerGetServiceStationsTypesEnum[];
  serviceCategories?: string[];
  services?: string[];
  electricChargerTypes?: string[];
  serviceCategoryIds?: string[];
  serviceIds?: string[];
  cityAbbreviation?: string;
  slug?: string;
}

export const useServiceStations = <TData = ServiceStationDto[]>(
  params?: ServiceStationParams,
  options?: UseQueryOptionsParam<ServiceStationDto[], unknown, TData>
) => {
  return useQuery({
    queryKey: ['service-station', 'list', params],
    queryFn: async () => {
      const { data } = await ServiceStationsApi.serviceStationsControllerGetServiceStations(
        params?.address,
        params?.types,
        params?.serviceCategories,
        params?.services,
        params?.electricChargerTypes,
        params?.serviceCategoryIds,
        params?.serviceIds,
        params?.cityAbbreviation,
        params?.slug
      );

      return data;
    },
    staleTime: Infinity,
    ...options,
  });
};
