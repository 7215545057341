import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { gsap } from 'gsap';
import { useLayoutEffect, useRef } from 'react';
import styles from './video.module.css';

gsap.registerPlugin(ScrollTrigger);

export const Video = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useLayoutEffect(() => {
    gsap.utils.toArray('.gsap-video span').forEach(span => {
      ScrollTrigger.create({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trigger: span,
        start: 'top center',
        end: 'bottom center',
        toggleClass: styles.active,
      });
    });

    gsap.to('.gsap-video', {
      scrollTrigger: {
        trigger: '.gsap-video',
        start: 'top top',
        end: 'bottom bottom',
        toggleClass: styles.active,
      },
    });

    gsap.to('.gsap-video video', {
      scrollTrigger: {
        trigger: '.gsap-video video',
        start: 'top bottom',
        end: 'top top',
        scrub: true,
        onEnter: () => {
          videoRef.current?.play();
        },
      },
      scale: 1,
      filter: 'brightness(0.3)',
      borderRadius: '0',
    });
  }, []);

  return (
    <div className={`${styles.wrapper} gsap-video`}>
      <h2 className={`${styles.title} h6-16 section-title`}>Что делает нас особенными?</h2>
      <div className={`${styles.videoWrap}`}>
        <video controls={false} loop muted ref={videoRef} playsInline>
          <source src={require('assets/video/production.mp4')} type="video/mp4" />
        </video>
      </div>
      <div className={`h2-52 ${styles.text}`}>
        <span>Мы заботимся о комфорте наших клиентов.</span>{' '}
        <span>Обслуживаем автомобили всех марок. </span>
        {/*todo: hid franchise video text*/}
        {/*<span>Запускаем СТО по проверенной бизнес-модели.</span>{' '}*/}
        {/*<span>Бесплатно привлекаем клиентов.</span>{' '}*/}
        <span>Бесплатно обучаем сотрудников и поддерживаем франчайзи на всех этапах.</span>{' '}
        <span>Работаем по четким стандартам и регламентам.</span>{' '}
        <span>Помогаем повысить качество обслуживания.</span>
      </div>
    </div>
  );
};
