import { ServiceStationDto } from '../../../../../../api/generated/caready';
import { FC, useEffect } from 'react';
import { ServiceStationWorkStatus } from '../../../../ServiceStationWorkStatus/ServiceStationWorkStatus';
import s from './Balloon.module.scss';

interface OwnProps {
  selectedServiceStation: ServiceStationDto;
  onClick: (selectedServiceStation: ServiceStationDto) => void;
  onCloseBalloon?: () => void;
}

export const Balloon: FC<OwnProps> = function Balloon(props) {
  useEffect(() => {
    // фиксит баг, при котором с мобильного устройства при клике на крестик в balloon panel
    // триггерится кнопка гео
    document
      .getElementsByClassName('ymaps-2-1-79-balloon__close-button')?.[0]
      ?.addEventListener('touchstart', e => e.preventDefault());

    return () => {
      props.onCloseBalloon?.();
    };
  }, []);

  return (
    <div className={s.wrapper}>
      <ServiceStationWorkStatus
        className={s.workStatus}
        serviceStation={props.selectedServiceStation}
      />
      <button
        className={'btn block balloon-button'}
        type={'button'}
        onClick={() => props.onClick(props.selectedServiceStation)}
      >
        Выбрать
      </button>
    </div>
  );
};
