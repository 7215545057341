import s from './Agreement.module.scss';
import { Checkbox } from '../../base/Checkbox';
import { PropsWithChildren } from 'react';

interface OwnProps {
  error?: string;
  touched?: boolean;
}

export const Agreement = function Agreement(props: PropsWithChildren<OwnProps>) {
  return (
    <Checkbox
      error={props.error}
      touched={props.touched}
      text={
        <p className={'p2'}>
          Я даю согласие на обработку{' '}
          <a href={'/personal-data-agreement/'} target={'_blank'} className={s.link}>
            персональных данных
          </a>
        </p>
      }
    >
      {props.children}
    </Checkbox>
  );
};
