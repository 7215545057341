import { CoreHead } from '../../ui/core';
import { SearchNews, SearchPromotions, SearchServices } from '../../ui/section/SearchSections';
import { useLocation } from 'react-router-dom';
import { useSearch } from '../../hooks/useSearch';
import { useEffect, useState } from 'react';
import classes from './search.module.css';
import { SearchResultsDto } from '../../api/generated/caready';

type SearchKeys = keyof SearchResultsDto;

type ButtonType = {
  label: string;
  name: SearchKeys;
  value: number;
};

const buttons: ButtonType[] = [
  {
    label: 'Услуги',
    name: 'maintenanceServices',
    value: 0,
  },
  {
    label: 'Новости',
    name: 'news',
    value: 0,
  },
  {
    label: 'Акции',
    name: 'promotions',
    value: 0,
  },
];

export default function Search() {
  const [activeTab, setActiveTab] = useState<SearchKeys>('maintenanceServices');

  const { search } = useLocation();
  const query = new URLSearchParams(search).get('query') || '';

  const { searchResults, getSearchResults } = useSearch(1, 5);

  useEffect(() => {
    getSearchResults(query);
  }, [query]);

  const searchResult = searchResults
    ? searchResults?.maintenanceServices.pagination.total +
      searchResults?.news.pagination.total +
      searchResults?.promotions.pagination.total
    : 0;

  return (
    <>
      <CoreHead title={'Результаты поиска'} />
      <div className={'section'}>
        <h2 className={`p2 ${classes.resultsNumber}`}>Найдено: {searchResult}</h2>
        <div className={classes.tabs}>
          {buttons.map((button, idx) => (
            <button
              key={idx}
              onClick={() => setActiveTab(button.name)}
              className={`btn tab ${activeTab === button.name ? '' : 'secondary black'}`}
            >
              {button.label}
              <span>{searchResults?.[button.name].pagination.total || button.value}</span>
            </button>
          ))}
        </div>
        {activeTab === 'maintenanceServices' && <SearchServices query={query} />}
        {activeTab === 'news' && <SearchNews query={query} />}
        {activeTab === 'promotions' && <SearchPromotions query={query} />}
      </div>
    </>
  );
}
