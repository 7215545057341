import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ReactComponent as Delete } from 'assets/svg/delete.svg';
import { ModalClose, ModalOverlay } from '../../base/ModalBase/ModalBase.v2';
import { useNavigate } from 'react-router-dom';
import s from './DeleteCarModal.module.scss';
import { GarageApi } from '../../../api';

export const DeleteCarModal = ({
  carName,
  carId,
  onCloseButtonClick,
}: {
  carName: string;
  carId: string;
  onCloseButtonClick: () => void;
}) => {
  const navigate = useNavigate();
  const handleDelete = () => {
    GarageApi.garageControllerControllerRemoveCarFromGarage(carId).then(() => {
      onCloseButtonClick();
      navigate('/profile/my-cars/');
    });
  };
  return (
    <ModalOverlay onCloseButtonClick={onCloseButtonClick}>
      <>
        <header className={'modal_header'}>
          <button className={`${s.backButton} btn-text gray`} onClick={onCloseButtonClick}>
            <ArrowLeft />
            Назад
          </button>
          <h4 className={`${s.header} h4`}>Удалить авто</h4>
          <ModalClose onCloseButtonClick={onCloseButtonClick} />
        </header>
        <div className={'modal_inner'}>
          <div className={s.textBlock}>
            <h4 className="h4">Удалить авто</h4>
            <p>Вы действительно хотите удалить информацию об автомобиле {carName}?</p>
          </div>
        </div>
        <footer className={`${s.footer} modal_footer`}>
          <button className="btn secondary black" onClick={onCloseButtonClick}>
            Отмена
          </button>
          <button className="btn" onClick={handleDelete}>
            <Delete />
            Удалить
          </button>
        </footer>
      </>
    </ModalOverlay>
  );
};
