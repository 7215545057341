import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { Field, Form, Formik } from 'formik';
import { ModalClose, ModalOverlay } from '../../base/ModalBase/ModalBase.v2';
import { Rating } from '../../components/Rating';
import { format } from 'date-fns';
import { object, string } from 'yup';
import { useEffect, useState } from 'react';
import { useRatingsById } from 'hooks/useRatings';
import classes from './maintenance-history.module.css';
import {
  MaintenanceAppointmentRatingDto,
  MaintenanceHistoryItemDto,
} from '../../../api/generated/caready';
import { MaintenanceAppointmentsApi } from '../../../api';
import { prettifyPhone } from '../../../utils/prettifyPhone';

const validationSchema = object().shape({
  workRate: string().required(),
  schedulingRate: string().required(),
  receptionRate: string().required(),
});

const buttons = ['1', '2', '3', '4', '5'];

const initialValues = {
  workRate: '0',
  schedulingRate: '0',
  receptionRate: '0',
};

export const MaintenanceHistoryModal = ({
  historyData,
  onCloseButtonClick,
}: {
  historyData: MaintenanceHistoryItemDto;
  onCloseButtonClick: () => void;
}) => {
  const [rated, setRated] = useState(true);
  const [error, setError] = useState('');
  const allRatings = historyData?.rating
    ? useRatingsById(historyData.rating.maintenanceAppointmentRequestId)
    : null;
  const [ratings, setRatings] = useState<
    Omit<MaintenanceAppointmentRatingDto, 'maintenanceAppointmentRequestId'>
  >({
    workRate: 0,
    schedulingRate: 0,
    receptionRate: 0,
  });

  useEffect(() => {
    setRatings({
      workRate: allRatings?.workRate ?? 0,
      schedulingRate: allRatings?.schedulingRate ?? 0,
      receptionRate: allRatings?.receptionRate ?? 0,
    });
  }, [allRatings]);

  return (
    <ModalOverlay onCloseButtonClick={onCloseButtonClick}>
      <>
        <header className={classes.header}>
          <h4 className="h4">{format(new Date(historyData.appointmentDate), 'dd.MM.yyyy')}</h4>
          <ModalClose onCloseButtonClick={onCloseButtonClick} />
        </header>
        <div className={`${classes.inner}`}>
          <div className={classes.mainInfoSection}>
            <div className={classes.mainInfos}>
              <div className={classes.mainInfo}>
                <p className="p2">Станция обслуживания:</p>
                <p className="p2">
                  {historyData.serviceStationAddress.city.name},{' '}
                  {historyData.serviceStationAddress.address}
                </p>
              </div>
              <div className={classes.mainInfo}>
                <p className="p2">Номер:</p>
                <p className="p2">
                  {historyData.serviceStationPhoneNumber
                    ? prettifyPhone(historyData.serviceStationPhoneNumber)
                    : '–'}
                </p>
              </div>
              <div className={classes.mainInfo}>
                <p className="p2">VIN:</p>
                <p className="p2">{historyData.carVin || '–'}</p>
              </div>
              {/* <div className={classes.mainInfo}>
                <p className="p2">Цена:</p>
                <h6 className="h6">
                  {historyData.totalMinPriceRub} <span className="ruble_back alt" />
                </h6>
              </div> */}
            </div>
          </div>
          <div className={classes.serviceInfoSection}>
            <div className={classes.textBox}>
              <p className="p2">Услуги</p>
              <h6 className="h6">Работы по техническому обслуживанию</h6>
            </div>
            <div className={classes.serviceCard}>
              <div className={classes.servicesBlock}>
                <div className={classes.cardHeader}>
                  <h6 className="h6">Услуга</h6>
                  {/* <h6 className="h6">Цена</h6> */}
                </div>
                <div className={classes.cardServices}>
                  {historyData.maintenanceServices.map((service, index) => (
                    <div className={classes.serviceInfo} key={index}>
                      <p className="p2">{index + 1}</p>
                      <p className="p2">{service.name}</p>
                      {/* <p className="p2">
                        {service.minPriceRub} <span className="ruble_back alt" />
                      </p> */}
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className={classes.totalPrice}>
                <h6 className="h6">Price</h6>
                <h6 className="h6">
                  {historyData.totalMinPriceRub} <span className="ruble_back alt" />
                </h6>
              </div> */}
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              if (
                values.workRate &&
                values.schedulingRate &&
                values.receptionRate &&
                historyData.rating
              ) {
                const rating = {
                  workRate: Number(values.workRate),
                  schedulingRate: Number(values.schedulingRate),
                  receptionRate: Number(values.receptionRate),
                };

                MaintenanceAppointmentsApi.maintenanceAppointmentsControllerRateMaintenanceAppointment(
                  historyData.rating.maintenanceAppointmentRequestId,
                  rating
                )
                  .then(({ data }) => {
                    setRated(true);
                    setRatings(data);
                    setError('');
                    onCloseButtonClick();
                  })
                  .catch((e: any) => {
                    setError(e.response.data.message);
                  });
                formikHelpers.setSubmitting(false);
              }
            }}
          >
            {({ values }) => (
              <Form>
                <div className={classes.ratingsSection}>
                  {(!!allRatings?.receptionRate ||
                    !!allRatings?.schedulingRate ||
                    !!allRatings?.workRate) &&
                  rated ? (
                    <>
                      <h5 className={`${classes.ratingsTitle} h5`}>Ваша оценка</h5>
                      <div className={classes.yourRatings}>
                        <div className={classes.ratingBox}>
                          <p className="p2">Наша работа</p>
                          <h4 className="h4">{ratings.workRate}</h4>
                        </div>
                        <div className={classes.ratingBox}>
                          <p className="p2">Запись на сервис</p>
                          <h4 className="h4">{ratings.schedulingRate}</h4>
                        </div>
                        <div className={classes.ratingBox}>
                          <p className="p2">Обслуживание</p>
                          <h4 className="h4">{ratings.receptionRate}</h4>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h5 className={`${classes.ratingsTitle} h5`}>Оцените наш сервис</h5>
                      <div className={classes.ratingsBlocks}>
                        <Rating label="Наша работа">
                          {buttons.map(button => (
                            <label key={button}>
                              <Field type="radio" name="workRate" value={button} />
                              <div>
                                <span className="p2">{button}</span>
                              </div>
                            </label>
                          ))}
                        </Rating>
                        <Rating label="Запись на сервис">
                          {buttons.map(button => (
                            <label key={button}>
                              <Field type="radio" name="schedulingRate" value={button} />
                              <div>
                                <span className="p2">{button}</span>
                              </div>
                            </label>
                          ))}
                        </Rating>
                        <Rating label="Обслуживание">
                          {buttons.map(button => (
                            <label key={button}>
                              <Field type="radio" name="receptionRate" value={button} />
                              <div>
                                <span className="p2">{button}</span>
                              </div>
                            </label>
                          ))}
                        </Rating>
                      </div>
                    </>
                  )}
                  {error && <p className={classes.errorText}>{error}</p>}
                  {(!!allRatings?.receptionRate ||
                    !!allRatings?.schedulingRate ||
                    !!allRatings?.workRate) &&
                  rated ? (
                    <button
                      className="btn black secondary"
                      type="button"
                      onClick={() => setRated(false)}
                    >
                      <Edit /> Изменить оценку
                    </button>
                  ) : (
                    <button
                      className="btn"
                      type="submit"
                      disabled={
                        values.workRate === '0' ||
                        values.receptionRate === '0' ||
                        values.schedulingRate === '0'
                      }
                    >
                      Подтвердить
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    </ModalOverlay>
  );
};
