import { AddNewCarForm } from './AddNewCarForm';
import { ERROR_REQUIRED, ERROR_VIN } from '../../../config/errorTexts';
import { Formik, FormikHelpers } from 'formik';
import { ModalClose, ModalOverlay } from '../../base/ModalBase/ModalBase.v2';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import classes from './add-new-car.module.scss';
import { type CarBrandDto, CarModelDto, GarageCarDto } from '../../../api/generated/caready';
import { FC } from 'react';
import { GarageApi } from '../../../api';

const validationSchema = object().shape({
  brand: object().required(ERROR_REQUIRED),
  carModel: object().required(ERROR_REQUIRED),
  generation: string(),
  bodyType: string(),
  transmissionType: string(),
  engineFuelType: string(),
  driveType: string(),
  modelModificationId: string(),
  licensePlateNumber: string().matches(
    /^[АВЕКМНОРСТУХABEKMHOPCTYXБГДЖЗИЛПФЦЧШ]\d{3}[АВЕКМНОРСТУХABEKMHOPCTYXБГДЖЗИЛПФЦЧШ]{2}\s?\d{2,3}$/g,
    'Неверный формат'
  ),
  vin: string().length(17, ERROR_VIN),
  colorHex: string(),
  photoS3Key: string(),
});

export interface AddNewCarFormValues {
  brand: CarBrandDto | null;
  carModel: CarModelDto | null;
  generation: string;
  bodyType: string;
  transmissionType: string;
  engineFuelType: string;
  driveType: string;
  modelModificationId: string;
  licensePlateNumber: string;
  vin: string;
  colorHex: string;
  photoS3Key: string;
}

interface OwnProps {
  onCloseButtonClick: () => void;
  carInfo?: GarageCarDto;
}

export const AddNewCarModal: FC<OwnProps> = function AddNewCarModal(props) {
  const navigate = useNavigate();

  const initialValues: AddNewCarFormValues = {
    brand: props.carInfo?.model.brand || null,
    carModel: props.carInfo?.model || null,
    generation: props.carInfo?.modelModification?.generation.id ?? '',
    bodyType: props.carInfo?.modelModification?.bodyTypes
      ? props.carInfo.modelModification.bodyTypes[0].id
      : '',
    transmissionType: props.carInfo?.modelModification?.transmissionType ?? '',
    engineFuelType: props.carInfo?.modelModification?.engineFuelType ?? '',
    driveType: props.carInfo?.modelModification?.driveType ?? '',
    modelModificationId: props.carInfo?.modelModification?.id ?? '',
    licensePlateNumber: props.carInfo?.licensePlateNumber ?? '',
    vin: props.carInfo?.vin ?? '',
    colorHex: props.carInfo?.colorHex ?? '',
    photoS3Key: props.carInfo?.photoS3Key ?? '',
  };

  const onSubmit = (
    values: AddNewCarFormValues,
    formikHelpers: FormikHelpers<AddNewCarFormValues>
  ) => {
    if (!values.carModel) return;

    const data = {
      modelId: values.carModel?.id,
      modelModificationId: values.modelModificationId ? values.modelModificationId : undefined,
      colorHex: !!values.colorHex ? values.colorHex : undefined,
      licensePlateNumber: !!values.licensePlateNumber ? values.licensePlateNumber : undefined,
      vin: !!values.vin ? values.vin : undefined,
      photoS3Key: !!values.photoS3Key ? values.photoS3Key : undefined,
    };

    (props.carInfo
      ? GarageApi.garageControllerControllerUpdateCarInGarage(props.carInfo.id, data)
      : GarageApi.garageControllerControllerAddCarToGarage(data)
    ).then(({ data }) => {
      formikHelpers.setSubmitting(false);
      if (!props.carInfo) {
        navigate(`/profile/my-cars/${data.id}/`);
      }
      props.onCloseButtonClick();
    });
  };
  return (
    <ModalOverlay onCloseButtonClick={props.onCloseButtonClick}>
      <>
        <header className={classes.header}>
          <h4 className="h4">{props.carInfo ? 'Редактировать машину' : 'Новая машина'}</h4>
          <ModalClose onCloseButtonClick={props.onCloseButtonClick} />
        </header>
        <div className={`${classes.inner}`}>
          <Formik<AddNewCarFormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount={true}
          >
            {({ values, touched, setFieldValue, errors, isValid }) => (
              <AddNewCarForm
                photoUrl={props.carInfo?.photoUrl}
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                isValid={isValid}
              />
            )}
          </Formik>
        </div>
      </>
    </ModalOverlay>
  );
};
