import {
  TertiaryService,
  TertiaryServiceSkeleton,
} from '../../components/ServiceCard/TertiaryService';
import commonStyles from '../../../styles/common/common-styles.module.scss';
import { SkeletonGroup } from '../../base/Skeleton/SkeletonGroup';
import { useQuery } from '@tanstack/react-query';
import { SearchApi } from '../../../api';
import { FC } from 'react';

interface OwnProps {
  query: string;
}

export const SearchServices: FC<OwnProps> = function SearchServices(props) {
  const { data, isLoading } = useQuery({
    queryKey: ['search', 'services', props.query],
    queryFn: async () => {
      const { data } = await SearchApi.searchControllerSearchMaintenanceServices(
        props.query,
        100,
        1
      );

      return data.items;
    },
  });

  return (
    <div className={commonStyles.cardsWrapper}>
      <SkeletonGroup quantity={4} isLoading={isLoading} skeleton={TertiaryServiceSkeleton}>
        {data?.map(card => (
          <TertiaryService link={`/services/${card.category.slug}`} key={card.id} service={card} />
        ))}
      </SkeletonGroup>
    </div>
  );
};
