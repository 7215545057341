import { AuthErrorEnum } from './auth-error.enum';
import { EnumAsUnion } from '../types/EnumAsUnion';

export const ERROR_REQUIRED = 'Поле является обязательным к заполнению';
export const ERROR_PHONE = 'Некорректный формат телефона';
export const ERROR_CYRILLIC = 'Допустимы только символы кириллицы';
export const ERROR_EMAIL = 'Некорректный формат e-mail';
export const ERROR_DATE = 'Некорректный формат даты';
export const ERROR_VIN = 'VIN должен содержать 17 символов';
export const ERROR_YEAR = `Укажите год с 1885 по ${new Date().getFullYear()}`;

export const AuthError = {
  user_is_not_active: 'Необходимо подтвердить почту',
  invalid_refresh_token: '',
  used_refresh_token: '',
  invalid_access_token: '',
  email_is_already_in_use: 'Пользователь с таким адресом электронной почты уже существует',
  user_not_found: 'Пользователь не найден',
  login_or_password_is_wrong: 'Неверный логин или пароль',
  something_went_wrong: 'Произошла ошибка, попробуйте позднее',
} as const satisfies Record<EnumAsUnion<typeof AuthErrorEnum>, string>;
