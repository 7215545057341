import { BREAD_CRUMB_MAIN } from '../../config/breadCrumbMain';
import { BreadCrumbs } from '../../ui/components/BreadCrumbs';
import { CoreHead } from '../../ui/core';
import { DynamicImg } from '../../ui/base/DynamicImg';
import { PartnersSection } from '../../ui/section/PartnersSection';
import { WarrantySection } from '../../ui/section/WarrantySection';
import classes from './spare-parts.module.css';

export default function SpareParts() {
  return (
    <>
      <CoreHead
        title={'Запасные части'}
        description={
          'Разработали собственный бренд автомобильных запчастей для оказания качественных и профессиональных услуг по обслуживанию автомобилей'
        }
      />
      <div className={`section ${classes.topSection}`}>
        <BreadCrumbs links={[BREAD_CRUMB_MAIN, { name: 'Запасные части и расходные материалы' }]} />
        <h1 className={`${classes.sectionTitle} h2`}>Запасные части и расходные материалы</h1>
        <div className={classes.textBlock}>
          <h2 className="h3">
            Для оказания качественных и профессиональных услуг по обслуживанию автомобилей
            <br />
            Caready разработал собственный бренд автомобильных запчастей
          </h2>
          <p className="h5">
            В сервисных станциях всегда есть в наличии часто используемые детали, расходные
            материалы и технические жидкости всех популярных и надежных производителей, в том числе
            и бренда Caready.
          </p>
          <p className="h5">
            Caready обслуживает и ремонтирует автомобили европейских, американских и азиатских
            автомобильных брендов.
          </p>
        </div>
        <div className={classes.decorationImage}>
          <DynamicImg path="spare-parts/car-brake-disc.webp" />
        </div>
      </div>
      <PartnersSection />
      <WarrantySection />
    </>
  );
}
